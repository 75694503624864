import "./ViewPDFSectionFormTable.css";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDialog from "../Modal";
import { BsFillTrashFill } from "react-icons/bs";
import { isSaveLocalPDFMapDataList } from "../../redux/Reducers/AdminFormManagementReducer";
import { editAdminFormAction } from "../../redux/Actions";
import { Toast } from "../Toast";
import { strings } from "../../utils/constants";
import NoRecordFound from "../NoRecordFound";
import AdminPagination from "../AdminPagination";

export const ViewPDFSectionFormTable = ({ resetHandler }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [formRowId, setFormRowId] = useState("");
  const [open, setOpen] = React.useState(false);
  const {
    saveLocalPDFMapDataList,
    addAdminFormData,
    editAdminFormData,
    isEditForm,
  } = useSelector((state) => state.adminFormManagementReducer);

  const recordPerPage = 10;
  const lastIndex = currentPage * recordPerPage;
  const firstIndex = lastIndex - recordPerPage;
  const records = saveLocalPDFMapDataList?.slice(firstIndex, lastIndex);
  const nPage = Math.ceil(saveLocalPDFMapDataList?.length / recordPerPage);
  const [formId] = useState(
    isEditForm ? editAdminFormData?.data?.id : addAdminFormData?.data?.id
  );

  const nextPage = () => {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changePage = (id) => {
    setCurrentPage(id);
  };

  const deleteHandler = (data) => {
    setFormRowId(data.id);
    setOpen(true);
  };

  const closeHandler = () => {
    setOpen(false);
  };

  const positiveClickHandler = async () => {
    let deleteData = saveLocalPDFMapDataList.filter(
      (e) => e.id.toString() !== formRowId.toString()
    );

    dispatch(isSaveLocalPDFMapDataList(deleteData));

    let tempRequestInfo = {
      id: formId,
      is_file: false,
      mapped_json: JSON.stringify(deleteData),
    };

    let editFormResponse = await dispatch(
      editAdminFormAction({
        language: strings.language.EN,
        requestInfo: tempRequestInfo,
        isFile: false,
        customTost: true,
      })
    );
    if (editFormResponse.payload?.success) {
      setOpen(false);
      resetHandler();
      Toast({
        type: "success",
        title: strings.words.SUCCESS,
        message: strings.general.MAPPED_RECORD_DELETE_SUCCESSFULLY,
      });
    }
  };

  return (
    <div className="vstable-wrapper">
      <table className="vstable">
        <thead>
          <tr>
            <th>{strings.general.SR_NO}</th>
            <th>{strings.general.SECTION_NAME}</th>
            <th>{strings.general.TAG_NAME}</th>
            <th className="vsexpand">{strings.general.DATA_TYPE}</th>
            <th>{strings.general.DATA_FIELD}</th>
            <th>{strings.general.FIELD_NO}</th>
            <th>{strings.words.ACTIONS}</th>
          </tr>
        </thead>
        <tbody>
          {records?.length > 0 ? (
            records.map((row, idx) => {
              return (
                <tr key={idx}>
                  <td>{(currentPage - 1) * 10 + idx + 1}</td>
                  <td>{row.sectionName}</td>
                  <td>{row.addTagToEntity}</td>
                  <td className="vsexpand">{row.entityName}</td>
                  <td>{row.dataFieldList}</td>
                  <td>{row.fieldNumber.join(", ")}</td>
                  <td className="fit">
                    <span className="vsactions mt-1">
                      <BsFillTrashFill
                        onClick={deleteHandler.bind(this, row)}
                        className="vsdelete-btn cursor-pointer"
                      />
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            <NoRecordFound colSpan={7} />
          )}
        </tbody>
      </table>
      {records?.length > 0 && (
        <AdminPagination
          currentPage={currentPage}
          totalRecords={saveLocalPDFMapDataList.length}
          nextPage={nextPage}
          prevPage={prevPage}
          changePage={changePage}
        />
      )}
      <ResponsiveDialog
        open={open}
        negativeButtonClickHandler={closeHandler}
        positiveButtonClickHandler={positiveClickHandler}
        handleClose={closeHandler}
        titleText={strings.general.ARE_YOU_SURE}
        subTitleText={strings.general.YOU_WANT_TO_DELETEMAPPING}
        negativeButtonText={strings.words.CANCEL}
        positiveButtonText={strings.words.YES}
      />
    </div>
  );
};
