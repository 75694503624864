import { createAsyncThunk } from "@reduxjs/toolkit";
import { postRequest } from "../../services/API";

const FETCH_RESET_PASSWORD_DETAILS = "resetPasswordAction";

export const resetPasswordAction = createAsyncThunk(
  FETCH_RESET_PASSWORD_DETAILS,
  async ({ language, isAdmin, requestInfo }, { dispatch, rejectWithValue }) => {
    try {
      let resetPasswordResult = await postRequest(
        language,
        isAdmin ? "adminResetPassword" : "resetPassword",
        requestInfo,
        isAdmin
      );
      return resetPasswordResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);
