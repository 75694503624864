import { useNavigate } from "react-router-dom";
import * as React from "react";
import { UserTable } from "../../component/UserTable";
import AddIcon from "@mui/icons-material/Add";
import { Button, Container, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  isFromEdit,
  isFromEditData,
} from "../../redux/Reducers/AdminMGAUserReducer";
import { strings, paths } from "../../utils/constants";

export default function UserPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addHandler = () => {
    dispatch(isFromEdit(false));
    dispatch(isFromEditData({}));
    navigate(paths.adminRoutes.ADD_EDIT_USER);
  };
  return (
    <>
      <Container style={{ width: "80%", marginRight: "4%" }}>
        <Stack direction="column" alignItems="end">
          <Button
            variant="contained"
            sx={{ color: "#184437" }}
            className="uabutton"
            startIcon={<AddIcon />}
            onClick={addHandler}
          >
            {strings.general.ADD_NEW_USER}
          </Button>
        </Stack>
      </Container>
      <UserTable />
    </>
  );
}
