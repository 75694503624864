import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { strings } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { isEmpty, newEmailValidation } from "../../services/validation";
import { Toast } from "../Toast";
import { updateMgaUserAction } from "../../redux/Actions";
import { useCookies } from "react-cookie";

export default function EmailDialog({ isOpen, clickHandler }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.generalReducer);
  const [cookies, setCookie] = useCookies(["access_token", "refresh_token"]);

  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
  };
  const firstNameChangeHandler = (e) => {
    setFirstName(e.target.value);
  };
  const lastNameChangeHandler = (e) => {
    setLastName(e.target.value);
  };

  const submitHandler = async () => {
    if (isEmpty(firstName)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.FIRSTNAME_REQUIRED_ERROR),
      });
    }else if (isEmpty(lastName)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.LASTNAME_REQUIRED_ERROR),
      });
    } else if (isEmpty(email)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.EMAIL_REQUIRED_ERROR),
      });
    } else if (!newEmailValidation(email)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.EMAIL_VALIDATION_ERROR),
      });
    } else {
      setIsLoading(true);
      const response = await dispatch(
        updateMgaUserAction({
          language: language,
          email: email,
          firstname: firstName,
          lastname: lastName,
        })
      );
      if (response.payload?.success) {
        const accessToken = response.payload.data.accessToken;
        const refershToken = response.payload.data.refershToken;
        localStorage.setItem("token", accessToken);
        setCookie("access_token", accessToken, {
          path: "/",
        });
        setCookie("refresh_token", refershToken, {
          path: "/",
        });
        Toast({
          type: "success",
          title: strings.words.SUCCESS,
          message: t(strings.translate.EMAIL_SENT_FOR_PASS),
        });
        clickHandler();
      }
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      keepMounted
      fullWidth={!isLoading}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        {isLoading ? (
          <div className="modal-body px-5">
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <CircularProgress color={"success"} />
            </div>
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{ fontStyle: { color: "#184437" } }}
            >
              {t(strings.translate.PLEASE_WAIT)}
            </DialogContentText>
          </div>
        ) : (
          <div className="modal-body px-5">
            <h1 className="font-30 font-lato_bold fg-primary mb-4">
              {t(strings.translate.NEW_TO_FORMDEPOT)}
            </h1>
            <p>{t(strings.translate.NEW_TO_FORMDEPOT_MSG)}</p>
            <input
              type="text"
              autoFocus
              className="form-control"
              placeholder={`${t(strings.translate.FIRST_NAME)}*`}
              value={firstName}
              onChange={firstNameChangeHandler}
            />
            <input
              type="text"
              autoFocus
              className="form-control mt-3"
              placeholder={`${t(strings.translate.LAST_NAME)}*`}
              value={lastName}
              onChange={lastNameChangeHandler}
            />
            <input
              type="email"
              autoFocus
              className="form-control mt-3"
              placeholder={`${t(strings.words.EMAIL)}*`}
              value={email}
              onChange={emailChangeHandler}
            />
            <button
              className="btn btn--primary text-uppercase mt-3"
              onClick={submitHandler}
            >
              {t(strings.words.SUBMIT)}
            </button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
