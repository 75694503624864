import { NotificationManager } from "react-notifications";
import { toast } from "react-toastify";
export const Toast = ({ type, title, message }) => {
  const customId = "custom-id-yes";
  switch (type) {
    case "info":
      return NotificationManager.info(message, title, 3000);
    case "success":
      return toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        toastId: customId,
      });
    case "warning":
      return NotificationManager.warning(message, title, 3000);
    case "error":
      return toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        toastId: customId,
        theme: "colored",
      });
    default:
      return;
  }
};
