import { createAsyncThunk } from "@reduxjs/toolkit";
import { postRequest } from "../../services/API";

const USER_CONTACT_US_DETAILS = "userContactUsAction";

export const userContactUsAction = createAsyncThunk(
  USER_CONTACT_US_DETAILS,
  async ({language, requestInfo}, { dispatch, rejectWithValue }) => {
    try {

      let userContactUsResponse = await postRequest(
        language,
        "userContactUs",
        requestInfo,
        false
      );
      
      return userContactUsResponse;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);
