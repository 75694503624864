import { createSlice } from "@reduxjs/toolkit";
import { fetchAllForms, fetchCarriers } from "../Actions";

const FORM_MANGMENT_SLICE_NAME = "formMangmentslice";

const formInitialState = {
  carrier: [],
  allForms: [],
  isLoading: false,
  formList: [],
};

const formMangmentslice = createSlice({
  name: FORM_MANGMENT_SLICE_NAME,
  initialState: formInitialState,
  reducers: {
    onSortingData(state, action){
      state.formList = action.payload;
      return state;
    }
  },
  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(fetchCarriers.pending, (state) => {
        state.isLoading = true;
        state.carrier = {};
        state.allForms= [];
        return state;
      })
      .addCase(
        fetchCarriers.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.carrier = action.payload;
          state.allForms= [];
          return state;
        }
      )
      .addCase(
        fetchCarriers.rejected,
        (state) => {
          state.isLoading = false;
          state.allForms= [];
        }
      )

    //fetch all forms

      .addCase(fetchAllForms.pending, (state) => {
        state.isLoading = true;
        state.allForms = {};
        return state;
      })
      .addCase(
        fetchAllForms.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.allForms = action.payload;
          state.formList = action.payload.data.data;
          return state;
        }
      )
      .addCase(
        fetchAllForms.rejected,
        (state) => {
          state.isLoading = false;
        }
      );
  },
});

export const {onSortingData} = formMangmentslice.actions;
export const formMangmentReducer = formMangmentslice.reducer;
