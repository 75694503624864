import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequestWithParams } from "../../services/API";

const FETCH_ADMIN_FORM_SECTION_DETAILS = "fetchAdminFormSectionAction";

export const fetchAdminFormSectionAction = createAsyncThunk(
  FETCH_ADMIN_FORM_SECTION_DETAILS,
  async ({ language, groupTypeId }, { dispatch, rejectWithValue }) => {
    try {
      let fetchAdminFormSectionResult = await getRequestWithParams(
        language,
        "pdfMasterField",
        groupTypeId,
        true
      );
      return fetchAdminFormSectionResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);
