import { createAsyncThunk } from "@reduxjs/toolkit";
import { postRequest } from "../../services/API";

const FETCH_LOGIN_DETAILS = "loginAction";

export const loginAction = createAsyncThunk(
  FETCH_LOGIN_DETAILS,
  async (
    { language, isAdmin, email, password, customTost = false },
    { dispatch, rejectWithValue }
  ) => {
    try {
      let userInfo = {
        username: email,
        password: password,
      };
      let loginResult = await postRequest(
        language,
        isAdmin ? "adminLogin" : "login",
        userInfo,
        isAdmin,
        customTost
      );
      return loginResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);
