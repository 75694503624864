import { createSlice } from "@reduxjs/toolkit";
import { resetPasswordAction } from "../Actions";

const RESET_PASSWORD_SLICE_NAME = "resetPasswordDetails";

const resetPasswordInitialState = {
  resetPassword: {},
  isResetPasswordLoading: false,
  successID: '#exampleModal'
};

const resetPasswordSlice = createSlice({
  name: RESET_PASSWORD_SLICE_NAME,
  initialState: resetPasswordInitialState,
  reducers: {
    onSuccess(state, action){
      state.successID = action.payload;
      return state;
    }
  },
  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(resetPasswordAction.pending, (state) => {
        state.isLoading = true;
        state.resetPassword = {};
        return state;
      })
      .addCase(
        resetPasswordAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.resetPassword = action.payload;
          return state;
        }
      )
      .addCase(
        resetPasswordAction.rejected,
        (state, action) => {
          state.isLoading = false;
          state.resetPassword = action.payload;
        }
      );
  },
});

export const {onSuccess} = resetPasswordSlice.actions;
export const resetPasswordReducer = resetPasswordSlice.reducer;
