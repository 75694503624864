import { createAsyncThunk } from "@reduxjs/toolkit";
import { postRequest } from "../../services/API";

const FETCH_FORGOT_PASSWORD_DETAILS = "forgotPasswordAction";

export const forgotPasswordAction = createAsyncThunk(
  FETCH_FORGOT_PASSWORD_DETAILS,
  async ({ language, isAdmin, email }, { dispatch, rejectWithValue }) => {
    try {
      let emailInfo = {
        email: email,
      };
      let forgotPasswordResult = await postRequest(
        language,
        isAdmin ? "adminForgotPassword" : "forgotPassword",
        emailInfo,
        isAdmin
      );
      return forgotPasswordResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);
