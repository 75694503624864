import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { persistedReducer } from "./redux/Store";
import { thunk } from "redux-thunk";
import "react-notifications/lib/notifications.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-quill/dist/quill.snow.css";
import "./i18";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import persistStore from "redux-persist/es/persistStore";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { registerLicense } from "@syncfusion/ej2-base";

// Registering Syncfusion license key
const registerLicenseKey = process.env.REACT_APP_REGISTER_LICENSE_KEY;

registerLicense(registerLicenseKey);

const root = ReactDOM.createRoot(document.getElementById("root"));
const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/* <React.StrictMode> */}
      <HelmetProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
      {/* <NotificationContainer /> */}
      <ToastContainer />
      {/* </React.StrictMode> */}
    </PersistGate>
  </Provider>
);

reportWebVitals();
