import { createSlice } from "@reduxjs/toolkit";
import { emailsVerify, profileDetails } from "../Actions";

const USER_PROFILE_SLICE_NAME = "userProfileSlice";

const userProfileInitialState = {
  profile: [],
  emails: [],
  isLoading: false,
};

const userProfileSlice = createSlice({
  name: USER_PROFILE_SLICE_NAME,
  initialState: userProfileInitialState,
  reducers: {},
  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(profileDetails.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(profileDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.profile = action.payload;
        return state;
      })
      .addCase(profileDetails.rejected, (state) => {
        state.isLoading = false;
        state.profile = {};
      })
      // For verify emails
      .addCase(emailsVerify.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(emailsVerify.fulfilled, (state, action) => {
        state.isLoading = false;
        state.emails = action.payload;
        return state;
      })
      .addCase(emailsVerify.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {} = userProfileSlice.actions;
export const userProfileReducer = userProfileSlice.reducer;
