import React from "react";
import { useTranslation } from "react-i18next";
import { strings } from "../../../utils/constants";

const PresentationsCard = () => {
  const { t } = useTranslation();
  const presentationsdata = [
    {
      p_title: "Demo - 1",
      p_url: "../../../assets/images/demo-1.png",
      p_link:
        "https://docs.google.com/presentation/d/10XSz-txei_lcJZ2joil1o2nEdP0oJ8zatofNC3uktgg/edit?usp=sharing",
      p_desc: `Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy.`,
    },
    {
      p_title: "Demo - 2",
      p_url: "../../../assets/images/demo-2.png",
      p_link:
        "https://docs.google.com/presentation/d/10XSz-txei_lcJZ2joil1o2nEdP0oJ8zatofNC3uktgg/edit?usp=sharing",
      p_desc: `Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy.`,
    },
    {
      p_title: "Demo - 3",
      p_url: "../../../assets/images/demo-3.png",
      p_link:
        "https://docs.google.com/presentation/d/10XSz-txei_lcJZ2joil1o2nEdP0oJ8zatofNC3uktgg/edit?usp=sharing",
      p_desc: `Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy.`,
    },
  ];
  return (
    <div className="row mb-md-5 presentations-list">
      <h2 className="font-lato_bold font-24 mb-4">
        {t(strings.words.PRESENTATIONS)}
      </h2>
      {presentationsdata.map((item, index) => (
        <div key={`${item.p_title}` + index} className="col-lg-4">
          <img
            src={item.p_url}
            alt=""
            className="img-fluid cursor-pointer"
            width="424"
            height="240"
            onClick={() => {
              window.open(item.p_link, "_blank");
            }}
          />
          <h3 className="font-18 font-lato_bold mt-3 mb-2">{item.p_title}</h3>
          <p className="line-height-2">{item.p_desc}</p>
        </div>
      ))}
    </div>
  );
};

export default PresentationsCard;
