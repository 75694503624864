import { createSlice } from "@reduxjs/toolkit";
import { profileDetails, updateProfile } from "../Actions";

const ADMIN_PROFILE_SLICE_NAME = "adminProfilerDetails";

const adminProfileInitialState = {
  profileData: {},
  updateProfileData: {},
  isProfileDataLoading: false,
};

const adminProfileSlice = createSlice({
  name: ADMIN_PROFILE_SLICE_NAME,
  initialState: adminProfileInitialState,
  reducers: {},
  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(profileDetails.pending, (state) => {
        state.isProfileDataLoading = true;
        return state;
      })
      .addCase(profileDetails.fulfilled, (state, action) => {
        state.isProfileDataLoading = false;
        state.profileData = action.payload;
        return state;
      })
      .addCase(profileDetails.rejected, (state, action) => {
        state.isProfileDataLoading = false;
        state.profileData = action.payload;
      })
      .addCase(updateProfile.pending, (state) => {
        state.isProfileDataLoading = true;
        state.updateProfileData = {};
        return state;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.isProfileDataLoading = false;
        state.updateProfileData = action.payload;
        return state;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.isProfileDataLoading = false;
        state.updateProfileData = action.payload;
      });
  },
});

export const {} = adminProfileSlice.actions;
export const adminProfileReducer = adminProfileSlice.reducer;
