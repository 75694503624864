import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link, Stack, IconButton, InputAdornment, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Iconify from "../Iconify";
import { isEmpty } from "../../services/validation";
import { Toast } from "../Toast";
import { loginAction } from "../../redux/Actions";
import { useCookies } from "react-cookie";
import { getToken } from "../../services/API";
import ForgotPasswordPage from "../ForgotPassword";
import ProgressDialog from "../ProgressDialog";
import { strings, paths } from "../../utils/constants";

const CssTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== "focusColor",
})((p) => ({
  "& label.Mui-focused": {
    color: p.focusColor,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: p.focusColor,
  },
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: p.focusColor,
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: p.focusColor,
    },
  },
}));

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([
    "admin_access_token",
    "admin_refresh_token",
  ]);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const isAdmin = true;
  const loginHandler = async (e) => {
    e.preventDefault();
    if (isEmpty(email) && isEmpty(password)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.USERNAME_AND_PASSWORD_REQUIRED,
      });
    } else if (isEmpty(email)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.USERNAME_REQUIRED,
      });
    } else if (isEmpty(password)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.PASSWORD_REQUIRED,
      });
    } else {
      setIsLoading(true);
      let response = await dispatch(
        loginAction({
          language: strings.language.EN,
          isAdmin,
          email,
          password,
          customTost: true,
        })
      );
      if (response.payload?.success) {
        localStorage.setItem("adminToken", response.payload.data.accessToken);
        setCookie("admin_access_token", response.payload.data.accessToken, {
          path: paths.adminRoutes.ADMIN,
        });
        setCookie("admin_refresh_token", response.payload.data.refershToken, {
          path: paths.adminRoutes.ADMIN,
        });
        await getToken(true);
        navigate(paths.adminRoutes.USERS, { replace: true });
        window.location.reload(false);
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <CssTextField
          name="username"
          label={strings.words.USERNAME}
          autoFocus
          sx={{ color: "#184437" }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          focusColor="#184437"
        />
        <CssTextField
          name="password"
          label={strings.words.PASSWORD}
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color={"#184437"}
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    color="#184437"
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          focusColor="#184437"
        />
      </Stack>
      <Stack
        direction="column"
        alignItems="end"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <Link
          variant="subtitle2"
          underline="hover"
          style={{ color: "#184437", cursor: "pointer" }}
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          id="forgotPass"
        >
          {`${strings.general.FORGOT_PASSWORD} ?`}
        </Link>
      </Stack>
      <Button
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        Disabled={isLoading}
        onClick={loginHandler}
        style={{ backgroundColor: "#184437", outlineColor: "#184437" }}
      >
        {strings.words.LOGIN}
      </Button>
      <ForgotPasswordPage isAdmin={isAdmin} />
      {isLoading && <ProgressDialog open={isLoading} isAdmin={isAdmin} />}
    </>
  );
}
