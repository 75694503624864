import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "../../services/API";

const FETCH_COUNTRY_DETAILS = "fetchCountryAction";

export const fetchCountryAction = createAsyncThunk(
  FETCH_COUNTRY_DETAILS,
  async (language, { dispatch, rejectWithValue }) => {
    try {
      let fetchCountryResult = await getRequest(language, "country", true);
      return fetchCountryResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);
