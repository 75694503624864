import { createSlice } from "@reduxjs/toolkit";
import { changeClientEmails, getClientEmails } from "../Actions";

const CLIENT_EMAILS_SLICE_NAME = "clientEmailsSlice";

const clientEmailsSliceInitialState = {
  clientEmail: [],
  tempEmail: [],
  updatedEmails: [],
  isLoading: false,
};

const clientEmailsSlice = createSlice({
  name: CLIENT_EMAILS_SLICE_NAME,
  initialState: clientEmailsSliceInitialState,
  reducers: {
    getEmails(state, action){
      state.clientEmail = action.payload;
      return state;
    }
  },
  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(getClientEmails.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(
        getClientEmails.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.clientEmail = action?.payload?.data;
          return state;
        }
      )
      .addCase(
        getClientEmails.rejected,
        (state, action) => {
          state.isLoading = false;
          state.clientEmail = action.payload;
        }
      )

      .addCase(changeClientEmails.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(
        changeClientEmails.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.updatedEmails = action?.payload;
          return state;
        }
      )
      .addCase(
        changeClientEmails.rejected,
        (state, action) => {
          state.isLoading = false;
          state.updatedEmails = action.payload;
        }
      );
  },
});

export const {getEmails} = clientEmailsSlice.actions;
export const clientEmailsReducer = clientEmailsSlice.reducer;
