import { createSlice } from "@reduxjs/toolkit";
import { loginAction } from "../Actions"; 

const LOGIN_SLICE_NAME = "loginDetails";

const loginInitialState = {
  login: {},
  isLoading: false,
};

const loginSlice = createSlice({
  name: LOGIN_SLICE_NAME,
  initialState: loginInitialState,
  reducers: {},
  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(loginAction.pending, (state) => {
        state.isLoading = true;
        state.login = {};
        return state;
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.login = action.payload;
        return state;
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.isLoading = false;
        state.login = action.payload;
      });
  },
});

export const {} = loginSlice.actions;
export const loginReducer = loginSlice.reducer;
