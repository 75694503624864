import { createAsyncThunk } from "@reduxjs/toolkit";
import { patchRequest } from "../../services/API";

const CHANGE_PASSWORD_DETAILS = "changePasswordAction";

export const changePasswordAction = createAsyncThunk(
  CHANGE_PASSWORD_DETAILS,
  async (
    { language, isAdmin, newPass, confirmPass, oldPass },
    { dispatch, rejectWithValue }
  ) => {
    try {
      let confirmRequest = {
        old_password: oldPass,
        password: newPass,
        confirm_password: confirmPass,
      };
      let passwordResult = await patchRequest(
        language,
        isAdmin ? "adminChangePassword" : "changepassword",
        confirmRequest,
        isAdmin
      );
      return passwordResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);
