import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteRequest,
  getRequestWithParams,
  patchRequest,
  postRequest,
} from "../../services/API";

const FETCH_ADMIN_MGA_DETAILS = "fetchAdminMgaAction";
const EDIT_ADMIN_MGA_DETAILS = "editAdminMgaAction";
const ADD_ADMIN_MGA_DETAILS = "addAdminMgaAction";
const DELETE_ADMIN_MGA_DETAILS = "deleteAdminMgaAction";

export const fetchAdminMgaAction = createAsyncThunk(
  FETCH_ADMIN_MGA_DETAILS,
  async (requestInfo) => {
    let requestUrl =
      "?page=" + requestInfo.page + "&limit=" + requestInfo.limit;
    try {
      let fetchMgaResult = await getRequestWithParams(
        requestInfo.language,
        "mga",
        requestUrl,
        true
      );
      return fetchMgaResult;
    } catch (error) {
      return JSON.parse(error.message);
    }
  }
);

export const editAdminMgaAction = createAsyncThunk(
  EDIT_ADMIN_MGA_DETAILS,
  async ({ language, requestInfo }, { dispatch, rejectWithValue }) => {
    try {
      let editAdminMgaResult = await patchRequest(
        language,
        "mga",
        requestInfo,
        true
      );
      return editAdminMgaResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);

export const addAdminMgaAction = createAsyncThunk(
  ADD_ADMIN_MGA_DETAILS,
  async ({ language, requestInfo }, { dispatch, rejectWithValue }) => {
    try {
      let addAdminMgaResult = await postRequest(
        language,
        "mga",
        requestInfo,
        true
      );
      return addAdminMgaResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);

export const deleteAdminMgaAction = createAsyncThunk(
  DELETE_ADMIN_MGA_DETAILS,
  async ({ language, mgaId }, { dispatch, rejectWithValue }) => {
    try {
      let deleteAdminMgaResult = await deleteRequest(
        language,
        "mga",
        mgaId,
        true
      );
      return deleteAdminMgaResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);
