import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../../component/Header";
import ProgressDialog from "../../../component/ProgressDialog";
import { Toast } from "../../../component/Toast";
import {
  changeClientEmails,
  getActiveSubscriptionPlan,
  getClientEmails,
  changePasswordAction,
  profileDetails,
  updateProfile,
  cancelAutoRenewal,
  getPaymentMethod,
} from "../../../redux/Actions";
import { getEmails } from "../../../redux/Reducers/ClientEmailsReducer";
import { changeActiveHeader } from "../../../redux/Reducers/GeneralReducer";
import {
  isEmpty,
  newEmailValidation,
  newPasswordValidation,
  convertToDate,
} from "../../../services/validation";
import { strings, paths } from "../../../utils/constants";
import { onFreeTrial } from "../../../redux/Reducers/UserSubscriptionReducer";
import ModifyPayment from "../../../component/ModifyPayment";

const MyProfile = () => {
  const blankTemp = {
    id: "",
    subscribe_user_id: "",
    email_id: "",
    key_type: null,
    key: "",
    is_key_used: false,
    created_at: "",
    updated_at: "",
  };
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { profile } = useSelector((state) => state.userProfileReducer);
  const { language } = useSelector((state) => state?.generalReducer);
  const { activePlanDetails, paymentMethodlist } = useSelector(
    (state) => state.subscriptionReducer
  );

  const activePlanData = activePlanDetails?.data ? activePlanDetails.data : {};

  let currPlan = activePlanData?.subscription
    ? activePlanData.subscription
    : {};

  const currPlanFeatureContent = currPlan?.feature_content
    ? JSON.parse(currPlan.feature_content)
    : [];
  const [showPassword, setShowPassword] = useState(false);
  const [showModifyPayment, setShowModifyPayment] = useState(false);
  const [name, setName] = useState(false);
  const [userName, setUserName] = useState(
    profile?.data?.firstname ? profile.data.firstname : ""
  );
  const [currPass, setCurrPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setNewConfirmPass] = useState("");
  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [navTabIndex, setNavTabIndex] = useState(0);

  const getProfile = async () => {
    await dispatch(changeActiveHeader(""));
    await dispatch(profileDetails({ language: language, isAdmin: false }));
  };

  const getPaymentmethod = async () => {
    await dispatch(getPaymentMethod({ language: language, isAdmin: false }));
  };

  const activePlan = async () => {
    const activePlan = await dispatch(
      getActiveSubscriptionPlan({ language: language })
    );
    if (activePlan.payload?.success) {
      if (!activePlan.payload.data?.isFreeUsed) {
        dispatch(onFreeTrial(true));
      } else {
        dispatch(onFreeTrial(false));
      }
    } else {
      dispatch(onFreeTrial(true));
    }
  };

  const getClients = async () => {
    if (currPlan.type === strings.subscriptionsTypes.BUSINESS) {
      let clientEmailsResponse = await dispatch(
        getClientEmails({ language: language })
      );
      if (clientEmailsResponse?.payload?.success) {
        let newObj = [...clientEmailsResponse.payload.data, blankTemp];
        if (
          clientEmailsResponse.payload.data.length <=
          currPlan.client_limit - 1
        ) {
          setEmails(newObj);
        } else {
          setEmails(clientEmailsResponse.payload.data);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    getProfile();
    activePlan();
    getClients();
    getPaymentmethod();
  }, [navTabIndex]);

  const changePasswordHandler = async (e) => {
    e.preventDefault();
    if (isEmpty(currPass)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.CURRENT_PASSWORD_ERROR),
      });
    } else if (isEmpty(newPass)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.NEW_PASSWORD_REQUIRED_ERROR),
      });
    } else if (!newPasswordValidation(newPass)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.PASSWORD_VALIDATION_ERROR),
      });
    } else if (isEmpty(confirmPass)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.CONFIRM_PASSWORD_REQUIRED_ERROR),
      });
    } else if (newPass !== confirmPass) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.SAME_PASSWORD_VALIDATION_ERROR),
      });
    } else if (newPass === currPass) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.SAME_CURR_NEW_PASSWORD_VALIDATION_ERROR),
      });
    } else {
      let response = await dispatch(
        changePasswordAction({
          language: language,
          isAdmin: false,
          newPass,
          confirmPass,
          oldPass: currPass,
        })
      );
      if (response?.payload?.success) {
        setCurrPass("");
        setNewPass("");
        setNewConfirmPass("");
        setShowPassword(false);
      }
    }
  };

  const nameChangeHandler = async (e) => {
    e.preventDefault();
    if (isEmpty(userName)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.NAME_REQUIRED_ERROR),
      });
    } else {
      let userInfo = {
        username: userName,
      };
      let updateResponse = await dispatch(
        updateProfile({ isAdmin: false, userInfo })
      );
      if (updateResponse?.payload?.success) {
        getProfile();
        setName(false);
      }
    }
  };

  const removeHandler = (ind) => {
    let response = emails?.filter((cur, index) => {
      return index !== ind;
    });
    dispatch(getEmails(response));
    let newObj = [...response, blankTemp];
    if (response.length <= currPlan.client_limit - 1) {
      setEmails(newObj);
    } else {
      setEmails(response);
    }
  };

  const addHandler = () => {
    if (emails.length <= currPlan.client_limit - 1) {
      let newObj = [...emails, blankTemp];
      dispatch(getEmails(newObj));
      setEmails(newObj);
    } else {
      Toast({
        type: "error",
        message: t(strings.error.CAN_NOT_ADD_MORE_EMAILS_ERROR),
      });
    }
  };

  const onUpdate = async (requestObj) => {
    setIsLoading(true);
    const response = await dispatch(
      changeClientEmails({ language: language, client_email: requestObj })
    );
    if (response?.payload?.success) {
      getClients();
    } else {
      setIsLoading(false);
    }
  };

  const submitHandler = async () => {
    dispatch(getEmails(emails));
    let emailResponse = emails.map((item) => {
      return item.email_id;
    });
    const removeDuplicate = emailResponse.reduce((acc, value) => {
      if (!acc.includes(value)) {
        acc.push(value);
      }
      return acc;
    }, []);
    const finalEmail = removeDuplicate.filter((item) => {
      return item !== "" && item !== undefined;
    });
    let requestObj = {
      client_email: finalEmail,
    };

    if (currPlan.type === strings.subscriptionsTypes.BUSINESS) {
      if (finalEmail.length < 1) {
        Toast({
          type: "error",
          message: t(strings.error.EMAIL_REQUIRED_ERROR),
        });
      } else if (!newEmailValidation(finalEmail[0])) {
        Toast({
          type: "error",
          message: t(strings.error.EMAIL_VALIDATION_ERROR),
        });
      } else if (finalEmail[1]) {
        if (!newEmailValidation(finalEmail[1])) {
          Toast({
            type: "error",
            message: t(strings.error.EMAIL_VALIDATION_ERROR),
          });
        } else if (finalEmail[2]) {
          if (!newEmailValidation(finalEmail[2])) {
            Toast({
              type: "error",
              message: t(strings.error.EMAIL_VALIDATION_ERROR),
            });
          } else if (finalEmail[3]) {
            if (!newEmailValidation(finalEmail[3])) {
              Toast({
                type: "error",
                message: t(strings.error.EMAIL_VALIDATION_ERROR),
              });
            } else if (finalEmail[4]) {
              if (!newEmailValidation(finalEmail[4])) {
                Toast({
                  type: "error",
                  message: t(strings.error.EMAIL_VALIDATION_ERROR),
                });
              } else {
                onUpdate(requestObj);
              }
            } else {
              onUpdate(requestObj);
            }
          } else {
            onUpdate(requestObj);
          }
        } else {
          onUpdate(requestObj);
        }
      } else {
        onUpdate(requestObj);
      }
    }
  };

  const handleChange = (e, ind) => {
    let addTxt = JSON.parse(JSON.stringify(emails)).filter((item, index) => {
      if (ind === index) {
        item.email_id = e.target.value;
      }
      return item;
    });
    setEmails(addTxt);
  };

  const personalDetailsHandler = () => {
    setNavTabIndex(0);
    setShowPassword(false);
    setShowModifyPayment(false);
    setName(false);
    getProfile();
  };
  const subscriptionsTabClickHandler = () => {
    setCurrPass("");
    setNewPass("");
    setNewConfirmPass("");
    setNavTabIndex(1);
  };
  const updateTabClickHandler = () => {
    setCurrPass("");
    setNewPass("");
    setNewConfirmPass("");
    setNavTabIndex(2);
  };
  const currPasswordChangeHandler = (e) => setCurrPass(e.target.value);
  const newPasswordChangeHandler = (e) => setNewPass(e.target.value);
  const newConfPasswordChangeHandler = (e) => setNewConfirmPass(e.target.value);
  const cancelPasswordChangeHandler = () => {
    setCurrPass("");
    setNewPass("");
    setNewConfirmPass("");
    setShowPassword(false);
  };
  const nameTextChangeHandler = (e) => setUserName(e.target.value);
  const nameCancleHandler = () => {
    setName(false);
    getProfile();
  };
  const updateUserCancelHandler = () => {
    setNavTabIndex(0);
    setShowPassword(false);
    setName(false);
    getProfile();
  };
  const cancelAutoRenewalHandler = async () => {
    await dispatch(cancelAutoRenewal({ language: language }));
    getProfile();
    activePlan();
    getClients();
  };
  const showChangePasswordHandler = () => setShowPassword(true);
  const modifyPaymentHandler = () => setShowModifyPayment(true);
  const cancelModifyPaymentHandler = () => setShowModifyPayment(false);
  const updateModifyPaymentHandler = () => {
    setShowModifyPayment(false);
    getPaymentmethod();
  };
  return (
    <>
      <div>
        <Header />
      </div>
      <body>
        <ProgressDialog
          open={isLoading}
          handleClose={!isLoading}
          isAdmin={false}
        />
        <section className="subplan-banner bg--primary">
          <div className="container">
            <h1 className="font-lato_bold font-26 fg-white text-center py-5 m-0">
              {t(strings.translate.MY_ACCOUNT)}
            </h1>
          </div>
        </section>
        <section className="py-3 py-lg-5 my-account-sec billing-sec">
          <div className="container">
            <div className="row flex-column-reverse flex-lg-row">
              <div className="col-12 me-auto">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        navTabIndex === 0 ? "active" : "nav-link"
                      } `}
                      id="pills-personal-details-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-personal-details"
                      type="button"
                      role="tab"
                      aria-controls="pills-personal-details"
                      aria-selected="true"
                      onClick={personalDetailsHandler}
                    >
                      {t(strings.translate.PERSONAL_DETAILS)}
                    </button>
                  </li>
                  {activePlanData.isFreeUsed && activePlanData.subscription && (
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          navTabIndex === 1 ? "active" : "nav-link"
                        }`}
                        id="pills-subscription-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-subscription"
                        type="button"
                        role="tab"
                        aria-controls="pills-subscription"
                        aria-selected="false"
                        onClick={subscriptionsTabClickHandler}
                      >
                        {t(strings.words.SUBSCRIPTION)}
                      </button>
                    </li>
                  )}
                  {activePlanData.subscription?.type ===
                    strings.subscriptionsTypes.BUSINESS && (
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          navTabIndex === 2 ? "active" : "nav-link"
                        }`}
                        id="pills-update-user-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-update-user"
                        type="button"
                        role="tab"
                        aria-controls="pills-update-user"
                        aria-selected="false"
                        onClick={updateTabClickHandler}
                      >
                        {t(strings.translate.UPDATE_USER)}
                      </button>
                    </li>
                  )}
                </ul>
                <div className="tab-content  pt-lg-5" id="pills-tabContent">
                  <div
                    className={`tab-pane fade show ${
                      navTabIndex === 0 ? "active" : ""
                    } fg-gray-20`}
                    id="pills-personal-details"
                    role="tabpanel"
                    aria-labelledby="pills-personal-details-tab"
                  >
                    {showPassword ? (
                      <div className="pb-4 col-lg-3">
                        <p className="form-title fg-gray-30 font-lato_bold mb-4 font-18">
                          {t(strings.translate.CHANGE_PASSWORD)}
                        </p>
                        <div className="mb-4">
                          <label
                            htmlFor="newPwd"
                            className="form-label mb-2 fg-gray-30"
                          >
                            {t(strings.translate.CURRENT_PASSWORD)}
                          </label>
                          <input
                            className="form-control"
                            id="oldPwd"
                            placeholder={t(strings.translate.CURRENT_PASSWORD)}
                            value={currPass}
                            onChange={currPasswordChangeHandler}
                          />
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="newPwd"
                            className="form-label mb-2 fg-gray-30"
                          >
                            {t(strings.translate.NEW_PASSWORD)}
                          </label>
                          <input
                            className="form-control"
                            id="newPwd"
                            placeholder={t(strings.translate.NEW_PASSWORD)}
                            value={newPass}
                            onChange={newPasswordChangeHandler}
                          />
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="cnfrmPwd"
                            className="form-label mb-2 fg-gray-30"
                          >
                            {t(strings.translate.CONFIRM_PASSWORD)}
                          </label>
                          <input
                            className="form-control"
                            id="cnfrmPwd"
                            placeholder={t(strings.translate.CONFIRM_PASSWORD)}
                            value={confirmPass}
                            onChange={newConfPasswordChangeHandler}
                          />
                        </div>
                        <div className="d-flex flex-row">
                          <div
                            style={{ marginRight: "6px", marginBottom: "16px" }}
                          >
                            <button
                              onClick={changePasswordHandler}
                              className="btn btn--primary text-uppercase"
                            >
                              {t(strings.words.UPDATE)}
                            </button>
                          </div>
                          <div style={{ marginBottom: "16px" }}>
                            <button
                              className="btn btn--gray-40 text-uppercase"
                              onClick={cancelPasswordChangeHandler}
                              data-bs-target="pills-personal-details-tab"
                            >
                              {t(strings.words.CANCEL)}
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : showModifyPayment ? (
                      <ModifyPayment
                        onCancelClick={cancelModifyPaymentHandler}
                        onUpdateClick={updateModifyPaymentHandler}
                      />
                    ) : (
                      <>
                        <div className="col-xl-7 pb-4 ">
                          <div className="row">
                            <div className="col-12">
                              <p className="form-title fg-gray-30 font-lato_bold mb-2 font-18">
                                {t(strings.translate.PERSONAL_DETAILS)}
                              </p>
                            </div>
                          </div>
                          {name ? (
                            <>
                              <div className="row mb-4">
                                <div className="col-3 col-md-2 mb-md-0">
                                  <span>{t(strings.words.NAME)}</span>
                                </div>
                                <div className="col-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={strings.general.YOUR_NAME}
                                    value={userName}
                                    onChange={nameTextChangeHandler}
                                  />
                                </div>
                                <div className="column">
                                  <div className="d-flex flex-row">
                                    <div
                                      style={{
                                        marginRight: "6px",
                                        marginTop: "16px",
                                      }}
                                    >
                                      <button
                                        onClick={nameChangeHandler}
                                        className="btn btn--primary text-uppercase"
                                      >
                                        {t(strings.words.SAVE)}
                                      </button>
                                    </div>
                                    <div style={{ marginTop: "16px" }}>
                                      <button
                                        onClick={nameCancleHandler}
                                        className="btn btn--gray-40 text-uppercase"
                                      >
                                        {t(strings.words.CANCEL)}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="row mb-4">
                              <div className="col-3 col-md-2 mb-md-0">
                                <span>{t(strings.words.NAME)}</span>
                              </div>
                              <div className="col-9">
                                <span className="font-lato_bold">
                                  {profile?.data?.firstname
                                    ? profile.data.firstname +
                                      (profile?.data?.lastname
                                        ? ` ${profile.data.lastname}`
                                        : "")
                                    : profile?.data?.username
                                    ? profile.data.username
                                    : ""}
                                </span>
                              </div>
                            </div>
                          )}
                          <div className="row mb-4">
                            <div className="col-3 col-md-2">
                              <span>{t(strings.words.EMAIL)}</span>
                            </div>
                            <div className="col-9">
                              <span className="font-lato_bold">
                                {profile?.data.email}
                              </span>
                            </div>
                          </div>
                          <button
                            onClick={showChangePasswordHandler}
                            className="btn btn--primary text-uppercase"
                          >
                            {t(strings.translate.CHANGE_PASSWORD)}
                          </button>
                        </div>
                        {profile?.data?.user_address?.address_line_1 && (
                          <div className="col-xl-7 pb-4 mb-4 ">
                            <div className="row">
                              <div className="col-12">
                                <p className="form-title fg-gray-30 font-lato_bold mb-2 font-18">
                                  {t(strings.translate.BILLING_ADDRESS)}
                                </p>
                              </div>
                              <div className="col-3 col-md-2">
                                <span>{t(strings.words.ADDRESS)}</span>
                              </div>
                              <div className="col-9">
                                <span className="font-lato_bold">
                                  {profile?.data?.user_address?.address_line_1}
                                  <br />
                                  {
                                    profile?.data?.user_address?.address_line_2
                                  }{" "}
                                  {profile?.data?.user_address?.address_line_2
                                    ? "-"
                                    : ""}{" "}
                                  {profile?.data?.user_address?.city_name},{" "}
                                  {profile?.data?.user_address?.zip_code}
                                  <br />
                                  {
                                    profile?.data?.user_address?.province
                                      ?.province_name
                                  }
                                  ,{" "}
                                  {
                                    profile?.data?.user_address?.country
                                      ?.country_name
                                  }
                                </span>
                              </div>
                            </div>
                            {paymentMethodlist?.length > 0 && (
                              <button
                                onClick={modifyPaymentHandler}
                                className="btn btn--primary text-uppercase mt-3"
                              >
                                {t(strings.translate.MODIFY_PAYMENT_METHOD)}
                              </button>
                            )}
                          </div>
                        )}
                      </>
                    )}
                    <div className="pb-4 col-lg-3 d-none">
                      <p className="form-title fg-gray-30 font-lato_bold mb-4 font-18">
                        {t(strings.translate.PASSWORD_CHANGED)}
                      </p>
                      <p className="m-0 fg-gray-30">
                        {t(strings.translate.PASSWORD_CHANGED_SUCCESSFULLY)}
                      </p>
                    </div>
                  </div>
                  {activePlanData.isFreeUsed && activePlanData.subscription && (
                    <div
                      className={`tab-pane fade show ${
                        navTabIndex === 1 ? "active" : ""
                      } fg-gray-20`}
                      id="pills-subscription"
                      role="tabpanel"
                      aria-labelledby="pills-subscription-tab"
                    >
                      <div className="row">
                        <div className="col-12">
                          <p className="form-title fg-gray-30 font-lato_bold mb-2 font-18">
                            {t(strings.translate.SUBSCRIPTIONS_PLAN_DETAILS)}
                          </p>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="row">
                            <div className="col-12 border-b mb-4 pb-4">
                              <p className="form-title fg-primary font-lato_bold mb-2 font-22">
                                {language === strings.language.FR
                                  ? activePlanData.subscription?.name_fr
                                  : activePlanData.subscription?.name}
                              </p>
                              <p className="mb-3">
                                {language === strings.language.FR
                                  ? activePlanData.subscription?.description_fr
                                  : activePlanData.subscription?.description}
                              </p>{" "}
                              {activePlanData.isFreeUsed &&
                                activePlanData.subscription &&
                                activePlanData.subscription?.type !==
                                  strings.subscriptionsTypes.FREE && (
                                  <div className="d-flex flex-row mb-3">
                                    <div
                                      clasName="col-3 col-md-2"
                                      style={{ marginRight: "6px" }}
                                    >
                                      <span>
                                        {t(
                                          strings.translate.AUTO_RENEWAL_STATUS
                                        )}
                                      </span>
                                    </div>
                                    <span className="fg-gray-30 font-lato_bold">
                                      {activePlanData.auto_renew_status
                                        ? t(strings.words.ACTIVE)
                                        : t(strings.words.CANCELLED)}
                                    </span>
                                  </div>
                                )}
                              {activePlanData.end_date && (
                                <div>
                                  <div className="d-flex flex-row">
                                    <div
                                      clasName="col-3 col-md-2"
                                      style={{ marginRight: "6px" }}
                                    >
                                      <span>
                                        {t(strings.translate.EXPIRY_DATE)}
                                      </span>
                                    </div>
                                    <span className="fg-gray-30 font-lato_bold">
                                      {convertToDate(activePlanData.end_date)}
                                    </span>
                                  </div>
                                  {activePlanData.auto_renew_status && (
                                    <div className="d-flex flex-row">
                                      <div
                                        clasName="col-3 col-md-2"
                                        style={{ marginRight: "6px" }}
                                      >
                                        <span>
                                          {t(
                                            strings.translate.NEXT_PAYMENT_DATE
                                          )}
                                        </span>
                                      </div>
                                      <span className="fg-gray-30 font-lato_bold">
                                        {convertToDate(activePlanData.end_date)}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                            <div className="col-12 border-b mb-4 pb-4">
                              <div className="plan-list">
                                <ul className="m-0 p-0">
                                  {currPlanFeatureContent.map((item) => {
                                    return (
                                      <li>
                                        <div
                                          data-tooltip={
                                            language === "fr"
                                              ? item.hintTextFr
                                              : item.hintText
                                          }
                                        ></div>
                                        {language === "fr"
                                          ? item.textFr
                                          : item.text}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                            <div className="col-12">
                              <p>
                                Lorem ipsum is a placeholder text commonly used
                                to demonstrate.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="d-flex flex-column col-lg-5 gap-3">
                            <button
                              onClick={() =>
                                navigate(paths.userRoutes.SUBSCRIPRION)
                              }
                              className="btn btn--primary text-uppercase"
                            >
                              {activePlanData.subscription?.type ===
                              strings.subscriptionsTypes.FREE
                                ? t(strings.translate.SUBSCRIBE_PLAN)
                                : t(strings.translate.UPGRADE_PLAN)}
                            </button>
                            {activePlanData.auto_renew_status && (
                              <button
                                onClick={cancelAutoRenewalHandler}
                                className="btn btn--primary text-uppercase"
                              >
                                {t(strings.translate.CANCEL_AUTO_RENEWAL)}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {activePlanData.subscription?.type ===
                    strings.subscriptionsTypes.BUSINESS && (
                    <div
                      className={`tab-pane fade show ${
                        navTabIndex === 2 ? "active" : ""
                      } fg-gray-20`}
                      id="pills-update-user"
                      role="tabpanel"
                      aria-labelledby="pills-update-user-tab"
                    >
                      <div className="row">
                        <div className="col-12">
                          <p className="form-title fg-gray-30 font-lato_bold mb-2 font-18">
                            {t(strings.translate.UPDATE_BUSINESS_USER)}
                          </p>
                        </div>
                      </div>
                      {emails?.map((item, ind) =>
                        emails.length > ind && item.id ? (
                          <div className="row align-items-center mb-4">
                            <div className="col-9 col-md-3">
                              <span className="fg-gray-30 font-lato_bold">
                                {item.email_id}
                              </span>
                            </div>
                            <div className="col-3 col-md-6">
                              <button
                                onClick={removeHandler.bind(this, ind)}
                                className="btn btn--icon btn--danger text-uppercase mt-3 mt-md-0"
                                title="remove"
                              >
                                <i
                                  className="fa fa-minus"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="row align-items-end mb-4">
                            <div className="col-9 col-md-3">
                              <input
                                type="email"
                                className="form-control"
                                id="email2"
                                value={item.email_id}
                                placeholder={t(strings.words.EMAIL)}
                                onChange={(e) => handleChange(e, ind, "loop")}
                              />
                            </div>
                            <div className="col-3 col-md-6">
                              <button
                                onClick={addHandler}
                                className="btn btn--icon btn--primary text-uppercase mt-3 mt-md-0"
                                title="add"
                              >
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          </div>
                        )
                      )}
                      <div className="flex-row">
                        <button
                          className="btn btn--primary text-uppercase me-2"
                          onClick={submitHandler}
                        >
                          {t(strings.words.SUBMIT)}
                        </button>
                        <button
                          id="first_tab"
                          className="btn btn--danger text-uppercase"
                          onClick={updateUserCancelHandler}
                        >
                          {t(strings.words.CANCEL)}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </body>
    </>
  );
};

export default MyProfile;
