import "./SubScriptionTable.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsFillPencilFill } from "react-icons/bs";
import { RiShutDownLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSubscriptionPlanAction,
  fetchSubscriptionPlanAction,
} from "../../redux/Actions";
import {
  isFromEditSubscription,
  isFromEditSubscriptionData,
} from "../../redux/Reducers/AdminSubscriptionReducer";
import ResponsiveDialog from "../Modal";
import { strings, paths } from "../../utils/constants";
import NoRecordFound from "../NoRecordFound";

export const SubScriptionTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isActive, setIsActive] = useState("");
  const [planId, setPlanId] = useState("");

  const { deleteSubscriptionPlanData, subscriptionPlanData } = useSelector(
    (state) => state.adminSubscriptionPlanReducer
  );

  const tableRecords = subscriptionPlanData?.data
    ? subscriptionPlanData.data
    : [];

  const getAPI = async () => {
    await dispatch(
      fetchSubscriptionPlanAction({ language: strings.language.EN })
    );
  };

  useEffect(() => {
    getAPI();
    if (isDelete) {
      setIsDelete(false);
      setOpen(false);
    }
  }, [deleteSubscriptionPlanData]);

  const editHandler = (data) => {
    if (data.is_active) {
      dispatch(isFromEditSubscription(true));
      dispatch(isFromEditSubscriptionData(data));
      navigate(paths.adminRoutes.EDIT_SUBSCRIPRION);
    }
  };

  const deletHandler = (data) => {
    setIsActive(data.is_active ? "inactive" : "active");
    setPlanId(data.id);
    setOpen(true);
  };

  const negativeClickHandler = () => {
    setIsDelete(false);
    setOpen(false);
  };

  const positiveClickHandler = async () => {
    setIsDelete(true);
    await dispatch(
      deleteSubscriptionPlanAction({
        language: strings.language.EN,
        planId: planId,
      })
    );
  };

  const closeHandler = () => {
    setOpen(false);
  };
  return (
    <div className="stable-wrapper">
      <table className="stable">
        <thead>
          <tr>
            <th>{strings.general.SR_NO}</th>
            <th>{strings.general.PLAN_NAME}</th>
            <th>{strings.general.PLAN_NAME_FRENCH}</th>
            <th>{strings.words.DESCRIPTION}</th>
            <th>{strings.general.DESCRIPTION_IN_FRENCH}</th>
            <th className="sexpand">
              {strings.general.DURATION_IN_MONTH_DAYS}
            </th>
            <th className="sexpand">{strings.general.DURATION_IN_YEAR}</th>
            <th>{strings.general.MONTHLY_PRICE}</th>
            <th>{strings.general.YEARLY_PRICE}</th>
            <th>{strings.words.STATUS}</th>
            <th>{strings.words.ACTIONS}</th>
          </tr>
        </thead>
        <tbody>
          {tableRecords.length > 0 ? (
            tableRecords.map((row, idx) => {
              return (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td className="sexpand">{row.name}</td>
                  <td className="sexpand">{row.name_fr}</td>
                  <td className="desColumn text-wrap">{row.description}</td>
                  <td className="desColumn text-wrap">{row.description_fr}</td>
                  <td>
                    {row.type === "free"
                      ? `${row.min_duration_in_days} days`
                      : `${row.min_duration_in_days} month`}
                  </td>
                  <td>
                    {row.type === "free"
                      ? "0"
                      : `${row.min_duration_in_days} year`}
                  </td>
                  <td>{row.month_price}</td>
                  <td>{row.year_price}</td>
                  <td>
                    <span
                      className={`slabel slabel-${
                        row.is_active
                          ? strings.words.ACTIVE
                          : strings.words.INACTIVE
                      }`}
                    >
                      {row.is_active
                        ? strings.words.ACTIVE
                        : strings.words.INACTIVE}
                    </span>
                  </td>
                  <td className="fit">
                    <span className="sactions">
                      <BsFillPencilFill
                        onClick={editHandler.bind(this, row)}
                        className="cursor-pointer"
                        style={row.is_active ? {} : { color: "#DEDEDF" }}
                      />
                      {row.type === strings.subscriptionsTypes.FREE ? (
                        <RiShutDownLine />
                      ) : (
                        <RiShutDownLine
                          onClick={deletHandler.bind(this, row)}
                          className="sdelete-btn cursor-pointer"
                        />
                      )}
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            <NoRecordFound colSpan={9} />
          )}
        </tbody>
      </table>
      <br />
      <ResponsiveDialog
        open={open}
        negativeButtonClickHandler={negativeClickHandler}
        positiveButtonClickHandler={positiveClickHandler}
        handleClose={closeHandler}
        titleText={strings.general.ARE_YOU_SURE}
        subTitleText={"You want to " + isActive + " this plan ?"}
        negativeButtonText={strings.words.CANCEL}
        positiveButtonText={strings.words.YES}
      />
    </div>
  );
};
