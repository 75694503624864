import "./CarrierTable.css";
import React, { useEffect, useState } from "react";
import { BsFillPencilFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  isFromEditCarrier,
  isFromEditCarrierData,
} from "../../redux/Reducers/AdminCarrierReducer";
import { fetchAdminCarrierAction } from "../../redux/Actions";
import { strings, paths } from "../../utils/constants";
import NoRecordFound from "../NoRecordFound";
import AdminPagination from "../AdminPagination";

export default function CarrierTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const { fetchAdminCarrierData } = useSelector(
    (state) => state.adminCarrierReducer
  );
  const tableRecords = fetchAdminCarrierData?.data
    ? fetchAdminCarrierData.data
    : [];

  const recordPerPage = 10;
  const lastIndex = currentPage * recordPerPage;
  const firstIndex = lastIndex - recordPerPage;
  const records = tableRecords.slice(firstIndex, lastIndex);
  const nPage = Math.ceil(tableRecords.length / recordPerPage);

  const nextPage = () => {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changePage = (id) => {
    setCurrentPage(id);
  };

  const fetchCarrier = async () => {
    await dispatch(
      fetchAdminCarrierAction({ language: strings.language.EN, isAdmin: true })
    );
  };

  useEffect(() => {
    fetchCarrier();
  }, []);

  const handleEdit = (data) => {
    dispatch(isFromEditCarrier(true));
    dispatch(isFromEditCarrierData(data));
    navigate(paths.adminRoutes.ADD_EDIT_CARRIER);
  };

  return (
    <div className="cr-table-wrapper">
      <table className="cr-table">
        <thead>
          <tr>
            <th>{strings.general.SR_NO}</th>
            <th className="w-50">{strings.general.CARRIER_NAME_ENGLISH}</th>
            <th className="w-50">{strings.general.CARRIER_NAME_FRENCH}</th>
            <th>{strings.words.ACTIONS}</th>
          </tr>
        </thead>
        <tbody>
          {records.length > 0 ? (
            records.map((row, idx) => {
              return (
                <tr key={idx}>
                  <td>{(currentPage - 1) * 10 + idx + 1}</td>
                  <td>{row?.carrier_name}</td>
                  <td>{row?.carrier_name_fr}</td>
                  <td className="fit">
                    <span className="cr-actions">
                      <BsFillPencilFill
                        onClick={handleEdit.bind(this, row)}
                        className="cursor-pointer"
                      />
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            <NoRecordFound colSpan={3} />
          )}
        </tbody>
      </table>
      {records?.length > 0 && (
        <AdminPagination
          currentPage={currentPage}
          totalRecords={tableRecords.length}
          nextPage={nextPage}
          prevPage={prevPage}
          changePage={changePage}
        />
      )}
    </div>
  );
}
