import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { CircularProgress } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import { strings } from "../../utils/constants";
import { useTranslation } from "react-i18next";

export default function ProgressDialog(props) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={props.open}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <CircularProgress color={"success"} />
        </div>
        <DialogContentText
          id="alert-dialog-slide-description"
          sx={{ fontStyle: { color: "#184437" } }}
        >
          {props.isAdmin
            ? strings.general.PLEASE_WAIT
            : t(strings.translate.PLEASE_WAIT)}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
