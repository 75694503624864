import React from "react";
import { useTranslation } from "react-i18next";
import { strings } from "../../utils/constants";

export default function ForgotPasswordComponent({ email, setEmail, btnClickHandler }) {
  const { t } = useTranslation();
  const emailChangeHandler = (e) => setEmail(e.target.value);

  return (
    <div className="forgot-password-body">
      <h1 className="font-30 font-lato_bold fg-primary mb-4">
        {t(strings.translate.FORGOT_PASSWORD)}
      </h1>
      <p>{t(strings.translate.FORGOT_PASSWORD_MESSAGE)}</p>
      <input
        type="email"
        autoFocus
        className="form-control"
        placeholder={t(strings.translate.YOUR_EMAIL)}
        value={email}
        onChange={emailChangeHandler}
      />
      <button
        className="btn btn--primary text-uppercase mt-3"
        onClick={btnClickHandler}
      >
        {t(strings.translate.SEND_VERIFICATION_CODE)}
      </button>
    </div>
  );
}
