import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ResponsiveDialog(props) {
  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{props.titleText}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {props.subTitleText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ color: "#184437" }}
          onClick={props.negativeButtonClickHandler}
        >
          {props.negativeButtonText}
        </Button>
        <Button
          style={{ color: "#184437" }}
          onClick={props.positiveButtonClickHandler}
        >
          {props.positiveButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
