import "./PolicyTable.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BsFillPencilFill } from "react-icons/bs";
import { fetchAdminPolicyAction } from "../../redux/Actions";
import {
  isFromEditPolicy,
  isFromEditPolicyData,
} from "../../redux/Reducers/AdminPolicyReducer";
import { strings, paths } from "../../utils/constants";
import NoRecordFound from "../NoRecordFound";
import AdminPagination from "../AdminPagination";

export default function PolicyTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);

  const fetchPolicy = async () => {
    await dispatch(
      fetchAdminPolicyAction({ language: strings.language.EN, isAdmin: true })
    );
  };

  useEffect(() => {
    fetchPolicy();
  }, []);

  const { fetchAdminPolicyData } = useSelector(
    (state) => state.adminPolicyReducer
  );

  const tableRecords = fetchAdminPolicyData?.data
    ? fetchAdminPolicyData.data
    : [];

  const recordPerPage = 10;
  const lastIndex = currentPage * recordPerPage;
  const firstIndex = lastIndex - recordPerPage;
  const records = tableRecords.slice(firstIndex, lastIndex);
  const nPage = Math.ceil(tableRecords.length / recordPerPage);

  const nextPage = () => {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changePage = (id) => {
    setCurrentPage(id);
  };
  const editHandler = (data) => {
    dispatch(isFromEditPolicy(true));
    dispatch(isFromEditPolicyData(data));
    navigate(paths.adminRoutes.ASS_EDIT_POLICY);
  };

  return (
    <div className="ptable-wrapper">
      <table className="ptable">
        <thead>
          <tr>
            <th>{strings.general.SR_NO}</th>
            <th className="w-50">{strings.general.POLICY_NAME_ENGLISH}</th>
            <th className="w-50">{strings.general.POLICY_NAME_FRENCH}</th>
            <th>{strings.words.ACTIONS}</th>
          </tr>
        </thead>
        <tbody>
          {records.length > 0 ? (
            records.map((row, idx) => {
              return (
                <tr key={idx}>
                  <td>{(currentPage - 1) * 10 + idx + 1}</td>
                  <td>{row?.policy_service_name}</td>
                  <td>{row?.policy_service_name_fr}</td>
                  <td className="fit">
                    <span className="pactions">
                      <BsFillPencilFill
                        onClick={editHandler.bind(this, row)}
                        className="cursor-pointer"
                      />
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            <NoRecordFound colSpan={3} />
          )}
        </tbody>
      </table>
      {records?.length > 0 && (
        <AdminPagination
          currentPage={currentPage}
          totalRecords={tableRecords.length}
          nextPage={nextPage}
          prevPage={prevPage}
          changePage={changePage}
        />
      )}
    </div>
  );
}
