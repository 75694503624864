import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, patchRequest, postRequest } from "../../services/API";

const FETCH_SUBSCRIPTIONS_PLANS = "getSubscriptions";
const PAY_FOR_SUBSCRIPTION = "payForSubscription";
const ACTIVE_SUBSCRIPTION_PLAN = "getActiveSubscriptionPlan";
const UPGRADE_ACTIVE_SUBSCRIPTION_PLAN = "upgradeActivePlan";
const GET_AVAILABLE_CREDITS = "getAvailableCredits";
const GET_PAYMENT_METHOD = "getPaymentMethod";
const UPDATE_PAYMENT_METHOD = "updatePaymentMethod";
const CANCEL_AUTO_RENEWAL = "cancelAutoRenewal";

export const getSubscriptions = createAsyncThunk(
  FETCH_SUBSCRIPTIONS_PLANS,
  async ({ language }) => {
    try {
      let subscriptionResult = await getRequest(
        language,
        "subscription",
        false
      );
      return subscriptionResult;
    } catch (error) {
      return JSON.parse(error.message);
    }
  }
);

export const getPaymentMethod = createAsyncThunk(
  GET_PAYMENT_METHOD,
  async ({ language }) => {
    try {
      let paymentmethodResult = await getRequest(
        language,
        "paymentmethod",
        false
      );
      return paymentmethodResult;
    } catch (error) {
      return JSON.parse(error.message);
    }
  }
);

export const updatePaymentMethod = createAsyncThunk(
  UPDATE_PAYMENT_METHOD,
  async ({ language, payment }) => {
    try {
      let paymentmethodResult = await patchRequest(
        language,
        "paymentmethod",
        payment,
        false
      );
      return paymentmethodResult;
    } catch (error) {
      return JSON.parse(error.message);
    }
  }
);

export const payForSubscription = createAsyncThunk(
  PAY_FOR_SUBSCRIPTION,
  async ({ language, payment }) => {
    try {
      let paymentResult = await postRequest(
        language,
        "payment",
        payment,
        false
      );
      return paymentResult;
    } catch (error) {
      return JSON.parse(error.message);
    }
  }
);

export const getActiveSubscriptionPlan = createAsyncThunk(
  ACTIVE_SUBSCRIPTION_PLAN,
  async ({ language }) => {
    try {
      let paymentResult = await getRequest(language, "payment", false);
      return paymentResult;
    } catch (error) {
      return JSON.parse(error.message);
    }
  }
);

export const upgradeActivePlan = createAsyncThunk(
  UPGRADE_ACTIVE_SUBSCRIPTION_PLAN,
  async ({ language, payment }) => {
    try {
      let paymentResult = await patchRequest(
        language,
        "payment",
        payment,
        false
      );
      return paymentResult;
    } catch (error) {
      return JSON.parse(error.message);
    }
  }
);

export const getAvailableCredits = createAsyncThunk(
  GET_AVAILABLE_CREDITS,
  async ({ language, payment, customTost = false }) => {
    try {
      let paymentResult = await postRequest(
        language,
        "credit",
        payment,
        false,
        customTost
      );
      return paymentResult;
    } catch (error) {
      return JSON.parse(error.message);
    }
  }
);

export const cancelAutoRenewal = createAsyncThunk(
  CANCEL_AUTO_RENEWAL,
  async ({ language }) => {
    try {
      let cancelAutoRenewalResult = await patchRequest(
        language,
        "renewalCancel",
        {},
        false
      );
      return cancelAutoRenewalResult;
    } catch (error) {
      return JSON.parse(error.message);
    }
  }
);
