import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, patchRequest, postRequest } from "../../services/API";

const FETCH_ADMIN_CARRIER_DETAILS = "fetchAdminCarrierAction";
const EDIT_ADMIN_CARRIER_DETAILS = "editAdminCarrierAction";
const ADD_ADMIN_CARRIER_DETAILS = "addAdminCarrierAction";

export const fetchAdminCarrierAction = createAsyncThunk(
  FETCH_ADMIN_CARRIER_DETAILS,
  async ({ language, isAdmin }) => {
    try {
      let fetchCarrierResult = await getRequest(language, "carrier", isAdmin);
      return fetchCarrierResult;
    } catch (error) {
      return JSON.parse(error.message);
    }
  }
);

export const editAdminCarrierAction = createAsyncThunk(
  EDIT_ADMIN_CARRIER_DETAILS,
  async ({ language, requestInfo }, { dispatch, rejectWithValue }) => {
    try {
      let editAdminCarrierResult = await patchRequest(
        language,
        "carrier",
        requestInfo,
        true
      );
      return editAdminCarrierResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);

export const addAdminCarrierAction = createAsyncThunk(
  ADD_ADMIN_CARRIER_DETAILS,
  async ({ language, requestInfo }, { dispatch, rejectWithValue }) => {
    try {
      let addAdminCarrierResult = await postRequest(
        language,
        "carrier",
        requestInfo,
        true
      );
      return addAdminCarrierResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);
