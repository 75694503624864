import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../../component/Header";
import { fetchAllForms, fetchCarriers } from "../../../redux/Actions";
import { changeActiveHeader } from "../../../redux/Reducers/GeneralReducer";
import { isEmpty } from "../../../services/validation";
import { usePagination } from "../../../hooks/usePagination";
import { strings, paths } from "../../../utils/constants";
import { Toast } from "../../../component/Toast";

const SearchForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { carrier, allForms, formList } = useSelector(
    (state) => state.formMangmentReducer
  );
  const { language } = useSelector((state) => state.generalReducer);

  const [page, setPage] = useState(1);
  const [isAda, setIsAda] = useState("all");
  const [carrierID, setCarrierID] = useState(strings.general.SELECT_CARRIER);
  const [carrierIdForFilter, setCarrierIdForFilter] = useState(
    strings.general.SELECT_CARRIER
  );
  const [search, setSearch] = useState("");
  const [searchForFilter, setSearchForFilter] = useState("");
  const [asc, setAsc] = useState("asc");
  const [isSearched, setIsSearched] = useState(false);
  const listOfCarrier = async () => {
    await dispatch(fetchCarriers({ language: language, isAdmin: false }));
  };

  useEffect(() => {
    dispatch(changeActiveHeader(strings.navigationHeader.user.LIBRARY));
    listOfCarrier();
  }, []);

  useEffect(() => {
    resetHandler();
  }, [language]);

  const paginationRange = usePagination({
    currentPage: page,
    totalCount: allForms?.data?.total,
    pageSize: 10,
    siblingCount: 2,
    isInAdmin: false,
  });

  const getAPI = async (page = 1, value = isAda, order = asc) => {
    if (
      value === "all" &&
      carrierIdForFilter !== strings.general.SELECT_CARRIER &&
      carrierIdForFilter !== "" &&
      searchForFilter !== ""
    ) {
      let formsRequest = {
        page: page,
        limit: 10,
        order: order,
        carrier_id: carrierIdForFilter,
        search_key: searchForFilter,
        language: language,
      };
      setIsAda("all");
      await dispatch(fetchAllForms({ formsRequest }));
    } else if (
      value !== "all" &&
      carrierIdForFilter !== strings.general.SELECT_CARRIER &&
      carrierIdForFilter !== "" &&
      searchForFilter !== ""
    ) {
      let formsRequest = {
        page: page,
        limit: 10,
        order: order,
        carrier_id: carrierIdForFilter,
        search_key: searchForFilter,
        isADA: value === "ada" ? true : false,
        language: language,
      };
      setIsAda(value === "ada" ? "ada" : "notada");
      await dispatch(fetchAllForms({ formsRequest }));
    } else if (
      (carrierIdForFilter === "" ||
        carrierIdForFilter === strings.general.SELECT_CARRIER) &&
      searchForFilter !== "" &&
      value !== "all"
    ) {
      let formsRequest = {
        page: page,
        limit: 10,
        order: order,
        isADA: value === "ada" ? true : false,
        search_key: searchForFilter,
        language: language,
      };
      setIsAda(value === "ada" ? "ada" : "notada");
      await dispatch(fetchAllForms({ formsRequest }));
    } else if (
      (carrierIdForFilter === "" ||
        carrierIdForFilter === strings.general.SELECT_CARRIER) &&
      searchForFilter !== "" &&
      value === "all"
    ) {
      let formsRequest = {
        page: page,
        limit: 10,
        order: order,
        search_key: searchForFilter,
        language: language,
      };
      setIsAda("all");
      await dispatch(fetchAllForms({ formsRequest }));
    } else if (
      carrierIdForFilter !== strings.general.SELECT_CARRIER &&
      carrierIdForFilter !== "" &&
      searchForFilter === "" &&
      value !== "all"
    ) {
      let formsRequest = {
        page: page,
        limit: 10,
        order: order,
        isADA: value === "ada" ? true : false,
        carrier_id: carrierIdForFilter,
        language: language,
      };
      setIsAda(value === "ada" ? "ada" : "notada");
      await dispatch(fetchAllForms({ formsRequest }));
    } else if (
      carrierIdForFilter !== strings.general.SELECT_CARRIER &&
      carrierIdForFilter !== "" &&
      searchForFilter === "" &&
      value === "all"
    ) {
      let formsRequest = {
        page: page,
        limit: 10,
        order: order,
        carrier_id: carrierIdForFilter,
        language: language,
      };
      setIsAda("all");
      await dispatch(fetchAllForms({ formsRequest }));
    } else if (isEmpty(value) || value === "all") {
      let formsRequest = {
        page: page,
        limit: 10,
        order: order,
        language: language,
      };
      setIsAda("all");
      await dispatch(fetchAllForms({ formsRequest }));
    } else {
      let formsRequest = {
        page: page,
        limit: 10,
        order: order,
        isADA: value === "ada" ? true : false,
        language: language,
      };
      setIsAda(value === "ada" ? "ada" : "notada");
      await dispatch(fetchAllForms({ formsRequest }));
    }
  };

  const pageClickHandler = async (item) => {
    getAPI(item, isAda);
    setPage(item);
  };

  const nextPage = async () => {
    if (page !== paginationRange[paginationRange.length - 1]) {
      getAPI(page + 1, isAda);
      setPage(page + 1);
    }
  };

  const prevPage = async () => {
    if (page !== 1) {
      getAPI(page - 1, isAda);
      setPage(page - 1);
    }
  };

  const filterHandler = async (value) => {
    setPage(1);
    getAPI(1, value);
  };

  const carrierChangeHandler = async () => {
    var selectedKey = document.getElementById("list").value;
    setCarrierID(
      selectedKey === strings.general.SELECT_CARRIER ? "" : selectedKey
    );
  };

  const searchHandler = async () => {
    setPage(1);
    setCarrierIdForFilter(carrierID);
    setSearchForFilter(search);
    setIsAda("all");
    setAsc("asc");
    if (
      search === "" &&
      (carrierID === strings.general.SELECT_CARRIER || carrierID === "")
    ) {
      setIsSearched(false);
      let formsRequest = {
        page: page,
        limit: 0,
        order: "asc",
        language: language,
      };
      dispatch(fetchAllForms({ formsRequest }));
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.translate.SELECT_CARRIER_OR_ADD_FORM_NUMBER_MSG),
      });
    } else if (
      search &&
      (carrierID === strings.general.SELECT_CARRIER || carrierID === "")
    ) {
      let formsRequest = {
        page: 1,
        limit: 10,
        order: "asc",
        search_key: search,
        language: language,
      };
      await dispatch(fetchAllForms({ formsRequest }));
      setIsSearched(true);
    } else if (search && carrierID) {
      let formsRequest = {
        page: 1,
        limit: 10,
        order: "asc",
        carrier_id: carrierID,
        search_key: search,
        language: language,
      };
      await dispatch(fetchAllForms({ formsRequest }));
      setIsSearched(true);
    } else if (search === "" && carrierID) {
      let formsRequest = {
        page: 1,
        limit: 10,
        order: "asc",
        carrier_id: carrierID,
        language: language,
      };
      await dispatch(fetchAllForms({ formsRequest }));
      setIsSearched(true);
    } else {
      let formsRequest = {
        page: 1,
        limit: 10,
        order: "asc",
        language: language,
      };
      await dispatch(fetchAllForms({ formsRequest }));
      setIsSearched(true);
    }
  };

  const resetHandler = async () => {
    let formsRequest = {
      page: page,
      limit: 0,
      language: language,
    };
    let resetResponse = await dispatch(fetchAllForms({ formsRequest }));
    if (resetResponse.payload?.data?.success) {
      setCarrierID(strings.general.SELECT_CARRIER);
      setCarrierIdForFilter(strings.general.SELECT_CARRIER);
      listOfCarrier();
      setIsAda("all");
      setSearch("");
      setSearchForFilter("");
      setIsSearched(false);
      setAsc("asc");
    }
  };

  const sortingHandler = (type) => {
    setAsc(type);
    setPage(1);
    getAPI(1, isAda, type);
  };

  const formNumberKeywordChangeHandler = (e) => setSearch(e.target.value);
  return (
    <body>
      <div>
        <Header />
      </div>
      <section className="subplan-banner bg--primary py-4">
        <div className="container">
          <div className="row justify-content-center align-items-end ">
            <div className="col-md-4 col-lg-3 mb-3 mb-md-0">
              <label htmlFor="carrier" className="form-label mb-2 text-white">
                {t(strings.words.CARRIER)}
              </label>
              <select
                className="form-select"
                id="list"
                aria-label="Default select example"
                onChange={carrierChangeHandler}
              >
                <option selected>{t(strings.translate.SELECT_CARRIER)}</option>
                {carrier?.data?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {language === strings.language.FR
                      ? item.carrier_name_fr
                      : item.carrier_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-4 col-lg-3 mb-3 mb-md-0">
              <label htmlFor="formNum" className="form-label mb-2 text-white">
                {t(strings.translate.FORM_NUMBER_KEYWORD)}
              </label>
              <input
                type="text"
                value={search}
                onChange={formNumberKeywordChangeHandler}
                className="form-control"
                id="formNum"
              />
            </div>
            <div className="col-md-4 col-xl-3">
              <button
                onClick={searchHandler}
                className="btn btn--secondary text-uppercase"
                style={{ marginRight: "6px" }}
              >
                {t(strings.words.SEARCH)}
              </button>
              <button
                onClick={resetHandler}
                className="btn btn--gray text-uppercase ms-lg-4"
              >
                {t(strings.words.RESET)}
              </button>
            </div>
          </div>
          <div className="row text-white justify-content-center mt-3">
            <div className="col-lg-9">
              {t(strings.translate.SEARCH_TIPS)}
              <span className="ms-5">
                {t(strings.translate.CAN_NOT_FIND)}
                <Link
                  className="fg-secondary text-decoration-none border-b-secondary ms-2"
                  to={paths.userRoutes.DEMO_TRAINING}
                >
                  {t(strings.translate.CLICK_HERE_FOR_HELP)}
                </Link>
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className="py-3 py-lg-5 search-form-sec">
        <div className="container py-4">
          {isSearched ? (
            <>
              <div className="mb-4 text-end filter-dropdow-main">
                <Link
                  className="dropdown-toggle px-0 filter-dropdown"
                  id="filter"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={"../../../../../assets/images/filter-icon.svg"}
                    width="34"
                    height="25"
                    alt="filter icon"
                  />
                </Link>
                <div
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="filter"
                >
                  <Link
                    onClick={filterHandler.bind(this, "all")}
                    className="dropdown-item"
                  >
                    {t(strings.translate.ALL_FORMS)}
                  </Link>
                  <Link
                    onClick={filterHandler.bind(this, "ada")}
                    className="dropdown-item"
                  >
                    {t(strings.translate.ADA_FORMS)}
                  </Link>
                  <Link
                    onClick={filterHandler.bind(this, "notada")}
                    className="dropdown-item"
                  >
                    {t(strings.translate.NON_ADA_FORMS)}
                  </Link>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="align-middle" scope="col">
                        {t(strings.words.CARRIER)}
                        <button className="btn px-2 py-0">
                          {asc === "asc" ? (
                            <img
                              onClick={sortingHandler.bind(this, "desc")}
                              src="../../../../../assets/images/sorting-bottom.svg"
                              alt="sorting-bottom"
                              width="16"
                              height="24"
                            />
                          ) : (
                            <img
                              onClick={sortingHandler.bind(this, "asc")}
                              src="../../../../../assets/images/sorting-top.svg"
                              alt="sorting-top"
                              width="16"
                              height="24"
                            />
                          )}
                        </button>
                      </th>
                      <th scope="col">{t(strings.words.NUMBER)}</th>
                      <th scope="col"></th>
                      <th scope="col">{t(strings.translate.DOCUMENT_NAME)}</th>
                      <th scope="col">{t(strings.words.DESCRIPTION)}</th>
                      <th scope="col">{t(strings.words.VIEW)}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formList.length > 0 ? (
                      formList?.map((item) => (
                        <tr>
                          <td className="text-center">
                            {language === strings.language.FR
                              ? item?.carrier?.carrier_name_fr
                              : item?.carrier?.carrier_name}
                          </td>
                          <td className="text-center">{item.form_number}</td>
                          <td className="pe-0">
                            {item.isADA && (
                              <span className="secondary-badge text-white d-inline-block">
                                {strings.words.ADA}
                              </span>
                            )}
                          </td>
                          <td>{item.form_name}</td>
                          <td>{item.form_description}</td>
                          <td className="text-center">
                            {/* EDITED */}
                            <Link
                              className="btn"
                              to={
                                item.form_actual_url &&
                                item.form_actual_url !== ""
                                  ? item.form_actual_url
                                  : item.form_url
                              }
                              target="_blank"
                            >
                              <img
                                src="../../../../../assets/images/pdf-icon.svg"
                                width="30"
                                height="30"
                                alt="pdf"
                              />
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center fw-bold py-5">
                          {t(strings.translate.NO_RECORD_FOUND)}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {formList.length > 0 && paginationRange.length > 0 && (
                <div className="pagination d-flex justify-content-center justify-content-md-end mt-4">
                  <ul className="m-0 p-0 d-flex ">
                    <li>
                      <Link
                        onClick={prevPage}
                        title="previous"
                        className="bg-transparent prev"
                      >
                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                      </Link>
                    </li>
                    {paginationRange?.map((item) => (
                      <li>
                        <Link
                          onClick={pageClickHandler.bind(this, item)}
                          title="2"
                          className={item === page ? "active" : ""}
                        >
                          {item}
                        </Link>
                      </li>
                    ))}
                    <li>
                      <Link
                        onClick={nextPage}
                        title="next"
                        className="bg-transparent next"
                      >
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </>
          ) : (
            <div className="container text-center pt-5">
              <img
                src={"../../../../../assets/images/search-form-bg.svg"}
                width="300"
                height="288"
                alt="search-form-bg"
              />
            </div>
          )}
        </div>
      </section>
    </body>
  );
};

export default SearchForm;
