import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  PdfViewerComponent,
  Annotation,
  Inject,
  FormDesigner,
  FormFields,
} from "@syncfusion/ej2-react-pdfviewer";
import {
  fetchAdminFormSectionAction,
  editAdminFormAction,
} from "../../redux/Actions";
import { Toast } from "../../component/Toast";
import { isEmpty } from "../../services/validation";
import {
  isFromEditForm,
  isFromEditFormData,
  isSaveLocalPDFMapDataList,
} from "../../redux/Reducers/AdminFormManagementReducer";
import { ViewPDFSectionFormTable } from "../../component/ViewPDFSectionFormTable.js";
import { strings, paths } from "../../utils/constants";
import CustomTextField from "../../component/CustomTextField";

const PDFSectionFormManagment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    isEditForm,
    editFormData,
    saveLocalPDFMapDataList,
    addAdminFormData,
    editAdminFormData,
  } = useSelector((state) => state.adminFormManagementReducer);

  let tempSaveData = saveLocalPDFMapDataList;

  const [formUrl] = useState(
    isEditForm
      ? editAdminFormData?.data?.form_url
      : addAdminFormData?.data?.form_url
  );

  const [mappedJson] = useState(
    isEditForm && editFormData?.mapped_json != null
      ? JSON.parse(editFormData?.mapped_json)
      : []
  );

  const [sectionFormName, setSectionFormName] = useState("");

  const [dataFieldList, setDataFieldList] = useState(
    JSON.stringify({
      id: "0",
      group_type: 0,
      field_name: strings.general.SELECT_DATA_FIELD,
    })
  );

  const [addTagToEntity, setAddTagToEntity] = useState("");
  const [fieldNumber, setFieldNumber] = useState("");

  const [tempPDFValue, setTempPDFValue] = useState([]);

  const [fetchedDataField, setFetchedDataField] = useState([]);

  const [allDataFieldsIsMapped, setAllDataFieldsIsMapped] = useState(false);

  const [formId] = useState(
    isEditForm ? editAdminFormData?.data?.id : addAdminFormData?.data?.id
  );

  let dataList = [];
  let formFieldDataList = [];

  const [entity, setEntity] = useState(
    JSON.stringify({
      group_type: 0,
      entityName: strings.general.SELECT_DATA_TYPE,
    })
  );

  const [isEntityDisabled, setIsEntityDisabled] = useState(true);
  const [isDataFieldDisabled, setIsDataFieldDisabled] = useState(true);

  const checkEntityDisabled = () => {
    setAllDataFieldsIsMapped(false);
    if (sectionFormName === "" || addTagToEntity === "") {
      setIsEntityDisabled(true);
    } else {
      setIsEntityDisabled(false);
    }
  };

  const getAPI = async (entity) => {
    let formResponse = await dispatch(
      fetchAdminFormSectionAction({
        language: strings.language.EN,
        groupTypeId: JSON.parse(entity).group_type.toString(),
      })
    );
    if (formResponse.payload?.success) {
      const alradyMappedFields = [];
      const finalFields = [];
      for (let data of tempSaveData) {
        if (
          data.sectionName === sectionFormName &&
          data.addTagToEntity === addTagToEntity &&
          data.entityName === JSON.parse(entity).entityName
        ) {
          alradyMappedFields.push(data.dataFieldList);
        }
      }
      const list = formResponse.payload.data;
      for (let item of list) {
        if (!alradyMappedFields.includes(item.field_name)) {
          finalFields.push(item);
        }
      }
      if (finalFields.length === 0) {
        if (
          JSON.parse(entity).entityName !== strings.general.SELECT_DATA_TYPE
        ) {
          setAllDataFieldsIsMapped(true);
        }
      } else {
        setAllDataFieldsIsMapped(false);
      }
      setFetchedDataField(finalFields);
    }
  };

  useEffect(() => {
    checkEntityDisabled();
    setEntity(
      JSON.stringify({
        group_type: 0,
        entityName: strings.general.SELECT_DATA_TYPE,
      })
    );
  }, [sectionFormName, addTagToEntity]);

  const entityList = [
    {
      group_type: 1,
      entityName: "Individual/Entity",
    },
    {
      group_type: 2,
      entityName: "Advisor's Information",
    },
    {
      group_type: 3,
      entityName: "Carrier Information",
    },
    {
      group_type: 4,
      entityName: "Portfolio",
    },
  ];

  useEffect(() => {
    if (
      fetchedDataField.length === 0 ||
      isEntityDisabled ||
      JSON.parse(entity).entityName === strings.general.SELECT_DATA_TYPE
    ) {
      setIsDataFieldDisabled(true);
      setDataFieldList(
        JSON.stringify({
          id: "0",
          group_type: 0,
          field_name: strings.general.SELECT_DATA_FIELD,
        })
      );
    } else {
      setIsDataFieldDisabled(false);
    }
  }, [fetchedDataField, isEntityDisabled, entity]);

  useEffect(() => {
    if (isEditForm && mappedJson?.length > 0) {
      dispatch(isSaveLocalPDFMapDataList(mappedJson));
    } else {
      dispatch(isSaveLocalPDFMapDataList([]));
    }
    tempSaveData = saveLocalPDFMapDataList;
  }, []);

  function documentLoaded() {
    let viewer = document.getElementById("container").ej2_instances[0];
    let tempValue;

    for (let i = 0; i < viewer.formFieldCollections.length; i++) {
      viewer.formDesignerModule.updateFormField(
        viewer.formFieldCollections[i],
        { value: i.toString(), color: "#ff0000", isReadOnly: true }
      );

      tempValue = viewer.formFieldCollections[i];
      dataList.push(tempValue);
    }

    setTempPDFValue(dataList);
  }

  const sectionNameChangeHandler = (e) => {
    setSectionFormName(e.target.value);
  };

  const addTagChangeHandler = (e) => {
    setAddTagToEntity(e.target.value);
  };
  const entityChangeHandler = async (e) => {
    setEntity(e.target.value);
    getAPI(e.target.value);
  };
  const dataFieldChangeHandler = (e) => {
    setDataFieldList(e.target.value);
  };
  const fildNumberChangeHandler = (e) => {
    const regex = new RegExp(/^[0-9,]*$/);
    if (regex.test(e.target.value)) {
      setFieldNumber(e.target.value);
    }
  };
  const ckeckFieldNumber = (number) => {
    for (let f of tempPDFValue) {
      if (f.value === number) {
        return true;
      }
    }
    return false;
  };
  const saveHandler = async () => {
    if (isEmpty(sectionFormName)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.SECTION_NAME_REQUIRED,
      });
    } else if (isEmpty(addTagToEntity)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.TAG_NAME_REQUIRED,
      });
    } else if (
      JSON.parse(entity).entityName === strings.general.SELECT_DATA_TYPE
    ) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.DATA_TYPE_REQUIRED,
      });
    } else if (
      JSON.parse(dataFieldList).field_name === strings.general.SELECT_DATA_FIELD
    ) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.DATA_FIELD_REQUIRED,
      });
    } else if (isEmpty(fieldNumber)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.FIELD_NUMBER_REQUIRED,
      });
    } else if (!ckeckFieldNumber(fieldNumber)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.FIELD_NUMBER_NOT_EXISTS,
      });
    } else {
      var mynewarray = fieldNumber.split(",");

      if (saveLocalPDFMapDataList.length === 0) {
        const data = tempPDFValue.find((obj) => {
          return obj.value === fieldNumber;
        });
        let obj = {
          id: formFieldDataList?.length + 1,
          fieldNumber: fieldNumber,
          fieldId: data.name,
          fieldType: data?.type,
          addTagToEntity: addTagToEntity,
          entityName: JSON.parse(entity)?.group_type,
          dataFieldList: JSON.parse(dataFieldList)?.id,
        };
        formFieldDataList.push(obj);
        let requestInfo = {
          id: saveLocalPDFMapDataList?.length + 1,
          sectionName: sectionFormName,
          addTagToEntity: addTagToEntity,
          entityName: JSON.parse(entity)?.entityName,
          dataFieldList: JSON.parse(dataFieldList)?.field_name,
          fieldNumber: mynewarray,
          fieldList: formFieldDataList,
        };

        let newObj = [...saveLocalPDFMapDataList, requestInfo];
        dispatch(isSaveLocalPDFMapDataList(newObj));

        let tempRequestInfo = {
          id: formId,
          is_file: false,
          mapped_json: JSON.stringify(newObj),
        };

        let editFormResponse = await dispatch(
          editAdminFormAction({
            language: strings.language.EN,
            requestInfo: tempRequestInfo,
            isFile: false,
            customTost: true,
          })
        );

        if (editFormResponse.payload?.success) {
          setSectionFormName("");
          setDataFieldList({
            id: "0",
            group_type: 0,
            field_name: strings.general.SELECT_DATA_FIELD,
          });
          setAddTagToEntity("");
          setFieldNumber("");
          dataList = [];
          formFieldDataList = [];
          setEntity(
            JSON.stringify({
              group_type: 0,
              entityName: strings.general.SELECT_DATA_TYPE,
            })
          );
          Toast({
            type: "success",
            title: strings.words.SUCCESS,
            message: strings.general.MAPPING_SAVE_SUCCESSFULLY,
          });
        }
      } else {
        for (let j = 0; j < tempSaveData?.length; j++) {
          var fieldListArray = tempSaveData[j]?.fieldList;
          if (tempSaveData[j]?.fieldList?.length === 1) {
            if (
              fieldListArray[0]?.fieldNumber.toString() ===
              fieldNumber.toString()
            ) {
              const newArr = [...tempSaveData];
              newArr.splice(j, 1);
              tempSaveData = newArr;
              await dispatch(isSaveLocalPDFMapDataList(newArr));
            }
          }
        }

        const data = tempPDFValue.find((obj) => {
          return obj.value === fieldNumber;
        });

        let requestParams = {
          id: formFieldDataList?.length + 1,
          fieldNumber: fieldNumber,
          fieldId: data.name,
          fieldType: data?.type,
          addTagToEntity: addTagToEntity,
          entityName: JSON.parse(entity)?.group_type,
          dataFieldList: JSON.parse(dataFieldList)?.id,
        };

        formFieldDataList.push(requestParams);

        let requestInfo = {
          id: tempSaveData?.length + 1,
          sectionName: sectionFormName,
          addTagToEntity: addTagToEntity,
          entityName: JSON.parse(entity)?.entityName,
          dataFieldList: JSON.parse(dataFieldList)?.field_name,
          fieldNumber: mynewarray,
          fieldList: formFieldDataList,
        };

        let newObj = [...tempSaveData, requestInfo];
        dispatch(isSaveLocalPDFMapDataList(newObj));

        let tempRequestInfo = {
          id: formId,
          is_file: false,
          mapped_json: JSON.stringify(newObj),
        };

        let editFormResponse = await dispatch(
          editAdminFormAction({
            language: strings.language.EN,
            requestInfo: tempRequestInfo,
            isFile: false,
            customTost: true,
          })
        );
        if (editFormResponse.payload?.success) {
          setSectionFormName("");
          setDataFieldList({
            id: "0",
            group_type: 0,
            field_name: strings.general.SELECT_DATA_FIELD,
          });
          setAddTagToEntity("");
          setFieldNumber("");
          dataList = [];
          formFieldDataList = [];
          setEntity(
            JSON.stringify({
              group_type: 0,
              entityName: strings.general.SELECT_DATA_TYPE,
            })
          );
          Toast({
            type: "success",
            title: strings.words.SUCCESS,
            message: strings.general.MAPPING_SAVE_SUCCESSFULLY,
          });
        }
      }
    }
  };

  const resetHandler = async () => {
    setSectionFormName("");
    setDataFieldList({
      id: "0",
      group_type: 0,
      field_name: strings.general.SELECT_DATA_FIELD,
    });
    setAddTagToEntity("");
    setFieldNumber("");
    dataList = [];
    formFieldDataList = [];
    setEntity(
      JSON.stringify({
        group_type: 0,
        entityName: strings.general.SELECT_DATA_TYPE,
      })
    );
  };

  const finishHandler = async () => {
    if (saveLocalPDFMapDataList?.length < 1) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.MAP_ATLIST_ONE_FIELD_REQUIRED,
      });
    } else {
      Toast({
        type: "success",
        title: strings.words.SUCCESS,
        message: strings.general.FORM_MAPPED_SUCCESSFULLY,
      });
      navigate(paths.adminRoutes.FORMS, {
        replace: true,
      });
    }
  };
 // EDITED
  const cancelHandler = () => {
    dispatch(isFromEditForm(true));
    dispatch(
      isFromEditFormData(
        isEditForm ? editAdminFormData?.data : addAdminFormData?.data
      )
    );
    navigate(paths.adminRoutes.EDIT_FORM, {
      replace: true,
    });
  };

  return (
    <div>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom sx={{ marginLeft: 5 }}>
          {isEditForm
            ? strings.general.EDIT_PDF_MAPPING
            : strings.general.PDF_MAPPING}
        </Typography>
      </Stack>
      <div style={{ marginRight: "50px", widgth: "100%" }}>
        <Stack
          justifyContent="space-between"
          direction={{
            xs: "column",
            md: "row",
          }}
        >
          <div style={{ direction: "column" }}>
            <div
              style={{
                direction: "column",
                maxWidth: "60%",
                marginLeft: "50px",
              }}
            >
              <CustomTextField
                className="w-100"
                type="text"
                label={`${strings.general.SECTION_NAME}*`}
                variant="outlined"
                focusColor="#184437"
                autoComplete="off"
                value={sectionFormName}
                onChange={sectionNameChangeHandler}
              />
              <CustomTextField
                style={{
                  width: "100%",
                  marginTop: "15px",
                }}
                type="text"
                label={`${strings.general.ADD_TAG_TO_GROUP}*`}
                variant="outlined"
                focusColor="#184437"
                autoComplete="off"
                value={addTagToEntity}
                onChange={addTagChangeHandler}
              />
              <div
                style={{
                  marginTop: "15px",
                  minWidth: "30%",
                }}
              >
                <select
                  className="aform-select form-select"
                  id="list"
                  aria-label="Default select example"
                  responsive={{
                    xsmall: {
                      maxWidth: "30%",
                    },
                    small: {
                      minWidth: "30%",
                    },
                  }}
                  value={entity}
                  onChange={entityChangeHandler}
                  disabled={isEntityDisabled}
                >
                  <option
                    selected
                    key={JSON.stringify({
                      group_type: 0,
                      entityName: strings.general.SELECT_DATA_TYPE,
                    })}
                    value={JSON.stringify({
                      group_type: 0,
                      entityName: strings.general.SELECT_DATA_TYPE,
                    })}
                  >
                    {strings.general.SELECT_DATA_TYPE}
                  </option>
                  {entityList.map((item) => (
                    <option
                      key={JSON.stringify(item)}
                      value={JSON.stringify(item)}
                    >
                      {item.entityName}
                    </option>
                  ))}
                </select>
              </div>
              <div
                style={{
                  marginTop: "15px",
                  minWidth: "30%",
                }}
              >
                <select
                  className="aform-select form-select"
                  id="list"
                  aria-label="Default select example"
                  responsive={{
                    xsmall: {
                      maxWidth: "30%",
                    },
                    small: {
                      minWidth: "30%",
                    },
                  }}
                  value={dataFieldList}
                  disabled={isDataFieldDisabled}
                  onChange={dataFieldChangeHandler}
                >
                  <option
                    selected
                    key={JSON.stringify({
                      id: "0",
                      group_type: 0,
                      field_name: strings.general.SELECT_DATA_FIELD,
                    })}
                    value={JSON.stringify({
                      id: "0",
                      group_type: 0,
                      field_name: strings.general.SELECT_DATA_FIELD,
                    })}
                  >
                    {allDataFieldsIsMapped
                      ? strings.general.ALL_FIELDS_ARE_MAPPED_SELECT_TEXT
                      : strings.general.SELECT_DATA_FIELD}
                  </option>
                  {!isDataFieldDisabled &&
                    fetchedDataField.map((item) => (
                      <option
                        key={JSON.stringify(item)}
                        value={JSON.stringify(item)}
                      >
                        {item.field_name}
                      </option>
                    ))}
                </select>
              </div>
              <CustomTextField
                name="fieldNumber"
                label={`${strings.general.FIELD_NUMBER}*`}
                type="text"
                variant="outlined"
                autoComplete="off"
                style={{
                  width: "100%",
                  marginTop: "15px",
                }}
                value={fieldNumber}
                onChange={fildNumberChangeHandler}
                focusColor="#184437"
              />
            </div>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems="start"
              justifyContent="left"
              style={{ marginLeft: "50px" }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#184437",
                  marginTop: "20px",
                  marginRight: "12px",
                }}
                onClick={saveHandler}
              >
                {strings.words.SAVE}
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#184437",
                  marginTop: "20px",
                  marginRight: "12px",
                }}
                onClick={finishHandler}
              >
                {strings.words.FINISH}
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#184437",
                  marginTop: "20px",
                  marginRight: "12px",
                }}
                onClick={cancelHandler}
              >
                {strings.words.CANCEL}
              </Button>
            </Stack>
            <div style={{ marginTop: "20px" }}>
              <ViewPDFSectionFormTable resetHandler={resetHandler} />
            </div>
          </div>

          <div style={{ width: "60%", maxHeight: "40%" }}>
            <div className="control-section">
              <PdfViewerComponent
                id="container"
                documentPath={formUrl?.split("/")[3]}
                serviceUrl={
                  process.env.REACT_APP_SYNCFUSION_BACKEND_SERVER_SERVICE_URL
                }
                documentLoad={documentLoaded}
                style={{ height: "650px", width: "100%" }}
              >
                <Inject services={[Annotation, FormDesigner, FormFields]} />
              </PdfViewerComponent>
            </div>
          </div>
        </Stack>
      </div>
    </div>
  );
};

export default PDFSectionFormManagment;
