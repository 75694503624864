import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptions } from "../../../redux/Actions";
import { strings } from "../../../utils/constants";
import FreePlanCard from "../../../component/FreePlanCard";

const FreeTrail = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.generalReducer);

  const getSubscriptionPlans = async () => {
    await dispatch(getSubscriptions({ language: language }));
  };
  useEffect(() => {
    getSubscriptionPlans();
  }, []);

  return (
    <body>
      <section className="subplan-banner bg--primary">
        <div className="container">
          <h1 className="font-lato_bold font-26 fg-white text-center py-5 m-0">
            {t(strings.translate.FREE_SUBSCRIPRION)}
          </h1>
        </div>
      </section>
      <section className="billing-sec start-trial mt-md-5 pt-md-5">
        <div className="container">
          <div className="row py-4 py-md-5 mb-lg-5">
            <div className="col-md-6 col-lg-5 col-xl-4  fg-gray-30 offset-lg-1 offset-xl-2">
              <p className="font-lato_bold font-24">About ADA</p>
              <p className="line-height-2">
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly used to demonstrate the visual form of a document
                or a typeface without relying on meaningful content.
              </p>
              <p className="line-height-2">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
            <div className="col-md-6 col-lg-5 col-xl-4  offset-xl-1 mt-5 mt-md-0">
              <FreePlanCard />
            </div>
          </div>
        </div>
      </section>
    </body>
  );
};

export default FreeTrail;
