import React, { useEffect } from "react";
import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import LoginPage from "./pages/User/Login";
import ThemeProvider from "./theme";
import ScrollToTop from "./component/ScrollToTop";
import Router from "./routes";
import Dashboard from "./pages/User/Dashboard";
import SearchForm from "./pages/User/SearchForms";
import MyProfile from "./pages/User/MyProfile";
import Aboutus from "./pages/User/AboutUs";
import DemoTraining from "./pages/User/DemoTraining";
import ContactUs from "./pages/User/ContactUs";
import Billing from "./pages/User/Billing";
import NotFound from "./pages/User/404";
import SubscriptionPlan from "./pages/User/SubscriptionPlan";
import AccessADA from "./pages/User/AccessADA";
import { useCookies } from "react-cookie";
import FreeSubscription from "./pages/User/SubscriptionPlan/FreeSubscription";
import { paths } from "./utils/constants";

const App = () => {
  const navigation = useNavigate();
  const loc = useLocation();

  const [cookies, setCookie, removeCookie] = useCookies([
    "access_token",
    "refresh_token",
    "admin_access_token",
    "admin_refresh_token",
  ]);
  useEffect(() => {
    const cookie = cookies.access_token;
    const adminCookie = cookies.admin_access_token;
    if (loc.pathname.includes("admin")) {
      if (!adminCookie) {
        navigation(paths.adminRoutes.ADMIN);
      }
    } else {
      if (!cookie) {
        navigation("/");
      }
    }
  }, [
    cookies.access_token,
    cookies.admin_access_token,
    loc.pathname,
    navigation,
  ]);

  const unAuthorized = localStorage.getItem("unAuthorized");
  useEffect(() => {
    if (unAuthorized === "user") {
      removeCookie("access_token", { path: "/" });
      removeCookie("refresh_token", { path: "/" });
      navigation(0);
      navigation("/", { replace: true });
      localStorage.removeItem("unAuthorized");
    } else if (unAuthorized === "admin") {
      removeCookie("admin_access_token", {
        path: paths.adminRoutes.ADMIN,
      });
      removeCookie("admin_refresh_token", {
        path: paths.adminRoutes.ADMIN,
      });
      navigation(paths.adminRoutes.ADMIN, { replace: true });
      localStorage.removeItem("unAuthorized");
    }
  }, [unAuthorized]);

  if (loc.pathname.includes("admin")) {
    return (
      <ThemeProvider>
        <ScrollToTop />
        <Router />
      </ThemeProvider>
    );
  } else {
    const token = cookies.access_token;

    if (token) {
      return (
        <Routes>
          {loc.pathname === "/" && <Route path="/" element={<LoginPage />} />}
          <Route path={paths.userRoutes.DASHBOARD} element={<Dashboard />} />
          <Route path={paths.userRoutes.SEARCH_FORM} element={<SearchForm />} />
          <Route path={paths.userRoutes.MY_PROFILE} element={<MyProfile />} />
          <Route path={paths.userRoutes.ABOUT_US} element={<Aboutus />} />
          <Route
            path={paths.userRoutes.DEMO_TRAINING}
            element={<DemoTraining />}
          />
          <Route path={paths.userRoutes.CONTACT_US} element={<ContactUs />} />
          <Route path={paths.userRoutes.ACCESS_ADA} element={<AccessADA />} />
          <Route
            path={paths.userRoutes.FREE_SUBSCRIPRION}
            element={<FreeSubscription />}
          />
          <Route path={paths.userRoutes.BILLING} element={<Billing />} />
          <Route
            path={paths.userRoutes.SUBSCRIPRION}
            element={<SubscriptionPlan />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      );
    } else {
      if (
        loc.pathname === paths.userRoutes.DASHBOARD ||
        loc.pathname === paths.userRoutes.SEARCH_FORM ||
        loc.pathname === paths.userRoutes.MY_PROFILE ||
        loc.pathname === paths.userRoutes.ABOUT_US ||
        loc.pathname === paths.userRoutes.DEMO_TRAINING ||
        loc.pathname === paths.userRoutes.CONTACT_US ||
        loc.pathname === paths.userRoutes.ACCESS_ADA ||
        loc.pathname === paths.userRoutes.FREE_SUBSCRIPRION ||
        loc.pathname === paths.userRoutes.BILLING ||
        loc.pathname === paths.userRoutes.SUBSCRIPRION
      ) {
        window.location.href = "/";
      } else {
        return (
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="*" element={<LoginPage />} />
          </Routes>
        );
      }
    }
  }
};

export default App;
