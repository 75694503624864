import "./MgaTable.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BsFillPencilFill } from "react-icons/bs";
import { deleteAdminMgaAction, fetchAdminMgaAction } from "../../redux/Actions";
import { strings, paths } from "../../utils/constants";
import NoRecordFound from "../NoRecordFound";
import AdminPagination from "../AdminPagination";
import {
  isFromEditMga,
  isFromEditMgaData,
} from "../../redux/Reducers/AdminMgaReducer";
import ResponsiveDialog from "../Modal";
import { RiShutDownLine } from "react-icons/ri";

export default function MgaTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isActive, setIsActive] = useState("");
  const [mgaId, setMgaId] = useState("");
  const { fetchAdminMgaData, deleteAdminMgaData } = useSelector(
    (state) => state.adminMgaReducer
  );

  const tableRecords = fetchAdminMgaData?.data ? fetchAdminMgaData.data : [];

  const totalPage = Math.ceil(fetchAdminMgaData?.total / 10);

  const getAPI = async (page) => {
    let requestInfo = {
      page: page,
      limit: 10,
      language: strings.language.EN,
    };
    await dispatch(fetchAdminMgaAction(requestInfo));
  };

  useEffect(() => {
    if (currentPage === 1 && !isDelete) {
      getAPI(1);
    }

    if (isDelete) {
      setIsDelete(false);
      setOpen(false);
    }
  }, [currentPage, deleteAdminMgaData]);

  const nextPage = () => {
    if (currentPage !== totalPage) {
      setCurrentPage(currentPage + 1);
      getAPI(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      getAPI(currentPage - 1);
    }
  };

  const changePage = (id) => {
    setCurrentPage(id);
    getAPI(id);
  };

  const deleteHandler = (data) => {
    setIsActive(data.active ? "inactive" : "active");
    setMgaId(data.id);
    setOpen(true);
  };

  const negativeClickHandler = () => {
    setIsDelete(false);
    setOpen(false);
  };

  const positiveClickHandler = async () => {
    setIsDelete(true);
    const response = await dispatch(
      deleteAdminMgaAction({
        language: strings.language.EN,
        mgaId: mgaId,
      })
    );
    if (response?.payload?.success) {
      getAPI(currentPage);
    }
  };

  const closeHandler = () => {
    setOpen(false);
  };

  const editHandler = (data) => {
    if (data.active) {
      dispatch(isFromEditMga(true));
      dispatch(isFromEditMgaData(data));
      navigate(paths.adminRoutes.ADD_EDIT_MGA);
    }
  };

  return (
    <>
      <div className="mtable-wrapper">
        <table className="mtable">
          <thead>
            <tr>
              <th>{strings.general.SR_NO}</th>
              <th className="w-50">{strings.general.MGA_NAME}</th>
              <th className="w-50">{strings.general.MGA_CODE}</th>
              <th>{strings.words.ACTIONS}</th>
            </tr>
          </thead>
          <tbody>
            {tableRecords.length > 0 ? (
              tableRecords.map((row, idx) => {
                return (
                  <tr key={idx}>
                    <td>{(currentPage - 1) * 10 + idx + 1}</td>
                    <td>{row?.mga_name}</td>
                    <td>{row?.code}</td>
                    <td className="fit">
                      <span className="mactions">
                        <BsFillPencilFill
                          className="cursor-pointer"
                          onClick={editHandler.bind(this, row)}
                          style={row.active ? {} : { color: "#DEDEDF" }}
                        />
                        <RiShutDownLine
                          className="udelete-btn cursor-pointer"
                          onClick={deleteHandler.bind(this, row)}
                        />
                      </span>
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoRecordFound colSpan={4} />
            )}
          </tbody>
        </table>
        {tableRecords.length > 0 && (
          <AdminPagination
            currentPage={currentPage}
            totalRecords={fetchAdminMgaData.total}
            nextPage={nextPage}
            prevPage={prevPage}
            changePage={changePage}
          />
        )}
      </div>
      <ResponsiveDialog
        open={open}
        negativeButtonClickHandler={negativeClickHandler}
        positiveButtonClickHandler={positiveClickHandler}
        handleClose={closeHandler}
        titleText={strings.general.ARE_YOU_SURE}
        subTitleText={"You want to " + isActive + " this MGA ?"}
        negativeButtonText={strings.words.CANCEL}
        positiveButtonText={strings.words.YES}
      />
    </>
  );
}
