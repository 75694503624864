import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCMSList } from "../../../redux/Actions";
import Header from "../../../component/Header";
import { strings } from "../../../utils/constants";
import { changeActiveHeader } from "../../../redux/Reducers/GeneralReducer";

const Aboutus = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { language, cmsList } = useSelector((state) => state.generalReducer);

  const cmsData = cmsList?.data ? cmsList.data : [];
  const [imageError, setImageError] = useState(false);
  const [videoError, setVideoError] = useState(false);

  const getCMS = async () => {
    dispatch(getCMSList({ language: language }));
  };

  useEffect(() => {
    getCMS();
    dispatch(changeActiveHeader(strings.navigationHeader.user.ABOUT));
  }, []);

  return (
    <body>
      <div>
        <Header />
      </div>
      <section className="subplan-banner bg--primary">
        <div className="container">
          <h1 className="font-lato_bold font-26 fg-white text-center py-5 m-0">
            {t(strings.translate.ABOUT_FORM_DEPOT)}
          </h1>
        </div>
      </section>
      <section className="py-4 py-md-5 billing-sec">
        <div className="container">
          <div className="row flex-column-reverse flex-md-row">
            {cmsData.map((item) => {
              if (item.key === strings.cmsPages.ABOUT) {
                return (
                  <div className="container" key={item}>
                    <div className="row justify-content-around align-items-center pb-lg-5 mb-5 flex-column-reverse flex-md-row">
                      <div className="col-md-5 col-lg-4 fg-gray-30">
                        <p className="font-lato_bold font-24 d-md-block">
                          {language === strings.language.FR
                            ? item?.title_fr
                            : item?.title}
                        </p>
                        <p className="line-height-2">
                          {language === strings.language.FR
                            ? JSON.parse(item?.content).description_fr
                            : JSON.parse(item?.content).description}
                        </p>
                      </div>
                      <div className="col-md-5 mb-3 mb-md-0">
                        {!imageError ? (
                          <img
                            src={JSON.parse(item?.content).imageLink}
                            alt=""
                            className="img-fluid"
                            width="535"
                            height="402"
                            onError={() => {
                              setImageError(true);
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p className="line-height-2">
                              {t(strings.translate.NO_IMAGE_AVAILABLE)}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    {JSON.parse(item?.content)?.videoLink && (
                      <div className="col-12 text-center ratio ratio-16x9">
                        {!videoError ? (
                          <video
                            width="1280"
                            height="720"
                            controls
                            autoPlay
                            onError={() => {
                              setVideoError(true);
                            }}
                          >
                            <source
                              src={JSON.parse(item?.content).videoLink}
                              type="video/mp4"
                            />
                            {t(strings.translate.VIDEO_TAG_NOT_SUPPORTED)}
                          </video>
                        ) : (
                          <div
                            style={{
                              backgroundColor: "#e6e6e6",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p className="line-height-2">
                              {t(strings.translate.NO_VIDEO_AVAILABLE)}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </section>
    </body>
  );
};

export default Aboutus;
