import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, postRequest } from "../../services/API";

const DEEPLINK_GET_VERIFICATION_CODE = "deepLinkGetVerificationCode";
const DEEPLINK_CHECK_STATUS = "deepLinkCheckStatus";

export const checkDeepLinkStstus = createAsyncThunk(
  DEEPLINK_CHECK_STATUS,
  async ({ language }) => {
    try {
      let deepLinkStstusResult = await getRequest(language, "deepLink", false);
      return deepLinkStstusResult;
    } catch (error) {
      return JSON.parse(error.message);
    }
  }
);

export const getDeepLinkVerificationCode = createAsyncThunk(
  DEEPLINK_GET_VERIFICATION_CODE,
  async ({ language }) => {
    try {
      let deepLinkVerificationCodeResult = await postRequest(
        language,
        "deepLink",
        {},
        false,
        true
      );
      return deepLinkVerificationCodeResult;
    } catch (error) {
      return JSON.parse(error.message);
    }
  }
);
