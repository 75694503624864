import React from "react";
import { strings } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ModifyCardElement from "./ModifyCardElement";

export default function ModifyPayment({ onUpdateClick, onCancelClick }) {
  const { t } = useTranslation();
  const { paymentMethodlist } = useSelector(
    (state) => state.subscriptionReducer
  );
  var options = {
    mode: "setup",
    currency: "usd",
    appearance: {
      theme: "stripe",
    },
  };
  const stripePromise = (async () => {
    try {
      return await loadStripe(process.env.REACT_APP_STRIPE_KEY);
    } catch (err) {
      window.location.reload();
    }
  })();

  return (
    <div className="pb-4 col-lg-4">
      <p className="form-title fg-gray-30 font-lato_bold mb-4 font-18">
        {t(strings.translate.CURRENT_PAYMENT_METHOD)}
      </p>
      <div className="mb-4">
        {paymentMethodlist.map((item) => {
          return (
            <>
              {item.type === "card" ? (
                <div>
                  <div>
                    <span className="fg-gray-30 font-lato_bold text-capitalize">
                      {item.card.brand}{" "}
                    </span>
                    <span className="fg-gray-30 font-lato_bold text-capitalize">
                      {item.card.funding}{" "}
                    </span>
                    <span className="fg-gray-30 font-lato_bold text-capitalize">
                      {item.type}{" "}
                    </span>
                  </div>
                  <div>
                    <span>{t(strings.translate.ENDING_WITH)}</span>
                    <span className="fg-gray-30 font-lato_bold text-capitalize ms-2">
                      {item.card.last4}
                    </span>
                  </div>
                  <div>
                    <span>{t(strings.translate.EXPIRY_DATE)}</span>
                    <span className="fg-gray-30 font-lato_bold text-capitalize ms-2">
                      {item.card.exp_month}/{item.card.exp_year}
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          );
        })}
      </div>
      <p className="form-title fg-gray-30 font-lato_bold mb-4 font-18">
        {t(strings.translate.MODIFY_PAYMENT_METHOD)}
      </p>
      <div className="mb-4">
        <Elements options={options} stripe={stripePromise}>
          <ElementsConsumer>
            {(ctx) => (
              <ModifyCardElement
                onUpdateClick={onUpdateClick}
                onCancelClick={onCancelClick}
                {...ctx}
              />
            )}
          </ElementsConsumer>
        </Elements>
      </div>
    </div>
  );
}
