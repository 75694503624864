import "./UserTable.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BsFillPencilFill } from "react-icons/bs";
import { RiShutDownLine } from "react-icons/ri";
import { Checkbox, Stack, Button, Link, Container } from "@mui/material";
import { TextField } from "@mui/material";
import {
  fetchMGAUserAction,
  deleteAdminMGAUserAction,
} from "../../redux/Actions";
import ResponsiveDialog from "../Modal";
import { isEmpty } from "../../services/validation";
import {
  isFromEdit,
  isFromEditData,
} from "../../redux/Reducers/AdminMGAUserReducer";
import { strings, paths } from "../../utils/constants";
import AdminPagination from "../AdminPagination";
import NoRecordFound from "../NoRecordFound";

export const UserTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [isMGA, setIsMGA] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isActive, setIsActive] = useState("");
  const [userId, setUserId] = useState("");

  const { deleteMGAUser, MGAUser } = useSelector(
    (state) => state.adminMGAUserReducer
  );

  const tableRecords = MGAUser?.data ? MGAUser.data : [];

  const totalPage = Math.ceil(MGAUser?.total / 10);

  const getAPI = async (page, searchText, isMGA) => {
    let requestInfo = {
      page: page,
      limit: 10,
      searchText: searchText,
      isMGA: isMGA,
      language: strings.language.EN,
    };
    await dispatch(fetchMGAUserAction(requestInfo));
  };

  useEffect(() => {
    if (currentPage === 1 && !isDelete) {
      getAPI(1, searchText, isMGA);
    }
    if (isDelete) {
      if (deleteMGAUser?.success) {
        getAPI(currentPage, searchText, isMGA);
      }
      setIsDelete(false);
      setOpen(false);
    }
  }, [currentPage, deleteMGAUser]);

  const nextPage = () => {
    if (currentPage !== totalPage) {
      setCurrentPage(currentPage + 1);
      getAPI(currentPage + 1, searchText, isMGA);
    }
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      getAPI(currentPage - 1, searchText, isMGA);
    }
  };

  const changePage = (id) => {
    setCurrentPage(id);
    getAPI(id, searchText, isMGA);
  };

  const searchHandler = () => {
    setIsMGA("AllUsers");
    if (isEmpty(searchText)) {
      setSearchText("");
      setCurrentPage(1);
      getAPI(1, "", "");
    } else {
      setCurrentPage(1);
      getAPI(1, searchText, "");
    }
  };

  const filterItemClickHandler = (userFlag) => {
    setIsMGA(userFlag);
    setCurrentPage(1);
    getAPI(1, searchText, userFlag);
  };

  const editHandler = (data) => {
    if (data.is_active) {
      dispatch(isFromEdit(true));
      dispatch(isFromEditData(data));
      navigate(paths.adminRoutes.ADD_EDIT_USER);
    }
  };

  const deleteHandler = (data) => {
    setIsActive(data.is_active ? "inactive" : "active");
    setUserId(data.id);
    setOpen(true);
  };

  const negativeClickHandler = () => {
    setIsDelete(false);
    setOpen(false);
  };

  const positiveClickHandler = async () => {
    setIsDelete(true);
    const response = await dispatch(
      deleteAdminMGAUserAction({
        language: strings.language.EN,
        userId: userId,
      })
    );
    if (response?.payload?.success) {
      getAPI(currentPage, searchText, isMGA);
    }
  };

  const closeHandler = () => {
    setOpen(false);
  };
  return (
    <>
      <Container className="container-wrapper">
        <Stack
          direction={{ xm: "column", lg: "row" }}
          alignItems="end"
          justifyContent="right"
          mb={4}
        >
          <div className="uinput">
            <TextField
              id="search"
              variant="outlined"
              size="small"
              placeholder={strings.words.SEARCH}
              sx={{ color: "#184437" }}
              onChange={(e) => setSearchText(e.target.value)}
              autoComplete="off"
            />
          </div>
          <Button
            variant="contained"
            sx={{ color: "#184437" }}
            className="ubutton"
            onClick={searchHandler}
          >
            {strings.words.SEARCH}
          </Button>
          <div className="align-self-xl-center mt-3 text-end filter-dropdow-main filterIcon-wrapper">
            <Link
              className="px-0 filter-dropdown"
              id="filter"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="/assets/icons/filter-icon.svg"
                width="34"
                height="30"
                alt="filter icon"
              />
            </Link>
            <div
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="filter"
            >
              <Link
                className="dropdown-item"
                onClick={filterItemClickHandler.bind(this, "AllUsers")}
              >
                {strings.general.ALL_USERS}
              </Link>
              <Link
                className="dropdown-item"
                onClick={filterItemClickHandler.bind(this, "isMGA")}
              >
                {strings.general.MGA_USERLS}
              </Link>
              <Link
                className="dropdown-item"
                onClick={filterItemClickHandler.bind(this, "isNonMGA")}
              >
                {strings.general.NON_MGA_USERLS}
              </Link>
            </div>
          </div>
        </Stack>
      </Container>

      <div className="utable-wrapper">
        <table className="utable">
          <thead>
            <tr>
              <th>{strings.general.SR_NO}</th>
              <th style={{ width: "20%" }}>{strings.general.FIRST_NAME}</th>
              <th style={{ width: "20%" }}>{strings.general.LAST_NAME}</th>
              <th style={{ width: "20%" }}>{strings.words.EMAIL}</th>
              <th style={{ width: "40%" }}>{strings.words.ADDRESS}</th>
              <th style={{ width: "30%" }}>{strings.words.PHONE}</th>
              <th>{strings.general.IS_MGA}</th>
              <th>{strings.words.STATUS}</th>
              <th className="uexpand">{strings.words.ACTIONS}</th>
            </tr>
          </thead>
          <tbody>
            {tableRecords.length > 0 ? (
              tableRecords.map((row, idx) => {
                return (
                  <tr key={idx}>
                    <td>{(currentPage - 1) * 10 + idx + 1}</td>
                    <td>{row.firstname}</td>
                    <td>{row.lastname}</td>
                    <td
                      style={{
                        width: "20%",
                      }}
                    >
                      {row.email}
                    </td>
                    <td
                      style={{
                        width: "40%",
                      }}
                    >{`${
                      row.user_address?.address_line_1
                        ? " " + row.user_address.address_line_1
                        : ""
                    }
                  ${
                    row.user_address?.city_name
                      ? ", " + row.user_address.city_name
                      : ""
                  }
                  ${
                    row.user_address?.province
                      ? ", " + row.user_address.province.province_name
                      : ""
                  }
                  ${
                    row.user_address?.country
                      ? ", " + row.user_address.country.country_name
                      : ""
                  }
                  ${
                    row.user_address?.zip_code
                      ? ", " + row.user_address?.zip_code
                      : ""
                  }`}</td>
                    <td
                      style={{
                        width: "30%",
                      }}
                    >
                      {row.phone}
                    </td>
                    <td className="uexpand">
                      <Checkbox
                        name="isMGA"
                        label="isMGA"
                        checked={row.isMGA}
                        disabled
                        style={
                          row.isMGA
                            ? {
                                color: "#184437",
                                paddingBottom: 0,
                                paddingTop: 0,
                              }
                            : {
                                paddingBottom: 0,
                                paddingTop: 0,
                              }
                        }
                      />
                    </td>
                    <td>
                      <span
                        className={`ulabel ulabel-${
                          row.is_active
                            ? strings.words.ACTIVE
                            : strings.words.INACTIVE
                        }`}
                        style={{ fontSize: 14 }}
                      >
                        {row.is_active
                          ? strings.words.ACTIVE
                          : strings.words.INACTIVE}
                      </span>
                    </td>
                    <td className="fit">
                      <span className="uactions">
                        <BsFillPencilFill
                          className="cursor-pointer"
                          onClick={editHandler.bind(this, row)}
                          style={row.is_active ? {} : { color: "#DEDEDF" }}
                        />
                        <RiShutDownLine
                          className="udelete-btn cursor-pointer"
                          onClick={deleteHandler.bind(this, row)}
                        />
                      </span>
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoRecordFound colSpan={9} />
            )}
          </tbody>
        </table>
        {tableRecords.length > 0 && (
          <AdminPagination
            currentPage={currentPage}
            totalRecords={MGAUser.total}
            nextPage={nextPage}
            prevPage={prevPage}
            changePage={changePage}
          />
        )}
      </div>

      <ResponsiveDialog
        open={open}
        negativeButtonClickHandler={negativeClickHandler}
        positiveButtonClickHandler={positiveClickHandler}
        handleClose={closeHandler}
        titleText={strings.general.ARE_YOU_SURE}
        subTitleText={"You want to " + isActive + " this user ?"}
        negativeButtonText={strings.words.CANCEL}
        positiveButtonText={strings.words.YES}
      />
    </>
  );
};
