import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Divider, Stack, MenuItem, Popover } from "@mui/material";
import { useCookies } from "react-cookie";
import { getToken } from "../../../services/API";
import { useSelector } from "react-redux";
import { strings, paths } from "../../../utils/constants";

const MENU_OPTIONS = [
  {
    label: strings.general.MY_PROFILE,
    icon: "eva:person-fill",
  },
  {
    label: strings.general.CHANGE_PASSWORD,
    icon: "eva:settings-2-fill",
  },
];

export default function AccountPopover() {
  const navigate = useNavigate();
  const { profileData } = useSelector((state) => state.adminProfileReducer);
  const [open, setOpen] = useState(null);

  const [cookies, setCookie, removeCookie] = useCookies([
    "admin_access_token",
    "admin_refresh_token",
  ]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const menuItemClickHandler = (data) => {
    if (data.label === strings.general.MY_PROFILE) {
      setOpen(null);
      navigate(paths.adminRoutes.PROFILE);
    } else {
      setOpen(null);
      navigate(paths.adminRoutes.CHANGE_PASSWORD);
    }
  };

  const logoutHandler = async () => {
    setOpen(null);
    await getToken(true);
    await localStorage.removeItem("adminToken");
    removeCookie("admin_access_token", {
      path: paths.adminRoutes.ADMIN,
    });
    removeCookie("admin_refresh_token", {
      path: paths.adminRoutes.ADMIN,
    });
    navigate(paths.adminRoutes.ADMIN, { replace: true });
  };

  return (
    <>
      <Link
        variant="subtitle2"
        className="nav-link dropdown-toggle px-0"
        style={{ color: "#184437" }}
        alignItems="left"
        onClick={handleOpen}
      >
        <span className="font-lato">{strings.words.WELCOME},</span>{" "}
        {profileData?.data?.username}
      </Link>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              onClick={menuItemClickHandler.bind(this, option)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>
        <Divider sx={{ borderStyle: "dashed" }} />
        <MenuItem onClick={logoutHandler} sx={{ m: 1 }}>
          {strings.words.LOGOUT}
        </MenuItem>
      </Popover>
    </>
  );
}
