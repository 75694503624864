import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getRequest , getToken } from "../../services/API";

const FETCH_FORM_CARRIER = "fetchCarriers";
const FETCH_ALL_FORM = "fetchAllForms";

export const fetchCarriers = createAsyncThunk(
  FETCH_FORM_CARRIER,
  async ({ language, isAdmin }) => {
    try {
      let carrierResult = await getRequest(
        language,
        "carrier",
        isAdmin
      );
      return carrierResult;
    } catch (error) {
      return JSON.parse(error.message);
    }
  }
);

export const fetchAllForms = createAsyncThunk(
  FETCH_ALL_FORM,
  async ({ formsRequest }) => {
    try {
      if (
        formsRequest.search_key !== undefined &&
        formsRequest.carrier_id !== undefined &&
        formsRequest.isADA === undefined
      ) {
        let formResult = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/${formsRequest.language}/form/?page=${formsRequest.page}&limit=${formsRequest.limit}&order=${formsRequest.order}&search_key=${formsRequest.search_key}&carrier_id=${formsRequest.carrier_id}`,
          {
            headers: {
              token: await getToken(false),
              "Content-Type": "application/json",
            },
          }
        );
        return formResult;
      }else if (
        formsRequest.search_key !== undefined &&
        formsRequest.carrier_id !== undefined &&
        formsRequest.isADA !== undefined
      ) {
        let formResult = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/${formsRequest.language}/form/?page=${formsRequest.page}&limit=${formsRequest.limit}&order=${formsRequest.order}&search_key=${formsRequest.search_key}&carrier_id=${formsRequest.carrier_id}&isADA=${formsRequest.isADA}`,
          {
            headers: {
              token: await getToken(false),
              "Content-Type": "application/json",
            },
          }
        );
        return formResult;
      } else if (
        formsRequest.search_key !== undefined &&
        formsRequest.carrier_id === undefined &&
        formsRequest.isADA !== undefined
      ) {
        let formResult = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/${formsRequest.language}/form/?page=${formsRequest.page}&limit=${formsRequest.limit}&order=${formsRequest.order}&search_key=${formsRequest.search_key}&isADA=${formsRequest.isADA}`,
          {
            headers: {
              token: await getToken(false),
              "Content-Type": "application/json",
            },
          }
        );
        return formResult;
      } else if (
        formsRequest.search_key !== undefined &&
        formsRequest.carrier_id === undefined &&
        formsRequest.isADA === undefined
      ) {
        let formResult = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/${formsRequest.language}/form/?page=${formsRequest.page}&limit=${formsRequest.limit}&order=${formsRequest.order}&search_key=${formsRequest.search_key}`,
          {
            headers: {
              token: await getToken(false),
              "Content-Type": "application/json",
            },
          }
        );
        return formResult;
      } else if (
        formsRequest.search_key === undefined &&
        formsRequest.carrier_id !== undefined &&
        formsRequest.isADA !== undefined
      ) {
        let formResult = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/${formsRequest.language}/form/?page=${formsRequest.page}&limit=${formsRequest.limit}&order=${formsRequest.order}&isADA=${formsRequest.isADA}&carrier_id=${formsRequest.carrier_id}`,
          {
            headers: {
              token: await getToken(false),
              "Content-Type": "application/json",
            },
          }
        );

        return formResult;
      } else if (
        formsRequest.search_key === undefined &&
        formsRequest.carrier_id !== undefined &&
        formsRequest.isADA === undefined
      ) {
        let formResult = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/${formsRequest.language}/form/?page=${formsRequest.page}&limit=${formsRequest.limit}&order=${formsRequest.order}&carrier_id=${formsRequest.carrier_id}`,
          {
            headers: {
              token: await getToken(false),
              "Content-Type": "application/json",
            },
          }
        );

        return formResult;
      } else if (
        formsRequest.isADA !== undefined &&
        formsRequest.carrier_id === undefined &&
        (formsRequest.search_key === "" ||
          formsRequest.search_key === undefined)
      ) {
        let formResult = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/${formsRequest.language}/form/?page=${formsRequest.page}&limit=${formsRequest.limit}&order=${formsRequest.order}&isADA=${formsRequest.isADA}`,
          {
            headers: {
              token: await getToken(false),
              "Content-Type": "application/json",
            },
          }
        );

        return formResult;
      } else {
        let formResult = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/${formsRequest.language}/form/?page=${formsRequest.page}&limit=${formsRequest.limit}&order=${formsRequest.order}`,
          {
            headers: {
              token: await getToken(false),
              "Content-Type": "application/json",
            },
          }
        );

        return formResult;
      }
    } catch (error) {
      return JSON.parse(error.message);
    }
  }
);
