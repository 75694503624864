import { useState } from "react";
import { Stack, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { editSubscriptionPlanAction } from "../../redux/Actions";
import { Toast } from "../../component/Toast";
import { isEmpty } from "../../services/validation";
import ProgressDialog from "../../component/ProgressDialog";
import { strings, paths } from "../../utils/constants";
import CustomTextField from "../../component/CustomTextField";

export default function EditSubScriptionManagementPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading, isEditSubscriptionPlan, editSubscriptionData } =
    useSelector((state) => state.adminSubscriptionPlanReducer);

  const [planNameInEnglish, setPlanNameInEnglish] = useState(
    isEditSubscriptionPlan && editSubscriptionData?.name != null
      ? editSubscriptionData?.name
      : ""
  );

  const [planNameInFranch, setPlanNameInFranch] = useState(
    isEditSubscriptionPlan && editSubscriptionData?.name_fr != null
      ? editSubscriptionData?.name_fr
      : ""
  );

  const [descriptionInEnglish, setDescriptionInEnglish] = useState(
    isEditSubscriptionPlan && editSubscriptionData?.description != null
      ? editSubscriptionData?.description
      : ""
  );

  const [descriptionInFranch, setDescriptionInFranch] = useState(
    isEditSubscriptionPlan && editSubscriptionData?.description_fr != null
      ? editSubscriptionData?.description_fr
      : ""
  );

  const [duration, setDuration] = useState(
    isEditSubscriptionPlan && editSubscriptionData?.min_duration_in_days != null
      ? editSubscriptionData?.min_duration_in_days
      : 0
  );

  const [monthPrice, setMonthPrice] = useState(
    isEditSubscriptionPlan && editSubscriptionData?.month_price != null
      ? editSubscriptionData?.month_price
      : 0
  );

  const [yearPrice, setYearPrice] = useState(
    isEditSubscriptionPlan && editSubscriptionData?.year_price != null
      ? editSubscriptionData?.year_price
      : 0
  );

  const [planType] = useState(
    isEditSubscriptionPlan && editSubscriptionData?.type != null
      ? editSubscriptionData?.type
      : ""
  );

  const [clientLimit, setClientLimit] = useState(
    isEditSubscriptionPlan && editSubscriptionData?.client_limit != null
      ? editSubscriptionData?.client_limit
      : 0
  );
  const submitUpdateHandler = async (e) => {
    e.preventDefault();
    if (isEmpty(planNameInEnglish)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.PLAN_NAME_ENGLISH_REQUIRED,
      });
    } else if (isEmpty(planNameInFranch)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.PLAN_NAME_FRENCH_REQUIRED,
      });
    } else if (isEmpty(descriptionInEnglish)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.DESCRIPTION_ENGLISH_REQUIRED,
      });
    } else if (isEmpty(descriptionInFranch)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.DESCRIPTION_FRENCH_REQUIRED,
      });
    } else if (
      planType === strings.subscriptionsTypes.FREE &&
      (isEmpty(JSON.stringify(duration)) ||
        isEmpty(duration.toString()) ||
        duration === 0 ||
        duration === "0")
    ) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.DURATION_IN_DAYS_REQUIRED,
      });
    } else if (
      (planType === strings.subscriptionsTypes.INDIVIDUAL ||
        planType === strings.subscriptionsTypes.BUSINESS) &&
      (isEmpty(JSON.stringify(monthPrice)) ||
        isEmpty(monthPrice.toString()) ||
        monthPrice === 0 ||
        monthPrice === "0")
    ) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.MONTHLY_PRICE_REQUIRED,
      });
    } else if (
      (planType === strings.subscriptionsTypes.INDIVIDUAL ||
        planType === strings.subscriptionsTypes.BUSINESS) &&
      (isEmpty(JSON.stringify(yearPrice)) ||
        isEmpty(yearPrice.toString()) ||
        yearPrice === 0 ||
        yearPrice === "0")
    ) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.YEARLY_PRICE_REQUIRED,
      });
    } else if (
      planType === strings.subscriptionsTypes.BUSINESS &&
      (isEmpty(JSON.stringify(clientLimit)) ||
        isEmpty(clientLimit.toString()) ||
        clientLimit === 0 ||
        clientLimit === "0")
    ) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.CLIENT_LIMIT_REQUIRED,
      });
    } else {
      let requestInfo = {
        id: editSubscriptionData?.id,
        name: planNameInEnglish,
        name_fr: planNameInFranch,
        description: descriptionInEnglish,
        description_fr: descriptionInFranch,
        min_duration_in_days: duration,
        month_price: monthPrice,
        year_price: yearPrice,
        client_limit: clientLimit,
      };
      let editSubscriptionResponse = await dispatch(
        editSubscriptionPlanAction({
          language: strings.language.EN,
          requestInfo: requestInfo,
        })
      );
      if (editSubscriptionResponse.payload?.success) {
        navigate(paths.adminRoutes.SUBSCRIPRIONS, {
          replace: true,
        });
      }
    }
  };

  const planNameChangeEnHandler = (e) => {
    setPlanNameInEnglish(e.target.value);
  };
  const planNameChangeFrHandler = (e) => {
    setPlanNameInFranch(e.target.value);
  };
  const descriptionChangeEnHandler = (e) => {
    setDescriptionInEnglish(e.target.value);
  };
  const descriptionChangeFrHandler = (e) => {
    setDescriptionInFranch(e.target.value);
  };
  const durationInDayChangeHandler = (e) => {
    const regex = new RegExp(/^[0-9]*$/);
    if (regex.test(e.target.value)) {
      setDuration(e.target.value);
    }
  };
  const monthPriceChangeHandler = (e) => {
    const regex = new RegExp(/^[0-9]*$/);
    if (regex.test(e.target.value)) {
      setMonthPrice(e.target.value);
    }
  };
  const yearPriceChangeHandler = (e) => {
    const regex = new RegExp(/^[0-9]*$/);
    if (regex.test(e.target.value)) {
      setYearPrice(e.target.value);
    }
  };
  const clientLimitChangeHandler = (e) => {
    const regex = new RegExp(/^[0-9]*$/);
    if (regex.test(e.target.value)) {
      setClientLimit(e.target.value);
    }
  };
  const cancelHandler = () => {
    navigate(paths.adminRoutes.SUBSCRIPRIONS, {
      replace: true,
    });
  };
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom sx={{ marginLeft: 5 }}>
          {isEditSubscriptionPlan
            ? strings.general.EDIT_SUBSCRIPTION_PLAN
            : strings.general.ADD_SUBSCRIPTION_PLAN}
        </Typography>
      </Stack>
      <form
        style={{
          marginBottom: "5px",
          marginLeft: "50px",
          marginRight: "50px",
        }}
      >
        <CustomTextField
          style={{
            width: "100%",
            marginTop: "5px",
            marginBottom: "5px",
            marginLeft: "5px",
            marginRight: "5px",
          }}
          type="text"
          label={`${strings.general.PLAN_NAME}*`}
          variant="outlined"
          focusColor="#184437"
          value={planNameInEnglish}
          autoComplete="off"
          autoFocus
          inputProps={{ maxLength: 150 }}
          onChange={planNameChangeEnHandler}
        />
        <br />
        <CustomTextField
          style={{
            width: "100%",
            marginTop: "5px",
            marginBottom: "5px",
            marginLeft: "5px",
            marginRight: "5px",
          }}
          type="text"
          label={`${strings.general.PLAN_NAME_FRENCH}*`}
          variant="outlined"
          focusColor="#184437"
          value={planNameInFranch}
          autoComplete="off"
          onChange={planNameChangeFrHandler}
        />
        <br />
        <CustomTextField
          style={{
            width: "100%",
            marginTop: "5px",
            marginBottom: "5px",
            marginLeft: "5px",
            marginRight: "5px",
          }}
          type="text"
          label={`${strings.words.DESCRIPTION}*`}
          variant="outlined"
          focusColor="#184437"
          value={descriptionInEnglish}
          autoComplete="off"
          inputProps={{ maxLength: 150 }}
          onChange={descriptionChangeEnHandler}
        />
        <br />
        <CustomTextField
          style={{
            width: "100%",
            marginTop: "5px",
            marginBottom: "5px",
            marginLeft: "5px",
            marginRight: "5px",
          }}
          type="text"
          label={`${strings.words.DESCRIPTION}*`}
          variant="outlined"
          focusColor="#184437"
          value={descriptionInFranch}
          autoComplete="off"
          onChange={descriptionChangeFrHandler}
        />

        {planType === strings.subscriptionsTypes.FREE ? (
          <CustomTextField
            style={{
              width: "100%",
              marginTop: "10px",
              marginBottom: "5px",
              marginLeft: "5px",
              marginRight: "5px",
            }}
            type="text"
            label={`${strings.general.DURATION_IN_DAYS}*`}
            variant="outlined"
            focusColor="#184437"
            value={duration}
            autoComplete="off"
            onChange={durationInDayChangeHandler}
          />
        ) : (
          <>
            <CustomTextField
              style={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "5px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
              type="text"
              label={`${strings.general.MONTHLY_PRICE}*`}
              variant="outlined"
              focusColor="#184437"
              value={monthPrice}
              autoComplete="off"
              onChange={monthPriceChangeHandler}
            />
            <CustomTextField
              style={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "5px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
              type="text"
              label={`${strings.general.YEARLY_PRICE}*`}
              variant="outlined"
              focusColor="#184437"
              value={yearPrice}
              autoComplete="off"
              onChange={yearPriceChangeHandler}
            />
            <br />
          </>
        )}
        {planType === strings.subscriptionsTypes.BUSINESS && (
          <CustomTextField
            style={{
              width: "100%",
              marginTop: "10px",
              marginBottom: "5px",
              marginLeft: "5px",
              marginRight: "5px",
            }}
            type="text"
            label={`${strings.general.CLIENT_LIMITS}*`}
            variant="outlined"
            focusColor="#184437"
            value={clientLimit}
            autoComplete="off"
            onChange={clientLimitChangeHandler}
          />
        )}
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="start"
          justifyContent="left"
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "#184437",
              marginTop: "12px",
              marginLeft: "4px",
              marginRight: "14px",
            }}
            onClick={submitUpdateHandler}
          >
            {isEditSubscriptionPlan
              ? strings.words.UPDATE
              : strings.words.SUBMIT}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "#184437",
              marginTop: "12px",
            }}
            onClick={cancelHandler}
          >
            {strings.words.CANCEL}
          </Button>
        </Stack>
      </form>
      {isLoading && <ProgressDialog open={isLoading} isAdmin={true} />}
    </>
  );
}
