import { createSlice } from "@reduxjs/toolkit";
import { adminCustomerPaymentsAction } from "../Actions";

const ADMIN_CUSTOMER_PAYMENTS_SLICE_NAME = "adminCustomerPaymentsDetails";

const adminCustomerPaymentsInitialState = {
  adminCustomerPaymentsData: {},
  isLoading: false,
};

const adminCustomerPaymentsSlice = createSlice({
  name: ADMIN_CUSTOMER_PAYMENTS_SLICE_NAME,
  initialState: adminCustomerPaymentsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(adminCustomerPaymentsAction.pending, (state) => {
        state.isLoading = true;
        state.adminCustomerPaymentsData = {};
        return state;
      })
      .addCase(
        adminCustomerPaymentsAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.adminCustomerPaymentsData = action.payload;
          return state;
        }
      )
      .addCase(
        adminCustomerPaymentsAction.rejected,
        (state, action) => {
          state.isLoading = false;
          state.adminCustomerPaymentsData = action.payload;
        }
      );
  },
});

export const {} = adminCustomerPaymentsSlice.actions;
export const adminCustomerPaymentsReducer = adminCustomerPaymentsSlice.reducer;
