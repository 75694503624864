import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { Checkbox, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAdminPolicyAction,
  fetchAdminCarrierAction,
  addAdminFormAction,
  editAdminFormAction,
} from "../../redux/Actions";
import { Toast } from "../../component/Toast";
import { isEmpty, urlValidation } from "../../services/validation";
import ProgressDialog from "../../component/ProgressDialog";
import { strings, paths } from "../../utils/constants";
import CustomTextField from "../../component/CustomTextField";

const AddEditFormManagementPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading, isEditForm, editFormData } = useSelector(
    (state) => state.adminFormManagementReducer
  );

  const [formId] = useState(isEditForm ? editFormData?.id : null);

  const [fileList, setFileList] = useState(null);

  const [formName, setFormName] = useState(
    isEditForm && editFormData?.form_name != null ? editFormData?.form_name : ""
  );

  const [formLanguage, setFormLanguage] = useState(
    isEditForm && editFormData?.form_lang != null
      ? editFormData?.form_lang
      : "en"
  );

  const [isFile, setIsFile] = useState(false);

  // EDITED
  const [isFormUrl, setIsFormUrl] = useState(
    isEditForm &&
      editFormData?.form_actual_url != null &&
      editFormData?.form_actual_url !== ""
      ? editFormData?.form_actual_url
      : editFormData?.form_url != null
      ? editFormData?.form_url
      : ""
  );

  const [formDescription, setFormDescription] = useState(
    isEditForm && editFormData?.form_description != null
      ? editFormData?.form_description
      : ""
  );

  const [formNumber, setFormNumber] = useState(
    isEditForm && editFormData?.form_number != null
      ? editFormData?.form_number?.toString()
      : ""
  );

  const [formVersion, setFormVersion] = useState(
    isEditForm && editFormData?.form_version != null
      ? editFormData?.form_version?.toString()
      : ""
  );

  const [isAda, setIsAda] = useState(
    isEditForm && editFormData?.isADA != null ? editFormData?.isADA : false
  );

  const [isMapped, setIsMapped] = useState(
    isEditForm && editFormData?.isMapped != null
      ? editFormData?.isMapped
      : false
  );

  const [carrierID, setCarrierID] = useState(
    isEditForm && editFormData?.carrier_id != null
      ? editFormData?.carrier_id
      : strings.general.SELECT_CARRIER
  );

  const [privacyPolicyID, setPrivacyPolicyID] = useState(
    isEditForm && editFormData?.policy_service_id != null
      ? editFormData?.policy_service_id
      : strings.general.SELECT_BUSINESS_ACTION
  );

  const { fetchAdminCarrierData } = useSelector(
    (state) => state.adminCarrierReducer
  );

  const { fetchAdminPolicyData } = useSelector(
    (state) => state.adminPolicyReducer
  );

  const carrierList = fetchAdminCarrierData?.data
    ? fetchAdminCarrierData.data
    : [];

  const policyList = fetchAdminPolicyData?.data
    ? fetchAdminPolicyData.data
    : [];

  const fileUplodHandler = (e) => {
    setFileList(e.target.files[0]);
  };

  const listOfCarrierAndPolicy = async () => {
    await dispatch(
      fetchAdminCarrierAction({ language: strings.language.EN, isAdmin: true })
    );
    await dispatch(
      fetchAdminPolicyAction({ language: strings.language.EN, isAdmin: true })
    );
  };

  useEffect(() => {
    listOfCarrierAndPolicy();
  }, []);

  const submitRefreshHandler = async (e) => {
    submitHandler(e, true);
  };

  const submitUpdateHandler = async (e) => {
    submitHandler(e, false);
  };

  const submitHandler = async (e, isRefresh) => {
    e.preventDefault();
    if (isEmpty(formName)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.FORM_NAME_ENGLISH_REQUIRED,
      });
    } else if (isEmpty(formLanguage)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.FORM_LANGUAGE_REQUIRED,
      });
    } else if (
      isEmpty(carrierID) ||
      carrierID === strings.general.SELECT_CARRIER
    ) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.general.SELECT_CARRIER,
      });
    } else if (
      (!isFile && isFormUrl.trim() === "") ||
      (isFile && fileList == null)
    ) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.FORM_UPLOD_URL_REQUIRED,
      });
    } else if (!isFile && !isEmpty(isFormUrl) && !urlValidation(isFormUrl)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.FORM_UPLOD_URL_VALIDATION,
      });
    } else {
      if (isEditForm) {
        let editFormResponse;
        if (!isFile) {
          let requestInfo = {
            id: formId,
            form_name: formName,
            form_lang: formLanguage,
            description: formDescription,
            form_number: formNumber,
            form_version: formVersion,
            isADA: isAda,
            isMapped: isMapped,
            carrier_id: carrierID,
            policy_service_id:
              privacyPolicyID === strings.general.SELECT_BUSINESS_ACTION
                ? ""
                : privacyPolicyID,
            is_file: false,
            form_url: isFormUrl,
            is_refresh: isRefresh,
          };

          editFormResponse = await dispatch(
            editAdminFormAction({
              language: strings.language.EN,
              requestInfo: requestInfo,
              isFile: false,
              customTost: isAda,
            })
          );
        } else {
          const requestInfo = new FormData();

          requestInfo.append("form_file", fileList);
          requestInfo.append("id", formId);
          requestInfo.append("form_name", formName);
          requestInfo.append("form_lang", formLanguage);
          requestInfo.append("form_number", formNumber);
          requestInfo.append("form_version", formVersion);
          requestInfo.append("description", formDescription);
          requestInfo.append("isADA", isAda);
          requestInfo.append("isMapped", isMapped);
          requestInfo.append("carrier_id", carrierID);
          requestInfo.append("policy_service_id", privacyPolicyID === strings.general.SELECT_BUSINESS_ACTION
          ? ""
          : privacyPolicyID);
          requestInfo.append("is_file", true);
          requestInfo.append("is_refresh", isRefresh);

          editFormResponse = await dispatch(
            editAdminFormAction({
              language: strings.language.EN,
              requestInfo: requestInfo,
              isFile: true,
              customTost: isAda,
            })
          );
        }
        if (editFormResponse.payload?.success) {
          if (editFormResponse.payload.data.isADA) {
            navigate(paths.adminRoutes.PDF_SECTION_FORM);
          } else {
            navigate(paths.adminRoutes.FORMS, { replace: true });
          }
        }
      } else {
        let addFormResponse;
        if (!isFile) {
          let requestInfo = {
            form_name: formName,
            form_lang: formLanguage,
            description: formDescription,
            form_number: formNumber,
            form_version: formVersion,
            isADA: isAda,
            isMapped: isMapped,
            carrier_id: carrierID,
            policy_service_id: privacyPolicyID === strings.general.SELECT_BUSINESS_ACTION
            ? ""
            : privacyPolicyID,
            is_file: false,
            form_url: isFormUrl,
          };

          addFormResponse = await dispatch(
            addAdminFormAction({
              language: strings.language.EN,
              requestInfo: requestInfo,
              isFile: false,
              customTost: isAda,
            })
          );
        } else {
          const requestInfo = new FormData();

          requestInfo.append("form_file", fileList);
          requestInfo.append("form_name", formName);
          requestInfo.append("form_lang", formLanguage);
          requestInfo.append("description", formDescription);
          requestInfo.append("form_number", formNumber);
          requestInfo.append("form_version", formVersion);
          requestInfo.append("isADA", isAda);
          requestInfo.append("isMapped", isMapped);
          requestInfo.append("carrier_id", carrierID);
          requestInfo.append("policy_service_id", privacyPolicyID === strings.general.SELECT_BUSINESS_ACTION
          ? ""
          : privacyPolicyID);
          requestInfo.append("is_file", true);

          addFormResponse = await dispatch(
            addAdminFormAction({
              language: strings.language.EN,
              requestInfo: requestInfo,
              isFile: true,
              customTost: isAda,
            })
          );
        }
        if (addFormResponse.payload?.success) {
          if (addFormResponse.payload.data.isADA) {
            navigate(paths.adminRoutes.PDF_SECTION_FORM);
          } else {
            navigate(paths.adminRoutes.FORMS, { replace: true });
          }
        }
      }
    }
  };

  const formNameChangeEnHandler = (e) => {
    setFormName(e.target.value);
  };

  const formDescriptionChangeEnHandler = (e) => {
    setFormDescription(e.target.value);
  };

  const formNumberChangeHandler = (e) => {
    setFormNumber(e.target.value);
  };

  const formVersionChangeHandler = (e) => {
    setFormVersion(e.target.value);
  };
  const carrierChangeHandler = (e) => {
    setCarrierID(e.target.value);
  };
  const policyChangeHandler = (e) => {
    setPrivacyPolicyID(e.target.value);
  };
  const adaChangeHandler = () => setIsAda(!isAda);
  const mappedChangeHandler = () => setIsMapped(!isMapped);
  const formURLChangeHandler = () => {
    setIsFile(false);
    setFileList(null);
  };
  const formURLTextChangeHandler = (e) => {
    setIsFile(false);
    setFileList(null);
    setIsFormUrl(e.target.value);
  };
  const uploadFormChangeHandler = () => {
    setIsFile(true);
    if (!isEditForm) {
      setIsFormUrl("");
    }
  };
  const formLanguageChangeHandler = (e) => {
    setFormLanguage(e.target.value);
  };
  const cancelHandler = () => {
    navigate(paths.adminRoutes.FORMS, {
      replace: true,
    });
  };
  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom sx={{ marginLeft: 5 }}>
            {isEditForm ? strings.general.EDIT_FORM : strings.general.ADD_FORM}
          </Typography>
        </Stack>
        <form style={{ marginLeft: "50px", marginRight: "50px" }}>
          <div>
            <FormControl
              sx={{ width: 310, marginLeft: 1, marginBottom: "10px" }}
            >
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-lang-label"
                name="row-radio-buttons-group-lang"
                defaultValue={formLanguage}
              >
                <FormControlLabel
                  value="en"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#184437",
                        },
                      }}
                    />
                  }
                  label="English Form"
                  style={{ colo: "#184437" }}
                  onChange={formLanguageChangeHandler}
                />
                <FormControlLabel
                  value="fr"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#184437",
                        },
                      }}
                    />
                  }
                  label="French Form"
                  style={{ color: "#184437" }}
                  onChange={formLanguageChangeHandler}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <CustomTextField
            style={{
              width: "100%",
              marginTop: "5px",
              marginBottom: "5px",
              marginLeft: "5px",
              marginRight: "5px",
            }}
            type="text"
            label={`${strings.general.FORM_NAME_ENGLISH}*`}
            variant="outlined"
            focusColor="#184437"
            value={formName}
            autoFocus
            autoComplete="off"
            inputProps={{ maxLength: 150 }}
            onChange={formNameChangeEnHandler}
          />
          <br />
          <CustomTextField
            style={{
              width: "100%",
              marginTop: "5px",
              marginBottom: "5px",
              marginLeft: "5px",
              marginRight: "5px",
            }}
            type="text"
            label={strings.general.FORM_DESCRIPTION_ENGLISH}
            variant="outlined"
            focusColor="#184437"
            value={formDescription}
            autoComplete="off"
            inputProps={{ maxLength: 150 }}
            onChange={formDescriptionChangeEnHandler}
          />
          <br />
          <CustomTextField
            style={{
              width: "100%",
              marginTop: "5px",
              marginBottom: "5px",
              marginLeft: "5px",
              marginRight: "5px",
            }}
            type="text"
            label={strings.general.FORM_NUMBER}
            variant="outlined"
            focusColor="#184437"
            value={formNumber}
            autoComplete="off"
            inputProps={{ maxLength: 150 }}
            onChange={formNumberChangeHandler}
          />
          <br />
          <CustomTextField
            style={{
              width: "100%",
              marginTop: "5px",
              marginBottom: "5px",
              marginLeft: "5px",
              marginRight: "5px",
            }}
            type="text"
            label={strings.general.FORM_VERSION}
            variant="outlined"
            focusColor="#184437"
            value={formVersion}
            autoComplete="off"
            inputProps={{ maxLength: 150 }}
            onChange={formVersionChangeHandler}
          />
          <br />
          <Stack
            direction={{
              xs: "column",
              md: "row",
            }}
          >
            <div style={{ marginLeft: "6px", minWidth: "30%" }}>
              <select
                className="aform-select form-select"
                id="carrierlist"
                aria-label="Default select example"
                responsive={{
                  xsmall: {
                    maxWidth: "30%",
                  },
                  small: {
                    minWidth: "30%",
                  },
                }}
                value={carrierID}
                onChange={carrierChangeHandler}
              >
                <option selected>{strings.general.SELECT_CARRIER}</option>
                {carrierList.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.carrier_name}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ marginLeft: "6px", minWidth: "30%" }}>
              <select
                className="aform-select form-select"
                id="policylist"
                aria-label="Default select example"
                responsive={{
                  xsmall: {
                    maxWidth: "30%",
                  },
                  small: {
                    minWidth: "30%",
                  },
                }}
                value={privacyPolicyID}
                onChange={policyChangeHandler}
              >
                <option selected>
                  {strings.general.SELECT_BUSINESS_ACTION}
                </option>
                {policyList.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.policy_service_name}
                  </option>
                ))}
              </select>
            </div>
          </Stack>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              marginTop: "8px",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                style={{
                  color: "#184437",
                  paddingBottom: 0,
                  paddingTop: 9,
                }}
                checked={isAda}
                onClick={adaChangeHandler}
              />
              <h6 style={{ marginTop: 20 }}>
                {strings.general.IS_ADA_COMPATIBLE}
              </h6>
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                marginLeft: 30,
              }}
            >
              <Checkbox
                style={{
                  color: "#184437",
                  paddingBottom: 0,
                  paddingTop: 9,
                }}
                checked={isMapped}
                onClick={mappedChangeHandler}
              />
              <h6 style={{ marginTop: 20 }}>{strings.general.IS_MAPPED}</h6>
            </div>
          </div>
          <FormControl sx={{ width: 310, marginLeft: 1, marginTop: "10px" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              defaultValue="Form URL"
            >
              <FormControlLabel
                value="Form URL"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#184437",
                      },
                    }}
                  />
                }
                label="Form URL"
                style={{ colo: "#184437" }}
                onChange={formURLChangeHandler}
              />
              <FormControlLabel
                value="Upload Form"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#184437",
                      },
                    }}
                  />
                }
                label="Upload Form"
                style={{ color: "#184437" }}
                onChange={uploadFormChangeHandler}
              />
            </RadioGroup>
          </FormControl>
          <Stack
            direction="column"
            alignItems="start"
            style={{ marginTop: "10px", marginLeft: "10px" }}
          >
            {!isFile ? (
              <CustomTextField
                style={{
                  width: "100%",
                  marginTop: "5px",
                  marginBottom: "5px",
                  marginRight: "5px",
                }}
                type="text"
                label={`${strings.general.FORM_URL}*`}
                variant="outlined"
                focusColor="#184437"
                value={isFormUrl}
                autoComplete="off"
                onChange={formURLTextChangeHandler}
              />
            ) : isAda ? (
              <input
                type="file"
                onChange={fileUplodHandler}
                style={{ marginTop: "20px" }}
                accept=".pdf"
              />
            ) : (
              <input
                type="file"
                onChange={fileUplodHandler}
                style={{ marginTop: "20px" }}
                accept=".pdf, .txt, .csv, .doc, .docx"
              />
            )}
          </Stack>
        </form>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="start"
          justifyContent="left"
          style={{ marginLeft: "60px", paddingBottom: "20px" }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "#184437",
              marginTop: "20px",
              marginRight: "12px",
            }}
            onClick={submitUpdateHandler}
          >
            {isEditForm ? strings.words.UPDATE : strings.words.SUBMIT}
          </Button>
          {isAda && isEditForm && (
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#184437",
                marginTop: "20px",
                marginRight: "12px",
              }}
              onClick={submitRefreshHandler}
            >
              {strings.general.REFRESH_ADA_FORM}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#184437", marginTop: "20px" }}
            onClick={cancelHandler}
          >
            {strings.words.CANCEL}
          </Button>
        </Stack>
      </div>
      {isLoading && <ProgressDialog open={isLoading} isAdmin={true} />}
    </>
  );
};

export default AddEditFormManagementPage;
