import { createSlice } from "@reduxjs/toolkit";
import {
  fetchUserLicenseKeyAction,
  updateUserLicenseKeyAction,
} from "../Actions";

const ADMIN_USER_LICENSE_KEY_SLICE_NAME = "adminUserLicenseKeyDetails";

const adminUserLicenseKeyInitialState = {
  updateUserLicenseKeyData: {},
  userLicenseKeyData: {},
  isLoading: false,
};

const adminUserLicenseKeySlice = createSlice({
  name: ADMIN_USER_LICENSE_KEY_SLICE_NAME,
  initialState: adminUserLicenseKeyInitialState,
  reducers: {},
  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(updateUserLicenseKeyAction.pending, (state) => {
        state.isLoading = true;
        state.updateUserLicenseKeyData = {};
        return state;
      })
      .addCase(updateUserLicenseKeyAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateUserLicenseKeyData = action.payload;
        return state;
      })
      .addCase(updateUserLicenseKeyAction.rejected, (state, action) => {
        state.isLoading = false;
        state.updateUserLicenseKeyData = action.payload;
      })
      .addCase(fetchUserLicenseKeyAction.pending, (state) => {
        state.isLoading = true;
        state.userLicenseKeyData = {};
        return state;
      })
      .addCase(fetchUserLicenseKeyAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userLicenseKeyData = action.payload;
        return state;
      })
      .addCase(fetchUserLicenseKeyAction.rejected, (state, action) => {
        state.isLoading = false;
        state.userLicenseKeyData = action.payload;
      });
  },
});

export const {} = adminUserLicenseKeySlice.actions;
export const adminUserLicenseKeyReducer = adminUserLicenseKeySlice.reducer;
