import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteRequest,
  patchRequest,
  postRequest,
  getRequestWithParams,
} from "../../services/API";
import { isEmpty } from "../../services/validation";

const ADD_ADMIN_MGA_USER_DETAILS = "addAdminMGAUserAction";
const EDIT_ADMIN_MGA_USER_DETAILS = "editAdminMGAUserAction";
const DELETE_ADMIN_MGA_USER_DETAILS = "deleteAdminMGAUserAction";
const FETCH_MGA_USER_DETAILS = "fetchMGAUserDetails";

export const fetchMGAUserAction = createAsyncThunk(
  FETCH_MGA_USER_DETAILS,
  async (requestInfo, { dispatch, rejectWithValue }) => {
    let requestUrl =
      "?page=" + requestInfo.page + "&limit=" + requestInfo.limit;

    try {
      if (!isEmpty(requestInfo.searchText)) {
        requestUrl = requestUrl + "&search_key=" + requestInfo.searchText;
      }

      if (requestInfo.isMGA === "isMGA") {
        requestUrl = requestUrl + "&isMGA=true";
      }

      if (requestInfo.isMGA === "isNonMGA") {
        requestUrl = requestUrl + "&isMGA=false";
      }

      let fetchMGAUserDetailsResult = await getRequestWithParams(
        requestInfo.language,
        "adminUserGet",
        requestUrl,
        true
      );
      return fetchMGAUserDetailsResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);

export const addAdminMGAUserAction = createAsyncThunk(
  ADD_ADMIN_MGA_USER_DETAILS,
  async (
    {
      language,
      email,
      firstName,
      lastName,
      phone,
      isMGA,
      address,
      zipcode,
      city,
      provinceId,
      countryId,
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      let requestInfo = {
        email: email,
        firstname: firstName,
        lastname: lastName,
        phone: phone,
        isMGA: isMGA,
        address_line_1: address,
        zip_code: zipcode,
        city_name: city,
        province_id: provinceId,
        country_id: countryId,
      };

      let addMGAUserResult = await postRequest(
        language,
        "adminUserAdd",
        requestInfo,
        true
      );
      return addMGAUserResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);

export const editAdminMGAUserAction = createAsyncThunk(
  EDIT_ADMIN_MGA_USER_DETAILS,
  async (
    {
      language,
      userId,
      email,
      firstName,
      lastName,
      phone,
      isMGA,
      address,
      zipcode,
      city,
      provinceId,
      countryId,
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      let requestInfo = {
        user_id: userId,
        email: email,
        firstname: firstName,
        lastname: lastName,
        phone: phone,
        isMGA: isMGA,
        address_line_1: address,
        zip_code: zipcode,
        city_name: city,
        province_id: provinceId,
        country_id: countryId,
      };

      let editMGAUserResult = await patchRequest(
        language,
        "adminUserUpdate",
        requestInfo,
        true
      );
      return editMGAUserResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);

export const deleteAdminMGAUserAction = createAsyncThunk(
  DELETE_ADMIN_MGA_USER_DETAILS,
  async ({ language, userId }, { dispatch, rejectWithValue }) => {
    try {
      let deleteMGAUserResult = await deleteRequest(
        language,
        "adminUserDelete",
        userId,
        true
      );
      return deleteMGAUserResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);
