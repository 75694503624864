import React from "react";
import { useTranslation } from "react-i18next";
import { strings } from "../../../utils/constants";

const VideoCard = () => {
  const {t} = useTranslation();
  const videodata = [
    {
      v_title: "Demo - 1",
      v_url: "../../../assets/video/food.mp4",
      v_desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.`,
    },
    {
      v_title: "Demo - 2",
      v_url: "../../../assets/video/food.mp4",
      v_desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.`,
    },
    {
      v_title: "Demo - 3",
      v_url: "../../../assets/video/food.mp4",
      v_desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.`,
    },
  ];
  return (
    <div className="row mb-md-5 pb-4 fg-gray-30">
      <h2 className="font-lato_bold font-24 mb-4">
        {t(strings.translate.VIDEO_DEMOS)}
      </h2>
      {videodata.map((item, index) => (
        <div className="col-lg-4" key={item.v_title + index}>
          <video width="424" height="240" controls className="ratio ratio-16x9">
            <source src={item.v_url} type="video/mp4" />
          </video>
          <h3 className="font-18 font-lato_bold mt-3 mb-2">{item.v_title}</h3>
          <p className="line-height-2">{item.v_desc}</p>
        </div>
      ))}
    </div>
  );
};

export default VideoCard;
