import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAdminCarrierAction,
  editAdminCarrierAction,
  addAdminCarrierAction,
} from "../Actions";

const ADMIN_CARRIER_SLICE_NAME = "adminCarrierDetails";

const adminCarrierInitialState = {
  fetchAdminCarrierData: {},
  editAdminCarrierData: {},
  isLoading: false,
  isEditCarrier: false,
  editCarrierData: {},
};

const adminCarrierSlice = createSlice({
  name: ADMIN_CARRIER_SLICE_NAME,
  initialState: adminCarrierInitialState,
  reducers: {
    isFromEditCarrier(state, action) {
      state.isEditCarrier = action.payload;
      return state;
    },
    isFromEditCarrierData(state, action) {
      state.editCarrierData = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(fetchAdminCarrierAction.pending, (state) => {
        state.isLoading = true;
        state.fetchAdminCarrierData = {};
        state.editAdminCarrierData = {};
        return state;
      })
      .addCase(fetchAdminCarrierAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.fetchAdminCarrierData = action.payload;
        state.editAdminCarrierData = {};
        return state;
      })
      .addCase(fetchAdminCarrierAction.rejected, (state, action) => {
        state.isLoading = false;
        state.fetchAdminCarrierData = action.payload;
        state.editAdminCarrierData = {};
      })
      .addCase(editAdminCarrierAction.pending, (state) => {
        state.isLoading = true;
        state.editAdminCarrierData = {};
        return state;
      })
      .addCase(editAdminCarrierAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.editAdminCarrierData = action.payload;
        return state;
      })
      .addCase(editAdminCarrierAction.rejected, (state, action) => {
        state.isLoading = false;
        state.editAdminCarrierData = action.payload;
      })
      .addCase(addAdminCarrierAction.pending, (state) => {
        state.isLoading = true;
        state.editAdminCarrierData = {};
        return state;
      })
      .addCase(addAdminCarrierAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.editAdminCarrierData = action.payload;
        return state;
      })
      .addCase(addAdminCarrierAction.rejected, (state, action) => {
        state.isLoading = false;
        state.editAdminCarrierData = action.payload;
      });
  },
});

export const { isFromEditCarrier, isFromEditCarrierData } = adminCarrierSlice.actions;
export const adminCarrierReducer = adminCarrierSlice.reducer;
