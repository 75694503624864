import { createSlice } from "@reduxjs/toolkit";
import { countryList, getCMSList, stateList } from "../Actions";
import { strings } from "../../utils/constants";

const GENERAL_SLICE_NAME = "genralSlice";
var language = window.navigator.userLanguage || window.navigator.language;
const generalInitialState = {
  language: language.includes(strings.language.EN)
    ? strings.language.EN
    : strings.language.FR,
  activeName: "Home",
  isLoading: false,
  countryListData: [],
  stateListData: [],
  cmsList: [],
};
const genralSlice = createSlice({
  name: GENERAL_SLICE_NAME,
  initialState: generalInitialState,
  reducers: {
    changeLanguage(state,action){
      state.language = action.payload;
      return state;
    },
    changeActiveHeader(state, action){
      state.activeName = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(countryList.pending, (state) => {
        state.isLoading = true;
        state.countryListData = {};
        return state;
      })
      .addCase(
        countryList.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.countryListData = action.payload;
          return state;
        }
      )
      .addCase(
        countryList.rejected,
        (state) => {
          state.isLoading = false;
          state.countryListData = {};
        }
      )
    /// State list
      .addCase(stateList.pending, (state) => {
        state.isLoading = true;
        state.stateListData = {};
        return state;
      })
      .addCase(
        stateList.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.stateListData = action.payload;
          return state;
        }
      )
      .addCase(
        stateList.rejected,
        (state) => {
          state.isLoading = false;
          state.stateListData = {};
        }
      )

    // cms list

      .addCase(getCMSList.pending, (state) => {
        state.isLoading = true;
        state.cmsList = {};
        return state;
      })
      .addCase(
        getCMSList.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.cmsList = action.payload;
          return state;
        }
      )
      .addCase(
        getCMSList.rejected,
        (state) => {
          state.isLoading = false;
          state.cmsList = {};
        }
      );
  },

});

export const {changeLanguage, changeActiveHeader} = genralSlice.actions;
export const generalReducer = genralSlice.reducer;
