import { createSlice } from "@reduxjs/toolkit";
import { fetchProvinceAction } from "../Actions";

const FETCH_PROVINCE_SLICE_NAME = "fetchProvinceDetails";

const fetchProvinceInitialState = {
  fetchProvince: {},
  isProvinceLoading: false,
};

const fetchProvinceSlice = createSlice({
  name: FETCH_PROVINCE_SLICE_NAME,
  initialState: fetchProvinceInitialState,
  reducers: {},
  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(fetchProvinceAction.pending, (state) => {
        state.isProvinceLoading = true;
        state.fetchProvince = {};
        return state;
      })
      .addCase(
        fetchProvinceAction.fulfilled,
        (state, action) => {
          state.isProvinceLoading = false;
          state.fetchProvince = action.payload;
          return state;
        }
      )
      .addCase(
        fetchProvinceAction.rejected,
        (state, action) => {
          state.isProvinceLoading = false;
          state.fetchProvince = action.payload;
        }
      );
  },
});

export const {} = fetchProvinceSlice.actions;
export const fetchProvinceReducer = fetchProvinceSlice.reducer;
