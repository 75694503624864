import React, { useEffect, useState } from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "../../../component/Toast";
import { loginAction } from "../../../redux/Actions";
import { isEmpty } from "../../../services/validation";
import { useCookies } from "react-cookie";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { getToken } from "../../../services/API";
import {
  changeActiveHeader,
  changeLanguage,
} from "../../../redux/Reducers/GeneralReducer";
import ForgotPasswordPage from "../../../component/ForgotPassword";
import { onSuccess } from "../../../redux/Reducers/ResetPasswordReducer";
import { toast } from "react-toastify";
import ProgressDialog from "../../../component/ProgressDialog";
import { strings, paths } from "../../../utils/constants";
import { useLocation } from "react-router-dom";

const LoginPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [flag, setFlag] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cookies, setCookie] = useCookies(["access_token", "refresh_token"]);
  const isAdmin = false;
  const { language } = useSelector((state) => state.generalReducer);

  // const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    const cookie = cookies.access_token;
    if (cookie) {
      navigation(paths.userRoutes.DASHBOARD);
    }
    dispatch(onSuccess("#exampleModal"));

    if (flag === false) {
      if (navigator.language.includes("fr")) {
        i18n.changeLanguage(strings.language.FR);
        dispatch(changeLanguage(strings.language.FR));
      } else {
        i18n.changeLanguage(strings.language.EN);
        dispatch(changeLanguage(strings.language.EN));
      }
    }
  }, [cookies.access_token, dispatch, navigation, flag]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const accessToken = searchParams.get("a");
    const language = searchParams.get("lang");

    // Check if the ACCESSTOKEN parameter is present in the URL
    if (accessToken) {
      dispatch(changeActiveHeader("Home"));
      localStorage.setItem("token", accessToken);
      setCookie("access_token", accessToken, {
        path: "/",
      });
      setCookie("refresh_token", accessToken, {
        path: "/",
      });
      navigation(paths.userRoutes.DASHBOARD);
      window.location.reload(false);
    }

    // set language
    if (language === "1") {
      i18n.changeLanguage(strings.language.FR);
      dispatch(changeLanguage(strings.language.FR));
      setFlag(true);
    } else if (language === "0") {
      i18n.changeLanguage(strings.language.EN);
      dispatch(changeLanguage(strings.language.EN));
      setFlag(true);
    }
  }, [location.search]);

  const submitHandler = async (e) => {
    e.preventDefault();
    toast.dismiss();
    if (isEmpty(email) && isEmpty(password)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.USERNAME_AND_PASSWORD_REQUIRED_ERROR),
      });
    } else if (isEmpty(email)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.EMAIL_REQUIRED_ERROR),
      });
    } else if (isEmpty(password)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.PASSWORD_REQUIRED_ERROR),
      });
    } else {
      setIsLoading(true);
      let response = await dispatch(
        loginAction({
          language: language,
          isAdmin,
          email,
          password,
          customTost: true,
        })
      );
      if (response.payload?.success) {
        dispatch(changeActiveHeader("Home"));
        const accessToken = response.payload.data.accessToken;
        const refershToken = response.payload.data.refershToken;
        localStorage.setItem("token", accessToken);
        setCookie("access_token", accessToken, {
          path: "/",
        });
        setCookie("refresh_token", refershToken, {
          path: "/",
        });
        await getToken(false);
        navigation(paths.userRoutes.DASHBOARD);
        window.location.reload(false);
      }
      setIsLoading(false);
    }
  };

  const emailChangeHandler = (e) => setEmail(e.target.value);
  const passwordChangeHandler = (e) => setPassword(e.target.value);
  const showPasswordChangeHandler = () => setShowPassword(!showPassword);
  return (
    <body>
      <div className="login-page min-h-100">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-7 login-form-content position-relative ">
              <div className="login-form-content-bg d-md-none bg--primary"></div>
              <div className="offset-md-1 offset-xl-2 position-relative z-index-1">
                <img
                  src="./assets/images/logo-white.svg"
                  width="274"
                  height="110"
                  alt="logo"
                  className="img-fluid login-logo"
                />
                <div className="fg-white-80 col-xl-7">
                  <p className="font-20 font-lato_bold m-0">
                    {t(strings.translate.WELCOME_LOGIN)}
                  </p>
                  <br />
                  <p className="">{t(strings.translate.LOGIN_TEXT)}</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 login-form-wrapper position-relative">
              <div className="login-form-wrapper-bg d-md-none bg--primary-100"></div>
              <div className="login-form-inner position-relative z-index-1">
                <h1 className="font-30 font-lato_bold fg-primary mb-4">
                  {t(strings.words.LOGIN)}
                </h1>
                <form>
                  <div className="mb-4">
                    <label htmlFor="userName" className="form-label mb-1">
                      {t(strings.words.EMAIL)}
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="userName"
                        value={email}
                        onChange={emailChangeHandler}
                      />
                      <span
                        className="input-group-text bg-white"
                        id="userNameIcon"
                      >
                        <img
                          style={{ cursor: "pointer" }}
                          src="./assets/images/user-icon.svg"
                          width="16"
                          height="14"
                          alt="user icon"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="password" className="form-label mb-1">
                      {t(strings.words.PASSWORD)}
                    </label>
                    <div className="input-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        id="password"
                        value={password}
                        onChange={passwordChangeHandler}
                      />
                      <span
                        className="input-group-text bg-white"
                        id="passwordIcon"
                      >
                        {showPassword ? (
                          <img
                            style={{ cursor: "pointer" }}
                            onClick={showPasswordChangeHandler}
                            src="./assets/images/eye-icon.svg"
                            width="19"
                            height="12"
                            alt="eye icon"
                          />
                        ) : (
                          <BsFillEyeSlashFill
                            style={{ cursor: "pointer" }}
                            onClick={showPasswordChangeHandler}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      onClick={submitHandler}
                      className="btn btn--primary text-uppercase mt-3"
                    >
                      {t(strings.words.LOGIN)}
                    </button>
                    {isLoading && (
                      <ProgressDialog open={isLoading} isAdmin={false} />
                    )}
                  </div>
                  <Link
                    className="d-inline-block fg-primary mt-4 text-decoration-none cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    id="forgotPass"
                  >
                    {t(strings.translate.FORGOT_PASSWORD) + " ?"}
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ForgotPasswordPage isAdmin={isAdmin} />
    </body>
  );
};

export default LoginPage;
