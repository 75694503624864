import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, patchRequest, postRequest } from "../../services/API";

const FETCH_PROFILE_DETAILS = "profileDetails";
const UPDATE_PROFILE_DETAILS = "updateProfile";
const EMAILS_VERIFY = "emailsVerify";

export const profileDetails = createAsyncThunk(
  FETCH_PROFILE_DETAILS,
  async ({ language, isAdmin }) => {
    try {
      let userProfile = await getRequest(
        language,
        isAdmin ? "getAdminProfile" : "getProfile",
        isAdmin ? true : false
      );
      return userProfile;
    } catch (error) {
      return JSON.parse(error.message);
    }
  }
);

export const updateProfile = createAsyncThunk(
  UPDATE_PROFILE_DETAILS,
  async ({ language, isAdmin, userInfo, customTost = false }) => {
    try {
      let updateUserProfile = await patchRequest(
        language,
        isAdmin ? "getAdminProfile" : "getProfile",
        userInfo,
        isAdmin,
        customTost
      );
      return updateUserProfile;
    } catch (error) {
      return JSON.parse(error.message);
    }
  }
);

export const emailsVerify = createAsyncThunk(
  EMAILS_VERIFY,
  async ({ language, client_email }) => {
    try {
      let emialVerifyresponse = await postRequest(language, "emails", client_email);
      return emialVerifyresponse;
    } catch (error) {
      return JSON.parse(error.message);
    }
  }
);
