import DashboardLayout from "./layouts/dashboard";
import UserPage from "./pages/Admin/UserPage";
import LoginPage from "./pages/Admin/LoginPage";
import SubScriptionManagementPage from "./pages/Admin/SubScriptionManagementPage";
import CutomersPaymentsPage from "./pages/Admin/CutomersPaymentsPage";
import CMSPage from "./pages/Admin/CMSPage";
import ProfilePage from "./pages/Admin/ProfilePage";
import ChangePasswordPage from "./pages/Admin/ChangePasswordPage";
import FormManagementPage from "./pages/Admin/FormManagementPage";
import EditCMSPage from "./pages/Admin/EditCMSPage";
import AddEditFormManagementPage from "./pages/Admin/AddEditFormManagementPage";
import AddEditMGAUserPage from "./pages/Admin/AddEditMGAUserPage";
import EditSubScriptionManagementPage from "./pages/Admin/EditSubScriptionManagementPage";
import UserLicenseKeyPage from "./pages/Admin/UserLicenseKeyPage";
import { useRoutes } from "react-router-dom";
import PDFSectionFormManagment from "./pages/Admin/PDFSectionFormManagment";
import NotFoundPageForAdmin from "./pages/Admin/NotFoundPage";
import CarrierManagementPage from "./pages/Admin/CarrierManagementPage";
import PolicyManagementPage from "./pages/Admin/PolicyManagementPage";
import AddEditCarrierForm from "./pages/Admin/AddEditCarrierForm";
import AddEditPolicyForm from "./pages/Admin/AddEditPolicyForm";
import { paths } from "./utils/constants";
import MgaPage from "./pages/Admin/MgaPage";
import AddEditMgaPage from "./pages/Admin/AddEditMgaPage";

export default function Router() {
  const routes = useRoutes([
    {
      path: paths.adminRoutes.ADMIN,
      element: <LoginPage />,
    },
    {
      path: paths.adminRoutes.DASHBOARD,
      element: <DashboardLayout />,
      children: [
        { path: `user`, element: <UserPage /> },
        {
          path: "user/addEditUser",
          element: <AddEditMGAUserPage />,
        },
        { path: `mga`, element: <MgaPage /> },
        {
          path: "mga/addEditMGA",
          element: <AddEditMgaPage />,
        },
        {
          path: `subscription`,
          element: <SubScriptionManagementPage />,
        },
        {
          path: "subscription/editSubscription",
          element: <EditSubScriptionManagementPage />,
        },
        { path: `cutomerPayments`, element: <CutomersPaymentsPage /> },
        { path: "formManagement", element: <FormManagementPage /> },
        {
          path: "formManagement/editForm",
          element: <AddEditFormManagementPage />,
        },
        {
          path: "formManagement/editForm/PDFSectionFormManagment",
          element: <PDFSectionFormManagment />,
        },
        {
          path: "carriers",
          element: <CarrierManagementPage />,
        },
        {
          path: "carriers/addEditCarrier",
          element: <AddEditCarrierForm />,
        },
        {
          path: "policies",
          element: <PolicyManagementPage />,
        },
        {
          path: "policies/addEditPolicy",
          element: <AddEditPolicyForm />,
        },
        { path: "userLicenseKeys", element: <UserLicenseKeyPage /> },
        { path: `cmsPage`, element: <CMSPage /> },
        {
          path: "cmsPage/editCMSPage",
          element: <EditCMSPage />,
        },
        {
          path: `profilePage`,
          element: <ProfilePage />,
        },
        {
          path: `changePassword`,
          element: <ChangePasswordPage />,
        },
        {
          path: paths.adminRoutes.DASHBOARD,
          element: <NotFoundPageForAdmin />,
        },
        {
          path: "*",
          element: <NotFoundPageForAdmin />,
        },
      ],
    },
    {
      path: "*",
      element: <LoginPage />,
    },
  ]);
  return routes;
}
