import { styled } from "@mui/material/styles";
import { Container, Typography, Stack } from "@mui/material";
import LoginForm from "../../component/LoginForm";
import Logo from "../../utils/Logo/logo.png";
import { paths, strings } from "../../utils/constants";
import { useCookies } from "react-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default function LoginPage() {
  const [cookies] = useCookies(["admin_access_token", "admin_refresh_token"]);
  const adminCookie = cookies.admin_access_token;
  const navigation = useNavigate();
  useEffect(() => {
    if (adminCookie) {
      navigation(paths.adminRoutes.USERS);
    }
  }, [adminCookie, navigation]);

  return (
    <body style={{ borderBottom: "none" }}>
      <Container
        maxWidth="sm"
        maxHeight="100%"
        sx={{ left: { xs: 16, sm: 24, md: 40 } }}
      >
        <StyledContent>
          <div style={{ marginTop: "50px" }}>
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}
            >
              <img
                src={Logo}
                alt="logo"
                style={{
                  marginBottom: "70px",
                  justifyContent: "center",
                }}
                width={180}
                height={80}
              />
            </Stack>
            <Typography variant="h4" gutterBottom style={{ color: "#184437" }}>
              {strings.words.LOGIN}
            </Typography>
            <LoginForm />
          </div>
        </StyledContent>
      </Container>
    </body>
  );
}
