import React from "react";
import { CircularProgress } from "@mui/material";
import { strings } from "../../utils/constants";
import { useTranslation } from "react-i18next";

export default function Loader({ isAdmin }) {
  const { t } = useTranslation();
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: "60px",
      }}
    >
      <div style={{ marginBottom: "30px" }}>
        <CircularProgress color={"success"} />
      </div>
      <p>
        {isAdmin
          ? strings.general.PLEASE_WAIT
          : t(strings.translate.PLEASE_WAIT)}
      </p>
    </div>
  );
}
