import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, patchRequest, postRequest } from "../../services/API";

const FETCH_ADMIN_POLICY_DETAILS = "fetchAdminPolicyAction";
const EDIT_ADMIN_POLICY_DETAILS = "editAdminPolicyAction";
const ADD_ADMIN_POLICY_DETAILS = "addAdminPolicyAction";

export const fetchAdminPolicyAction = createAsyncThunk(
  FETCH_ADMIN_POLICY_DETAILS,
  async ({ language, isAdmin }) => {
    try {
      let fetchPolicyResult = await getRequest(
        language,
        "policyService",
        isAdmin
      );
      return fetchPolicyResult;
    } catch (error) {
      return JSON.parse(error.message);
    }
  }
);

export const editAdminPolicyAction = createAsyncThunk(
  EDIT_ADMIN_POLICY_DETAILS,
  async ({ language, requestInfo }, { dispatch, rejectWithValue }) => {
    try {
      let editAdminPolicyResult = await patchRequest(
        language,
        "policyService",
        requestInfo,
        true
      );
      return editAdminPolicyResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);

export const addAdminPolicyAction = createAsyncThunk(
  ADD_ADMIN_POLICY_DETAILS,
  async ({ language, requestInfo }, { dispatch, rejectWithValue }) => {
    try {
      let addAdminPolicyResult = await postRequest(
        language,
        "policyService",
        requestInfo,
        true
      );
      return addAdminPolicyResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);
