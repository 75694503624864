import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { strings, paths } from "../../utils/constants";

const NotFoundPageForAdmin = () => {
  const { t } = useTranslation();
  return (
    <body>
      <section className="py-5 thank-you-sec position-fixed">
        <div className="container d-flex align-items-center justify-content-center text-center fg-gray-30">
          <div>
            <h1 className="fg-primary mb-3 font-lato_bold font-100">404</h1>
            <h2 className="font-26 mb-3 font-lato_bold">
              {t(strings.translate.PAGE_NOT_FOUND)}
            </h2>
            <p>{t(strings.translate.PAGE_NOT_FOUND_DESCRIPTION)}</p>
            <Link
              className="btn btn--primary text-uppercase me-4 px-3"
              to={paths.adminRoutes.USERS}
            >
              {t(strings.translate.BACK_TO_HOME)}
            </Link>
          </div>
        </div>
      </section>
    </body>
  );
};

export default NotFoundPageForAdmin;
