import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAdminMgaAction,
  editAdminMgaAction,
  addAdminMgaAction,
  deleteAdminMgaAction,
} from "../Actions";

const ADMIN_MGA_SLICE_NAME = "adminMgaDetails";

const adminMgaInitialState = {
  fetchAdminMgaData: {},
  editAdminMgaData: {},
  deleteAdminMgaData: {},
  isLoading: false,
  isEditMga: false,
  editMgaData: {},
};

const adminMgaSlice = createSlice({
  name: ADMIN_MGA_SLICE_NAME,
  initialState: adminMgaInitialState,
  reducers: {
    isFromEditMga(state, action) {
      state.isEditMga = action.payload;
      return state;
    },
    isFromEditMgaData(state, action) {
      state.editMgaData = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(fetchAdminMgaAction.pending, (state) => {
        state.isLoading = true;
        state.fetchAdminMgaData = {};
        state.editAdminMgaData = {};
        return state;
      })
      .addCase(fetchAdminMgaAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.fetchAdminMgaData = action.payload;
        state.editAdminMgaData = {};
        return state;
      })
      .addCase(fetchAdminMgaAction.rejected, (state, action) => {
        state.isLoading = false;
        state.fetchAdminMgaData = action.payload;
        state.editAdminMgaData = {};
      })
      .addCase(editAdminMgaAction.pending, (state) => {
        state.isLoading = true;
        state.editAdminMgaData = {};
        state.deleteAdminMgaData = {};
        return state;
      })
      .addCase(editAdminMgaAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.editAdminMgaData = action.payload;
        state.deleteAdminMgaData = {};
        return state;
      })
      .addCase(editAdminMgaAction.rejected, (state, action) => {
        state.isLoading = false;
        state.editAdminMgaData = action.payload;
        state.deleteAdminMgaData = {};
      })
      .addCase(addAdminMgaAction.pending, (state) => {
        state.isLoading = true;
        state.editAdminMgaData = {};
        return state;
      })
      .addCase(addAdminMgaAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.editAdminMgaData = action.payload;
        state.deleteAdminMgaData = {};
        return state;
      })
      .addCase(addAdminMgaAction.rejected, (state, action) => {
        state.isLoading = false;
        state.editAdminMgaData = action.payload;
        state.deleteAdminMgaData = {};
      })
      .addCase(deleteAdminMgaAction.pending, (state) => {
        state.isLoading = true;
        state.editAdminMgaData = {};
        state.deleteAdminMgaData = {};
        return state;
      })
      .addCase(deleteAdminMgaAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.editAdminMgaData = {};
        state.deleteAdminMgaData = action.payload;
        return state;
      })
      .addCase(deleteAdminMgaAction.rejected, (state, action) => {
        state.isLoading = false;
        state.editAdminMgaData = {};
        state.deleteAdminMgaData = action.payload;
      });
  },
});

export const { isFromEditMga, isFromEditMgaData } = adminMgaSlice.actions;
export const adminMgaReducer = adminMgaSlice.reducer;
