import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  Button,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { isEmpty, newPasswordValidation } from "../../services/validation";
import { Toast } from "../../component/Toast";
import { useDispatch } from "react-redux";
import { changePasswordAction } from "../../redux/Actions";
import { useCookies } from "react-cookie";
import Iconify from "../../component/Iconify";
import { strings, paths } from "../../utils/constants";
import CustomTextField from "../../component/CustomTextField";

export default function ChangePasswordPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setNewConfirmPass] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies([
    "admin_access_token",
    "admin_refresh_token",
  ]);

  const updateHandler = async (e) => {
    e.preventDefault();
    if (isEmpty(currentPass)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.CURRENT_PASSWORD_REQUIRED,
      });
    } else if (isEmpty(newPass)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.NEW_PASSWORD_REQUIRED,
      });
    } else if (!newPasswordValidation(newPass)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.PASSWORD_VALIDATION,
      });
    } else if (isEmpty(confirmPass)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.CONFIRM_PASSWORD_REQUIRED,
      });
    } else if (newPass !== confirmPass) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.PASSWORDS_MUST_BE_SAME,
      });
    }else if (newPass === currentPass) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.NEW_AND_CURRENT_PASSWORDS_NOT_BE_SAME,
      });
    } else {
      let response = await dispatch(
        changePasswordAction({
          language: strings.language.EN,
          isAdmin: true,
          newPass,
          confirmPass,
          oldPass: currentPass,
        })
      );
      if (response.payload?.success) {
        await localStorage.removeItem("adminToken");
        removeCookie("admin_access_token", { path: paths.adminRoutes.ADMIN });
        removeCookie("admin_refresh_token", { path: paths.adminRoutes.ADMIN });
        navigate(paths.adminRoutes.ADMIN, { replace: true });
      }
    }
  };

  const currentPasswordHandler = (e) => setCurrentPass(e.target.value);

  const currentPassIconHandler = () =>
    setShowCurrentPassword(!showCurrentPassword);

  const newPasswordHandler = (e) => setNewPass(e.target.value);

  const newPassIconHandler = () => setShowNewPassword(!showNewPassword);

  const newConfirmPasswordHandler = (e) => setNewConfirmPass(e.target.value);

  const newConfirmPassIconHandler = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const cancelHandler = () => {
    navigate(paths.adminRoutes.USERS, { replace: true });
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginLeft: 5, marginTop: 5 }}
        >
          {strings.general.CHANGE_PASSWORD}
        </Typography>
      </Stack>
      <form
        style={{ marginBottom: "5px", marginLeft: "50px", marginRight: "50px" }}
      >
        <CustomTextField
          style={{
            width: "100%",
            marginTop: "4px",
            marginBottom: "5px",
          }}
          type={showCurrentPassword ? "text" : "password"}
          label={`${strings.general.CURRENT_PASSWORD}*`}
          variant="outlined"
          focusColor="#184437"
          value={currentPass}
          autoFocus
          autoComplete="off"
          onChange={currentPasswordHandler}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color={"#184437"}
                  onClick={currentPassIconHandler}
                  edge="end"
                >
                  <Iconify
                    icon={
                      showCurrentPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                    }
                    color="#184437"
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <br />
        <CustomTextField
          style={{
            width: "100%",
            marginTop: "10px",
            marginBottom: "5px",
          }}
          type={showNewPassword ? "text" : "password"}
          label={`${strings.general.NEW_PASSWORD}*`}
          variant="outlined"
          focusColor="#184437"
          value={newPass}
          autoComplete="off"
          onChange={newPasswordHandler}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color={"#184437"}
                  onClick={newPassIconHandler}
                  edge="end"
                >
                  <Iconify
                    icon={showNewPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    color="#184437"
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <br />
        <CustomTextField
          style={{
            width: "100%",
            marginTop: "10px",
            marginBottom: "5px",
          }}
          type={showConfirmPassword ? "text" : "password"}
          label={`${strings.general.CONFIRM_PASSWORD}*`}
          variant="outlined"
          focusColor="#184437"
          value={confirmPass}
          autoComplete="off"
          onChange={newConfirmPasswordHandler}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color={"#184437"}
                  onClick={newConfirmPassIconHandler}
                  edge="end"
                >
                  <Iconify
                    icon={
                      showConfirmPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                    }
                    color="#184437"
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <br />
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="start"
          justifyContent="left"
          style={{ paddingBottom: "20px" }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "#184437",
              marginTop: 5,
              marginRight: "12px",
            }}
            onClick={updateHandler}
          >
            {strings.words.UPDATE}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#184437", marginTop: 5 }}
            onClick={cancelHandler}
          >
            {strings.words.CANCEL}
          </Button>
        </Stack>
      </form>
    </>
  );
}
