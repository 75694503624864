import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Header from "../../../component/Header";
import PresentationsCard from "./PresantationCard";
import VideoCard from "./VideoCard";
import { changeActiveHeader } from "../../../redux/Reducers/GeneralReducer";
import { strings } from "../../../utils/constants";

const DemoTraining = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(changeActiveHeader(strings.navigationHeader.user.DEMO_TRAINING));
  }, []);

  return (
    <body>
      <div>
        <Header />
      </div>
      <>
        <section className="subplan-banner bg--primary">
          <div className="container">
            <h1 className="font-lato_bold font-26 fg-white text-center py-5 m-0">
              {t(strings.translate.DEMO_TRAINING)}
            </h1>
          </div>
        </section>
        <section className="py-4 py-lg-5 billing-sec">
          <div className="container">
            <div className="row mb-md-5 pb-4">
              <div className="col-12 col-lg-10 fg-gray-30">
                <p className="font-lato_bold font-24">FAQ</p>
                <p className="line-height-2 mb-3 mb-md-5">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
                <div
                  className="accordion accordion-flush col-lg-11 faq-accordion"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header " id="flush-headingOne">
                      <button
                        className="accordion-button collapsed font-22 fg-gray-30 font-lato_bold"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        {t(strings.translate.FAQ_Q1)}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body pt-0">
                        <p className="fg-gray-30 m-0">
                          {t(strings.translate.FAQ_A1)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwo">
                      <button
                        className="accordion-button collapsed font-22 fg-gray-30 font-lato_bold"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        {t(strings.translate.FAQ_Q2)}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body pt-0">
                        <p className="fg-gray-30 m-0">
                          {t(strings.translate.FAQ_A2)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingThree">
                      <button
                        className="accordion-button collapsed font-22 fg-gray-30 font-lato_bold"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        {t(strings.translate.FAQ_Q3)}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body pt-0">
                        <p className="fg-gray-30 m-0">
                          {t(strings.translate.FAQ_A3)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFour">
                      <button
                        className="accordion-button collapsed font-22 fg-gray-30 font-lato_bold"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                      >
                        {t(strings.translate.FAQ_Q4)}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingFour"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body pt-0">
                        <p className="fg-gray-30 m-0">
                          {t(strings.translate.FAQ_A4)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <VideoCard />
            <PresentationsCard />
          </div>
        </section>
      </>
    </body>
  );
};

export default DemoTraining;
