import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, patchRequest } from "../../services/API";

const FETCH_ADMIN_CMS_PAGES_DETAILS = "fetchAdminCMSPagesAction";
const EDIT_ADMIN_CMS_PAGES_DETAILS = "editAdminCMSPagesAction";

export const fetchAdminCMSPagesAction = createAsyncThunk(
  FETCH_ADMIN_CMS_PAGES_DETAILS,
  async ({ language }, { dispatch, rejectWithValue }) => {
    try {
      let adminCustomerPaymentsResult = await getRequest(language, "cms", true);
      return adminCustomerPaymentsResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);

export const editAdminCMSPagesAction = createAsyncThunk(
  EDIT_ADMIN_CMS_PAGES_DETAILS,
  async ({ language, requestInfo }, { dispatch, rejectWithValue }) => {
    try {
      let addAdminCMSPagesResult = await patchRequest(
        language,
        "cms",
        requestInfo,
        true
      );
      return addAdminCMSPagesResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);
