import "./customer.css";
import React, { useState, useEffect } from "react";
import { BsDownload } from "react-icons/bs";
import { Container, Stack, Button, Link } from "@mui/material";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { adminCustomerPaymentsAction } from "../../redux/Actions";
import { convertToDate, isEmpty } from "../../services/validation";
import { strings } from "../../utils/constants";
import AdminPagination from "../AdminPagination";
import NoRecordFound from "../NoRecordFound";

export const CustomerTable = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [isFilter, setFilter] = useState(strings.general.ALL_DATA);
  const { adminCustomerPaymentsData } = useSelector(
    (state) => state.adminCustomerPaymentsReducer
  );

  // We are getting two diff status for sucessfull paymewnt that's why created array of seccess status
  const paymentSuccessStatusArray = ["Success", "paid"];

  const tableRecords = adminCustomerPaymentsData?.data
    ? adminCustomerPaymentsData.data
    : [];

  const totalPage = Math.ceil(adminCustomerPaymentsData?.total / 10);

  const getAPI = async (page, searchText, isFilter) => {
    let requestInfo = {
      page: page,
      limit: 10,
      searchText: searchText,
      isFilter: isFilter === "" ? strings.general.ALL_DATA : isFilter,
      language: strings.language.EN,
    };
    await dispatch(adminCustomerPaymentsAction(requestInfo));
  };

  useEffect(() => {
    getAPI(1, searchText, isFilter);
  }, []);

  const nextPage = () => {
    if (currentPage !== totalPage) {
      setCurrentPage(currentPage + 1);
      getAPI(currentPage + 1, searchText, isFilter);
    }
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      getAPI(currentPage - 1, searchText, isFilter);
    }
  };

  const changePage = (id) => {
    setCurrentPage(id);
    getAPI(id, searchText, isFilter);
  };

  const searchHandler = () => {
    setFilter(strings.general.ALL_DATA);
    if (isEmpty(searchText)) {
      setSearchText("");
      setCurrentPage(1);
      getAPI(1, "", "");
    } else {
      setCurrentPage(1);
      getAPI(1, searchText, "");
    }
  };

  const allSubscriptionHandler = () => {
    setFilter(strings.general.ALL_DATA);
    setCurrentPage(1);
    getAPI(1, searchText, strings.general.ALL_DATA);
  };

  const activeSubscriptionHandler = () => {
    setFilter(strings.words.ACTIVE);
    setCurrentPage(1);
    getAPI(1, searchText, strings.words.ACTIVE);
  };

  const expierdSubscriptionHandler = () => {
    setFilter(strings.words.EXPIRED);
    setCurrentPage(1);
    getAPI(1, searchText, strings.words.EXPIRED);
  };

  const downloadHandler = (data) => {
    const filepath = data.payment?.invoice_pdf;
    const fileName = data.payment?.invoice_pdf.split("/").pop();
    const a = document.createElement("a");
    a.setAttribute("download", fileName);
    a.href = filepath;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <Container className="container-wrapper">
        <Stack
          direction={{ xm: "column", lg: "row" }}
          alignItems="end"
          justifyContent="right"
          mb={4}
        >
          <div className="cinput">
            <TextField
              id="search"
              variant="outlined"
              size="small"
              placeholder={strings.words.SEARCH}
              sx={{ color: "#184437" }}
              onChange={(e) => setSearchText(e.target.value)}
              autoComplete="off"
            />
          </div>
          <Button
            variant="contained"
            sx={{ color: "#184437" }}
            className="cbutton"
            onClick={searchHandler}
          >
            {strings.words.SEARCH}
          </Button>
          <div className="align-self-xl-center mt-3 text-end filter-dropdow-main filterIcon-wrapper">
            <Link
              className="px-0 filter-dropdown"
              id="filter"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="/assets/icons/filter-icon.svg"
                width="34"
                height="30"
                alt="filter icon"
              />
            </Link>
            <div
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="filter"
            >
              <Link className="dropdown-item" onClick={allSubscriptionHandler}>
                {strings.general.ALL_SUBSCRIPTIONS}
              </Link>
              <Link
                className="dropdown-item"
                onClick={activeSubscriptionHandler}
              >
                {strings.general.ACTIVE_SUBSCRIPTIONS}
              </Link>
              <Link
                className="dropdown-item"
                onClick={expierdSubscriptionHandler}
              >
                {strings.general.EXPIRED_SUBSCRIPTIONS}
              </Link>
            </div>
          </div>
        </Stack>
      </Container>
      <div className="ctable-wrapper">
        <table className="ctable">
          <thead>
            <tr>
              <th>{strings.general.SR_NO}</th>
              <th>{strings.words.USERNAME}</th>
              <th className="cexpand">{strings.words.EMAIL}</th>
              <th>{strings.words.PHONE}</th>
              <th>{strings.general.SUBSCRIPTIONS_TYPE}</th>
              <th>{strings.general.SUBSCRIPTIONS_STATUS}</th>
              <th>{strings.general.EXPIRY_DATE}</th>
              <th>{strings.general.PAYMENT_AMOUNT}</th>
              <th>{strings.general.PAYMENT_STATUS}</th>
              <th>{strings.general.PAYMENT_METHOD}</th>
              <th>{strings.words.DOWNLOAD}</th>
            </tr>
          </thead>
          <tbody>
            {tableRecords.length > 0 ? (
              tableRecords.map((row, idx) => {
                return (
                  <tr key={idx}>
                    <td>{(currentPage - 1) * 10 + idx + 1}</td>
                    <td>{row.user?.username}</td>
                    <td>{row.user?.email}</td>
                    <td>{row.user?.phone}</td>
                    <td className="capitalizeText">{row.subscription?.type}</td>
                    <td>
                      <span
                        className={`clabel clabel-${
                          row.is_expire
                            ? strings.words.INACTIVE
                            : strings.words.ACTIVE
                        }`}
                      >
                        {row.is_expire
                          ? strings.words.EXPIRED
                          : strings.words.ACTIVE}
                      </span>
                    </td>
                    <td>{convertToDate(row.end_date)}</td>
                    <td>
                      {row.payment ? `$ ${row.payment.amount / 100}` : ""}
                    </td>
                    <td>
                      <span
                        className={`clabel clabel-${
                          paymentSuccessStatusArray.includes(
                            row.payment?.payment_status
                          )
                            ? "Done"
                            : "None"
                        }`}
                      >
                        {paymentSuccessStatusArray.includes(
                          row.payment?.payment_status
                        )
                          ? strings.words.SUCCESS
                          : ""}
                      </span>
                    </td>
                    <td className="capitalizeText">
                      {row.payment?.payment_mode}
                    </td>
                    <td className="fit">
                      <span className="cactions">
                        {paymentSuccessStatusArray.includes(
                          row.payment?.payment_status
                        ) && (
                          <BsDownload
                            onClick={downloadHandler.bind(this, row)}
                            className="mt-1 cursor-pointer"
                          />
                        )}
                      </span>
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoRecordFound colSpan={11} />
            )}
          </tbody>
        </table>
        {tableRecords.length > 0 && (
          <AdminPagination
            currentPage={currentPage}
            totalRecords={adminCustomerPaymentsData.total}
            nextPage={nextPage}
            prevPage={prevPage}
            changePage={changePage}
          />
        )}
      </div>
    </>
  );
};
