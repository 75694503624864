import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteRequest, getRequest, patchRequest } from "../../services/API";

const DELETE_SUB_SCRIPTION_PLAN_DETAILS = "deleteSubscriptionPlanAction";
const EDIT_SUB_SCRIPTION_PLAN_DETAILS = "editSubscriptionPlanAction";
const FETCH_SUB_SCRIPTION_PLAN_DETAILS = "fetchSubscriptionPlanAction";

export const fetchSubscriptionPlanAction = createAsyncThunk(
  FETCH_SUB_SCRIPTION_PLAN_DETAILS,
  async ({ language }, { dispatch, rejectWithValue }) => {
    try {
      let fetchSubscriptionPlanResult = await getRequest(
        language,
        "subscription",
        true
      );
      return fetchSubscriptionPlanResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);

export const editSubscriptionPlanAction = createAsyncThunk(
  EDIT_SUB_SCRIPTION_PLAN_DETAILS,
  async ({ language, requestInfo }, { dispatch, rejectWithValue }) => {
    try {
      let editSubscriptionPlanResult = await patchRequest(
        language,
        "subscription",
        requestInfo,
        true
      );

      return editSubscriptionPlanResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);

export const deleteSubscriptionPlanAction = createAsyncThunk(
  DELETE_SUB_SCRIPTION_PLAN_DETAILS,
  async ({ language, planId }, { dispatch, rejectWithValue }) => {
    try {
      let deleteSubscriptionPlanResult = await deleteRequest(
        language,
        "subscription",
        planId,
        true
      );

      return deleteSubscriptionPlanResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);
