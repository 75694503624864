import React from "react";
import { strings } from "../../utils/constants";
import { usePagination } from "../../hooks/usePagination";
import "./adminPagination.css";

export default function AdminPagination({
  currentPage,
  totalRecords,
  nextPage,
  prevPage,
  changePage,
}) {
  const recordPerPage = 10;
  const totalPage = Math.ceil(totalRecords / recordPerPage);

  const paginationRange = usePagination({
    currentPage: currentPage,
    totalCount: totalRecords,
    pageSize: recordPerPage,
    siblingCount: 1,
  });

  const DOTS = "...";

  const handleChangePage = (item) => {
    if (item !== DOTS) {
      changePage(item);
    }
  };

  return (
    <nav>
      <ul className="admin-pagination pagination">
        <li className="admin-page-item page-item">
          {currentPage !== 1 && (
            <button className="admin-page-link page-link" onClick={prevPage}>
              {strings.words.PREV}
            </button>
          )}
        </li>
        {paginationRange.map((item, index) => (
          <li
            className={`admin-page-item page-item ${
              currentPage === item ? "active" : ""
            }`}
            key={index}
          >
            <button
              className="admin-page-link page-link"
              onClick={handleChangePage.bind(this, item)}
            >
              {item}
            </button>
          </li>
        ))}
        <li className="admin-page-item">
          {currentPage !== totalPage && (
            <button className="admin-page-link page-link" onClick={nextPage}>
              {strings.words.NEXT}
            </button>
          )}
        </li>
      </ul>
    </nav>
  );
}
