import SvgColor from "../../../component/SvgColor";
import { paths } from "../../../utils/constants";

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 0.5, height: 0.8 }}
  />
);

const navConfig = [
  {
    title: "Advisor Management",
    path: paths.adminRoutes.USERS,
    icon: icon("ic_user"),
  },
  {
    title: "MGA Management",
    path: paths.adminRoutes.MGAS,
    icon: icon("ic_user"),
  },
  {
    title: "Subscription Management",
    path: paths.adminRoutes.SUBSCRIPRIONS,
    icon: icon("ic_cart"),
  },
  {
    title: "Customers & Payments",
    path: paths.adminRoutes.CUSTOMER_PAYMENTS,
    icon: icon("ic_blog"),
  },
  {
    title: "Form Management",
    path: paths.adminRoutes.FORMS,
    icon: icon("form_management"),
  },
  {
    title: "Carrier Management",
    path: paths.adminRoutes.CARRIERS,
    icon: icon("carrier_management"),
  },
  {
    title: "Business Action Management",
    path: paths.adminRoutes.POLICIES,
    icon: icon("policy_management"),
  },
  {
    title: "User License Key",
    path: paths.adminRoutes.USER_LICENS_KEY,
    icon: icon("licence_key"),
  },
  {
    title: "CMS Pages",
    path: paths.adminRoutes.CMS_PAGES,
    icon: icon("cms_pages"),
  },
];

export default navConfig;
