import { createSlice } from "@reduxjs/toolkit";
import {
  getSubscriptions,
  getActiveSubscriptionPlan,
  getAvailableCredits,
  payForSubscription,
  upgradeActivePlan,
  cancelAutoRenewal,
  getPaymentMethod,
  updatePaymentMethod,
} from "../Actions";

const SUBSCRIPTION_PLAN_SLICE_NAME = "subscriptionSlice";

const subscriptionInitialState = {
  subscription: [],
  subscriptionID: "",
  subscriptionPrice: "",
  subscriptionType: "",
  activePlanDetails: [],
  freeTrail: true,
  upgradePlan: [],
  clintEmails: [],
  paymentID: "",
  paymentData: [],
  availableCredits: [],
  isLoading: false,
  planStatus: "",
  isSamePlan: false,
  cancelAutoRenewalResponse: {},
  paymentMethodResponse: {},
  paymentMethodlist: [],
  updatePaymentMethodResponse: {},
};

const subscriptionSlice = createSlice({
  name: SUBSCRIPTION_PLAN_SLICE_NAME,
  initialState: subscriptionInitialState,
  reducers: {
    onPriceSelected(state, action) {
      state.subscriptionPrice = action.payload;
      return state;
    },
    getSubscriptionID(state, action) {
      state.subscriptionID = action.payload;
      return state;
    },
    onTypeSelect(state, action) {
      state.subscriptionType = action.payload;
      return state;
    },
    on2BTypeSelect(state, action) {
      state.subscriptionType = action.payload;
      return state;
    },
    on5BTypeSelect(state, action) {
      state.subscriptionType = action.payload;
      return state;
    },
    getPaymentId(state, action) {
      state.paymentID = action.payload;
      return state;
    },
    getClientEmail(state, action) {
      state.clintEmails = action.payload;
      return state;
    },
    getPlanStatus(state, action) {
      state.planStatus = action.payload;
      return state;
    },
    checkStatus(state, action) {
      state.isSamePlan = action.payload;
      return state;
    },
    onFreeTrial(state, action) {
      state.freeTrail = action.payload;
      return state;
    },
  },

  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(getSubscriptions.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(getSubscriptions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subscription = action.payload;
        return state;
      })
      .addCase(getSubscriptions.rejected, (state) => {
        state.isLoading = false;
      })
      // For payment
      .addCase(payForSubscription.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(payForSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.paymentData = action.payload;
        return state;
      })
      .addCase(payForSubscription.rejected, (state) => {
        state.isLoading = false;
      })

    // Active plan

      .addCase(getActiveSubscriptionPlan.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(getActiveSubscriptionPlan.fulfilled, (state, action) => {
        state.isLoading = false;
        state.activePlanDetails = action.payload;
        return state;
      })
      .addCase(getActiveSubscriptionPlan.rejected, (state) => {
        state.isLoading = false;
      })

    // Upgrade plan

      .addCase(upgradeActivePlan.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(upgradeActivePlan.fulfilled, (state, action) => {
        state.isLoading = false;
        state.upgradePlan = action.payload;
        return state;
      })
      .addCase(upgradeActivePlan.rejected, (state) => {
        state.isLoading = false;
      })

      // Avilable cradits
      .addCase(getAvailableCredits.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(getAvailableCredits.fulfilled, (state, action) => {
        state.isLoading = false;
        state.availableCredits = action.payload;
        return state;
      })
      .addCase(getAvailableCredits.rejected, (state) => {
        state.isLoading = false;
      })

      // cancel auto renewal
      .addCase(cancelAutoRenewal.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(cancelAutoRenewal.fulfilled, (state, action) => {
        state.isLoading = false;
        state.cancelAutoRenewalResponse = action.payload;
        return state;
      })
      .addCase(cancelAutoRenewal.rejected, (state) => {
        state.isLoading = false;
      })

      // get payment method
      .addCase(getPaymentMethod.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(getPaymentMethod.fulfilled, (state, action) => {
        state.isLoading = false;
        state.paymentMethodResponse = action.payload;
        if (action.payload?.data?.data) {
          state.paymentMethodlist = action.payload.data.data;
        } else {
          state.paymentMethodlist = [];
        }
        return state;
      })
      .addCase(getPaymentMethod.rejected, (state) => {
        state.isLoading = false;
      })

      // update payment method
      .addCase(updatePaymentMethod.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(updatePaymentMethod.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updatePaymentMethodResponse = action.payload;
        return state;
      })
      .addCase(updatePaymentMethod.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  onPriceSelected,
  onTypeSelect,
  on2BTypeSelect,
  on5BTypeSelect,
  getPaymentId,
  getSubscriptionID,
  getClientEmail,
  getPlanStatus,
  checkStatus,
  onFreeTrial,
} = subscriptionSlice.actions;
export const subscriptionReducer = subscriptionSlice.reducer;
