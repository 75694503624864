import { createSlice } from "@reduxjs/toolkit";
import {
  addAdminFormAction,
  deleteAdminFormAction,
  editAdminFormAction,
  fetchAdminFormAction,
} from "../Actions";

const ADMIN_FORM_MANAGEMENT_SLICE_NAME = "adminFormManagementDetails";

const adminFormManagementInitialState = {
  adminFormData: {},
  addAdminFormData: {},
  editAdminFormData: {},
  deleteAdminFormData: {},
  isLoading: false,
  isEditForm: false,
  editFormData: {},
  saveLocalPDFMapDataList: [],
};

const adminFormManagementSlice = createSlice({
  name: ADMIN_FORM_MANAGEMENT_SLICE_NAME,
  initialState: adminFormManagementInitialState,
  reducers: {
    isFromEditForm(state, action) {
      state.isEditForm = action.payload;
      return state;
    },
    isFromEditFormData(state, action) {
      state.editFormData = action.payload;
      return state;
    },
    isSaveLocalPDFMapDataList(state, action) {
      state.saveLocalPDFMapDataList = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(addAdminFormAction.pending, (state) => {
        state.isLoading = true;
        state.addAdminFormData = {};
        state.editAdminFormData = {};
        state.deleteAdminFormData = {};
        return state;
      })
      .addCase(addAdminFormAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.addAdminFormData = action.payload;
        state.editAdminFormData = {};
        state.deleteAdminFormData = {};
        return state;
      })
      .addCase(addAdminFormAction.rejected, (state, action) => {
        state.isLoading = false;
        state.addAdminFormData = action.payload;
        state.editAdminFormData = {};
        state.deleteAdminFormData = {};
      })
      .addCase(deleteAdminFormAction.pending, (state) => {
        state.isLoading = true;
        state.addAdminFormData = {};
        state.editAdminFormData = {};
        state.deleteAdminFormData = {};
        return state;
      })
      .addCase(deleteAdminFormAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.deleteAdminFormData = action.payload;
        state.editAdminFormData = {};
        state.addAdminFormData = {};
        return state;
      })
      .addCase(deleteAdminFormAction.rejected, (state, action) => {
        state.isLoading = false;
        state.deleteAdminFormData = action.payload;
        state.editAdminFormData = {};
        state.addAdminFormData = {};
      })
      .addCase(editAdminFormAction.pending, (state) => {
        state.isLoading = true;
        state.addAdminFormData = {};
        state.editAdminFormData = {};
        state.deleteAdminFormData = {};
        return state;
      })
      .addCase(editAdminFormAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.editAdminFormData = action.payload;
        state.deleteAdminFormData = {};
        state.addAdminFormData = {};
        return state;
      })
      .addCase(editAdminFormAction.rejected, (state, action) => {
        state.isLoading = false;
        state.editAdminFormData = action.payload;
        state.deleteAdminFormData = {};
        state.addAdminFormData = {};
      })
      .addCase(fetchAdminFormAction.pending, (state) => {
        state.isLoading = true;
        state.adminFormData = {};
        return state;
      })
      .addCase(fetchAdminFormAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.adminFormData = action.payload;
        return state;
      })
      .addCase(fetchAdminFormAction.rejected, (state, action) => {
        state.isLoading = false;
        state.adminFormData = action.payload;
      });
  },
});

export const {
  isFromEditForm,
  isFromEditFormData,
  isSaveLocalPDFMapDataList,
} = adminFormManagementSlice.actions;
export const adminFormManagementReducer = adminFormManagementSlice.reducer;
