import React, { useState } from "react";
import { strings } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { CardElement } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import ProgressDialog from "../ProgressDialog";
import { updatePaymentMethod } from "../../redux/Actions";

export default function ModifyCardElement(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const { language } = useSelector((state) => state.generalReducer);
  const { elements, stripe, onUpdateClick, onCancelClick } = props;
  const updateHandler = async () => {
    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    if (!error) {
      const payment = {
        payment_method_id: paymentMethod.id,
      };
      setIsLoading(true);
      const paymentResult = await dispatch(
        updatePaymentMethod({ language: language, payment })
      );
      if (paymentResult.payload?.success) {
        setIsLoading(false);
        setDisable(false);
        onUpdateClick();
      } else if (paymentResult.payload?.errors) {
        setIsLoading(false);
        setDisable(false);
      } else {
        setIsLoading(false);
        setDisable(false);
      }
    }
  };

  return (
    <>
      <CardElement
        options={{
          style: {
            base: {
              fontSize: "16px",
              color: "#424770",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              color: "#9e2146",
            },
          },
          hidePostalCode: true,
        }}
      />
      <div className="d-flex flex-row mt-4">
        <div style={{ marginRight: "10px", marginBottom: "16px" }}>
          <button
            onClick={updateHandler}
            className="btn btn--primary text-uppercase"
            disabled={disable}
          >
            {t(strings.words.MODIFY)}
          </button>
        </div>
        <div style={{ marginBottom: "16px" }}>
          <button
            className="btn btn--gray-40 text-uppercase"
            onClick={onCancelClick}
            data-bs-target="pills-personal-details-tab"
          >
            {t(strings.words.CANCEL)}
          </button>
        </div>
      </div>
      {isLoading && <ProgressDialog open={isLoading} isAdmin={false} />}
    </>
  );
}
