import { createAsyncThunk } from "@reduxjs/toolkit";
import { postRequest } from "../../services/API";

const FETCH_FORGOT_PASSWORD_OTP_VERIFY_DETAILS = "forgotPasswordOTPVerityAction";

export const forgotPasswordOTPVerityAction = createAsyncThunk(
  FETCH_FORGOT_PASSWORD_OTP_VERIFY_DETAILS,
  async ({ language,isAdmin, requestInfo }, { dispatch, rejectWithValue }) => {
    try {

      let forgotPasswordOTPVerityResult = await postRequest(
        language,
        isAdmin ? "adminForgotPasswordOTPVerify" : "forgotPasswordOTPVerify",
        requestInfo,
        isAdmin
      );
      return forgotPasswordOTPVerityResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);
