import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../../component/Header";
import ProgressDialog from "../../../component/ProgressDialog";
import { Toast } from "../../../component/Toast";
import { getCMSList, userContactUsAction } from "../../../redux/Actions";
import {
  isEmpty,
  newEmailValidation,
  adminContactNumberValidation,
} from "../../../services/validation";
import { strings } from "../../../utils/constants";
import Flag from "../../../utils/Logo/caneda_flag.png";
import { changeActiveHeader } from "../../../redux/Reducers/GeneralReducer";

const ContactUs = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { language, cmsList } = useSelector((state) => state.generalReducer);
  const cmsListData = cmsList?.data ? cmsList.data : [];

  const { isUserContactLoading } = useSelector(
    (state) => state.userContactUsReducer
  );

  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const getCMS = async () => {
    await dispatch(getCMSList({ language: language }));
  };

  useEffect(() => {
    dispatch(changeActiveHeader(strings.navigationHeader.user.CONTECT_US));
    getCMS();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (isEmpty(userName)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.NAME_REQUIRED_ERROR),
      });
    } else if (isEmpty(email)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.EMAIL_REQUIRED_ERROR),
      });
    } else if (!newEmailValidation(email)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.EMAIL_VALIDATION_ERROR),
      });
    } else if (adminContactNumberValidation(phone)) {
      if (
        adminContactNumberValidation(phone) ===
        strings.admin.error.PHONE_NUMBER_REQUIRED
      ) {
        Toast({
          type: "error",
          title: strings.words.ERROR,
          message: t(strings.error.PHONE_NUMBER_REQUIRED_ERROR),
        });
      } else {
        Toast({
          type: "error",
          title: strings.words.ERROR,
          message: t(strings.error.PHONE_NUMBER_VALIDATION_ERROR),
        });
      }
    } else if (isEmpty(message)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.MESSAGE_REQUIRED_ERROR),
      });
    } else {
      let requestInfo = {
        name: userName,
        email: email,
        phone: phone,
        message: message,
      };
      let userContactUsResponse = await dispatch(
        userContactUsAction({ language: language, requestInfo })
      );
      if (userContactUsResponse.payload?.success) {
        setUserName("");
        setEmail("");
        setPhone("");
        setMessage("");
      }
    }
  };

  const nameChangeHandler = (e) => {
    const regex = new RegExp(/^[a-zA-Z0-9]*$/);
    if (regex.test(e.target.value)) {
      setUserName(e.target.value);
    }
  };
  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
  };
  const phoneChangeHandler = (e) => {
    const regex = new RegExp(/^[0-9]*$/);
    if (e.target.value.length <= 10 && regex.test(e.target.value)) {
      setPhone(e.target.value);
    } else {
      return;
    }
  };
  const messageChangeHandler = (e) => {
    setMessage(e.target.value);
  };

  const data = cmsListData.filter(
    (data) => data.key === strings.cmsPages.CONTACT
  );
  const contactUsData = data.length === 1 ? data[0] : {};

  return (
    <body>
      <Header />
      <section className="subplan-banner bg--primary">
        <div className="container">
          <h1 className="font-lato_bold font-26 fg-white text-center py-5 m-0">
            {language === strings.language.FR
              ? contactUsData?.title_fr
              : contactUsData?.title}
          </h1>
        </div>
      </section>
      <section className="py-4 py-lg-5 billing-sec">
        <div className="container">
          <div className="row flex-column-reverse flex-md-row">
            {cmsListData.map((item) => {
              if (item.key === strings.cmsPages.CONTACT) {
                return (
                  <div className="col-md-5 offset-lg-1 fg-gray-30" key={item}>
                    <p>
                      {language === strings.language.FR
                        ? JSON.parse(item.content).address_fr
                        : JSON.parse(item.content).address}
                    </p>
                    <p className="pb-4 mb-4 border-b"></p>
                    <p className="pb-4 mb-4 border-b">
                      {t(strings.translate.CONTECT_US_TEXT)} <br />
                      <a
                        href={"mailto:" + JSON.parse(item.content).email}
                        className="text-decoration-none fg-primary font-lato_bold"
                      >
                        {JSON.parse(item.content).email}
                      </a>
                    </p>
                    <div className="d-flex mb-4 mb-md-0">
                      <Link
                        className="me-3"
                        onClick={() => {
                          var pattern = /^((http|https|ftp):\/\/)/;
                          if (!pattern.test(JSON.parse(item.content)?.fbLink)) {
                            const url =
                              "http://" + JSON.parse(item.content)?.fbLink;

                            window.open(url, "_blank");
                          } else {
                            window.open(
                              JSON.parse(item.content).fbLink,
                              "_blank"
                            );
                          }
                        }}
                      >
                        <img
                          src="../../../assets/images/facebook.svg"
                          alt="fb-icon"
                          width="24"
                          height="24"
                        />
                      </Link>
                      <Link
                        className="me-3"
                        onClick={() => {
                          window.open(
                            JSON.parse(item.content).instagramLink,
                            "_blank"
                          );
                        }}
                      >
                        <img
                          src="../../../assets/images/instagram.svg"
                          alt="instagram-icon"
                          width="24"
                          height="24"
                        />
                      </Link>
                      <Link
                        className="me-3"
                        onClick={() => {
                          window.open(
                            JSON.parse(item.content).twitterLink,
                            "_blank"
                          );
                        }}
                      >
                        <img
                          src="../../../assets/images/twitter.svg"
                          alt="twitter-icon"
                          width="24"
                          height="24"
                        />
                      </Link>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
            <div className="col-md-6 col-xl-4 mx-auto">
              <div className="mb-3">
                <label htmlFor="name" className="form-label mb-2 fg-gray-30">
                  {t(strings.words.NAME)}
                  <span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder={t(strings.translate.YOUR_NAME)}
                  autoComplete="off"
                  autoFocus
                  value={userName}
                  max={150}
                  maxLength={150}
                  onChange={nameChangeHandler}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label mb-2 fg-gray-30">
                  {t(strings.words.EMAIL)}
                  <span>*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder={t(strings.translate.YOUR_EMAIL)}
                  autoComplete="off"
                  value={email}
                  onChange={emailChangeHandler}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="phoneNumber"
                  className="form-label mb-2 fg-gray-30"
                >
                  {t(strings.translate.PHONE_NUMBER)}
                  <span>*</span>
                </label>
                <div className="row">
                  <div className="col-3">
                    <div className="form-control d-flex align-items-center">
                      <img src={Flag} alt="flag" width={40} height={20} />
                      <span className="ms-1">+1</span>
                    </div>
                  </div>
                  <div className="col-9 ps-0">
                    <input
                      type="text"
                      className="form-control col-10"
                      id="phoneNumber"
                      value={phone}
                      max={10}
                      maxLength={10}
                      onChange={phoneChangeHandler}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label mb-2 fg-gray-30">
                  {strings.words.MESSAGE}
                  <span>*</span>
                </label>
                <textarea
                  id="message"
                  placeholder={t(strings.translate.YOUR_MESSAGE)}
                  className="form-control"
                  rows="4"
                  autoComplete="off"
                  value={message}
                  max={255}
                  maxLength={255}
                  onChange={messageChangeHandler}
                ></textarea>
              </div>
              <div className="mb-5 mb-md-0">
                <Link
                  className="btn btn--primary text-uppercase"
                  onClick={submitHandler}
                >
                  {t(strings.words.SUBMIT)}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isUserContactLoading && (
        <ProgressDialog open={isUserContactLoading} isAdmin={false} />
      )}
    </body>
  );
};

export default ContactUs;
