import { useState } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Header from "./header";
import Nav from "./nav";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("xl")]: {
    marginLeft: theme.spacing(32),
  },
}));

const Main = styled("div")(({ theme }) => ({
  paddingTop: APP_BAR_MOBILE + 24,
  [theme.breakpoints.up("xl")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0),
    width: "100%",
  },
}));

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  const navHandler = (isOpen) => {
    setOpen(isOpen);
  };
  
  return (
    <body style={{ borderBottom: "none" }}>
      <StyledRoot>
        <Header onOpenNav={navHandler.bind(this, true)} />
        <Nav openNav={open} onCloseNav={navHandler.bind(this, false)} />
        <Main>
          <Outlet />
        </Main>
      </StyledRoot>
    </body>
  );
}
