import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptions } from "../../../redux/Actions";
import { useTranslation } from "react-i18next";
import FreePlanCard from "../../../component/FreePlanCard";

const FreeSubscription = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.generalReducer);

  const getSubscriptionPlans = async () => {
    await dispatch(getSubscriptions({ language: language }));
  };
  useEffect(() => {
    getSubscriptionPlans();
  }, []);
  return (
    <body>
      <section className="subplan-banner bg--primary">
        <div className="container">
          <h1 className="font-lato_bold font-26 fg-white text-center py-5 m-0">
            {t("FreeSubscription")}
          </h1>
        </div>
      </section>
      <section className="billing-sec start-trial mt-md-5 pt-md-5">
        <div className="container">
          <div className="row py-4 py-md-5 mb-lg-5 justify-content-center">
            <div className="col-md-6 col-lg-5 col-xl-4 mt-5 mt-md-0">
              <FreePlanCard />
            </div>
          </div>
        </div>
      </section>
    </body>
  );
};

export default FreeSubscription;
