import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../../component/Header";
import {
  getActiveSubscriptionPlan,
  getSubscriptions,
  profileDetails,
} from "../../../redux/Actions";
import { changeActiveHeader } from "../../../redux/Reducers/GeneralReducer";
import { strings, paths } from "../../../utils/constants";
import { onFreeTrial } from "../../../redux/Reducers/UserSubscriptionReducer";
import EmailDialog from "../../../component/EmailDialog";

const Dashboard = () => {
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.generalReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [emailDialogShow, setEmailDialogShow] = useState(false);

  const helpCard = [
    {
      icon: "../../../assets/images/bank-building-icon.svg",
      title: t(strings.translate.LIBRARY),
      desc1: t(strings.translate.LIBRARY_CARD_DSC_1),
      desc2: t(strings.translate.LIBRARY_CARD_DSC_2),
      desc3: "",
      redirect: paths.userRoutes.SEARCH_FORM,
      label: strings.navigationHeader.user.LIBRARY,
    },
    {
      icon: "../../../assets/images/edit-box-icon.svg",
      title: t(strings.translate.ADA_CARD_TITAL),
      desc1: t(strings.translate.ADA_CARD_DSC_1),
      desc2: t(strings.translate.ADA_CARD_DSC_2),
      desc3: t(strings.translate.ADA_CARD_DSC_3),
      redirect: paths.userRoutes.ACCESS_ADA,
      label: strings.navigationHeader.user.ACCESS_ADA,
    },
    {
      icon: "../../../assets/images/online-learning-icon.svg",
      title: t(strings.translate.DEMO_TRAINING),
      desc1: t(strings.translate.DEMO_TRAINING_CARD_DSC_1),
      desc2: "",
      desc3: "",
      redirect: paths.userRoutes.DEMO_TRAINING,
      label: strings.navigationHeader.user.DEMO_TRAINING,
    },
  ];

  const getProfile = async () => {
    const data = await dispatch(
      profileDetails({ language: language, isAdmin: false })
    );
    return data;
  };

  const activePlan = async () => {
    const activePlan = await dispatch(
      getActiveSubscriptionPlan({ language: language })
    );
    if (activePlan.payload?.success) {
      if (!activePlan.payload.data?.isFreeUsed) {
        dispatch(onFreeTrial(true));
      } else {
        dispatch(onFreeTrial(false));
      }
    } else {
      dispatch(onFreeTrial(true));
    }
  };

  const getSubscriptionPlans = async () => {
    await dispatch(getSubscriptions({ language: language }));
  };

  const checkToken = async () => {
    const data = await getProfile();
    if (data.payload?.success) {
      if (!data.payload.data?.userExist) {
        setEmailDialogShow(true);
      }
    }
    activePlan();
    getSubscriptionPlans();
  };

  useEffect(() => {
    dispatch(changeActiveHeader("Home"));
    checkToken();
  }, []);

  const helpCardClickHandler = (item) => {
    dispatch(changeActiveHeader(item.label));
    navigate(item.redirect);
  };

  const arrowClickHandler = (item) => {
    dispatch(changeActiveHeader(item.label));
  };
  return (
    <body>
      <div>
        <Header />
      </div>
      <section className="banner-section">
        <div className="container">
          <h1 className="font-lato_bold font-26 fg-white-80 col-xl-4">
            {t(strings.translate.HOME_TITAL_TEXT)}
          </h1>
        </div>
      </section>
      <section className="how-we-help">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <h2 className="text-center font-lato_bold font-26 fg-primary mb-4">
                {t(strings.translate.HOW_MAY_WE_HELP)}
              </h2>
            </div>
            {helpCard.map((item, index) => (
              <div
                key={`${item.title}` + index}
                onClick={helpCardClickHandler.bind(this, item)}
                style={{ cursor: "pointer" }}
                className="col-md-6 col-lg-4 mb-4 mb-lg-0"
              >
                <div className="card">
                  <div className="card-icon">
                    <img
                      src={item.icon}
                      width="64"
                      height="35"
                      className="img-fluid"
                      alt="Search Forms"
                    />
                  </div>
                  <div className="card-title mb-3">
                    <h3 className="fg-primary font-22 font-lato_black mb-0">
                      {item.title}
                    </h3>
                  </div>
                  <div className="card-content fg-gray-30">
                    <p>{item.desc1}</p>
                    <p>{item.desc2}</p>
                    <p>{item.desc3}</p>
                  </div>
                  <div className="card-link text-end">
                    <Link
                      to={item.redirect}
                      onClick={arrowClickHandler.bind(this, item.label)}
                    >
                      <img
                        src="../../../assets/images/right-arrow.svg"
                        width="18"
                        height="12"
                        alt="right-arrow"
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {emailDialogShow && (
        <EmailDialog
          isOpen={emailDialogShow}
          clickHandler={() => {
            setEmailDialogShow(false);
            checkToken();
          }}
        />
      )}
    </body>
  );
};

export default Dashboard;
