import React from "react";
import { useTranslation } from "react-i18next";
import { strings } from "../../utils/constants";

export default function ChangePassword({
  newPassword,
  setNewPassword,
  confirmPassword,
  setConfirmPassword,
  changePasswordHandler,
}) {
  const { t } = useTranslation();
  const newPasswordChangehandler = (e) => {
    setNewPassword(e.target.value);
  };
  const confirmPasswordChangehandler = (e) => {
    setConfirmPassword(e.target.value);
  };

  return (
    <form>
      <div className="change-password">
        <h1 className="font-30 font-lato_bold fg-primary mb-4">
          {t(strings.translate.CHANGE_PASSWORD)}
        </h1>
        <div className="mb-4">
          <input
            type="password"
            className="form-control"
            id="newPwd"
            placeholder={t(strings.translate.NEW_PASSWORD)}
            value={newPassword}
            autoFocus
            onChange={newPasswordChangehandler}
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            className="form-control"
            id="cnfrmPwd"
            placeholder={t(strings.translate.CONFIRM_PASSWORD)}
            value={confirmPassword}
            onChange={confirmPasswordChangehandler}
          />
        </div>
        <button
          className="btn btn--primary text-uppercase"
          id="changePwd"
          onClick={changePasswordHandler}
        >
          {t(strings.translate.CHANGE_PASSWORD)}
        </button>
      </div>
    </form>
  );
}
