import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Drawer } from "@mui/material";
import useResponsive from "../../../hooks/useResponsive";
import Logo from "../../../utils/Logo/Form-depot-logo.svg";
import Scrollbar from "../../../component/Scrollbar";
import NavSection from "../../../component/NavSection";
import navConfig from "./config";
import { profileDetails } from "../../../redux/Actions";
import { strings } from "../../../utils/constants";
const NAV_WIDTH = 280;

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isDesktop = useResponsive("up", "xl");

  const getProfile = async () => {
    await dispatch(
      profileDetails({ language: strings.language.EN, isAdmin: true })
    );
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    getProfile();
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
        <img src={Logo} alt="logo" width={120} height={50} />
      </Box>
      <NavSection data={navConfig} />
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { xl: 0 },
        width: { xl: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "white",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
