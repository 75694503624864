import "./UserLicenseKeyTable.css";
import React, { useEffect, useState } from "react";
import { BsArrowClockwise } from "react-icons/bs";
import { FiMinus, FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Container, Stack, Button } from "@mui/material";
import { TextField } from "@mui/material";
import {
  fetchUserLicenseKeyAction,
  updateUserLicenseKeyAction,
} from "../../redux/Actions";
import { isEmpty } from "../../services/validation";
import { strings } from "../../utils/constants";
import ProgressDialog from "../ProgressDialog";
import NoRecordFound from "../NoRecordFound";
import AdminPagination from "../AdminPagination";

export const UserLicenseKeyTable = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedRows, setExpandedRows] = useState([]);
  const [isRegenerateKey, setRegenerateKey] = useState(false);
  const [searchText, setSearchText] = useState("");

  const { isLoading, updateUserLicenseKeyData, userLicenseKeyData } =
    useSelector((state) => state.adminUserLicenseKeyReducer);

  const tableRecords = userLicenseKeyData?.data ? userLicenseKeyData.data : [];

  const totalPage = Math.ceil(userLicenseKeyData?.total / 10);

  const handleExpandRow = (event, userId) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== userId)
      : currentExpandedRows.concat(userId);

    setExpandedRows(newExpandedRows);
  };

  const nextPage = () => {
    if (currentPage !== totalPage) {
      setCurrentPage(currentPage + 1);
      getAPI(currentPage + 1, searchText);
    }
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      getAPI(currentPage - 1, searchText);
    }
  };

  const changePage = (id) => {
    setCurrentPage(id);
    getAPI(id, searchText);
  };

  const getAPI = async (page, searchText) => {
    let requestInfo = {
      page: page,
      limit: 10,
      searchText: searchText,
      language: strings.language.EN,
    };
    await dispatch(fetchUserLicenseKeyAction(requestInfo));
  };

  useEffect(() => {
    if (currentPage === 1 && !isRegenerateKey) {
      getAPI(1, searchText);
    }
    if (isRegenerateKey) {
      if (updateUserLicenseKeyData?.success) {
        getAPI(currentPage, searchText);
      }
      setRegenerateKey(false);
    }
  }, [currentPage, updateUserLicenseKeyData]);

  const searchHandler = () => {
    if (isEmpty(searchText)) {
      setSearchText("");
      setCurrentPage(1);
      getAPI(1, "");
    } else {
      setCurrentPage(1);
      getAPI(1, searchText);
    }
  };

  const regenerateHandler = async (email) => {
    setRegenerateKey(true);
    let requestInfo = {
      email: email,
    };
    await dispatch(
      updateUserLicenseKeyAction({
        language: strings.language.EN,
        requestInfo,
      })
    );
  };
  return (
    <>
      <Container className="container-wrapper">
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="end"
          justifyContent="right"
          mb={4}
        >
          <div className="ulinput">
            <TextField
              id="search"
              variant="outlined"
              size="small"
              placeholder={strings.words.SEARCH}
              sx={{ color: "#184437" }}
              onChange={(e) => setSearchText(e.target.value)}
              autoComplete="off"
            />
          </div>
          <Button
            variant="contained"
            sx={{ color: "#184437" }}
            className="ulbutton"
            onClick={searchHandler}
          >
            {strings.words.SEARCH}
          </Button>
        </Stack>
      </Container>

      <div className="ultable-wrapper">
        <table className="ultable">
          <thead>
            <tr>
              <th style={{ width: "5%" }}></th>
              <th style={{ fontSize: 14 }}>{strings.general.SR_NO}</th>
              <th style={{ width: "65%" }}>{strings.words.EMAIL}</th>
              <th>{strings.general.SUBSCRIPTION_PLAN}</th>
              <th
                style={{
                  width: "100%",
                }}
              >
                {strings.general.REGENERATE_LICENSE_KEY}
              </th>
            </tr>
          </thead>
          <tbody>
            {tableRecords.length > 0 ? (
              tableRecords.map((row, idx) => {
                return (
                  <>
                    <tr key={idx}>
                      <td style={{ width: "5%", padding: "10px" }}>
                        {row.user.user_key?.length > 0 ? (
                          expandedRows.includes(row.id) ? (
                            <FiMinus
                              onClick={(e) => {
                                handleExpandRow(e, row.id);
                              }}
                            />
                          ) : (
                            <FiPlus
                              onClick={(e) => {
                                handleExpandRow(e, row.id);
                              }}
                            />
                          )
                        ) : (
                          <FiPlus className="invisible" />
                        )}
                      </td>
                      <td style={{ fontSize: 14, padding: "10px" }}>
                        {(currentPage - 1) * 10 + idx + 1}
                      </td>
                      <td
                        style={{
                          width: "65%",
                          fontSize: 14,
                          padding: "10px",
                        }}
                      >
                        {row.user?.email}
                      </td>
                      <td style={{ fontSize: 14, padding: "10px" }}>
                        {row.subscription?.name}
                      </td>
                      <td className="fit w-100">
                        <span className="ulactions">
                          <BsArrowClockwise
                            onClick={regenerateHandler.bind(
                              this,
                              row.user.email
                            )}
                          />
                        </span>
                      </td>
                    </tr>
                    {expandedRows.includes(row.id) &&
                      row.user.user_key?.map((subItem, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ width: "5%", padding: "10px" }}></td>
                            <td style={{ fontSize: 14, padding: "10px" }}>
                              {(currentPage - 1) * 10 + idx + 1}.{index + 1}
                            </td>
                            <td
                              style={{
                                width: "65%",
                                fontSize: 14,
                                padding: "10px",
                              }}
                            >
                              {subItem.email_id}
                            </td>
                            <td style={{ fontSize: 14, padding: "10px" }}>
                              {row.subscription.name}
                            </td>
                            <td
                              className="fit"
                              style={{
                                width: "100%",
                              }}
                            >
                              <span className="ulactions">
                                <BsArrowClockwise
                                  onClick={regenerateHandler.bind(
                                    this,
                                    subItem.email_id
                                  )}
                                />
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                  </>
                );
              })
            ) : (
              <NoRecordFound colSpan={5} />
            )}
          </tbody>
        </table>
        {tableRecords.length > 0 && (
          <AdminPagination
            currentPage={currentPage}
            totalRecords={userLicenseKeyData.total}
            nextPage={nextPage}
            prevPage={prevPage}
            changePage={changePage}
          />
        )}
      </div>
      {isLoading && <ProgressDialog open={isLoading} isAdmin={true} />}
    </>
  );
};
