import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Typography, Button } from "@mui/material";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import "react-quill/dist/quill.snow.css";
import { editAdminCMSPagesAction } from "../../redux/Actions";
import { Toast } from "../../component/Toast";
import {
  isEmpty,
  newEmailValidation,
  urlValidation,
} from "../../services/validation";
import { strings, paths } from "../../utils/constants";
import CustomTextField from "../../component/CustomTextField";

function EditCMSPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isEditCMSPages, editCMSPagesData } = useSelector(
    (state) => state.adminCMSPagesReducer
  );

  const [id] = useState(isEditCMSPages ? editCMSPagesData?.id : "");

  const [title, setTitle] = useState(
    isEditCMSPages && editCMSPagesData?.title != null
      ? editCMSPagesData?.title
      : ""
  );

  const [titleInFrench, setTitleInFrench] = useState(
    isEditCMSPages && editCMSPagesData?.title_fr != null
      ? editCMSPagesData?.title_fr
      : ""
  );

  const [address, setAddress] = useState(
    isEditCMSPages &&
      editCMSPagesData?.key === strings.cmsPages.CONTACT &&
      JSON.parse(editCMSPagesData?.content).address != null
      ? JSON.parse(editCMSPagesData?.content).address
      : ""
  );

  const [addressInFrench, setAddressInFrench] = useState(
    isEditCMSPages &&
      editCMSPagesData?.key === strings.cmsPages.CONTACT &&
      JSON.parse(editCMSPagesData?.content).address_fr != null
      ? JSON.parse(editCMSPagesData?.content).address_fr
      : ""
  );

  const [email, setEmail] = useState(
    isEditCMSPages &&
      editCMSPagesData?.key === strings.cmsPages.CONTACT &&
      JSON.parse(editCMSPagesData?.content).email != null
      ? JSON.parse(editCMSPagesData?.content).email
      : ""
  );

  const [fbLink, setFBLink] = useState(
    isEditCMSPages &&
      editCMSPagesData?.key === strings.cmsPages.CONTACT &&
      JSON.parse(editCMSPagesData?.content).fbLink != null
      ? JSON.parse(editCMSPagesData?.content).fbLink
      : ""
  );

  const [instagramLink, setInstagramLink] = useState(
    isEditCMSPages &&
      editCMSPagesData?.key === strings.cmsPages.CONTACT &&
      JSON.parse(editCMSPagesData?.content).instagramLink != null
      ? JSON.parse(editCMSPagesData?.content).instagramLink
      : ""
  );

  const [twitterLink, setTwitterLink] = useState(
    isEditCMSPages &&
      editCMSPagesData?.key === strings.cmsPages.CONTACT &&
      JSON.parse(editCMSPagesData?.content).twitterLink != null
      ? JSON.parse(editCMSPagesData?.content).twitterLink
      : ""
  );

  const [aboutUsdDescritpion, setAboutUsdDescritpion] = useState(
    isEditCMSPages &&
      editCMSPagesData?.key === strings.cmsPages.ABOUT &&
      JSON.parse(editCMSPagesData?.content).description != null
      ? JSON.parse(editCMSPagesData?.content).description
      : ""
  );

  const [aboutUsdDescritpionInFrench, setAboutUsdDescritpionInFrench] =
    useState(
      isEditCMSPages &&
        editCMSPagesData?.key === strings.cmsPages.ABOUT &&
        JSON.parse(editCMSPagesData?.content).description_fr != null
        ? JSON.parse(editCMSPagesData?.content).description_fr
        : ""
    );

  const [aboutUsImageLink, setAboutUsImageLink] = useState(
    isEditCMSPages &&
      editCMSPagesData?.key === strings.cmsPages.ABOUT &&
      JSON.parse(editCMSPagesData?.content).imageLink != null
      ? JSON.parse(editCMSPagesData?.content).imageLink
      : ""
  );

  const [videoLink, setVideoLink] = useState(
    isEditCMSPages &&
      editCMSPagesData?.key === strings.cmsPages.ABOUT &&
      JSON.parse(editCMSPagesData?.content).videoLink != null
      ? JSON.parse(editCMSPagesData?.content).videoLink
      : ""
  );

  const submitHandler = async (e) => {
    e.preventDefault();
    if (isEmpty(title)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.PAGE_TITLE_ENGLISH_REQUIRED,
      });
    } else if (isEmpty(titleInFrench)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.PAGE_TITLE_FRENCH_REQUIRED,
      });
    } else if (editCMSPagesData?.key === strings.cmsPages.CONTACT && isEmpty(address)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.ADDRESS_ENGLISH_REQUIRED,
      });
    } else if (editCMSPagesData?.key === strings.cmsPages.CONTACT && isEmpty(addressInFrench)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.ADDRESS_FRENCH_REQUIRED,
      });
    } else if (editCMSPagesData?.key === strings.cmsPages.CONTACT && isEmpty(email)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.EMAIL_REQUIRED,
      });
    } else if (
      editCMSPagesData?.key === strings.cmsPages.CONTACT &&
      !newEmailValidation(email)
    ) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.EMAIL_VALIDATION,
      });
    } else if (editCMSPagesData?.key === strings.cmsPages.CONTACT && isEmpty(fbLink)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.FB_LINK_REQUIRED,
      });
    } else if (editCMSPagesData?.key === strings.cmsPages.CONTACT && !urlValidation(fbLink)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.FB_LINK_VALIDATION,
      });
    } else if (editCMSPagesData?.key === strings.cmsPages.CONTACT && isEmpty(instagramLink)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.INSTAGRAM_LINK_REQUIRED,
      });
    } else if (
      editCMSPagesData?.key === strings.cmsPages.CONTACT &&
      !urlValidation(instagramLink)
    ) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.INSTAGRAM_LINK_VALIDATION,
      });
    } else if (editCMSPagesData?.key === strings.cmsPages.CONTACT && isEmpty(twitterLink)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.TWITTER_LINK_REQUIRED,
      });
    } else if (
      editCMSPagesData?.key === strings.cmsPages.CONTACT &&
      !urlValidation(twitterLink)
    ) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.TWITTER_LINK_VALIDATION,
      });
    } else if (
      editCMSPagesData?.key !== strings.cmsPages.CONTACT &&
      isEmpty(aboutUsdDescritpion)
    ) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.DESCRIPTION_ENGLISH_REQUIRED,
      });
    } else if (
      editCMSPagesData?.key !== strings.cmsPages.CONTACT &&
      isEmpty(aboutUsdDescritpionInFrench)
    ) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.DESCRIPTION_FRENCH_REQUIRED,
      });
    } else if (editCMSPagesData?.key !== strings.cmsPages.CONTACT && isEmpty(aboutUsImageLink)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.IMAGE_LINK_REQUIRED,
      });
    } else if (
      editCMSPagesData?.key !== strings.cmsPages.CONTACT &&
      !urlValidation(aboutUsImageLink)
    ) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.IMAGE_LINK_VALIDATION,
      });
    } else if (editCMSPagesData?.key !== strings.cmsPages.CONTACT && isEmpty(videoLink)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.VIDEO_LINK_REQUIRED,
      });
    } else if (editCMSPagesData?.key !== strings.cmsPages.CONTACT && !urlValidation(videoLink)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.VIDEO_LINK_VALIDATION,
      });
    } else {
      let requestInfo;

      if (editCMSPagesData?.key === strings.cmsPages.CONTACT) {
        let content = JSON.stringify({
          address: address,
          address_fr: addressInFrench,
          email: email,
          fbLink: fbLink,
          instagramLink: instagramLink,
          twitterLink: twitterLink,
        });

        requestInfo = {
          id: id,
          title: title,
          content: content,
          title_fr: titleInFrench,
        };
      } else {
        let content = JSON.stringify({
          description: aboutUsdDescritpion,
          description_fr: aboutUsdDescritpionInFrench,
          imageLink: aboutUsImageLink,
          videoLink: videoLink,
        });

        requestInfo = {
          id: id,
          title: title,
          content: content,
          title_fr: titleInFrench,
        };
      }
      let editCMSPagesResponse = await dispatch(
        editAdminCMSPagesAction({ language: strings.language.EN, requestInfo })
      );
      if (editCMSPagesResponse.payload?.success) {
        navigate(paths.adminRoutes.CMS_PAGES, { replace: true });
      }
    }
  };

  const titleChangeEnHandler = (e) => {
    setTitle(e.target.value);
  };
  const titleChangeFrHandler = (e) => {
    setTitleInFrench(e.target.value);
  };
  const addressChangeEnHandler = (e) => {
    setAddress(e.target.value);
  };
  const addressChangeFrHandler = (e) => {
    setAddressInFrench(e.target.value);
  };
  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
  };
  const fbLinkChangeHandler = (e) => {
    setFBLink(e.target.value);
  };
  const instaLinkChageHandler = (e) => {
    setInstagramLink(e.target.value);
  };
  const twitterLinkChangeHandler = (e) => {
    setTwitterLink(e.target.value);
  };
  const aboutUsdDescritpionEnHandler = (e) => {
    setAboutUsdDescritpion(e.target.value);
  };
  const aboutUsdDescritpionFrHandler = (e) => {
    setAboutUsdDescritpionInFrench(e.target.value);
  };
  const imageLinkChangeHandler = (e) => {
    setAboutUsImageLink(e.target.value);
  };
  const videoLinkChangeHandler = (e) => {
    setVideoLink(e.target.value);
  };
  const cancelHandler = () => {
    navigate(paths.adminRoutes.CMS_PAGES, { replace: true });
  };
  return (
    <div>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginLeft: 5, marginTop: 5 }}
        >
          {editCMSPagesData?.key === strings.cmsPages.CONTACT
            ? strings.general.EDIT_CONTACT_US
            : editCMSPagesData?.key === strings.cmsPages.ABOUT
            ? strings.general.EDIT_ABOUT_US
            : strings.general.CMS_PAGE}
        </Typography>
      </Stack>

      <form
        style={{
          marginBottom: "5px",
          marginLeft: "50px",
          marginRight: "50px",
          paddingBottom: "20px",
        }}
      >
        <CustomTextField
          style={{
            width: "100%",
            marginTop: "5px",
            marginBottom: "5px",
            marginLeft: "5px",
            marginRight: "5px",
          }}
          type="text"
          label={`${strings.general.PAGE_TITLE_ENGLISH}*`}
          variant="outlined"
          focusColor="#184437"
          value={title}
          autoFocus
          autoComplete="off"
          inputProps={{ maxLength: 150 }}
          onChange={titleChangeEnHandler}
        />
        <br />
        <CustomTextField
          style={{
            width: "100%",
            marginTop: "5px",
            marginBottom: "5px",
            marginLeft: "5px",
            marginRight: "5px",
          }}
          type="text"
          label={`${strings.general.PAGE_TITLE_FRENCH}*`}
          variant="outlined"
          focusColor="#184437"
          value={titleInFrench}
          autoFocus
          autoComplete="off"
          inputProps={{ maxLength: 150 }}
          onChange={titleChangeFrHandler}
        />
        <br />
        {editCMSPagesData?.key === strings.cmsPages.CONTACT ? (
          <>
            <CustomTextField
              style={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "5px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
              type="text"
              label={`${strings.words.ADDRESS}*`}
              variant="outlined"
              focusColor="#184437"
              value={address}
              autoFocus
              autoComplete="off"
              inputProps={{ maxLength: 150 }}
              onChange={addressChangeEnHandler}
            />
            <br />
            <CustomTextField
              style={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "5px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
              type="text"
              label={`${strings.words.ADRESSE}*`}
              variant="outlined"
              focusColor="#184437"
              value={addressInFrench}
              autoFocus
              autoComplete="off"
              inputProps={{ maxLength: 150 }}
              onChange={addressChangeFrHandler}
            />
            <br />
            <CustomTextField
              style={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "5px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
              type="text"
              label={`${strings.words.EMAIL}*`}
              variant="outlined"
              focusColor="#184437"
              value={email}
              autoFocus
              autoComplete="off"
              inputProps={{ maxLength: 150 }}
              onChange={emailChangeHandler}
            />
            <br />
            <CustomTextField
              style={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "5px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
              type="text"
              label={`${strings.general.FB_LINK}*`}
              variant="outlined"
              focusColor="#184437"
              value={fbLink}
              autoFocus
              autoComplete="off"
              inputProps={{ maxLength: 150 }}
              onChange={fbLinkChangeHandler}
            />
            <br />
            <CustomTextField
              style={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "5px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
              type="text"
              label={`${strings.general.INSTAGRAM_LINK}*`}
              variant="outlined"
              focusColor="#184437"
              value={instagramLink}
              autoFocus
              autoComplete="off"
              inputProps={{ maxLength: 150 }}
              onChange={instaLinkChageHandler}
            />
            <br />
            <CustomTextField
              style={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "5px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
              type="text"
              label={`${strings.general.TWITTER_LINK}*`}
              variant="outlined"
              focusColor="#184437"
              value={twitterLink}
              autoFocus
              autoComplete="off"
              inputProps={{ maxLength: 150 }}
              onChange={twitterLinkChangeHandler}
            />
            <br />
          </>
        ) : (
          <>
            <CustomTextField
              style={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "5px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
              type="text"
              label={`${strings.words.DESCRIPTION}*`}
              variant="outlined"
              focusColor="#184437"
              value={aboutUsdDescritpion}
              autoComplete="off"
              onChange={aboutUsdDescritpionEnHandler}
            />
            <br />
            <CustomTextField
              style={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "5px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
              type="text"
              label={`${strings.general.DESCRIPTION_IN_FRENCH}*`}
              variant="outlined"
              focusColor="#184437"
              value={aboutUsdDescritpionInFrench}
              autoComplete="off"
              onChange={aboutUsdDescritpionFrHandler}
            />
            <br />
            <CustomTextField
              style={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "5px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
              type="text"
              label={`${strings.general.IMAGE_LINK}*`}
              variant="outlined"
              focusColor="#184437"
              value={aboutUsImageLink}
              autoComplete="off"
              onChange={imageLinkChangeHandler}
            />
            <br />
            <CustomTextField
              style={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "5px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
              type="text"
              label={`${strings.general.VIDEO_LINK}*`}
              variant="outlined"
              focusColor="#184437"
              value={videoLink}
              autoComplete="off"
              onChange={videoLinkChangeHandler}
            />
          </>
        )}
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="start"
          justifyContent="left"
          style={{ paddingBottom: "20px", marginTop: 20 }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "#184437",
              marginTop: 5,
              marginRight: "12px",
            }}
            onClick={submitHandler}
          >
            {strings.words.SUBMIT}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#184437", marginTop: 5 }}
            onClick={cancelHandler}
          >
            {strings.words.CANCEL}
          </Button>
        </Stack>
      </form>
    </div>
  );
}

export default EditCMSPage;
