import { strings } from "../utils/constants";

export const newEmailValidation = (email) => {
  if (/^[a-zA-Z0-9.a-zA-Z0-9.%+-_]+@[a-zA-Z0-9]+\.[a-zA-Z]+/.test(email)) {
    return true;
  } else {
    return false;
  }
};

export const urlValidation = (url) => {
  const regex = new RegExp(
    "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
  );

  if (regex.test(url)) {
    return true;
  }
  return false;
};

export const contactNumberValidation = (number) => {
  if (/^[0-9]{10}$/.test(number)) {
    return true;
  }
  return false;
};

export const adminContactNumberValidation = (number) => {
  if (typeof number === "function") {
    if (isEmpty(number)) {
      return strings.admin.error.PHONE_NUMBER_REQUIRED;
    }
    if (/^[0-9]{10}$/.test(number)) {
      return null;
    }
    return strings.admin.error.PHONE_NUMBER_VALIDATION;
  } else {
    if (number === "") {
      return strings.admin.error.PHONE_NUMBER_REQUIRED;
    }
    if (/^[0-9]{10}$/.test(number)) {
      return null;
    }
    return strings.admin.error.PHONE_NUMBER_VALIDATION;
  }
};

export const newPasswordValidation = (password) => {
  const regex = new RegExp(
    "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#?!@#$%^&+=])(?=\\S+$).{8,20}$"
  );

  if (regex.test(password)) {
    return true;
  }

  return false;
};

export const confirmPasswordValidation = (password, confirmPassword) => {
  if (password === confirmPassword) {
    return true;
  }
  return false;
};

export const isEmpty = (value) => {
  return value.trim() === "";
};

export const zipCodeValidation = (text) => {
  const alphanumericRegex = /^[a-zA-Z0-9]+$/;
  return alphanumericRegex.test(text);
};

export const convertToDate = (value) => {
  let d = value?.split("T");
  const [day, month, year] = d[0].split("-");
  var dateStirng = year + "/" + month + "/" + day;
  return dateStirng;
};
