export const strings = {
  language: {
    FR: "fr",
    EN: "en",
  },
  cmsPages: {
    CONTACT: "contact",
    ABOUT: "about",
  },
  subscriptionsTypes: {
    FREE: "free",
    INDIVIDUAL: "individual",
    BUSINESS: "business",
  },
  navigationHeader: {
    user: {
      HOME: "Home",
      SEARCH_FORMS: "SearchForms",
      LIBRARY: "Library",
      ACCESS_ADA: "AccessADA",
      SUBSCRIPTION_PLANS: "SubscriptionPlans",
      SUBSCRIPTIONS: "Subscriptions",
      ABOUT_US: "AboutUs",
      ABOUT: "About",
      DEMO_TRAINING: "DemoTraining",
      CONTECT_US: "ContactUs",
    },
  },
  words: {
    ENGLISH: "English",
    FRENCH: "French",
    ACTIONS: "Actions",
    DOWNLOAD: "Download",
    SEARCH: "Search",
    NAME: "Name",
    PHONE: "Phone",
    INACTIVE: "InActive",
    ACTIVE: "Active",
    EXPIRED: "Expired",
    SUCCESS: "Success",
    ERROR: "Error",
    PREV: "Prev",
    NEXT: "Next",
    SUBMIT: "Submit",
    RESET: "Reset",
    CANCEL: "Cancel",
    CANCELLED: "Cancelled",
    YES: "Yes",
    WELCOME: "Welcome",
    LOGOUT: "Logout",
    LOGIN: "Login",
    UPDATE: "Update",
    MODIFY: "Modify",
    SAVE: "Save",
    ADDRESS: "Address",
    ADRESSE: "Adresse",
    PASSWORD: "Password",
    CARRIER: "Carrier",
    NUMBER: "Number",
    DESCRIPTION: "Description",
    ADA: "ADA",
    STATUS: "Status",
    USERNAME: "Username",
    EMAIL: "Email",
    FINISH: "Finish",
    MESSAGE: "Message",
    COUNTRY: "Country",
    PROVINCE: "Province",
    CODE: "Code",
    CITY: "City",
    PRICE: "Price",
    TOTAL: "Total",
    TAX: "Tax",
    BUY: "Buy",
    VIEW: "View",
    SUBSCRIPTION: "Subscription",
    MONTHLY: "Monthly",
    YEARLY: "Yearly",
    DOWNGRADE: "Downgrade",
    UPGRADE: "Upgrade",
    PRESENTATIONS: "Presentations",
  },
  general: {
    REFRESH_ADA_FORM: "Refresh ADA Form",
    SR_NO: "Sr. No",
    IS_MGA: "Is MGA User",
    MGA_CODE: "MGA Code",
    PLAN_NAME: "Plan Name",
    PLAN_NAME_FRENCH: "Nom du régime",
    SECTION_NAME: "Section Name",
    TAG_NAME: "Tag Name",
    DATA_FIELD: "Data Field",
    DATA_TYPE: "Data Type",
    FIELD_NO: "Field No.",
    EDIT_FORM: "Edit Form",
    ADD_FORM: "Add Form",
    EDIT_PDF_MAPPING: "Edit PDF Mapping",
    PDF_MAPPING: "PDF Mapping",
    DESCRIPTION_IN_FRENCH: "Description in french",
    DURATION_IN_MONTH_DAYS: "Duration In Month/Days",
    DURATION_IN_DAYS: "Duration In Days",
    CLIENT_LIMITS: "Client Limits",
    DURATION_IN_YEAR: "Duration In Year",
    MONTHLY_PRICE: "Monthly Price($)",
    YEARLY_PRICE: "Yearly Price($)",
    NO_RECORD_FOUND: "No records found",
    SUBSCRIPTION_PLAN: "Subscription Plan",
    REGENERATE_LICENSE_KEY: "Regenerate License Key",
    ALL_USERS: "All Users",
    MGA_USERLS: "MGA Users",
    MY_PROFILE: "My Profile",
    NON_MGA_USERLS: "Non MGA Users",
    EXPIRY_DATE: "Expiry Date",
    PAYMENT_AMOUNT: "Payment Amount",
    PAYMENT_STATUS: "Payment Status",
    PAYMENT_METHOD: "Payment Method",
    CHANGE_PASSWORD: "Change Password",
    PLEASE_WAIT: "Please wait...",
    SELECT_CARRIER: "Select Carrier",
    SELECT_BUSINESS_ACTION: "Select Business Action",
    ALL_FORMS: "All Forms",
    CARRIER_NAME_ENGLISH: "Carrier Name",
    CARRIER_NAME_FRENCH: "Nom du transporteur",
    MGA_NAME: "MGA Name",
    POLICY_NAME_ENGLISH: "Business Action",
    POLICY_NAME_FRENCH: "Action commerciale",
    PAGE_TITLE_ENGLISH: "Page Title",
    PAGE_TITLE_FRENCH: "Titre de la page",
    ALL_SUBSCRIPTIONS: "All subscriptions",
    ACTIVE_SUBSCRIPTIONS: "Active Subscriptions",
    EXPIRED_SUBSCRIPTIONS: "Expired Subscriptions",
    SUBSCRIPTIONS_TYPE: "Subscription Type",
    SUBSCRIPTIONS_STATUS: "Subscription Status",
    ALL_DATA: "All Data",
    ADA_FORMS: "ADA Forms",
    NON_ADA_FORMS: "Non ADA Forms",
    MAPPED_FORMS: "Mapped Forms",
    NON_MAPPED_FORMS: "Non Mapped Forms",
    FORM_NAME_ENGLISH: "Form Name",
    FORM_LANGUAGE: "Form Language",
    FORM_NAME_FRENCH: "Nom de forme",
    FORM_NO: "Form No.",
    FORM_VERSION: "Form Version",
    IS_ADA_COMPATIBLE: "Is ADA Compatible",
    IS_MAPPED: "Is Mapped",
    ARE_YOU_SURE: "Are you sure?",
    YOU_WANT_TO_DELETE_THIS_FORM: "You want to delete this form!",
    NEW_PASSWORD: "New Password",
    CONFIRM_PASSWORD: "Confirm Password",
    MAPPED_RECORD_DELETE_SUCCESSFULLY: "Mapped Record Deleted Successfully.",
    YOU_WANT_TO_DELETEMAPPING: "You want to delete this form mapping data?",
    EDIT_CARRIER: "Edit Carrier",
    ADD_NEW_CARRIER: "Add New Carrier",
    EDIT_USER: "Edit User",
    ADD_NEW_USER: "Add New User",
    YOUR_NAME: "Your Name",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    CITY_NAME: "City Name",
    MOBILE_NUMBER: "Mobile Number",
    POSTAL_CODE: "Postal Code",
    SELECT_COUNTRY: "Select Country",
    SELECT_PROVINCE: "Select Province",
    EDIT_POLICY: "Edit Policy",
    ADD_NEW_POLICY: "Add New Policy",
    EDIT_MGA: "Edit MGA",
    ADD_NEW_MGA: "Add New MGA",
    FORM_MANAGEMENT: "Form Management",
    ADD_NEW: "Add New",
    FORGOT_PASSWORD: "Forgot Password",
    CURRENT_PASSWORD: "Current Password",
    EDIT_CONTACT_US: "Edit Contact Us",
    EDIT_ABOUT_US: "Edit About Us",
    CMS_PAGE: "CMS Page",
    FB_LINK: "Facebook Link",
    INSTAGRAM_LINK: "Instagram Link",
    TWITTER_LINK: "Twitter Link",
    IMAGE_LINK: "Image Link",
    VIDEO_LINK: "Video Link",
    FORM_DESCRIPTION_ENGLISH: "Form Description",
    FORM_DESCRIPTION_FRENCH: "Description du formulaire",
    FORM_NUMBER: "Form Number",
    FORM_URL: "Form URL",
    EDIT_SUBSCRIPTION_PLAN: "Edit Subscription Plan",
    ADD_SUBSCRIPTION_PLAN: "Add Subscription Plan",
    SELECT_DATA_FIELD: "Select Data Field",
    ALL_FIELDS_ARE_MAPPED_SELECT_TEXT:
      "All Fields are mapped with same Section & Tag!",
    SELECT_DATA_TYPE: "Select Data Type",
    ADD_TAG_TO_GROUP: "Add Tag To Group",
    FIELD_NUMBER: "Field Number",
    MAPPING_SAVE_SUCCESSFULLY: "Mapping saved successfully.",
    FORM_MAPPED_SUCCESSFULLY: "Form Mapped successfully",
  },
  error: {
    NETWORK_ERROR: "ERR_NETWORK",
    NOT_AUTHORIZED_ERROR: "Not authorized",
    EMAIL_REQUIRED_ERROR: "EmailRequiredError",
    EMAIL_VALIDATION_ERROR: "EmailValidationError",
    DUPLICATE_EMAILS_ERROR: "DuplicateEmailsError",
    EMAIL_ALREADY_EXISTS_ERROR: "AlreadyExistsEmailsError",
    NAME_REQUIRED_ERROR: "NameRequiredError",
    PHONE_NUMBER_VALIDATION_ERROR: "PhoneNumberValidationError",
    PHONE_NUMBER_REQUIRED_ERROR: "PhoneNumberRequiredError",
    MESSAGE_REQUIRED_ERROR: "MessageRequiredError",
    PASSWORDS_REQUIRED_ERROR: "PasswordsRequiredError",
    NEW_PASSWORD_REQUIRED_ERROR: "NewPasswordRequiredError",
    PASSWORD_VALIDATION_ERROR: "PasswordValidationError",
    CONFIRM_PASSWORD_REQUIRED_ERROR: "ConfirmPasswordRequiredError",
    SAME_PASSWORD_VALIDATION_ERROR: "SamePasswordValidationError",
    SAME_CURR_NEW_PASSWORD_VALIDATION_ERROR:
      "SameCurrNewPasswordValidationError",
    CURRENT_PASSWORD_ERROR: "CurrentPasswordError",
    CAN_NOT_ADD_MORE_EMAILS_ERROR: "Can'tAddMoreEmailsError",
    USERNAME_AND_PASSWORD_REQUIRED_ERROR: "UserNameAndPasswordRequiredError",
    USERNAME_REQUIRED_ERROR: "UserNameRequiredError",
    FIRSTNAME_REQUIRED_ERROR: "FirstNameRequiredError",
    LASTNAME_REQUIRED_ERROR: "LastNameRequiredError",
    PASSWORD_REQUIRED_ERROR: "PasswordRequiredError",
    BILLING_ADDRESS_ERROR: "BillingAddressError",
    POSTALCODE_ERROR: "PostalCodeError",
    POSTALCODE_CONTAIN_VALIDATION_ERROR: "PostalCodeContainValidationError",
    POSTALCODE_VALIDATION_ERROR: "PostalCodeValidationError",
    COUNTRY_ERROR: "CountryError",
    PROVINCE_ERROR: "ProvinceError",
    CITY_ERROR: "CityError",
    OTP_ERROR: "OtpError",
    UNAVAILABLE_PLAN_ERROR: "UnavailablePlanError",
    BUSINESSUSER_EMAIL_REQUIRED_ERROR: "BusinessUserEmailRequiredError",
    CAN_NOT_UPGRAD_YEARLY_TO_MONTHLY: "Can'tUpgradeYearlyPlanError",
  },
  translate: {
    BACK_TO_HOME: "BackToHome",
    FIRST_NAME: "FirstName",
    LAST_NAME: "LastName",
    FINAL_PRICE: "FinalPrice",
    NO_RECORD_FOUND: "NoRecordsFound",
    BILLING_DETAILS: "BillingDetails",
    SUBSCRIPTION_PLANS: "SubscriptionPlans",
    SUBSCRIPTION_DSC_1: "SubDsc1",
    SUBSCRIPTION_DSC_2: "SubDsc2",
    SUBSCRIPTION_DSC_3: "SubDsc3",
    CURRENT_PASSWORD: "CurrentPassword",
    SUBSCRIBE_PLAN: "SubscribePlan",
    EMAIL_SENT_FOR_PASS: "EmailSentForPassword",
    UPGRADE_PLAN: "UpgradePlan",
    CANCEL_AUTO_RENEWAL: "CancelAutoRenewal",
    UPDATE_BUSINESS_USER: "UpdateBusinessUser",
    HOME_TITAL_TEXT: "HomeTitalText",
    HOW_MAY_WE_HELP: "HowMayWeHelp",
    PAYMENT: "Payment",
    LIBRARY: "Library",
    NEW_TO_FORMDEPOT: "NewToFormdepot",
    NEW_TO_FORMDEPOT_MSG: "NewToFormdepotMsg",
    PLEASE_WAIT: "PleaseWait",
    SEARCH_FORMS: "SearchForms",
    LIBRARY_CARD_DSC_1: "SearchFormCardDesc1",
    LIBRARY_CARD_DSC_2: "SearchFormCardDesc2",
    ADA_CARD_TITAL: "ADACardTital",
    ADA_CARD_DSC_1: "ADACardDesc1",
    ADA_CARD_DSC_2: "ADACardDesc2",
    ADA_CARD_DSC_3: "ADACardDesc3",
    ACCESS_ADA: "AccessADA",
    DESKTOP_APP_NOT_OPEN: "DesktopAppNotOpen",
    DEMO_TRAINING: "DemoTraining",
    DEMO_TRAINING_CARD_DSC_1: "DemoTrainingCardDesc1",
    FAQ_Q1: "FAQq1",
    FAQ_A1: "FAQa1",
    FAQ_Q2: "FAQq2",
    FAQ_A2: "FAQa2",
    FAQ_Q3: "FAQq3",
    FAQ_A3: "FAQa3",
    FAQ_Q4: "FAQq4",
    FAQ_A4: "FAQa4",
    FREE_SUBSCRIPRION: "FreeSubscription",
    START_FREE_TRIAL: "StartYourFreeTrial",
    ABOUT_FORM_DEPOT: "AboutFormDepot",
    CONTECT_US: "ContactUs",
    CONTECT_US_TEXT: "ContectUsText",
    NO_IMAGE_AVAILABLE: "NoImageAvailable",
    VIDEO_TAG_NOT_SUPPORTED: "VideoTagNotSupported",
    NO_VIDEO_AVAILABLE: "NoVideoAvailable",
    PHONE_NUMBER_IS_REQUIRED: "PhoneNumberIsRequired",
    PAGE_NOT_FOUND: "PageNotFound",
    PAGE_NOT_FOUND_DESCRIPTION: "PageNotFoundDescription",
    MY_ACCOUNT: "MyAccount",
    DOCUMENT_NAME: "DocumentName",
    SEARCH_TIPS: "SearchTips",
    CAN_NOT_FIND: "CanNotFind",
    CLICK_HERE_FOR_HELP: "ClickHereForHelp",
    WELCOME_LOGIN: "WelcomeLogin",
    LOGIN_TEXT: "LoginText",
    CURRENT_PAYMENT_METHOD: "CurrentPaymentMethod",
    MODIFY_PAYMENT_METHOD: "ModifyPaymentMethod",
    CHANGE_PASSWORD: "ChangePassword",
    FORGOT_PASSWORD: "ForgotPassword",
    FORGOT_PASSWORD_MESSAGE: "ForgotPasswordMessage",
    YOUR_EMAIL: "YourEmail",
    YOUR_NAME: "YourName",
    YOUR_MESSAGE: "YourMessage",
    SAVE_AND_CONTINUE: "Save&Continue",
    ORDER_SUMMARY: "OrderSummary",
    YOUR_CITY: "YourCity",
    AVAILABLE_CREDITS: "AvailableCredits",
    PHONE_NUMBER: "PhoneNumber",
    SEND_VERIFICATION_CODE: "SendVerificationCode",
    VERIFICATION_CODE: "VerificationCode",
    SENT_VERIFICATION_CODE_MESSAGE: "SentVerificationCodeMessage",
    CHECK_VERIFICATION_CODE_MESSAGE: "CheckVerificationCodeMessage",
    DID_NOT_RECIVED_YET: "Didn'tReceivedYet",
    RESEND_CODE: "ResendCode",
    ALL_FORMS: "AllForms",
    ADA_FORMS: "ADAForms",
    NON_ADA_FORMS: "NonADAForms",
    NEW_PASSWORD: "NewPassword",
    CONFIRM_PASSWORD: "ConfirmPassword",
    PERSONAL_DETAILS: "PersonalDetails",
    BILLING_ADDRESS: "BillingAddress",
    PASSWORD_CHANGED: "PasswordChanged",
    PASSWORD_CHANGED_SUCCESSFULLY: "PasswordChangedSuccessfully",
    SUBSCRIPTIONS_PLAN_DETAILS: "SubscriptionPlanDetails",
    SELECT_CARRIER: "SelectCarrier",
    FORM_NUMBER_KEYWORD: "FormNumberKeyword",
    POSTAL_CODE: "PostalCode",
    ADD_BUSINESS_USERS: "AddBusinessUsers",
    ADD_EMAIL: "AddEmail(s)",
    UPDATE_USER: "UpdateUser",
    SUBSCRIBE_NOW: "SubscribeNow",
    COMING_SOON: "ComingSoon",
    ACTIVE_PLAN_MESSAGE: "ActivePlanMessage",
    TIME_REMAINING: "TimeRemaining",
    OPEN_DESKTOP_APP_MESSAGE: "OpenDesktopAppMessage",
    EXPIRY_DATE: "ExpiryDate",
    ENDING_WITH: "EndingWith",
    NEXT_PAYMENT_DATE: "NextPaymentDate",
    AUTO_RENEWAL_STATUS: "AutoRenewalStatus",
    VIDEO_DEMOS: "VideoDemos",
    SELECT_CARRIER_OR_ADD_FORM_NUMBER_MSG:
      "SelectCarrierOrAddFormNumberMessage",
  },
  admin: {
    error: {
      DURATION_IN_DAYS_REQUIRED: "Duration In Days is required.",
      MONTHLY_PRICE_REQUIRED: "Monthly Price is required.",
      YEARLY_PRICE_REQUIRED: "Yearly Price is required.",
      CLIENT_LIMIT_REQUIRED: "Client Limit is required.",
      CARRIER_NAME_ENGLISH_REQUIRED: "Carrier Name is required.",
      CARRIER_NAME_FRENCH_REQUIRED: "Le nom du transporteur est requis.",
      PLAN_NAME_ENGLISH_REQUIRED: "Plan Name is required.",
      PLAN_NAME_FRENCH_REQUIRED: "Le nom du transporteur est requis.",
      FORM_NAME_ENGLISH_REQUIRED: "Form Name is required.",
      FORM_LANGUAGE_REQUIRED: "Please select Form Language.",
      FORM_NUMBER_REQUIRED: "Form Number is required.",
      FORM_UPLOD_URL_REQUIRED: "Please upload form or add form url.",
      FORM_UPLOD_URL_VALIDATION: "Please add valid form url.",
      FORM_DESCRIPTION_ENGLISH_REQUIRED: "Form Description is required.",
      FORM_DESCRIPTION_FRENCH_REQUIRED:
        "La description du formulaire est requise.",
      POLICY_NAME_ENGLISH_REQUIRED: "Business Action is required.",
      MGA_NAME_REQUIRED: "MGA Name is required.",
      POLICY_NAME_FRENCH_REQUIRED: "Une action commerciale est requise.",
      FIRST_NAME_REQUIRED: "First Name is required.",
      LAST_NAME_REQUIRED: "Last Name is required.",
      USERNAME_REQUIRED: "Username is required.",
      PASSWORD_REQUIRED: "Password is required.",
      USERNAME_AND_PASSWORD_REQUIRED: "Username and password is required.",
      EMAIL_REQUIRED: "Email is required.",
      EMAIL_VALIDATION: "Please enter valid email.",
      PHONE_NUMBER_VALIDATION: "Please enter valid phone number.",
      PHONE_NUMBER_REQUIRED: "Phone number is required.",
      ADDRESS_ENGLISH_REQUIRED: "Address is required.",
      ADDRESS_FRENCH_REQUIRED: "L'adresse est requise.",
      DESCRIPTION_ENGLISH_REQUIRED: "Description is required.",
      DESCRIPTION_FRENCH_REQUIRED: "Une description est requise.",
      POSTALCODE_REQUIRED: "Postal Code is required.",
      POSTALCODE_VALIDATION: "Please enter valid Postal Code.",
      COUNTRY_REQUIRED: "Please select country.",
      PROVINCE_REQUIRED: "Please select province.",
      CITY_REQUIRED: "Please select city.",
      CURRENT_PASSWORD_REQUIRED: "Current password is required.",
      NEW_PASSWORD_REQUIRED: "New password is required.",
      CONFIRM_PASSWORD_REQUIRED: "Confirm password is required.",
      PAGE_TITLE_ENGLISH_REQUIRED: "Page Title is required.",
      FB_LINK_REQUIRED: "Facebook Link is required.",
      FB_LINK_VALIDATION: "Please enter valid Facebook Link.",
      INSTAGRAM_LINK_REQUIRED: "Instagram Link is required.",
      INSTAGRAM_LINK_VALIDATION: "Please enter valid Instagram Link.",
      TWITTER_LINK_REQUIRED: "Twitter Link is required.",
      TWITTER_LINK_VALIDATION: "Please enter valid Twitter Link.",
      IMAGE_LINK_REQUIRED: "Image Link is required.",
      IMAGE_LINK_VALIDATION: "Please enter valid Image Link.",
      VIDEO_LINK_REQUIRED: "Video Link is required.",
      VIDEO_LINK_VALIDATION: "Please enter valid Video Link.",
      SECTION_NAME_REQUIRED: "Please enter Section Name",
      TAG_NAME_REQUIRED: "Please enter Tag Name.",
      DATA_TYPE_REQUIRED: "Please Select Data Type.",
      DATA_FIELD_REQUIRED: "Please Select Data Field.",
      FIELD_NUMBER_REQUIRED: "Please enter Field Number.",
      FIELD_NUMBER_NOT_EXISTS: "Field number does not exist in the form!",
      DATA_FIELD_ALREADY_MAPPED:
        "This data field is already mapped with the same section and same tag.",
      MAP_ATLIST_ONE_FIELD_REQUIRED: "Please map atleast one Field.",
      PAGE_TITLE_FRENCH_REQUIRED: "Le titre de la page est requis.",
      PASSWORDS_MUST_BE_SAME:
        "New password and confirm password must be the same.",
      NEW_AND_CURRENT_PASSWORDS_NOT_BE_SAME:
        "New password and Current password should not be the same.",
      PASSWORD_VALIDATION:
        "Password should be minimum 8 to maximum 20 characters, must contain one special character, at least one uppercase character, and have a numerical.",
    },
  },
};
