import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Stack, Button, Container } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  isFromEditCarrier,
  isFromEditCarrierData,
} from "../../redux/Reducers/AdminCarrierReducer";
import { strings, paths } from "../../utils/constants";
import CarrierTable from "../../component/CarrierTable";

export default function CarrierManagementPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addNewHandler = () => {
    dispatch(isFromEditCarrier(false));
    dispatch(isFromEditCarrierData({}));
    navigate(paths.adminRoutes.ADD_EDIT_CARRIER);
  };
  return (
    <>
      <Container
        style={{ width: "80%", marginRight: "4%", marginBottom: "12px" }}
      >
        <Stack direction="column" alignItems="end">
          <Button
            variant="contained"
            sx={{ color: "#184437" }}
            className="button"
            startIcon={<AddIcon />}
            onClick={addNewHandler}
          >
            {strings.general.ADD_NEW}
          </Button>
        </Stack>
      </Container>
      <CarrierTable />
    </>
  );
}
