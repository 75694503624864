import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../../component/Header";
import {
  getActiveSubscriptionPlan,
  getSubscriptions,
} from "../../../redux/Actions";
import { Toast } from "../../../component/Toast";
import {
  getPlanStatus,
  getSubscriptionID,
  onFreeTrial,
  onPriceSelected,
  onTypeSelect,
} from "../../../redux/Reducers/UserSubscriptionReducer";
import moment from "moment";
import { changeActiveHeader } from "../../../redux/Reducers/GeneralReducer";
import FreeSubscription from "./FreeSubscription";
import { strings, paths } from "../../../utils/constants";

const SubscriptionPlan = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subscription, activePlanDetails, freeTrail } =
    useSelector((state) => state.subscriptionReducer);
  const { language } = useSelector((state) => state.generalReducer);

  const activePlanData = activePlanDetails?.data ? activePlanDetails.data : {};

  const indiSub = subscription?.data
    ? subscription.data.find(
        (sub) => sub.type === strings.subscriptionsTypes.INDIVIDUAL
      )
    : {};
  const bussinessArr = subscription?.data
    ? subscription.data.filter(
        (sub) => sub.type === strings.subscriptionsTypes.BUSINESS
      )
    : [];
  let business1 = {};
  let business2 = {};

  if (bussinessArr.length === 2) {
    business1 = bussinessArr[0];
    business2 = bussinessArr[1];
  }

  let startDate = moment(activePlanData.start_date).format("YYYY-MM-DD");
  let endDate = moment(activePlanData.end_date).format("YYYY-MM-DD");

  let isMonthly = moment(endDate).diff(startDate, "days");
  const [indiPrice, setIndiPrice] = useState(indiSub.month_price);
  const [indiCheck, setIndiCheck] = useState("Monthly");
  const [ubusinessPrice, setUBusinessPrice] = useState(business1.month_price);
  const [ubusinessCheck, setUbusinessCheck] = useState("Monthly");
  const [bussinessPrice, setBussinessPrice] = useState(business2.month_price);
  const [bussinessCheck, setBussinessCheck] = useState("Monthly");
  const getSubscriptionPlans = async () => {
    let allSubScription = await dispatch(
      getSubscriptions({ language: language })
    );
    if (allSubScription.payload?.success) {
      let activePlan = await dispatch(
        getActiveSubscriptionPlan({ language: language })
      );
      if (activePlan.payload?.success) {
        if (!activePlanData?.isFreeUsed) {
          dispatch(onFreeTrial(true));
        } else {
          dispatch(onFreeTrial(false));
          if (activePlanData?.subscription?.id === indiSub?.id) {
            if (isMonthly <= 31) {
              setIndiCheck("Monthly");
              setIndiPrice(activePlanData.subscription.month_price);
            } else {
              setIndiCheck("Yearly");
              setIndiPrice(activePlanData.subscription.year_price);
            }
          } else if (activePlanData?.subscription?.id === business1?.id) {
            if (isMonthly <= 31) {
              setUbusinessCheck("Monthly");
              setUBusinessPrice(activePlanData.subscription.month_price);
            } else {
              setUbusinessCheck("Yearly");
              setUBusinessPrice(activePlanData.subscription.year_price);
            }
          } else if (activePlanData?.subscription?.id === business2?.id) {
            if (isMonthly <= 31) {
              setBussinessCheck("Monthly");
              setBussinessPrice(activePlanData.subscription.month_price);
            } else {
              setBussinessCheck("Yearly");
              setBussinessPrice(activePlanData.subscription.year_price);
            }
          }
        }

        dispatch(
          getPlanStatus(
            business2?.id === activePlanData?.subscription?.id
              ? planDuration === bussinessCheck
                ? strings.words.ACTIVE
                : indiCheck === "Monthly"
                ? "Downgrade"
                : "Upgrade"
              : business1?.id === activePlanData?.subscription?.id ||
                indiSub?.id === activePlanData?.subscription?.id
              ? "Upgrade"
              : ""
          )
        );
      } else {
        dispatch(onFreeTrial(true));
      }
    }
  };

  useEffect(() => {
    getSubscriptionPlans();
    dispatch(
      changeActiveHeader(strings.navigationHeader.user.SUBSCRIPTIONS)
    );
  }, []);

  let planDuration = isMonthly <= 31 ? "Monthly" : "Yearly";
  const isFree = freeTrail === undefined || freeTrail ? true : false;
  const indiSubMonthHandler = () => {
    setIndiPrice(indiSub.month_price);
    dispatch(onPriceSelected(indiSub.month_price));
    setIndiCheck("Monthly");
  };
  const indiSubYearHandler = () => {
    setIndiPrice(indiSub.year_price);
    dispatch(onPriceSelected(indiSub.year_price));
    setIndiCheck("Yearly");
  };

  const indiSubClickHandler = () => {
    if (activePlanData?.subscription?.id === indiSub.id) {
      if (planDuration === indiCheck) {
        Toast({
          type: "error",
          message: t(strings.translate.ACTIVE_PLAN_MESSAGE),
        });
      } else if (!indiSub.is_active) {
        Toast({
          type: "error",
          message: t(strings.error.UNAVAILABLE_PLAN_ERROR),
        });
      } else {
        dispatch(
          onPriceSelected(
            indiCheck === "Monthly" ? indiSub.month_price : indiSub.year_price
          )
        );
        dispatch(getSubscriptionID(indiSub.id));
        dispatch(
          getPlanStatus(
            indiSub === activePlanData?.subscription?.id
              ? planDuration === indiCheck
                ? strings.words.ACTIVE
                : indiCheck === "Monthly"
                ? "Downgrade"
                : "Upgrade"
              : business1.id === activePlanData?.subscription?.id ||
                business2.id === activePlanData?.subscription?.id
              ? "Downgrade"
              : ""
          )
        );
        dispatch(onTypeSelect(indiCheck));
        navigate(paths.userRoutes.BILLING);
      }
    } else if (!indiSub.is_active) {
      Toast({
        type: "error",
        message: t(strings.error.UNAVAILABLE_PLAN_ERROR),
      });
    } else {
      dispatch(
        onPriceSelected(
          indiCheck === "Monthly" ? indiSub.month_price : indiSub.year_price
        )
      );
      dispatch(getSubscriptionID(indiSub.id));
      dispatch(
        getPlanStatus(
          indiSub.id === activePlanData?.subscription?.id
            ? planDuration === indiCheck
              ? strings.words.ACTIVE
              : indiCheck === "Monthly"
              ? "Downgrade"
              : "Upgrade"
            : business1.id === activePlanData?.subscription?.id ||
              business2.id === activePlanData?.subscription?.id
            ? "Downgrade"
            : ""
        )
      );
      dispatch(onTypeSelect(indiCheck));
      navigate(paths.userRoutes.BILLING);
    }
  };

  const business1MonthHandler = () => {
    setUBusinessPrice(business1.month_price);
    setUbusinessCheck("Monthly");
    dispatch(onPriceSelected(business1.month_price));
  };
  const business1YearHandler = () => {
    setUBusinessPrice(business1.year_price);
    dispatch(onPriceSelected(business1.year_price));
    setUbusinessCheck("Yearly");
  };

  const business1ClickHandler = () => {
    // Toast({
    //   type: "success",
    //   message: t(strings.translate.COMING_SOON),
    // });
    /* WILL BE USED IN SECOND PHASE */
    // if (activePlanData?.subscription?.id === business1.id) {
    //   if (planDuration === ubusinessCheck) {
    //     Toast({
    //       type: "error",
    //       message: t(strings.translate.ACTIVE_PLAN_MESSAGE),
    //     });
    //   } else if (!business1.is_active) {
    //     Toast({
    //       type: "error",
    //       message: t(strings.error.UNAVAILABLE_PLAN_ERROR),
    //     });
    //   } else {
    //     dispatch(
    //       onPriceSelected(
    //         ubusinessCheck === "Monthly"
    //           ? business1.month_price
    //           : business1.year_price
    //       )
    //     );
    //     dispatch(getSubscriptionID(business1.id));
    //     dispatch(
    //       getPlanStatus(
    //         business1.id === activePlanData?.subscription?.id
    //           ? planDuration === ubusinessCheck
    //             ? strings.words.ACTIVE
    //             : ubusinessCheck === "Monthly"
    //             ? "Downgrade"
    //             : "Upgrade"
    //           : indiSub.id === activePlanData?.subscription?.id
    //           ? "Upgrade"
    //           : business2.id === activePlanData?.subscription?.id
    //           ? "Downgrade"
    //           : ""
    //       )
    //     );
    //     dispatch(onTypeSelect(ubusinessCheck));
    //     navigate(paths.userRoutes.BILLING);
    //   }
    // } else if (!business1.is_active) {
    //   Toast({
    //     type: "error",
    //     message: t(strings.error.UNAVAILABLE_PLAN_ERROR),
    //   });
    // } else {
    //   if (
    //     activePlanData.subscription.type !== strings.subscriptionsTypes.FREE
    //   ) {
    //     if (
    //       planDuration === "Yearly" &&
    //       ubusinessCheck !== "Yearly" &&
    //       planStatus !== "Downgrade" &&
    //       planStatus !== "Free" &&
    //       planStatus !== ""
    //     ) {
    //       Toast({
    //         type: "error",
    //         message: t(strings.error.CAN_NOT_UPGRAD_YEARLY_TO_MONTHLY),
    //       });
    //     } else if (activePlanData?.subscription?.id === business2.id) {
    //       dispatch(
    //         onPriceSelected(
    //           ubusinessCheck === "Monthly"
    //             ? business1.month_price
    //             : business1.year_price
    //         )
    //       );
    //       dispatch(getSubscriptionID(business1.id));
    //       dispatch(
    //         getPlanStatus(
    //           business1.id === activePlanData?.subscription?.id
    //             ? planDuration === ubusinessCheck
    //               ? strings.words.ACTIVE
    //               : ubusinessCheck === "Monthly"
    //               ? "Downgrade"
    //               : "Upgrade"
    //             : indiSub.id === activePlanData?.subscription?.id
    //             ? "Upgrade"
    //             : business2.id === activePlanData?.subscription?.id
    //             ? "Downgrade"
    //             : ""
    //         )
    //       );
    //       dispatch(onTypeSelect(ubusinessCheck));
    //       navigate(paths.userRoutes.BILLING);
    //     } else {
    //       dispatch(
    //         onPriceSelected(
    //           ubusinessCheck === "Monthly"
    //             ? business1.month_price
    //             : business1.year_price
    //         )
    //       );
    //       dispatch(getSubscriptionID(business1.id));
    //       dispatch(
    //         getPlanStatus(
    //           business1.id === activePlanData?.subscription?.id
    //             ? planDuration === ubusinessCheck
    //               ? strings.words.ACTIVE
    //               : ubusinessCheck === "Monthly"
    //               ? "Downgrade"
    //               : "Upgrade"
    //             : indiSub.id === activePlanData?.subscription?.id
    //             ? "Upgrade"
    //             : business2.id === activePlanData?.subscription?.id
    //             ? "Downgrade"
    //             : ""
    //         )
    //       );
    //       dispatch(onTypeSelect(ubusinessCheck));
    //       navigate(paths.userRoutes.BILLING);
    //     }
    //   } else {
    //     dispatch(
    //       onPriceSelected(
    //         ubusinessCheck === "Monthly"
    //           ? business1.month_price
    //           : business1.year_price
    //       )
    //     );
    //     dispatch(getSubscriptionID(business1.id));
    //     dispatch(
    //       getPlanStatus(
    //         business1.id === activePlanData?.subscription?.id
    //           ? planDuration === ubusinessCheck
    //             ? strings.words.ACTIVE
    //             : ubusinessCheck === "Monthly"
    //             ? "Downgrade"
    //             : "Upgrade"
    //           : indiSub.id === activePlanData?.subscription?.id
    //           ? "Upgrade"
    //           : business2.id === activePlanData?.subscription?.id
    //           ? "Downgrade"
    //           : ""
    //       )
    //     );
    //     dispatch(onTypeSelect(ubusinessCheck));
    //     navigate(paths.userRoutes.BILLING);
    //   }
    // }
  };

  const business2MonthHandler = () => {
    setBussinessPrice(business2.month_price);
    dispatch(onPriceSelected(business2.month_price));
    setBussinessCheck("Monthly");
  };
  const business2YearHandler = () => {
    setBussinessPrice(business2.year_price);
    dispatch(onPriceSelected(business2.year_price));
    setBussinessCheck("Yearly");
  };

  const business2ClicksHandler = () => {
    // Toast({
    //   type: "success",
    //   message: t(strings.translate.COMING_SOON),
    // });
    /* WILL BE USED IN SECOND PHASE */
    // if (activePlanData?.subscription?.id === business2.id) {
    //   if (planDuration === bussinessCheck) {
    //     Toast({
    //       type: "error",
    //       message: t(strings.translate.ACTIVE_PLAN_MESSAGE),
    //     });
    //   } else if (!business2.is_active) {
    //     Toast({
    //       type: "error",
    //       message: t(strings.error.UNAVAILABLE_PLAN_ERROR),
    //     });
    //   } else {
    //     dispatch(getSubscriptionID(business2.id));
    //     dispatch(
    //       onPriceSelected(
    //         bussinessCheck === "Monthly"
    //           ? business2.month_price
    //           : business2.year_price
    //       )
    //     );
    //     dispatch(
    //       getPlanStatus(
    //         business2.id === activePlanData?.subscription?.id
    //           ? planDuration === bussinessCheck
    //             ? strings.words.ACTIVE
    //             : indiCheck === "Monthly"
    //             ? "Downgrade"
    //             : "Upgrade"
    //           : business1.id === activePlanData?.subscription?.id ||
    //             indiSub.id === activePlanData?.subscription?.id
    //           ? "Upgrade"
    //           : ""
    //       )
    //     );
    //     dispatch(onTypeSelect(bussinessCheck));
    //     navigate(paths.userRoutes.BILLING);
    //   }
    // } else if (!business2.is_active) {
    //   Toast({
    //     type: "error",
    //     message: t(strings.error.UNAVAILABLE_PLAN_ERROR),
    //   });
    // } else {
    //   if (
    //     planDuration === "Yearly" &&
    //     bussinessCheck === "Monthly" &&
    //     ((planStatus !== "Free" && planStatus !== "") ||
    //       planStatus === "Upgrade")
    //   ) {
    //     Toast({
    //       type: "error",
    //       message: t(strings.error.CAN_NOT_UPGRAD_YEARLY_TO_MONTHLY),
    //     });
    //   } else {
    //     dispatch(getSubscriptionID(business2.id));
    //     dispatch(
    //       onPriceSelected(
    //         bussinessCheck === "Monthly"
    //           ? business2.month_price
    //           : business2.year_price
    //       )
    //     );
    //     dispatch(
    //       getPlanStatus(
    //         business2.id === activePlanData?.subscription?.id
    //           ? planDuration === bussinessCheck
    //             ? strings.words.ACTIVE
    //             : indiCheck === "Monthly"
    //             ? "Downgrade"
    //             : "Upgrade"
    //           : business1.id === activePlanData?.subscription?.id ||
    //             indiSub.id === activePlanData?.subscription?.id
    //           ? "Upgrade"
    //           : ""
    //       )
    //     );
    //     dispatch(onTypeSelect(bussinessCheck));
    //     navigate(paths.userRoutes.BILLING);
    //   }
    // }
  };
  
  return (
    <body>
      <div>
        <Header />
      </div>
      {isFree ? (
        <FreeSubscription />
      ) : (
        <>
          <section className="subplan-banner bg--primary">
            <div className="container">
              <h1 className="font-lato_bold font-26 fg-white text-center py-5 m-0">
                {t(strings.navigationHeader.user.SUBSCRIPTIONS)}
              </h1>
            </div>
          </section>
          <section className="py-5 subplans-sec">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mx-auto text-center">
                  <p className="fg-gray-30 font-lato_bold">
                    {t(strings.translate.SUBSCRIPTION_DSC_1)}
                  </p>
                  <p className="fg-gray-30 mb-5">
                    {t(strings.translate.SUBSCRIPTION_DSC_2)}
                  </p>
                </div>
              </div>
              <div className="row justify-content-center align-items-center pt-3">
                <div className="col-md-7 col-lg-4 px-xl-4 free-trial mb-5 mb-lg-0">
                  <div
                    className={`card fg-gray-20 ${
                      indiSub.id === activePlanData?.subscription?.id &&
                      "activ-plan"
                    } px-3`}
                  >
                    <div className="plan-header bg--gray-40 text-white d-inline-block text-center">
                      <h3 className="m-0 font-18">
                        {language === "fr" ? indiSub.name_fr : indiSub.name}
                      </h3>
                    </div>
                    <div className="plan-details">
                      <p className="fg-gray-30 col-lg-9 mb-2 mb-lg-1 mx-auto">
                        {" "}
                        {language === "fr"
                          ? indiSub.description_fr
                          : indiSub.description}
                      </p>
                    </div>
                    <div className="plan-prize py-3 py-lg-0 text-center">
                      <p className="font-48 mb-2 fg-gray-40 font-lato_bold text-center d-flex align-items-center justify-content-center">
                        <sup className="font-lato font-16 fg-gray-20 me-3">
                          $
                        </sup>
                        {indiPrice === undefined
                          ? indiSub.month_price
                          : indiPrice}
                      </p>
                      <p className="mb-2 text-capitalize">
                        {language === "fr" ? indiSub.name_fr : indiSub.name}
                      </p>
                    </div>
                    <div className="plan-list pt-2">
                      <ul className="m-0 p-0 col-10 col-md-9 col-xl-8 mx-auto mb-2">
                        {JSON.parse(indiSub.feature_content).map((item) => {
                          return (
                            <li>
                              <div
                                data-tooltip={
                                  language === "fr"
                                    ? item.hintTextFr
                                    : item.hintText
                                }
                              ></div>
                              {language === "fr" ? item.textFr : item.text}
                            </li>
                          );
                        })}
                      </ul>
                      <div className="text-center">
                        <div className="d-flex justify-content-center">
                          <div onClick={indiSubMonthHandler} className="radio">
                            <input
                              id="radio-1"
                              name="radio1"
                              type="radio"
                              checked={indiCheck === "Monthly" ? true : false}
                            />
                            <label
                              htmlFor="radio-1"
                              className="radio-label fg-gray-30"
                            >
                              {t(strings.words.MONTHLY)}
                            </label>
                          </div>
                          <div onClick={indiSubYearHandler} className="radio">
                            <input
                              id="radio-2"
                              name="radio1"
                              type="radio"
                              checked={indiCheck !== "Monthly" ? true : false}
                            />
                            <label
                              htmlFor="radio-2"
                              className="radio-label fg-gray-30"
                            >
                              {t(strings.words.YEARLY)}
                            </label>
                          </div>
                        </div>
                        <div
                          className={`${
                            indiSub.id === activePlanData?.subscription?.id
                              ? planDuration === indiCheck
                                ? "btn btn--primary"
                                : indiCheck === "Monthly"
                                ? // ? "btn btn--gray-40"
                                  "btn btn--secondary"
                                : "btn btn--secondary"
                              : business1.id ===
                                  activePlanData?.subscription?.id ||
                                business2.id ===
                                  activePlanData?.subscription?.id
                              ? "btn btn--gray-40"
                              : "btn btn--gray-40"
                          }`}
                          onClick={indiSubClickHandler}
                        >
                          {indiSub.id === activePlanData?.subscription?.id
                            ? planDuration === indiCheck
                              ? t(strings.words.ACTIVE)
                              : indiCheck === "Monthly"
                              ? t(strings.words.DOWNGRADE)
                              : t(strings.words.UPGRADE)
                            : business1.id ===
                                activePlanData?.subscription?.id ||
                              business2.id === activePlanData?.subscription?.id
                            ? t(strings.words.DOWNGRADE)
                            : t(strings.translate.SUBSCRIBE_NOW)}
                        </div>
                        <p className="font-11 mt-3 mb-0">
                          Rådgiving utover 0,5t koster 1250 per time eks. mva.
                          *Påfølgende økter/dager gis 20% rabatt.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 col-lg-4 individual-plan mb-5 mb-lg-0">
                  <div
                    className={`card fg-gray-20  ${
                      business1.id === activePlanData?.subscription?.id &&
                      "activ-plan"
                    }`}
                  >
                    <div className="plan-header bg--primary text-white d-inline-block text-center">
                      <h3 className="m-0 font-18">
                        {language === "fr" ? business1.name_fr : business1.name}
                      </h3>
                    </div>
                    <div className="plan-details">
                      <p className="fg-gray-30 col-lg-9 mx-auto mb-2">
                        {language === "fr"
                          ? business1.description_fr
                          : business1.description}
                      </p>
                    </div>
                    <div className="plan-prize py-3 text-center">
                      <p className="font-48 mb-2 fg-primary font-lato_bold text-center d-flex align-items-center justify-content-center">
                        <sup className="font-lato font-16 fg-gray-20 me-3">
                          $
                        </sup>
                        {ubusinessPrice === undefined
                          ? business1.month_price
                          : ubusinessPrice}
                      </p>
                      <p className="mb-2 text-capitalize">
                        {language === "fr" ? business1.name_fr : business1.name}
                      </p>
                    </div>
                    <div className="plan-list pt-2 pt-lg-4">
                      <ul className="m-0 p-0 col-10 col-md-9 col-xl-8 mx-auto mb-2 mb-lg-5">
                        {JSON.parse(business1.feature_content).map((item) => {
                          return (
                            <li>
                              <div
                                data-tooltip={
                                  language === "fr"
                                    ? item.hintTextFr
                                    : item.hintText
                                }
                              ></div>
                              {language === "fr" ? item.textFr : item.text}
                            </li>
                          );
                        })}
                      </ul>
                      <div className="text-center">
                        <div className="d-flex justify-content-center">
                          <div
                            onClick={business1MonthHandler}
                            className="radio"
                          >
                            <input
                              id="radio-3"
                              name="radio2"
                              type="radio"
                              checked={
                                ubusinessCheck === "Monthly" ? true : false
                              }
                            />
                            <label
                              htmlFor="radio-3"
                              className="radio-label fg-gray-30"
                            >
                              {t(strings.words.MONTHLY)}
                            </label>
                          </div>
                          <div onClick={business1YearHandler} className="radio">
                            <input
                              id="radio-4"
                              name="radio2"
                              type="radio"
                              checked={
                                ubusinessCheck !== "Monthly" ? true : false
                              }
                            />
                            <label
                              htmlFor="radio-4"
                              className="radio-label fg-gray-30"
                            >
                              {t(strings.words.YEARLY)}
                            </label>
                          </div>
                        </div>
                        <div
                          className={`${
                            business1.id === activePlanData?.subscription?.id
                              ? planDuration === ubusinessCheck
                                ? "btn btn--primary"
                                : ubusinessCheck === "Monthly"
                                ? "btn btn--gray-40"
                                : "btn btn--secondary"
                              : indiSub.id === activePlanData?.subscription?.id
                              ? "btn btn--secondary"
                              : business2.id ===
                                activePlanData?.subscription?.id
                              ? "btn btn--gray-40"
                              : "btn btn--gray-40"
                          }`}
                          onClick={business1ClickHandler}
                        >
                          {business1.id === activePlanData?.subscription?.id
                            ? planDuration === ubusinessCheck
                              ? strings.words.ACTIVE
                              : ubusinessCheck === "Monthly"
                              ? t(strings.words.DOWNGRADE)
                              : t(strings.words.UPGRADE)
                            : indiSub.id === activePlanData?.subscription?.id
                            ? t(strings.words.UPGRADE)
                            : business2.id === activePlanData?.subscription?.id
                            ? t(strings.words.DOWNGRADE)
                            : t(strings.translate.SUBSCRIBE_NOW)}
                        </div>
                        <p className="font-11 mt-3 mb-0">
                          Rådgiving utover 1t koster 1250 per time eks. mva.
                          *Påfølgende økter/dager gis 15% rabatt.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 col-lg-4 px-xl-4 business-plan">
                  <div
                    className={`card fg-gray-20 ${
                      business2.id === activePlanData?.subscription?.id &&
                      "activ-plan"
                    } px-3`}
                  >
                    <div className="plan-header bg--secondary text-white d-inline-block text-center">
                      <h3 className="m-0 font-18">
                        {language === "fr" ? business2.name_fr : business2.name}
                      </h3>
                    </div>
                    <div className="plan-details">
                      <p className="fg-gray-30 col-lg-9 mx-auto mb-2 mb-lg-1">
                        {" "}
                        {language === "fr"
                          ? business2.description_fr
                          : business2.description}
                      </p>
                    </div>
                    <div className="plan-prize py-2 text-center">
                      <p className="font-48 mb-2 fg-secondary font-lato_bold text-center d-flex align-items-center justify-content-center">
                        <sup className="font-lato font-16 fg-gray-20 me-3">
                          $
                        </sup>
                        {bussinessPrice === undefined
                          ? business2.month_price
                          : bussinessPrice}
                      </p>
                      <p className="mb-2 text-capitalize">
                        {language === "fr" ? business2.name_fr : business2.name}
                      </p>
                    </div>
                    <div className="plan-list pt-2">
                      <ul className="m-0 p-0 col-10 col-md-9 col-xl-8 mx-auto mb-2">
                        {JSON.parse(business2.feature_content).map((item) => {
                          return (
                            <li>
                              <div
                                data-tooltip={
                                  language === "fr"
                                    ? item.hintTextFr
                                    : item.hintText
                                }
                              ></div>
                              {language === "fr" ? item.textFr : item.text}
                            </li>
                          );
                        })}
                      </ul>
                      <div className="text-center">
                        <div className="d-flex justify-content-center">
                          <div
                            onClick={business2MonthHandler}
                            className="radio"
                          >
                            <input
                              id="radio-5"
                              name="radio3"
                              type="radio"
                              checked={
                                bussinessCheck === "Monthly" ? true : false
                              }
                            />
                            <label
                              htmlFor="radio-5"
                              className="radio-label fg-gray-30"
                            >
                              {t(strings.words.MONTHLY)}
                            </label>
                          </div>
                          <div onClick={business2YearHandler} className="radio">
                            <input
                              id="radio-6"
                              name="radio3"
                              type="radio"
                              checked={
                                bussinessCheck !== "Monthly" ? true : false
                              }
                            />
                            <label
                              htmlFor="radio-6"
                              className="radio-label fg-gray-30"
                            >
                              {t(strings.words.YEARLY)}
                            </label>
                          </div>
                        </div>
                        <div
                          className={`${
                            business2.id === activePlanData?.subscription?.id
                              ? planDuration === bussinessCheck
                                ? "btn btn--primary"
                                : indiCheck === "Monthly"
                                ? "btn btn--secondary"
                                : "btn btn--secondary"
                              : business1.id ===
                                  activePlanData?.subscription?.id ||
                                indiSub.id === activePlanData?.subscription?.id
                              ? "btn btn--secondary"
                              : "btn btn--gray-40"
                          }`}
                          onClick={business2ClicksHandler}
                        >
                          {business2.id === activePlanData?.subscription?.id
                            ? planDuration === bussinessCheck
                              ? strings.words.ACTIVE
                              : bussinessCheck === "Monthly"
                              ? t(strings.words.DOWNGRADE)
                              : t(strings.words.UPGRADE)
                            : business1.id ===
                                activePlanData?.subscription?.id ||
                              indiSub.id === activePlanData?.subscription?.id
                            ? t(strings.words.UPGRADE)
                            : t(strings.translate.SUBSCRIBE_NOW)}
                        </div>
                        <p className="font-11 mt-3 mb-0">
                          Rådgiving utover 1t koster 1250 per time eks. mva.
                          *Påfølgende økter/dager gis 10% rabatt.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8 mx-auto text-center">
                  <p className="fg-gray-30 mt-5 mb-0">
                    {t(strings.translate.SUBSCRIPTION_DSC_3)}
                  </p>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </body>
  );
};

export default SubscriptionPlan;
