import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Stack, Button, Typography } from "@mui/material";
import ProgressDialog from "../../component/ProgressDialog";
import { isEmpty } from "../../services/validation";
import { Toast } from "../../component/Toast";
import {
  editAdminPolicyAction,
  addAdminPolicyAction,
} from "../../redux/Actions";
import { strings, paths } from "../../utils/constants";
import CustomTextField from "../../component/CustomTextField";

export default function AddEditPolicyForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isEditPolicy, editPolicyData, isLoading } = useSelector(
    (state) => state.adminPolicyReducer
  );

  const [policyNameEn, setPolicyNameEn] = useState(
    isEditPolicy && editPolicyData?.policy_service_name != null
      ? editPolicyData?.policy_service_name
      : ""
  );
  const [policyNameFr, setPolicyNameFr] = useState(
    isEditPolicy && editPolicyData?.policy_service_name_fr != null
      ? editPolicyData?.policy_service_name_fr
      : ""
  );

  const submitUpateHamdler = async (e) => {
    e.preventDefault();
    if (isEmpty(policyNameEn)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.POLICY_NAME_ENGLISH_REQUIRED,
      });
    } else if (isEmpty(policyNameFr)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.POLICY_NAME_FRENCH_REQUIRED,
      });
    } else {
      if (isEditPolicy) {
        let editAdminPolicyResponse = await dispatch(
          editAdminPolicyAction({
            language: strings.language.EN,
            requestInfo: {
              id: editPolicyData?.id,
              policy_service_name: policyNameEn,
              policy_service_name_fr: policyNameFr,
            },
          })
        );
        if (editAdminPolicyResponse.payload?.success) {
          navigate(paths.adminRoutes.POLICIES, { replace: true });
        }
      } else {
        let addAdminPolicyResponse = await dispatch(
          addAdminPolicyAction({
            language: strings.language.EN,
            requestInfo: {
              policy_service_name: policyNameEn,
              policy_service_name_fr: policyNameFr,
            },
          })
        );
        if (addAdminPolicyResponse.payload?.success) {
          navigate(paths.adminRoutes.POLICIES, { replace: true });
        }
      }
    }
  };

  const policyNameEnChangeHandler = (e) => {
    setPolicyNameEn(e.target.value);
  };

  const policyNameFrChangeHandler = (e) => {
    setPolicyNameFr(e.target.value);
  };

  const cancelHandler = () => {
    navigate(paths.adminRoutes.POLICIES, {
      replace: true,
    });
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom sx={{ marginLeft: 5 }}>
          {isEditPolicy
            ? strings.general.EDIT_POLICY
            : strings.general.ADD_NEW_POLICY}
        </Typography>
      </Stack>
      <form
        style={{
          marginBottom: "5px",
          marginLeft: "50px",
          marginRight: "50px",
        }}
      >
        <CustomTextField
          style={{
            width: "100%",
            marginTop: "5px",
            marginBottom: "5px",
          }}
          type="text"
          label={`${strings.general.POLICY_NAME_ENGLISH}*`}
          variant="outlined"
          focusColor="#184437"
          value={policyNameEn}
          autoFocus
          autoComplete="off"
          inputProps={{ maxLength: 150 }}
          onChange={policyNameEnChangeHandler}
        />
        <br />
        <CustomTextField
          style={{
            width: "100%",
            marginTop: "10px",
            marginBottom: "5px",
          }}
          type="text"
          label={`${strings.general.POLICY_NAME_FRENCH}*`}
          variant="outlined"
          focusColor="#184437"
          value={policyNameFr}
          autoComplete="off"
          inputProps={{ maxLength: 150 }}
          onChange={policyNameFrChangeHandler}
        />
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="start"
          justifyContent="left"
          style={{ paddingBottom: "20px" }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "#184437",
              marginTop: "14px",
              marginRight: "12px",
            }}
            onClick={submitUpateHamdler}
          >
            {isEditPolicy ? strings.words.UPDATE : strings.words.SUBMIT}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#184437", marginTop: "14px" }}
            onClick={cancelHandler}
          >
            {strings.words.CANCEL}
          </Button>
        </Stack>
      </form>
      {isLoading && <ProgressDialog open={isLoading} isAdmin={true} />}
    </>
  );
}
