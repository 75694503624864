import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import i18n from "i18next";
import {
  changeActiveHeader,
  changeLanguage,
} from "../../redux/Reducers/GeneralReducer";
import { profileDetails } from "../../redux/Actions";
import { useCookies } from "react-cookie";
import { strings, paths } from "../../utils/constants";

const Header = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies([
    "access_token",
    "refresh_token",
  ]);
  const [navData, setNavData] = useState([
    {
      label: strings.navigationHeader.user.HOME,
      to: paths.userRoutes.DASHBOARD,
      active: true,
    },
    {
      label: strings.navigationHeader.user.LIBRARY,
      to: paths.userRoutes.SEARCH_FORM,
      active: false,
    },
    {
      label: strings.navigationHeader.user.ACCESS_ADA,
      to: paths.userRoutes.ACCESS_ADA,
      active: false,
    },
    {
      label: strings.navigationHeader.user.SUBSCRIPTIONS,
      to: paths.userRoutes.SUBSCRIPRION,
      active: false,
    },
    {
      label: strings.navigationHeader.user.ABOUT,
      to: paths.userRoutes.ABOUT_US,
      active: false,
    },
    {
      label: strings.navigationHeader.user.DEMO_TRAINING,
      to: paths.userRoutes.DEMO_TRAINING,
      active: false,
    },
    {
      label: strings.navigationHeader.user.CONTECT_US,
      to: paths.userRoutes.CONTACT_US,
      active: false,
    },
  ]);

  const { language, activeName } = useSelector((state) => state.generalReducer);

  const { profile } = useSelector((state) => state.userProfileReducer);

  const languageHandler = async (ln) => {
    i18n.changeLanguage(ln);
    await dispatch(changeLanguage(ln));
  };
  const getProfile = async () => {
    await dispatch(profileDetails({ language: language, isAdmin: false }));
  };
  useEffect(() => {
    getProfile();
  }, [navData]);

  // if mga user come from mga url and has any prefered lang choise
  useEffect(() => {
    languageHandler(i18n.language);
  }, []);

  const navClickHandler = (item, index) => {
    let response = navData.map((cur, ind) => {
      if (ind === index && item.label === cur.label) {
        cur.active = true;
        dispatch(changeActiveHeader(cur.label));
      } else {
        cur.active = false;
      }
      return cur;
    });
    setNavData(response);
  };

  const logoutHandler = async () => {
    await localStorage.removeItem("token");
    removeCookie("access_token", { path: "/" });
    removeCookie("refresh_token", { path: "/" });
    navigate(0);
    navigate("/", { replace: true });
  };

  return (
    <header>
      <nav className="navbar navbar-expand-xl navbar-light bg-white">
        <div className="container align-items-stretch">
          <Link
            to={paths.userRoutes.DASHBOARD}
            className="navbar-brand col-xl-2"
          >
            <img
              src={"../../../assets/images/logo.svg"}
              width="148"
              height="60"
              alt="logo"
              className="header-logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i
              className="fa fa-bars toggler-icon font-24"
              aria-hidden="true"
            ></i>
            <i
              className="close-toggle fa fa-times font-24"
              aria-hidden="true"
            ></i>
          </button>

          <div
            className="collapse navbar-collapse align-items-end "
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav nav-center col-xl-9 me-auto container ">
              {navData.map((item, index) => (
                <li
                  key={item.label + index}
                  onClick={navClickHandler.bind(this, item, index)}
                  className={`nav-item ${
                    activeName === undefined || activeName === item.label
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    to={item.to}
                    className="nav-link"
                    title={t(`${item.label}`)}
                  >
                    {t(`${item.label}`)}
                  </Link>
                </li>
              ))}
            </ul>
            <ul
              className="navbar-nav col-xl-3 align-self-start justify-content-end navbar-right container"
              style={{
                marginBottom: language === strings.language.FR ? "50px" : "0px",
              }}
            >
              <li className="nav-item dropdown px-0">
                <Link
                  className="nav-link dropdown-toggle px-0 text-uppercase"
                  id="language"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {language}
                </Link>
                <div className="dropdown-menu" aria-labelledby="language">
                  <Link
                    onClick={languageHandler.bind(this, strings.language.EN)}
                    className="dropdown-item"
                    href="#"
                  >
                    EN
                  </Link>
                  <Link
                    onClick={languageHandler.bind(this, strings.language.FR)}
                    className="dropdown-item"
                    href="#"
                  >
                    FR
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown px-0">
                <Link
                  className="nav-link dropdown-toggle px-0"
                  id="navbarDropdown"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="font-lato">
                    {`${t(strings.words.WELCOME)}, `}
                  </span>
                  {profile?.data?.firstname
                    ? profile.data.firstname +
                      (profile?.data?.lastname
                        ? ` ${profile.data.lastname}`
                        : "")
                    : profile?.data?.username
                    ? profile.data.username
                    : ""}
                </Link>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link
                    to={paths.userRoutes.MY_PROFILE}
                    className="dropdown-item"
                  >
                    <img
                      src="../../../assets/images/person_2.svg"
                      width="20"
                      height="20"
                      alt="person"
                      className="person me-2"
                    />
                    {t(strings.translate.MY_ACCOUNT)}
                  </Link>
                  <Link
                    onClick={logoutHandler}
                    className="dropdown-item"
                    href="./login.html"
                  >
                    <img
                      src="../../../assets/images/logout.svg"
                      width="20"
                      height="20"
                      alt="logout"
                      className="logout me-2"
                    />
                    {t(strings.words.LOGOUT)}
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
