import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequestWithParams } from "../../services/API";

const FETCH_PROVINCE_DETAILS = "fetchProvinceAction";

export const fetchProvinceAction = createAsyncThunk(
  FETCH_PROVINCE_DETAILS,
  async ({ language, countryId }, { dispatch, rejectWithValue }) => {
    try {
      let fetchProvinceResult = await getRequestWithParams(
        language,
        "province",
        countryId,
        true
      );
      return fetchProvinceResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);
