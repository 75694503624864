import { generalReducer } from "../Reducers/GeneralReducer";
import { loginReducer } from "../Reducers/LoginReducer";
import storage from "redux-persist/lib/storage";
import { thunk } from "redux-thunk";
import { adminUserLicenseKeyReducer } from "../Reducers/AdminUserLicenseKeyReducer";
import { adminSubscriptionPlanReducer } from "../Reducers/AdminSubscriptionReducer";
import { adminMGAUserReducer } from "../Reducers/AdminMGAUserReducer";
import { fetchCountryReducer } from "../Reducers/FetchCountryReducer";
import { fetchProvinceReducer } from "../Reducers/FetchProvinceReducer";
import { applyMiddleware, combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { userProfileReducer } from "../Reducers/UserProfileReducer";
import { changePasswordReducer } from "../Reducers/ChangePasswordReducer";
import { subscriptionReducer } from "../Reducers/UserSubscriptionReducer";
import { formMangmentReducer } from "../Reducers/FormMangmentReducer";
import { adminFormManagementReducer } from "../Reducers/AdminFormManagementReducer";
import { forgotPasswordReducer } from "../Reducers/ForgotPasswordReducer";
import { resetPasswordReducer } from "../Reducers/ResetPasswordReducer";
import { forgotPasswordOTPVerifyReducer } from "../Reducers/ForgotPasswordOTPVerifyReducer";
import { clientEmailsReducer } from "../Reducers/ClientEmailsReducer";
import { adminCustomerPaymentsReducer } from "../Reducers/AdminCustomerPaymentsReducer";
import { adminCMSPagesReducer } from "../Reducers/AdminCMSPagesReducer";
import { userContactUsReducer } from "../Reducers/UserContactUsReducer";
import { adminProfileReducer } from "../Reducers/AdminProfileReducer";
import { adminPolicyReducer } from "../Reducers/AdminPolicyReducer";
import { adminCarrierReducer } from "../Reducers/AdminCarrierReducer";
import { adminMgaReducer } from "../Reducers/AdminMgaReducer";

const reducers = combineReducers({
  loginReducer: loginReducer,
  generalReducer: generalReducer,
  adminUserLicenseKeyReducer: adminUserLicenseKeyReducer,
  adminSubscriptionPlanReducer: adminSubscriptionPlanReducer,
  adminMGAUserReducer: adminMGAUserReducer,
  fetchCountryReducer: fetchCountryReducer,
  fetchProvinceReducer: fetchProvinceReducer,
  userProfileReducer: userProfileReducer,
  changePasswordReducer: changePasswordReducer,
  subscriptionReducer: subscriptionReducer,
  formMangmentReducer: formMangmentReducer,
  adminFormManagementReducer: adminFormManagementReducer,
  forgotPasswordReducer: forgotPasswordReducer,
  forgotPasswordOTPVerifyReducer: forgotPasswordOTPVerifyReducer,
  resetPasswordReducer: resetPasswordReducer,
  clientEmailsReducer: clientEmailsReducer,
  adminCustomerPaymentsReducer: adminCustomerPaymentsReducer,
  adminCMSPagesReducer: adminCMSPagesReducer,
  userContactUsReducer: userContactUsReducer,
  adminProfileReducer: adminProfileReducer,
  adminPolicyReducer: adminPolicyReducer,
  adminCarrierReducer: adminCarrierReducer,
  adminMgaReducer: adminMgaReducer,
});

const persistConfig = {
  key: "root",
  storage: storage,
};

const persistedReducer = persistReducer(
  persistConfig,
  reducers,
  applyMiddleware(thunk)
);

export { persistedReducer };
