import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { paths, strings } from "../../utils/constants";
import {
  getPlanStatus,
  getSubscriptionID,
} from "../../redux/Reducers/UserSubscriptionReducer";
import { Link } from "react-router-dom";

export default function FreePlanCard() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.generalReducer);
  const { subscription } = useSelector((state) => state.subscriptionReducer);

  const FreePlan = subscription?.data
    ? subscription.data.find(
        (item) => item.type === strings.subscriptionsTypes.FREE
      )
    : {};
  const FreePlanFeatureContent = FreePlan?.feature_content ? JSON.parse(FreePlan.feature_content) : [];

  const startTrialHandler = () => {
    dispatch(getSubscriptionID(FreePlan?.id));
    dispatch(getPlanStatus("Free"));
  };
  return (
    <div className="free-trial">
      <div className="card bg-transparent fg-gray-20 px-3">
        <div className="plan-header bg--gray-40 text-white d-inline-block text-center">
          <h3 className="m-0 font-18">
            {language === "fr" ? FreePlan.name_fr : FreePlan.name}
          </h3>
        </div>
        <div className="plan-details">
          <p className="fg-gray-30 col-lg-7 mb-2 mb-lg-1 mx-auto">
            {language === "fr" ? FreePlan.description_fr : FreePlan.description}
          </p>
        </div>
        <div className="plan-prize py-3 py-lg-0 text-center">
          <p className="font-48 mb-2 fg-gray-40 font-lato_bold text-center d-flex align-items-center justify-content-center">
            <sup className="font-lato font-16 fg-gray-20 me-3">$</sup>
            000
          </p>
          <p className="mb-2 text-capitalize">
            {language === "fr" ? FreePlan.name_fr : FreePlan.name}
          </p>
        </div>
        <div className="plan-list py-4">
          <ul className="p-0 col-10 col-md-9 col-xl-8 mx-auto m-0">
            {FreePlanFeatureContent.map((item) => {
              return (
                <li>
                  <div
                    data-tooltip={
                      language === "fr" ? item.hintTextFr : item.hintText
                    }
                  ></div>
                  {language === "fr" ? item.textFr : item.text}
                </li>
              );
            })}
          </ul>
          <div className="text-center">
            <Link
              onClick={startTrialHandler}
              to={paths.userRoutes.BILLING}
              state={{ key: "Free" }}
              className="btn btn--gray-40"
            >
              {t(strings.translate.START_FREE_TRIAL)}
            </Link>
            <p className="font-11 mt-3 mb-0">
              Rådgiving utover 0,5t koster 1250 per time eks. mva. *Påfølgende
              økter/dager gis 20% rabatt.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
