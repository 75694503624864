import React, { useState } from "react";
import { CardElement } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveSubscriptionPlan,
  payForSubscription,
  upgradeActivePlan,
} from "../../../redux/Actions";
import { useNavigate } from "react-router-dom";
import ProgressDialog from "../../../component/ProgressDialog";
import { useTranslation } from "react-i18next";
import { strings, paths } from "../../../utils/constants";

const CheckoutForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const {
    subscriptionID,
    subscriptionType,
    planStatus,
    clintEmails,
    subscription,
  } = useSelector((state) => state.subscriptionReducer);

  let currPlan = {};

  subscription.data.map((r) =>
    r.id === subscriptionID ? (currPlan = r) : null
  );

  const { language } = useSelector((state) => state.generalReducer);
  const { paymentMethodlist } = useSelector(
    (state) => state.subscriptionReducer
  );
  const buyHandler = async () => {
    setDisable(true);
    const { elements, stripe } = props;
    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    if (!error) {
      makePayment(paymentMethod.id);
    }
  };
  const useCardHandler = async (item) => {
    makePayment(item.id);
  };

  const makePayment = async (paymentMethodId) => {
    let payment =
      currPlan.type === strings.subscriptionsTypes.BUSINESS
        ? {
            subscription_id: subscriptionID,
            is_monthly: subscriptionType === "Monthly" ? true : false,
            client_email: clintEmails,
            payment_method_id: paymentMethodId,
          }
        : {
            subscription_id: subscriptionID,
            is_monthly: subscriptionType === "Monthly" ? true : false,
            payment_method_id: paymentMethodId,
          };
    setIsLoading(true);
    let paymentResult =
      planStatus === "Upgrade"
        ? await dispatch(upgradeActivePlan({ language: language, payment }))
        : await dispatch(payForSubscription({ language: language, payment }));
    if (paymentResult.payload?.success) {
      await dispatch(getActiveSubscriptionPlan({ language: language }));
      setIsLoading(false);
      setDisable(false);
      navigate(paths.userRoutes.SUBSCRIPRION);
    } else if (paymentResult.payload?.errors) {
      setIsLoading(false);
      setDisable(false);
    } else {
      setIsLoading(false);
      setDisable(false);
    }
  };

  return (
    <div>
      <CardElement
        options={{
          style: {
            base: {
              fontSize: "16px",
              color: "#424770",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              color: "#9e2146",
            },
          },
          hidePostalCode: true,
        }}
      />
      <button
        className="btn btn--primary text-uppercase mt-10"
        style={{ marginTop: "5%" }}
        onClick={buyHandler}
        disabled={disable}
      >
        {t(strings.words.BUY)}
      </button>
      {paymentMethodlist.length > 0 && (
        <div className="pb-4 col-lg-4 mt-4">
          <p className="form-title fg-gray-30 font-lato_bold mb-4 font-18">
            Or Use Below Card
          </p>
          {paymentMethodlist.map((item) => {
            return (
              <>
                {item.type === "card" ? (
                  <div>
                    <div>
                      <span className="fg-gray-30 font-lato_bold text-capitalize">
                        {item.card.brand}{" "}
                      </span>
                      <span className="fg-gray-30 font-lato_bold text-capitalize">
                        {item.card.funding}{" "}
                      </span>
                      <span className="fg-gray-30 font-lato_bold text-capitalize">
                        {item.type}{" "}
                      </span>
                    </div>
                    <div>
                      <span>{t(strings.translate.ENDING_WITH)}</span>
                      <span className="fg-gray-30 font-lato_bold text-capitalize ms-2">
                        {item.card.last4}
                      </span>
                    </div>
                    <div>
                      <span>{t(strings.translate.EXPIRY_DATE)}</span>
                      <span className="fg-gray-30 font-lato_bold text-capitalize ms-2">
                        {item.card.exp_month}/{item.card.exp_year}
                      </span>
                    </div>
                    <button
                      className="btn btn--primary text-uppercase mt-10"
                      style={{ marginTop: "5%" }}
                      onClick={useCardHandler.bind(this, item)}
                      disabled={disable}
                    >
                      {t(strings.words.BUY)}
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </>
            );
          })}
        </div>
      )}
      {isLoading && <ProgressDialog open={isLoading} isAdmin={false} />}
    </div>
  );
};

export default CheckoutForm;
