import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAdminPolicyAction,
  editAdminPolicyAction,
  addAdminPolicyAction,
} from "../Actions";

const ADMIN_POLICY_SLICE_NAME = "adminPolicyDetails";

const adminPolicyInitialState = {
  fetchAdminPolicyData: {},
  editAdminPolicyData: {},
  isLoading: false,
  isEditPolicy: false,
  editPolicyData: {},
};

const adminPolicySlice = createSlice({
  name: ADMIN_POLICY_SLICE_NAME,
  initialState: adminPolicyInitialState,
  reducers: {
    isFromEditPolicy(state, action) {
      state.isEditPolicy = action.payload;
      return state;
    },
    isFromEditPolicyData(state, action) {
      state.editPolicyData = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(fetchAdminPolicyAction.pending, (state) => {
        state.isLoading = true;
        state.fetchAdminPolicyData = {};
        state.editAdminPolicyData = {};
        return state;
      })
      .addCase(fetchAdminPolicyAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.fetchAdminPolicyData = action.payload;
        state.editAdminPolicyData = {};
        return state;
      })
      .addCase(fetchAdminPolicyAction.rejected, (state, action) => {
        state.isLoading = false;
        state.fetchAdminPolicyData = action.payload;
        state.editAdminPolicyData = {};
      })
      .addCase(editAdminPolicyAction.pending, (state) => {
        state.isLoading = true;
        state.editAdminPolicyData = {};
        return state;
      })
      .addCase(editAdminPolicyAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.editAdminPolicyData = action.payload;
        return state;
      })
      .addCase(editAdminPolicyAction.rejected, (state, action) => {
        state.isLoading = false;
        state.editAdminPolicyData = action.payload;
      })
      .addCase(addAdminPolicyAction.pending, (state) => {
        state.isLoading = true;
        state.editAdminPolicyData = {};
        return state;
      })
      .addCase(addAdminPolicyAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.editAdminPolicyData = action.payload;
        return state;
      })
      .addCase(addAdminPolicyAction.rejected, (state, action) => {
        state.isLoading = false;
        state.editAdminPolicyData = action.payload;
      });
  },
});

export const { isFromEditPolicy, isFromEditPolicyData } = adminPolicySlice.actions;
export const adminPolicyReducer = adminPolicySlice.reducer;
