import React, { useEffect, useState } from "react";
import Header from "../../../component/Header";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FreeTrail from "../FreeTrial";
import {
  checkDeepLinkStstus,
  getActiveSubscriptionPlan,
  getDeepLinkVerificationCode,
  profileDetails,
} from "../../../redux/Actions";
import { onFreeTrial } from "../../../redux/Reducers/UserSubscriptionReducer";
import { useTranslation } from "react-i18next";
import { Toast } from "../../../component/Toast";
import { strings } from "../../../utils/constants";
import { changeActiveHeader } from "../../../redux/Reducers/GeneralReducer";
import { CircularProgress } from "@mui/material";

const AccessADA = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { freeTrail } = useSelector((state) => state.subscriptionReducer);
  const { language } = useSelector((state) => state.generalReducer);
  const { profile } = useSelector((state) => state.userProfileReducer);

  const getActivePlan = async () => {
    let activePlan = await dispatch(
      getActiveSubscriptionPlan({ language: language })
    );
    if (activePlan.payload?.success) {
      if (!activePlan.payload.data?.isFreeUsed) {
        dispatch(onFreeTrial(true));
      } else {
        dispatch(onFreeTrial(false));
      }
    } else {
      dispatch(onFreeTrial(true));
    }
  };

  const getProfile = async () => {
    await dispatch(profileDetails({ language: language, isAdmin: false }));
  };

  useEffect(() => {
    getActivePlan();
    getProfile();
    dispatch(changeActiveHeader(strings.navigationHeader.user.ACCESS_ADA));
  }, []);

  // This code will be used when Desktop App will be available on Apple store and Microsoft store.

  const checkStatus = async () => {
    const response = await dispatch(
      checkDeepLinkStstus({ language: language })
    );
    if (response?.payload?.success && response?.payload?.data === false) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.translate.DESKTOP_APP_NOT_OPEN),
      });
    }
    setLoading(false);
  };
  const openApp = (id) => {
    window.open(
      "formdepot://id=" + id + "&email=" + profile?.data.email,
      "_self"
    );
  };
  const triggerAppOpen = async () => {
    setLoading(true);
    const response = await dispatch(
      getDeepLinkVerificationCode({ language: language })
    );
    if (response?.payload?.success) {
      openApp(response?.payload?.data);
      setTimeout(checkStatus, 5000);
    } else {
      setLoading(false);
    }
  };

  // const downloadFile = (url) => {
  //   // const url1 = "https://formdepot.s3.ca-central-1.amazonaws.com/1688467678642.71249a3b-d8a2-40be-a9fb-b5067365faa4.pdf"
  //   const url1 =
  //     "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf";
  //   const fileName = url1.split("/").pop();
  //   const aTag = document.createElement("a");
  //   aTag.href = url1;
  //   aTag.setAttribute("download", fileName);
  //   document.body.appendChild(aTag);
  //   aTag.click();
  //   aTag.remove();
  // };
  const isFree = freeTrail === undefined || freeTrail ? true : false;

  return (
    <body>
      <div>
        <Header />
      </div>
      {isFree ? (
        <FreeTrail />
      ) : (
        <>
          <section className="subplan-banner bg--primary">
            <div className="container">
              <h1 className="font-lato_bold font-26 fg-white text-center py-5 m-0">
                {t(strings.translate.ACCESS_ADA)}
              </h1>
            </div>
          </section>
          <section className="billing-sec start-trial mt-md-5 pt-md-5">
            <div className="container">
              <div className="row py-4 py-md-5 mb-lg-5">
                <div className="col-md-6 col-lg-6 col-xl-6  fg-gray-30 offset-lg-1 offset-xl-2">
                  <p className="font-lato_bold font-24 mb-5">
                    <i
                      className="fa fa-info-circle me-3"
                      aria-hidden="true"
                    ></i>
                    Please install the desktop application to access ADA
                  </p>
                  <p className="line-height-2 mb-4">
                    It seems that you have not installed the desktop
                    application.
                    <br />
                    Please install the desktop application to access ADA.
                  </p>
                  <p className="line-height-2">
                    For more information on the Desktop Application download and
                    installation process, please check your email.
                  </p>
                  {loading ? (
                    <div>
                      <CircularProgress color={"success"} size={25} />
                    </div>
                  ) : (
                    <Link
                      // data-bs-toggle="modal"
                      // data-bs-target={"#exampleModal"}
                      className="btn btn--primary"
                      onClick={() => triggerAppOpen()}
                      // onClick={() => {
                      //   Toast({
                      //     type: "error",
                      //     title: strings.words.ERROR,
                      //     message: t(strings.translate.OPEN_DESKTOP_APP_MESSAGE),
                      //   });
                      // }}
                    >
                      {loading ? "loading" : "Open Desktop Application"}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </body>
  );
};

export default AccessADA;
