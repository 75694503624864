import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequestWithParams, patchRequest } from "../../services/API";
import { isEmpty } from "../../services/validation";

const FETCH_USER_LICENSE_KEY_DETAILS = "fetchUserLicenseKeyAction";
const UPDATE_USER_LICENSE_KEY_DETAILS = "updateUserLicenseKeyAction";

export const fetchUserLicenseKeyAction = createAsyncThunk(
  FETCH_USER_LICENSE_KEY_DETAILS,
  async (requestInfo, { dispatch, rejectWithValue }) => {
    let requestUrl =
      "?page=" + requestInfo.page + "&limit=" + requestInfo.limit;

    try {
      if (!isEmpty(requestInfo.searchText)) {
        requestUrl = requestUrl + "&search_key=" + requestInfo.searchText;
      }

      let fetchUserLicenseKeyResult = await getRequestWithParams(
        requestInfo.language,
        "adminUserLicenseKey",
        requestUrl,
        true
      );

      return fetchUserLicenseKeyResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);

export const updateUserLicenseKeyAction = createAsyncThunk(
  UPDATE_USER_LICENSE_KEY_DETAILS,
  async ({ language, requestInfo }, { dispatch, rejectWithValue }) => {
    try {
      let updateUserLicenseKeyResult = await patchRequest(
        language,
        "adminUserLicenseKey",
        requestInfo,
        true
      );
      return updateUserLicenseKeyResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);
