export const paths = {
  userRoutes: {
    DASHBOARD: "/user/dashboard",
    SEARCH_FORM: "/user/searchform",
    MY_PROFILE: "/user/myprofile",
    ABOUT_US: "/user/aboutus",
    CONTACT_US: "/user/contactus",
    DEMO_TRAINING: "/user/demotraining",
    ACCESS_ADA: "/user/accessada",
    FREE_SUBSCRIPRION: "/user/freesubscription",
    BILLING: "/user/billing",
    SUBSCRIPRION: "/user/subscription",
  },
  adminRoutes: {
    ADMIN: "/admin/",
    DASHBOARD: "/admin/dashboard/",
    USERS: "/admin/dashboard/user",
    ADD_EDIT_USER: "/admin/dashboard/user/addEditUser",
    MGAS: "/admin/dashboard/mga",
    ADD_EDIT_MGA: "/admin/dashboard/mga/addEditMGA",
    SUBSCRIPRIONS: "/admin/dashboard/subscription",
    EDIT_SUBSCRIPRION: "/admin/dashboard/subscription/editSubscription",
    CUSTOMER_PAYMENTS: "/admin/dashboard/cutomerPayments",
    FORMS: "/admin/dashboard/formManagement",
    EDIT_FORM: "/admin/dashboard/formManagement/editForm",
    PDF_SECTION_FORM:
      "/admin/dashboard/formManagement/editForm/PDFSectionFormManagment",
    CARRIERS: "/admin/dashboard/carriers",
    ADD_EDIT_CARRIER: "/admin/dashboard/carriers/addEditCarrier",
    POLICIES: "/admin/dashboard/policies",
    ASS_EDIT_POLICY: "/admin/dashboard/policies/addEditPolicy",
    USER_LICENS_KEY: "/admin/dashboard/userLicenseKeys",
    CMS_PAGES: "/admin/dashboard/cmsPage",
    EDIT_CMS_PAGES: "/admin/dashboard/cmsPage/editCMSPage",
    PROFILE: "/admin/dashboard/profilePage",
    CHANGE_PASSWORD: "/admin/dashboard/changePassword",
  },
  endpoints: {
    adminUserGet: "/user/get",
    adminUserAdd: "/user/add",
    adminUserUpdate: "/user/update",
    adminUserDelete: "/user/delete",
    deepLink: "/user/deeplink",
    country: "/country/",
    province: "/province/",
    adminUserLicenseKey: "/license-key/",
    login: "/user/login",
    adminLogin: "/admin/login",
    changepassword: "/user/changepassword",
    adminChangePassword: "/admin/changepassword",
    getProfile: "/user/",
    getAdminProfile: "/admin/",
    subscription: "/subscription/",
    payment: "/payment/",
    renewalCancel: "/payment/renewal-cancel",
    paymentmethod: "/payment/paymentmethod",
    credit: "/payment/credit-count",
    carrier: "/carrier/",
    form: "/form/",
    policyService: "/policy-service/",
    forgotPassword: "/user/forgotpassword",
    adminForgotPassword: "/admin/forgotpassword",
    forgotPasswordOTPVerify: "/user/forgotpassword/otpverify",
    adminForgotPasswordOTPVerify: "/admin/forgotpassword/otpverify",
    resetPassword: "/user/forgotpassword/password",
    mgaUserUpdate:"/user/mga-user-update",
    adminResetPassword: "/admin/forgotpassword/password",
    adminCustomerPayments: "/payment/customer",
    licencekey: "/license-key/user/",
    updateLicencekey: "/license-key/update-client",
    cms: "/cms/",
    emails: "/payment/client-email-verify",
    pdfMasterField: "/master-field/",
    userContactUs: "/user/contact-us",
    mgaCheck: "/user/mga-check",
    mga:"/mga/"
  },
};
