import "./FormManagementTable.css";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BsFillTrashFill, BsFillPencilFill, BsDownload } from "react-icons/bs";
import { Checkbox, Container, Stack, Button, Link } from "@mui/material";
import { TextField } from "@mui/material";
import {
  fetchAdminFormAction,
  deleteAdminFormAction,
  fetchAdminPolicyAction,
  fetchAdminCarrierAction,
} from "../../redux/Actions";
import { isEmpty } from "../../services/validation";
import ResponsiveDialog from "../Modal";
import {
  isFromEditForm,
  isFromEditFormData,
} from "../../redux/Reducers/AdminFormManagementReducer";
import { strings, paths } from "../../utils/constants";
import NoRecordFound from "../NoRecordFound";
import AdminPagination from "../AdminPagination";

export const FormManagementTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [isFilter, setFilter] = useState(strings.general.ALL_FORMS);
  const [isDelete, setIsDelete] = useState(false);
  const [formId, setFormId] = useState("");
  const [carrierID, setCarrierID] = useState(strings.general.SELECT_CARRIER);
  const [policyID, setPolicyID] = useState(
    strings.general.SELECT_BUSINESS_ACTION
  );

  const { fetchAdminCarrierData } = useSelector(
    (state) => state.adminCarrierReducer
  );

  const { fetchAdminPolicyData } = useSelector(
    (state) => state.adminPolicyReducer
  );

  const { deleteAdminFormData, adminFormData } = useSelector(
    (state) => state.adminFormManagementReducer
  );

  const carrierList = fetchAdminCarrierData?.data
    ? fetchAdminCarrierData.data
    : [];

  const policyList = fetchAdminPolicyData?.data
    ? fetchAdminPolicyData.data
    : [];

  const tableRecords = adminFormData?.data ? adminFormData.data : [];

  const totalPage = Math.ceil(adminFormData?.total / 10);

  const nextPage = () => {
    if (currentPage !== totalPage) {
      setCurrentPage(currentPage + 1);
      getAPI(currentPage + 1, searchText, "");
    }
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      getAPI(currentPage - 1, searchText, "");
    }
  };

  const changePage = (id) => {
    setCurrentPage(id);
    getAPI(id, searchText, "");
  };

  const getAPI = async (page, searchText, isFilter) => {
    let requestInfo = {
      page: page,
      limit: 10,
      searchText: searchText,
      carrierId: carrierID === strings.general.SELECT_CARRIER ? "" : carrierID,
      privacyServiceId:
        policyID === strings.general.SELECT_BUSINESS_ACTION ? "" : policyID,
      isFilter: isFilter === "" ? strings.general.ALL_FORMS : isFilter,
      language: strings.language.EN,
    };
    await dispatch(fetchAdminFormAction(requestInfo));
  };

  const listOfCarrierAndPolicy = async () => {
    await dispatch(
      fetchAdminCarrierAction({ language: strings.language.EN, isAdmin: true })
    );
    await dispatch(
      fetchAdminPolicyAction({ language: strings.language.EN, isAdmin: true })
    );
  };

  useEffect(() => {
    if (
      currentPage === 1 &&
      carrierID === strings.general.SELECT_CARRIER &&
      policyID === strings.general.SELECT_BUSINESS_ACTION &&
      isFilter === strings.general.ALL_FORMS &&
      isEmpty(searchText) &&
      !isDelete
    ) {
      getAPI(1, searchText, "");
      listOfCarrierAndPolicy();
    }

    if (isDelete) {
      if (deleteAdminFormData?.success) {
        getAPI(1, searchText, "");
        listOfCarrierAndPolicy();
      }
      setIsDelete(false);
      setIsOpen(false);
    }
  }, [
    currentPage,
    carrierID,
    policyID,
    isFilter,
    searchText,
    deleteAdminFormData,
  ]);

  const customStyles = useMemo(
    () => ({
      valueContainer: (provided) => ({
        ...provided,
        whiteSpace: "nowrap",
        overflow: "hidden",
        flexWrap: "nowrap",
        textOverflow: "ellipsis",
      }),
      input: (provided) => ({
        ...provided,
        minWidth: "20%",
        textOverflow: "ellipsis",
      }),
    }),
    []
  );

  const searchHandler = () => {
    setFilter(strings.general.ALL_FORMS);
    if (isEmpty(searchText)) {
      setSearchText("");
      setCurrentPage(1);
      getAPI(1, "");
    } else {
      setCurrentPage(1);
      getAPI(1, searchText, "");
    }
  };

  const resetHandler = () => {
    setSearchText("");
    setCurrentPage(1);
    setFilter(strings.general.ALL_FORMS);
    setCarrierID(strings.general.SELECT_CARRIER);
    setPolicyID(strings.general.SELECT_BUSINESS_ACTION);
  };

  const allFormsHandler = () => {
    setFilter(strings.general.ALL_FORMS);
    setCurrentPage(1);
    getAPI(1, searchText, strings.general.ALL_FORMS);
  };

  const adaFormHandler = () => {
    setFilter(strings.general.ADA_FORMS);
    setCurrentPage(1);
    getAPI(1, searchText, strings.general.ADA_FORMS);
  };

  const nonAdaFormHandler = () => {
    setFilter(strings.general.NON_ADA_FORMS);
    setCurrentPage(1);
    getAPI(1, searchText, strings.general.NON_ADA_FORMS);
  };

  const mappedFormHandler = () => {
    setFilter(strings.general.MAPPED_FORMS);
    setCurrentPage(1);
    getAPI(1, searchText, strings.general.MAPPED_FORMS);
  };

  const nonMappedFormHandler = () => {
    setFilter(strings.general.NON_MAPPED_FORMS);
    setCurrentPage(1);
    getAPI(1, searchText, strings.general.NON_MAPPED_FORMS);
  };

    // EDITED
  const downloadHandler = (data) => {
    const filepath = data.form_actual_url && data.form_actual_url !== "" ? data.form_actual_url : data.form_url;
    const fileName = filepath.split("/").pop();
    const a = document.createElement("a");
    a.setAttribute("download", fileName);
    a.href = filepath;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const editFormHandler = (data) => {
    dispatch(isFromEditForm(true));
    dispatch(isFromEditFormData(data));
    navigate(paths.adminRoutes.EDIT_FORM);
  };

  const deleteFormHandler = (data) => {
    setFormId(data.id);
    setIsOpen(true);
  };

  const negativeClickHandler = () => {
    setIsDelete(false);
    setIsOpen(false);
  };

  const positiveClickHandler = async () => {
    setIsDelete(true);
    await dispatch(
      deleteAdminFormAction({ language: strings.language.EN, formId: formId })
    );
    if (currentPage !== 1 && tableRecords.length === 1) {
      prevPage();
    } else {
      getAPI(currentPage, searchText, "");
    }
  };

  const closeHandler = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Container className="container-wrapper">
        <Stack
          direction={{ xs: "column", lg: "row" }}
          alignItems="end"
          justifyContent="right"
          mb={4}
        >
          <div style={{ minWidth: "226px" }}>
            <select
              className="fform-select form-select"
              id="carrier-list"
              aria-label="Default select example"
              styles={customStyles}
              onChange={(e) => setCarrierID(e.target.value)}
            >
              <option selected value={""}>
                {strings.general.SELECT_CARRIER}
              </option>
              {carrierList.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.carrier_name}
                </option>
              ))}
            </select>
          </div>
          <div style={{ minWidth: "226px", marginLeft: "10px" }}>
            <select
              className="fform-select form-select"
              id="policy-list"
              aria-label="Default select example"
              onChange={(e) => {
                setPolicyID(e.target.value);
              }}
            >
              <option selected value={""}>
                {strings.general.SELECT_BUSINESS_ACTION}
              </option>
              {policyList.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.policy_service_name}
                </option>
              ))}
            </select>
          </div>
          <div className="finput" style={{ minWidth: "186px" }}>
            <TextField
              id="search"
              variant="outlined"
              size="small"
              placeholder={strings.words.SEARCH}
              sx={{ color: "#184437" }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              autoComplete="off"
            />
          </div>
          <Button
            variant="contained"
            sx={{ color: "#184437" }}
            className="fbutton"
            onClick={searchHandler}
          >
            {strings.words.SEARCH}
          </Button>
          <Button
            variant="contained"
            sx={{ color: "#184437" }}
            className="fresetbutton"
            onClick={resetHandler}
          >
            {strings.words.RESET}
          </Button>
          <div className="align-self-xl-center mt-4 text-end filter-dropdow-main filterIcon-wrapper">
            <Link
              className="px-0 filter-dropdown"
              id="filter"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="/assets/icons/filter-icon.svg"
                width="34"
                height="30"
                alt="filter icon"
              />
            </Link>
            <div
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="filter"
            >
              <Link className="dropdown-item" onClick={allFormsHandler}>
                {strings.general.ALL_FORMS}
              </Link>
              <Link className="dropdown-item" onClick={adaFormHandler}>
                {strings.general.ADA_FORMS}
              </Link>
              <Link className="dropdown-item" onClick={nonAdaFormHandler}>
                {strings.general.NON_ADA_FORMS}
              </Link>
              <Link className="dropdown-item" onClick={mappedFormHandler}>
                {strings.general.MAPPED_FORMS}
              </Link>
              <Link className="dropdown-item" onClick={nonMappedFormHandler}>
                {strings.general.NON_MAPPED_FORMS}
              </Link>
            </div>
          </div>
        </Stack>
      </Container>
      {
        <div className="ftable-wrapper">
          <table className="ftable">
            <thead>
              <tr>
                <th>{strings.general.SR_NO}</th>
                <th className="w-25">{strings.general.FORM_NAME_ENGLISH}</th>
                <th>{strings.general.FORM_LANGUAGE}</th>
                <th className="w-25">{strings.general.FORM_NO}</th>
                <th>{strings.general.FORM_VERSION}</th>
                <th className="w-25">{strings.general.CARRIER_NAME_ENGLISH}</th>
                <th className="w-25">{strings.general.POLICY_NAME_ENGLISH}</th>
                <th>{strings.general.IS_ADA_COMPATIBLE}</th>
                <th>{strings.general.IS_MAPPED}</th>
                <th>{strings.words.ACTIONS}</th>
              </tr>
            </thead>
            <tbody>
              {tableRecords.length > 0 ? (
                tableRecords.map((row, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="table-cell">
                        {(currentPage - 1) * 10 + idx + 1}
                      </td>
                      <td className="table-cell w-25">{row.form_name}</td>
                      <td className="table-cell">
                        {row.form_lang === strings.language.EN
                          ? strings.words.ENGLISH
                          : row.form_lang === strings.language.FR
                          ? strings.words.FRENCH
                          : ""}
                      </td>
                      <td className="table-cell w-25">{row.form_number}</td>
                      <td className="table-cell">{row.form_version}</td>
                      <td className="table-cell w-25">
                        {row.carrier.carrier_name}
                      </td>
                      <td className="table-cell w-25">
                        {row.policy_service?.policy_service_name}
                      </td>
                      <td>
                        <Checkbox
                          name="isADA"
                          label="isADA"
                          checked={row.isADA}
                          disabled
                          style={row.isADA ? { color: "#184437" } : {}}
                        />
                      </td>
                      <td>
                        <Checkbox
                          name="isMapped"
                          label="isMapped"
                          checked={row.isMapped}
                          disabled
                          style={row.isMapped ? { color: "#184437" } : {}}
                        />
                      </td>
                      <td className="fit">
                        <span className="factions mt-1">
                          <BsDownload
                            onClick={downloadHandler.bind(this, row)}
                            className="cursor-pointer"
                          />
                          <BsFillPencilFill
                            onClick={editFormHandler.bind(this, row)}
                            className="cursor-pointer"
                          />
                          <BsFillTrashFill
                            onClick={deleteFormHandler.bind(this, row)}
                            className="fdelete-btn cursor-pointer"
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoRecordFound colSpan={10} />
              )}
            </tbody>
          </table>
          {tableRecords.length > 0 && (
            <AdminPagination
              currentPage={currentPage}
              totalRecords={adminFormData.total}
              nextPage={nextPage}
              prevPage={prevPage}
              changePage={changePage}
            />
          )}
          <ResponsiveDialog
            open={isOpen}
            negativeButtonClickHandler={negativeClickHandler}
            positiveButtonClickHandler={positiveClickHandler}
            handleClose={closeHandler}
            titleText={strings.general.ARE_YOU_SURE}
            subTitleText={strings.general.YOU_WANT_TO_DELETE_THIS_FORM}
            negativeButtonText={strings.words.CANCEL}
            positiveButtonText={strings.words.YES}
          />
        </div>
      }
    </>
  );
};
