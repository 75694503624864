import { createSlice } from "@reduxjs/toolkit";
import { userContactUsAction } from "../Actions";

const USER_CONTACT_US_SLICE_NAME = "userContactUsDetails";

const userContactUsDetailsInitialState = {
  userContactUsData: {},
  isUserContactLoading: false,
};

const userContactUsSlice = createSlice({
  name: USER_CONTACT_US_SLICE_NAME,
  initialState: userContactUsDetailsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(userContactUsAction.pending, (state) => {
        state.isUserContactLoading = true;
        state.userContactUsData = {};
        return state;
      })
      .addCase(userContactUsAction.fulfilled, (state, action) => {
        state.isUserContactLoading = false;
        state.userContactUsData = action.payload;
        return state;
      })
      .addCase(userContactUsAction.rejected, (state) => {
        state.isUserContactLoading = false;
        state.userContactUsData = {};
      });
  },
});

export const { } = userContactUsSlice.actions;
export const userContactUsReducer = userContactUsSlice.reducer;
