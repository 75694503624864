import { createSlice } from "@reduxjs/toolkit";
import { forgotPasswordAction } from "../Actions"; 
const FORGOT_PASSWORD_SLICE_NAME = "forgotPasswordDetails";

const forgotPasswordInitialState = {
  forgotPassword: {},
  isForgotPasswordLoading: false,
};

const forgotPasswordSlice = createSlice({
  name: FORGOT_PASSWORD_SLICE_NAME,
  initialState: forgotPasswordInitialState,
  reducers: {},
  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(forgotPasswordAction.pending, (state) => {
        state.isForgotPasswordLoading = true;
        state.forgotPassword = {};
        return state;
      })
      .addCase(forgotPasswordAction.fulfilled, (state, action) => {
        state.isForgotPasswordLoading = false;
        state.forgotPassword = action.payload;
        return state;
      })
      .addCase(forgotPasswordAction.rejected, (state, action) => {
        state.isForgotPasswordLoading = false;
        state.forgotPassword = action.payload;
      });
  },
});

export const {} = forgotPasswordSlice.actions;
export const forgotPasswordReducer = forgotPasswordSlice.reducer;
