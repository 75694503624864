import { useNavigate } from "react-router-dom";
import { Stack, Button, Container } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { FormManagementTable } from "../../component/FormManagementTable";
import {
  isFromEditForm,
  isFromEditFormData,
} from "../../redux/Reducers/AdminFormManagementReducer";
import { useDispatch } from "react-redux";
import { strings, paths } from "../../utils/constants";

export default function FormManagementPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addFormHandler = () => {
    dispatch(isFromEditForm(false));
    dispatch(isFromEditFormData({}));
    navigate(paths.adminRoutes.EDIT_FORM);
  };
  return (
    <>
      <Container style={{ width: "80%", marginRight: "4%" }}>
        <Stack direction="column" alignItems="end">
          <Button
            variant="contained"
            sx={{ color: "#184437" }}
            className="button"
            startIcon={<AddIcon />}
            onClick={addFormHandler}
          >
            {strings.general.ADD_NEW}
          </Button>
        </Stack>
      </Container>
      <FormManagementTable />
    </>
  );
}
