import { useEffect, useState } from "react";
import { Stack, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  addAdminMGAUserAction,
  editAdminMGAUserAction,
  fetchCountryAction,
  fetchProvinceAction,
} from "../../redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import {
  isEmpty,
  newEmailValidation,
  adminContactNumberValidation,
} from "../../services/validation";
import { Toast } from "../../component/Toast";
import ProgressDialog from "../../component/ProgressDialog";
import { strings, paths } from "../../utils/constants";
import CustomTextField from "../../component/CustomTextField";
import Flag from "../../utils/Logo/caneda_flag.png";

export default function AddEditMGAUserPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);

  const { isLoading, isEdit, editData } = useSelector(
    (state) => state.adminMGAUserReducer
  );

  const [firstName, setFirstName] = useState(
    isEdit && editData?.firstname != null ? editData?.firstname : ""
  );
  const [lastName, setLastName] = useState(
    isEdit && editData?.lastname != null ? editData?.lastname : ""
  );

  const [email, setEmail] = useState(
    isEdit && editData?.email != null ? editData?.email : ""
  );
  
  const [phone, setPhone] = useState(
    isEdit && editData?.phone != null ? editData?.phone : ""
  );

  const [address, setAddress] = useState(
    isEdit && editData?.user_address?.address_line_1 != null
      ? editData?.user_address?.address_line_1
      : ""
  );

  const [zipcode, setZipcode] = useState(
    isEdit && editData?.user_address?.zip_code != null
      ? editData?.user_address?.zip_code
      : ""
  );

  const [city, setCity] = useState(
    isEdit && editData?.user_address?.city_name != null
      ? editData?.user_address?.city_name
      : ""
  );

  const [countryId, setCountry] = useState(
    isEdit && editData?.user_address?.country != null
      ? editData?.user_address?.country?.id
      : ""
  );

  const [provinceId, setProvince] = useState(
    isEdit && editData?.user_address?.province != null
      ? editData?.user_address?.province?.id
      : ""
  );

  const { fetchCountry } = useSelector((state) => state.fetchCountryReducer);
  const countryList = fetchCountry?.data ? fetchCountry.data : [];

  const { fetchProvince } = useSelector((state) => state.fetchProvinceReducer);
  const provinceList = fetchProvince?.data ? fetchProvince.data : [];

  const getCountryAndProvince = async () => {
    let CountryResponse = await dispatch(
      fetchCountryAction(strings.language.EN)
    );
    if (isEdit && provinceId !== "") {
      dispatch(
        fetchProvinceAction({
          language: strings.language.EN,
          countryId: countryId,
        })
      );
    }
    if (CountryResponse?.payload?.success) {
      setCountries([fetchCountry]);
    }
  };

  useEffect(() => {
    if (countries.length === 0) {
      getCountryAndProvince();
    }
  }, [countries]);

  const submitUpdateHandler = async (e) => {
    e.preventDefault();
    if (isEmpty(firstName)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.FIRST_NAME_REQUIRED,
      });
    } else if (isEmpty(lastName)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.LAST_NAME_REQUIRED,
      });
    } else if (isEmpty(email)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.EMAIL_REQUIRED,
      });
    } else if (!newEmailValidation(email)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.EMAIL_VALIDATION,
      });
    } else if (adminContactNumberValidation(phone)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: adminContactNumberValidation(phone),
      });
    } else if (isEmpty(address)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.ADDRESS_ENGLISH_REQUIRED,
      });
    } else if (isEmpty(zipcode)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.POSTALCODE_REQUIRED,
      });
    } else if (zipcode.length !== 6) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.POSTALCODE_VALIDATION,
      });
    } else if (
      isEmpty(countryId) ||
      countryId === strings.general.SELECT_COUNTRY
    ) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.COUNTRY_REQUIRED,
      });
    } else if (
      isEmpty(provinceId) ||
      provinceId === strings.general.SELECT_PROVINCE
    ) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.PROVINCE_REQUIRED,
      });
    } else if (isEmpty(city)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.CITY_REQUIRED,
      });
    } else {
      if (isEdit) {
        let editMGAUserResponse = await dispatch(
          editAdminMGAUserAction({
            language: strings.language.EN,
            userId: editData?.id,
            email,
            firstName,
            lastName,
            phone,
            address,
            zipcode,
            city,
            provinceId,
            countryId,
          })
        );
        if (editMGAUserResponse.payload?.success) {
          navigate(paths.adminRoutes.USERS, { replace: true });
        }
      } else {
        let addMGAUserResponse = await dispatch(
          addAdminMGAUserAction({
            language: strings.language.EN,
            email,
            firstName,
            lastName,
            phone,
            address,
            zipcode,
            city,
            provinceId,
            countryId,
          })
        );
        if (addMGAUserResponse.payload?.success) {
          navigate(paths.adminRoutes.USERS, { replace: true });
        }
      }
    }
  };

  const firstNameChangeHandler = (e) => {
    setFirstName(e.target.value);
  };

  const lastNameChangeHandler = (e) => {
    setLastName(e.target.value);
  };

  const emailChangeHandler = (e) => {
    if (!isEdit) {
      setEmail(e.target.value);
    }
  };

  const phoneChangeHandler = (e) => {
    const regex = new RegExp(/^[0-9]*$/);
    if (regex.test(e.target.value)) {
      setPhone(e.target.value);
    }
  };

  const phoneKeyDownHandler = (evt) => evt.key === "e" && evt.preventDefault();

  const addressChangeHandler = (e) => {
    setAddress(e.target.value);
  };

  const postalCodeChangeHandler = (e) => {
    const regex = new RegExp(/^[a-zA-Z0-9]*$/);
    if (regex.test(e.target.value)) {
      setZipcode(e.target.value);
    }
  };

  const countryChangeHandler = (e) => {
    setCountry(e.target.value);
    setProvince(strings.general.SELECT_PROVINCE);
    dispatch(
      fetchProvinceAction({
        language: strings.language.EN,
        countryId: e.target.value,
      })
    );
  };

  const countryOptionChangeHandler = (item) => {
    setCountry(item.id === strings.general.SELECT_COUNTRY ? "" : item.id);
    dispatch(
      fetchProvinceAction({
        language: strings.language.EN,
        countryId: item.id,
      })
    );
  };

  const provinceChangeHandler = (e) => {
    setProvince(e.target.value);
  };

  const provinceOptionChangeHandler = (item) => {
    setProvince(item.id === strings.general.SELECT_PROVINCE ? "" : item.id);
  };

  const cityChangeHandler = (e) => {
    setCity(e.target.value);
  };

  const cancelHandler = () => {
    navigate(paths.adminRoutes.USERS, { replace: true });
  };
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom sx={{ marginLeft: 5 }}>
          {isEdit ? strings.general.EDIT_USER : strings.general.ADD_NEW_USER}
        </Typography>
      </Stack>
      <form
        style={{
          marginBottom: "5px",
          marginLeft: "50px",
          marginRight: "50px",
        }}
      >
        <CustomTextField
          style={{
            width: "100%",
            marginTop: "5px",
            marginBottom: "5px",
            marginLeft: "5px",
            marginRight: "5px",
          }}
          type="text"
          label={`${strings.general.FIRST_NAME}*`}
          variant="outlined"
          focusColor="#184437"
          value={firstName}
          autoFocus
          autoComplete="off"
          inputProps={{ maxLength: 150 }}
          onChange={firstNameChangeHandler}
        />
        <br />
        <CustomTextField
          style={{
            width: "100%",
            marginTop: "10px",
            marginBottom: "5px",
            marginLeft: "5px",
            marginRight: "5px",
          }}
          type="text"
          label={`${strings.general.LAST_NAME}*`}
          variant="outlined"
          focusColor="#184437"
          value={lastName}
          autoComplete="off"
          inputProps={{ maxLength: 150 }}
          onChange={lastNameChangeHandler}
        />
        <br />
        <CustomTextField
          style={{
            width: "100%",
            marginTop: "10px",
            marginBottom: "5px",
            marginLeft: "5px",
            marginRight: "5px",
          }}
          type="text"
          label={`${strings.words.EMAIL}*`}
          variant="outlined"
          focusColor="#184437"
          value={email}
          autoComplete="off"
          disabled={isEdit}
          onChange={emailChangeHandler}
        />
        <br />
        <div
          direction="row"
          style={{
            width: "100%",
            marginTop: "10px",
            marginBottom: "5px",
            marginLeft: "5px",
            marginRight: "5px",
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ widht: "8%" }}>
            <div
              className="d-flex align-items-center"
              style={{
                border: "1px solid",
                borderColor: "#184437",
                borderRadius: "5px",
                padding: "16px 12px",
              }}
            >
              <img src={Flag} alt="flag" width={40} height={20} />
              <span className="ms-2">+1</span>
            </div>
          </div>
          <CustomTextField
            style={{
              width: "100%",
              marginLeft: "8px",
              marginRight: "5px",
            }}
            type="text"
            label={`${strings.general.MOBILE_NUMBER}*`}
            variant="outlined"
            focusColor="#184437"
            value={phone}
            autoComplete="off"
            inputProps={{ maxLength: 10 }}
            onKeyDown={phoneKeyDownHandler}
            onChange={phoneChangeHandler}
          />
        </div>
        <CustomTextField
          style={{
            width: "100%",
            marginTop: "10px",
            marginBottom: "5px",
            marginLeft: "5px",
            marginRight: "5px",
          }}
          type="text"
          label={`${strings.words.ADDRESS}*`}
          variant="outlined"
          focusColor="#184437"
          value={address}
          autoComplete="off"
          inputProps={{ maxLength: 250 }}
          onChange={addressChangeHandler}
        />
        <br />
        <CustomTextField
          style={{
            width: "100%",
            marginTop: "10px",
            marginBottom: "5px",
            marginLeft: "5px",
            marginRight: "5px",
          }}
          type="text"
          label={`${strings.general.POSTAL_CODE}*`}
          variant="outlined"
          focusColor="#184437"
          value={zipcode}
          inputProps={{ maxLength: 6 }}
          autoComplete="off"
          onChange={postalCodeChangeHandler}
        />
        <br />
        <Stack direction={{ xs: "column", lg: "row" }} sx={{ my: 2 }}>
          <div style={{ marginLeft: "6px", minWidth: "30%" }}>
            <select
              className="uform-select form-select"
              id="contryList"
              aria-label="Default select example"
              value={countryId}
              onChange={countryChangeHandler}
            >
              <option selected>{strings.general.SELECT_COUNTRY}</option>
              {countryList.length > 0 &&
                countryList.map((item) => (
                  <>
                    <option
                      onChange={countryOptionChangeHandler.bind(this, item)}
                      key={item.id}
                      value={item.id}
                    >
                      {item.country_name}
                    </option>
                  </>
                ))}
            </select>
          </div>
          <br />
          <div style={{ marginLeft: "6px", minWidth: "30%" }}>
            <select
              className="uform-select form-select"
              id="provinceList"
              aria-label="Default select example"
              value={provinceId}
              disabled={
                provinceList.length > 0 && countryId !== "" ? false : true
              }
              onChange={provinceChangeHandler}
            >
              <option selected>{strings.general.SELECT_PROVINCE}</option>
              {provinceList.length > 0 &&
                provinceList.map((item) => (
                  <>
                    <option
                      onChange={provinceOptionChangeHandler.bind(this, item)}
                      key={item.id}
                      value={item.id}
                    >
                      {item.province_name}
                    </option>
                  </>
                ))}
            </select>
          </div>
          <br />
          <CustomTextField
            sx={{
              minWidth: "30%",
              marginLeft: 1,
            }}
            type="text"
            label={`${strings.general.CITY_NAME}*`}
            variant="outlined"
            focusColor="#184437"
            value={city}
            autoComplete="off"
            inputProps={{ maxLength: 150 }}
            onChange={cityChangeHandler}
          />
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="start"
          justifyContent="left"
          style={{ paddingBottom: "20px" }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "#184437",
              marginTop: "14px",
              marginRight: "12px",
            }}
            onClick={submitUpdateHandler}
          >
            {isEdit ? strings.words.UPDATE : strings.words.SUBMIT}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#184437", marginTop: "14px" }}
            onClick={cancelHandler}
          >
            {strings.words.CANCEL}
          </Button>
        </Stack>
      </form>
      {isLoading && <ProgressDialog open={isLoading} isAdmin={true} />}
    </>
  );
}
