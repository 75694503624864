import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../../../component/Header";
import { Toast } from "../../../component/Toast";
import {
  contactNumberValidation,
  isEmpty,
  newEmailValidation,
  zipCodeValidation,
} from "../../../services/validation";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import {
  getClientEmail,
  onFreeTrial,
} from "../../../redux/Reducers/UserSubscriptionReducer";
import {
  countryList,
  getActiveSubscriptionPlan,
  stateList,
  payForSubscription,
  profileDetails,
  updateProfile,
  emailsVerify,
  getAvailableCredits,
  getPaymentMethod,
} from "../../../redux/Actions";
import ProgressDialog from "../../../component/ProgressDialog";
import { useTranslation } from "react-i18next";
import { strings, paths } from "../../../utils/constants";
import Flag from "../../../utils/Logo/caneda_flag.png";

const stripePromise = (async () => {
  try {
    return await loadStripe(process.env.REACT_APP_STRIPE_KEY);
  } catch (err) {
    window.location.reload();
  }
})();

const Billing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useLocation();
  const { t } = useTranslation();
  const {
    subscriptionPrice,
    subscription,
    planStatus,
    subscriptionType,
    subscriptionID,
    availableCredits,
    freeTrail,
  } = useSelector((state) => state.subscriptionReducer);
  const { language, countryListData, stateListData } = useSelector(
    (state) => state.generalReducer
  );
  const provinceDataList = stateListData?.data ? stateListData.data : [];
  const countryDataList = countryListData?.data ? countryListData.data : [];
  const { profile } = useSelector((state) => state.userProfileReducer);
  const profileDetail = profile?.data ? profile.data : {};

  const [emails, setEmails] = useState([
    {
      type: "email",
      id: 1,
      value: "",
    },
  ]);

  const [paymentTab, setPaymentTab] = useState("");
  const [name, setName] = useState(
    profileDetail.firstname ? profileDetail.firstname : ""
  );
  const [phone, setPhone] = useState(
    profileDetail.phone ? profileDetail.phone.toString() : ""
  );
  const [address, setAddress] = useState(
    profileDetail.user_address?.address_line_1
      ? profileDetail.user_address.address_line_1
      : ""
  );
  const [zipcode, setZipCode] = useState(
    profileDetail.user_address?.zip_code
      ? profileDetail.user_address.zip_code
      : ""
  );
  const [countryID, setCountryID] = useState(
    profileDetail.user_address?.country?.id
      ? profileDetail.user_address.country.id
      : ""
  );
  const [stateID, setStateID] = useState(
    profileDetail.user_address?.province?.id
      ? profileDetail.user_address.province.id
      : ""
  );
  const [city, setCity] = useState(
    profileDetail.user_address?.city_name
      ? profileDetail.user_address.city_name
      : ""
  );
  const [isLoadning, setIsLoading] = useState(false);

  const isFree = freeTrail === undefined || freeTrail ? true : false;

  if (
    subscription?.data?.success &&
    subscription.data[0]?.type !== strings.subscriptionsTypes.FREE
  ) {
    var options = {
      mode: "payment",
      amount: subscriptionPrice,
      currency: "usd",
      appearance: {
        theme: "stripe",
      },
    };
  }

  const getUserProfile = async () => {
    await dispatch(profileDetails({ language: language, isAdmin: false }));
  };

  const getCredits = async () => {
    let payment = {
      subscription_id: subscriptionID,
      is_monthly: subscriptionType === "Monthly" ? true : false,
    };
    const response = await dispatch(
      getAvailableCredits({ language: language, payment, customTost: true })
    );
    return response;
  };

  const allCountry = async () => {
    await dispatch(countryList({ language: language }));
    if (stateID !== "") {
      await dispatch(
        stateList({
          language: language,
          selectedKey: countryID,
          isAdmin: false,
        })
      );
    }
  };

  const getActivePlan = async () => {
    let activePlan = await dispatch(
      getActiveSubscriptionPlan({ language: language })
    );
    if (activePlan.payload?.success) {
      if (
        activePlan.payload.data.subscription?.type ===
        strings.subscriptionsTypes.FREE
      ) {
        dispatch(onFreeTrial(false));
      }
    }
  };

  const getPaymentmethod = async () => {
    await dispatch(getPaymentMethod({ language: language, isAdmin: false }));
  };

  useEffect(() => {
    getUserProfile();
    allCountry();
    getActivePlan();
    getPaymentmethod();
    // if (!freeTrail) {
    //   getCredits();
    // }
  }, [paymentTab]);

  const addInputHandler = () => {
    if (emails.length <= currPlan.client_limit - 1) {
      setEmails((s) => {
        return [
          ...s,
          {
            type: "email",
            value: "",
          },
        ];
      });
    } else {
      Toast({
        type: "error",
        message: t(strings.error.CAN_NOT_ADD_MORE_EMAILS_ERROR),
      });
    }
  };

  const minusInputHandler = (index) => {
    let removeObject = emails.filter((item, ind) => {
      return index !== ind;
    });
    setEmails(removeObject);
  };

  const emailChangeHandler = (e) => {
    e.preventDefault();
    const index = e.target.id;
    setEmails((s) => {
      const newArr = s.slice();
      newArr[index].value = e.target.value;
      return newArr;
    });
  };
  const subscribeFreePlan = async () => {
    setIsLoading(true);
    const freePlan = subscription?.data.find(
      (item) => item.type === strings.subscriptionsTypes.FREE
    );
    let payment = {
      subscription_id: freePlan?.id,
    };
    let paymentResult = await dispatch(
      payForSubscription({ language: language, payment })
    );
    if (paymentResult.payload?.success) {
      dispatch(onFreeTrial(false));
      dispatch(getActiveSubscriptionPlan({ language: language }));
      setIsLoading(false);
      if (params.state?.key === "subscription") {
        navigate(paths.userRoutes.SUBSCRIPRION);
      } else {
        navigate(paths.userRoutes.ACCESS_ADA);
      }
    } else {
      setIsLoading(false);
    }
  };

  const onUpdate = async (userInfo, email) => {
    let emailRequest = {
      client_email: email,
    };
    if (currPlan.type === strings.subscriptionsTypes.BUSINESS) {
      await dispatch(
        emailsVerify({ language: language, client_email: emailRequest })
      );
    }
    if (isEmpty(name)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.NAME_REQUIRED_ERROR),
      });
    } else if (isEmpty(phone)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.PHONE_NUMBER_REQUIRED_ERROR),
      });
    } else if (!contactNumberValidation(phone)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.PHONE_NUMBER_VALIDATION_ERROR),
      });
    } else if (isEmpty(address)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.BILLING_ADDRESS_ERROR),
      });
    } else if (isEmpty(zipcode)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.POSTALCODE_ERROR),
      });
    } else if (zipcode.length <= 5 || zipcode.length >= 7) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.POSTALCODE_CONTAIN_VALIDATION_ERROR),
      });
    } else if (!zipCodeValidation(zipcode)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.POSTALCODE_VALIDATION_ERROR),
      });
    } else if (isEmpty(countryID)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.COUNTRY_ERROR),
      });
    } else if (countryID === "Your Country") {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.COUNTRY_ERROR),
      });
    } else if (isEmpty(stateID)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.PROVINCE_ERROR),
      });
    } else if (stateID === "Province") {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.PROVINCE_ERROR),
      });
    } else if (isEmpty(city)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.CITY_ERROR),
      });
    } else {
      let updateResponse = await dispatch(
        updateProfile({ isAdmin: false, userInfo, customTost: true })
      );
      if (updateResponse.payload?.success) {
        if (planStatus === "Free") {
          subscribeFreePlan();
        } else {
          const response = await getCredits();
          if (response.payload?.success) {
            setPaymentTab(isFree ? "" : "#pills-payment");
          }
        }
      }
    }
  };

  const saveAndContinueHandler = async (e) => {
    e.preventDefault();
    let uniqueNames = new Set();
    let hasDuplicates = emails.some((email) => {
      if (uniqueNames.has(email.value)) {
        return true;
      } else {
        uniqueNames.add(email.value);
        return false;
      }
    });
    let emailResult = emails.map((item) => {
      return item.value;
    });
    let finalEmail = emailResult.filter((item) => {
      return item;
    });
    let findEmail = profileDetail.email;
    let emailExits = emails.some((email) => email.value === findEmail);
    dispatch(getClientEmail(finalEmail));
    let userInfo = {
      country_id: countryID,
      province_id: stateID,
      firstname: name,
      phone: phone,
      address_line_1: address,
      zip_code: zipcode,
      city_name: city,
    };
    if (currPlan.type === strings.subscriptionsTypes.BUSINESS) {
      if (finalEmail.length < 1) {
        Toast({
          type: "error",
          message: t(strings.error.BUSINESSUSER_EMAIL_REQUIRED_ERROR),
        });
      } else if (!newEmailValidation(finalEmail[0])) {
        Toast({
          type: "error",
          message: t(strings.error.EMAIL_VALIDATION_ERROR),
        });
      } else if (hasDuplicates) {
        Toast({
          type: "error",
          title: strings.words.ERROR,
          message: t(strings.error.DUPLICATE_EMAILS_ERROR),
        });
        return;
      } else if (emailExits) {
        Toast({
          type: "error",
          title: strings.words.ERROR,
          message: t(strings.error.EMAIL_ALREADY_EXISTS_ERROR),
        });
        return;
      } else {
        for (let email of finalEmail) {
          if (!newEmailValidation(email)) {
            Toast({
              type: "error",
              message: t(strings.error.EMAIL_VALIDATION_ERROR),
            });
            return;
          }
        }
        onUpdate(userInfo, finalEmail);
      }
    } else if (!emailExits && !hasDuplicates) {
      onUpdate(userInfo, finalEmail);
    }
  };

  let currPlan = {};
  subscription?.data.map((r) =>
    r.id === subscriptionID ? (currPlan = r) : null
  );

  const countryChangeHandler = async () => {
    var selectedKey = document.getElementById("list").value;
    if (selectedKey === "Your Country") {
      setStateID("");
    }
    setCountryID(selectedKey);
    await dispatch(
      stateList({
        language: language,
        selectedKey: selectedKey,
        isAdmin: false,
      })
    );
  };

  const provinceChangeHandler = async () => {
    var selectedKey = document.getElementById("stateList").value;
    setStateID(selectedKey);
  };

  const billingDetailsNavClickHandler = () => {
    setPaymentTab("");
  };

  const nameChangeHandler = (e) => {
    setName(e.target.value);
  };

  const phoneKeyDownHandler = (evt) => {
    evt.key === "e" && evt.preventDefault();
  };

  const phoneChangeHandler = (e) => {
    const regex = new RegExp(/^[0-9]*$/);
    if (regex.test(e.target.value)) {
      if (e.target.value.length <= 10) {
        setPhone(e.target.value);
      } else {
        return;
      }
    }
  };

  const billingAddChangeHandler = (e) => {
    setAddress(e.target.value);
  };

  const zipCodeChangeHandler = (e) => {
    setZipCode(e.target.value);
  };

  const cityChangeHandler = (e) => {
    setCity(e.target.value);
  };
  return (
    <body>
      <div>
        <Header />
      </div>
      <ProgressDialog open={isLoadning} isAdmin={false} />
      <section className="subplan-banner bg--primary">
        <div className="container">
          <h1 className="font-lato_bold font-26 fg-white text-center py-5 m-0 text-capitalize">
            Purchase{" "}
            {language === strings.language.FR
              ? currPlan.name_fr
              : currPlan.name}
          </h1>
        </div>
      </section>
      <section className="py-4 py-lg-5 billing-sec">
        <div className="container">
          <div className="row flex-column-reverse flex-lg-row">
            <div className="col-lg-7 col-xl-6 me-auto">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      paymentTab.length <= 0 ? "active" : ""
                    } `}
                    id="pills-billing-details-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-billing-details"
                    type="button"
                    role="tab"
                    aria-controls="pills-billing-details"
                    aria-selected="true"
                    onClick={billingDetailsNavClickHandler}
                  >
                    {t(strings.translate.BILLING_DETAILS)}
                  </button>
                </li>
                {!isFree && (
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        paymentTab.length >= 1 ? "active" : ""
                      } `}
                      id="pills-payment-tab"
                      data-bs-toggle="pill"
                      data-bs-target={paymentTab}
                      type="button"
                      role="tab"
                      aria-controls="pills-payment"
                      aria-selected="false"
                      disabled={paymentTab.length >= 1 ? false : true}
                    >
                      {t(strings.translate.PAYMENT)}
                    </button>
                  </li>
                )}
              </ul>
              <div className="tab-content  pt-lg-5" id="pills-tabContent">
                <div
                  className={`tab-pane fade ${
                    paymentTab.length <= 0 ? "show active" : ""
                  }`}
                  id="pills-billing-details"
                  role="tabpanel"
                  aria-labelledby="pills-billing-details-tab"
                >
                  <div className="pb-3 mb-4 email-form border-b">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <div>
                          <p className="mb-2 fg-gray">
                            {t(strings.words.EMAIL)}
                          </p>
                          <label className="mb-2 fg-gray font-lato_bold">
                            {profileDetail.email}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* For Bussiness plan only */}
                  {currPlan.type === strings.subscriptionsTypes.BUSINESS && (
                    <div className="pb-4 mb-4 email-form border-b">
                      <p className="form-title fg-gray-30 font-lato_bold mb-2">
                        {t(strings.translate.ADD_BUSINESS_USERS)}
                      </p>
                      <label
                        htmlFor="email1"
                        className="form-label mb-2 fg-gray-30"
                      >
                        {t(strings.translate.ADD_EMAIL)}
                      </label>
                      {emails.map((item, ind) => (
                        <div className="row align-items-end mb-4">
                          <div className="col-md-6">
                            <div>
                              <input
                                type="email"
                                className="form-control"
                                id={ind}
                                placeholder={t(strings.words.EMAIL)}
                                value={item.value}
                                onChange={emailChangeHandler}
                              />
                            </div>
                          </div>
                          {emails.length > ind + 1 ? (
                            <div className="col-md-6">
                              <button
                                className="btn btn--icon btn--danger text-uppercase mt-3 mt-md-0"
                                title="remove"
                                onClick={minusInputHandler.bind(this, ind)}
                              >
                                <i
                                  className="fa fa-minus"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          ) : (
                            <div className="col-md-6">
                              <button
                                className="btn btn--icon btn--primary text-uppercase mt-3 mt-md-0"
                                title="add"
                                onClick={addInputHandler}
                              >
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="pb-4 mb-3 border-b">
                    <p className="form-title fg-gray-30 font-lato_bold mb-2">
                      {t(strings.translate.PERSONAL_DETAILS)}
                    </p>
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <div>
                          <label
                            htmlFor="name"
                            className="form-label mb-2 fg-gray-30"
                          >
                            {t(strings.words.NAME)}
                            <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder={t(strings.translate.YOUR_NAME)}
                            value={name}
                            onChange={nameChangeHandler}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-3 mt-md-0">
                        <div>
                          <label
                            htmlFor="phoneNumber"
                            className="form-label mb-2 fg-gray-30"
                          >
                            {t(strings.translate.PHONE_NUMBER)}
                            <span>*</span>
                          </label>
                          <div className="row">
                            <div className="col-4">
                              <div className="form-control d-flex align-items-center">
                                <img
                                  src={Flag}
                                  alt="flag"
                                  width={40}
                                  height={20}
                                />
                                <span className="ms-2">+1</span>
                              </div>
                            </div>
                            <div className="col-8 ps-0">
                              <input
                                type="text"
                                className="form-control col-10"
                                id="phoneNumber"
                                value={phone}
                                max={10}
                                maxLength={10}
                                onKeyDown={phoneKeyDownHandler}
                                onChange={phoneChangeHandler}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="form-title fg-gray-30 font-lato_bold mb-2 fg-gray-30">
                      {t(strings.translate.BILLING_DETAILS)}
                    </p>
                    <div className="row align-items-center">
                      <div className="col-md-12 mb-3">
                        <div>
                          <label
                            htmlFor="billingAdd"
                            className="form-label mb-1"
                          >
                            {t(strings.translate.BILLING_ADDRESS)}
                            <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="billingAdd"
                            placeholder={t(strings.words.ADDRESS)}
                            value={address}
                            onChange={billingAddChangeHandler}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-3 mt-md-0 mb-3">
                        <div>
                          <label
                            htmlFor="country"
                            className="form-label mb-2 fg-gray-30"
                          >
                            {t(strings.words.COUNTRY)}
                            <span>*</span>
                          </label>
                          <select
                            className="form-select"
                            id="list"
                            value={countryID}
                            onChange={countryChangeHandler}
                          >
                            <option selected>{"Your Country"}</option>
                            {countryDataList.map((item) => (
                              <option value={item.id}>
                                {item.country_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-6 mt-3 mt-md-0 mb-3">
                        <div>
                          <label
                            htmlFor="province"
                            className="form-label mb-2 fg-gray-30"
                          >
                            {t(strings.words.PROVINCE)}
                            <span>*</span>
                          </label>
                          <select
                            className="form-select"
                            id="stateList"
                            value={stateID}
                            onChange={provinceChangeHandler}
                          >
                            <option selected>{"Province"}</option>
                            {provinceDataList.map((item) => (
                              <option value={item.id}>
                                {item.province_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-6 mt-3 mt-md-0 mb-3">
                        <div>
                          <label
                            htmlFor="zipCode"
                            className="form-label mb-2 fg-gray-30"
                          >
                            {t(strings.translate.POSTAL_CODE)}
                            <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="zipCode"
                            placeholder={t(strings.words.CODE)}
                            value={zipcode}
                            maxLength={6}
                            onChange={zipCodeChangeHandler}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-lg-3">
                        <div>
                          <label
                            htmlFor="city"
                            className="form-label mb-2 fg-gray-30"
                          >
                            {t(strings.words.CITY)}
                            <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            placeholder={t(strings.translate.YOUR_CITY)}
                            value={city}
                            onChange={cityChangeHandler}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 text-end mt-5">
                        <Link
                          onClick={saveAndContinueHandler}
                          className="btn btn--primary text-uppercase"
                        >
                          {t(strings.translate.SAVE_AND_CONTINUE)}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade ${
                    paymentTab.length >= 1 ? "show active" : ""
                  } `}
                  id="pills-payment"
                  role="tabpanel"
                  aria-labelledby="pills-payment-tab"
                >
                  <Elements options={options} stripe={stripePromise}>
                    <ElementsConsumer>
                      {(ctx) => <CheckoutForm {...ctx} />}
                    </ElementsConsumer>
                  </Elements>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-xxl-4 my-4 my-lg-0">
              <div className="card order-summary-card">
                <h2 className="font-24 font-lato_bold fg-gray-10 mb-0 pb-3 mb-3 border-b">
                  {t(strings.translate.ORDER_SUMMARY)}
                </h2>
                <p className="font-lato_bold mb-2 fg-gray-10 text-capitalize">
                  {language === strings.language.FR
                    ? currPlan.name_fr
                    : currPlan.name}
                </p>
                <p className="mb-4 fg-gray-10 ">
                  {language === strings.language.FR
                    ? currPlan.description_fr
                    : currPlan.description}
                </p>
                <div className="d-flex pb-1">
                  <div className="col-6">{t(strings.words.PRICE)}</div>
                  <div className="col-6 text-end">
                    {currPlan.type === strings.subscriptionsTypes.FREE
                      ? "$ 0.00"
                      : `$ ${Number(subscriptionPrice)?.toFixed(2)}`}
                  </div>
                </div>
                {paymentTab.length >= 1 && availableCredits && (
                  <>
                    <div className="d-flex border-b pb-2">
                      <div className="col-6">{t(strings.words.TAX)}</div>
                      <div className="col-6 text-end">
                        {availableCredits?.data?.taxAmount
                          ? `+ $ ${Number(
                              availableCredits.data.taxAmount
                            ).toFixed(2)}`
                          : "+ $ 0.00"}
                      </div>
                    </div>
                    <div className="d-flex font-lato_bold py-2">
                      <div className="col-6">
                        {t(strings.translate.FINAL_PRICE)}
                      </div>
                      <div className="col-6 text-end">
                        {`$ ${Number(
                          availableCredits?.data?.includeTaxAmount
                        )?.toFixed(2)}`}
                      </div>
                    </div>
                    <div className="d-flex border-b pb-2">
                      <div className="col-6">
                        {t(strings.translate.AVAILABLE_CREDITS)}
                      </div>
                      <div className="col-6 text-end">
                        {availableCredits?.data?.creditAmount
                          ? `- $ ${Number(
                              availableCredits.data.creditAmount
                            ).toFixed(2)}`
                          : "- $ 0.00"}
                      </div>
                    </div>
                    <div className="d-flex font-lato_bold pt-2">
                      <div className="col-6">{t(strings.words.TOTAL)}</div>
                      <div className="col-6 text-end">
                        {`$ ${Number(
                          availableCredits?.data?.totalAmount
                        )?.toFixed(2)}`}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </body>
  );
};

export default Billing;
