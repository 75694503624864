import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequestWithParams } from "../../services/API";
import { isEmpty } from "../../services/validation";
import { strings } from "../../utils/constants/strings";

const ADMIN_CUSTOMER_PAYMENTS_DETAILS = "adminCustomerPaymentsAction";

export const adminCustomerPaymentsAction = createAsyncThunk(
  ADMIN_CUSTOMER_PAYMENTS_DETAILS,
  async (requestInfo, { dispatch, rejectWithValue }) => {
    let requestUrl =
      "?page=" + requestInfo.page + "&limit=" + requestInfo?.limit;

    try {
      if (!isEmpty(requestInfo.searchText)) {
        requestUrl = requestUrl + "&search_key=" + requestInfo.searchText;
      }

      if (requestInfo?.isFilter === strings.words.EXPIRED) {
        requestUrl = requestUrl + "&isExpired=true";
      }

      if (requestInfo?.isFilter === strings.words.ACTIVE) {
        requestUrl = requestUrl + "&isExpired=false";
      }

      let adminCustomerPaymentsResult = await getRequestWithParams(
        requestInfo.language,
        "adminCustomerPayments",
        requestUrl,
        true
      );

      return adminCustomerPaymentsResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);
