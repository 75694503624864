import { createAsyncThunk } from "@reduxjs/toolkit";
import { patchRequest } from "../../services/API";

const UPDATE_MGA_USER_DETAILS = "mgaUserActions";

export const updateMgaUserAction = createAsyncThunk(
  UPDATE_MGA_USER_DETAILS,
  async (
    { language, email, firstname, lastname },
    { dispatch, rejectWithValue }
  ) => {
    try {
      let userInfo = {
        firstname,
        lastname,
        email,
      };
      let updateMgaUserResult = await patchRequest(
        language,
        "mgaUserUpdate",
        userInfo,
        false,
        true
      );
      return updateMgaUserResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);
