import { createSlice } from "@reduxjs/toolkit";
import {  changePasswordAction } from "../Actions";

const CHANGE_PASSWORD_SLICE_NAME = "changePasswordSlice";

const changePasswordInitialState = {
  changePass: [],
  isLoading: false,
};

const changePasswordSlice = createSlice({
  name: CHANGE_PASSWORD_SLICE_NAME,
  initialState: changePasswordInitialState,
  reducers: {},
  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(changePasswordAction.pending, (state) => {
        state.isLoading = true;
        state.changePass = {};
        return state;
      })
      .addCase(
        changePasswordAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.changePass = action.payload;
          return state;
        }
      )
      .addCase(
        changePasswordAction.rejected,
        (state) => {
          state.isLoading = false;
        }
      );
  },
});

export const {} = changePasswordSlice.actions;
export const changePasswordReducer = changePasswordSlice.reducer;
