import React from 'react';
import { strings } from '../../utils/constants';
import { TableCell } from "@mui/material";

export default function NoRecordFound({colSpan}) {
  return (
    <tr>
      <TableCell colSpan={colSpan}>
        <p align="center" style={{ marginTop: "10px" }}>
          {strings.general.NO_RECORD_FOUND}
        </p>
      </TableCell>
    </tr>
  );
}
