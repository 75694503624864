import React, { useState } from "react";
import {
  confirmPasswordValidation,
  isEmpty,
  newEmailValidation,
  newPasswordValidation,
} from "../../services/validation";
import { Toast } from "../Toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { forgotPasswordAction } from "../../redux/Actions";
import { useEffect } from "react";
import { onSuccess } from "../../redux/Reducers/ResetPasswordReducer";
import { useCookies } from "react-cookie";
import { paths, strings } from "../../utils/constants";
import Modal from "bootstrap/js/dist/modal";
import { resetPasswordAction } from "../../redux/Actions";
import Loader from "./Loader";
import ForgotPasswordComponent from "./ForgotPasswordComponent";
import OtpVerification from "./OtpVerification";
import ChangePassword from "./ChangePassword";

export default function ForgotPasswordPage({ isAdmin }) {
  const [openOTPVerification, setOpenOTPVerification] = useState(false);
  const [openResetPassword, setResetPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [resetOpen] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [OTP, setOTP] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [cookies, setCookie, removeCookie] = useCookies([
    "access_token",
    "refresh_token",
  ]);

  const { language } = useSelector((state) => state.generalReducer);

  const { isForgotPasswordLoading } = useSelector(
    (state) => state.forgotPasswordReducer
  );

  const { isForgotPasswordOTPVerifyLoading, forgotPasswordOTPVerify } =
    useSelector((state) => state.forgotPasswordOTPVerifyReducer);

  const { isResetPasswordLoading, resetPassword } = useSelector(
    (state) => state.resetPasswordReducer
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes, forgotPasswordOTPVerify, resetPassword, resetOpen]);

  const resetEverything = () => {
    setForgotPasswordEmail("");
    setOTP({
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
    });
    setMinutes(1);
    setSeconds(0);
    setNewPassword("");
    setConfirmPassword("");
    dispatch(onSuccess(""));
    setOpenOTPVerification(false);
    setResetPassword(false);
  };

  const resendCodeHandler = (e) => {
    setOTP({
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
    });
    forgotPasswordClickHandler(e);
  };

  const forgotPasswordClickHandler = async (e) => {
    e.preventDefault();
    if (isEmpty(forgotPasswordEmail)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.EMAIL_REQUIRED_ERROR),
      });
    } else if (!newEmailValidation(forgotPasswordEmail)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.EMAIL_VALIDATION_ERROR),
      });
    } else {
      let response;
      if (isAdmin) {
        response = await dispatch(
          forgotPasswordAction({
            language: strings.language.EN,
            isAdmin,
            email: forgotPasswordEmail,
          })
        );
      } else {
        response = await dispatch(
          forgotPasswordAction({
            language: language,
            isAdmin,
            email: forgotPasswordEmail,
          })
        );
      }
      setMinutes(1);
      setSeconds(0);
      if (response.payload?.success) {
        setOpenOTPVerification(true);
        setId(response.payload.data.id);
      } else if (response.payload?.errors) {
        removeCookie("access_token", { path: "/" });
        removeCookie("refresh_token", { path: "/" });
        navigate(0);
        if (isAdmin) {
          navigate(paths.adminRoutes.ADMIN, { replace: true });
        } else {
          navigate("/", { replace: true });
        }
      }
    }
  };

  function removeElementsByClass(className) {
    const elements = document.getElementsByClassName(className);
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  const changePasswordHandler = async (e) => {
    e.preventDefault();
    if (isEmpty(newPassword) && isEmpty(confirmPassword)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.PASSWORDS_REQUIRED_ERROR),
      });
    } else if (isEmpty(newPassword)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.NEW_PASSWORD_REQUIRED_ERROR),
      });
    } else if (!newPasswordValidation(newPassword)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.PASSWORD_VALIDATION_ERROR),
      });
    } else if (isEmpty(confirmPassword)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.CONFIRM_PASSWORD_REQUIRED_ERROR),
      });
    } else if (!confirmPasswordValidation(newPassword, confirmPassword)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.SAME_PASSWORD_VALIDATION_ERROR),
      });
    } else {
      if (isAdmin) {
        let requestInfo = {
          password: newPassword,
          confirm_password: confirmPassword,
          admin_id: id,
        };

        let resetPasswordResponse = await dispatch(
          resetPasswordAction({
            language: strings.language.EN,
            isAdmin: isAdmin,
            requestInfo: requestInfo,
          })
        );

        if (resetPasswordResponse.payload?.success) {
          resetEverything();

          const truck_modal = document.querySelector("#exampleModal");
          const modalTest = Modal.getInstance(truck_modal);
          modalTest.hide();
          removeElementsByClass("modal-backdrop");

          if (isAdmin) {
            navigate(paths.adminRoutes.ADMIN);
          } else {
            navigate("/");
          }
        }
      } else {
        let requestInfo = {
          password: newPassword,
          confirm_password: confirmPassword,
          user_id: id,
        };
        let resetPasswordResponse = await dispatch(
          resetPasswordAction({
            language: language,
            isAdmin: isAdmin,
            requestInfo: requestInfo,
          })
        );

        if (resetPasswordResponse.payload?.success) {
          resetEverything();
          const truck_modal = document.querySelector("#exampleModal");
          const modalTest = Modal.getInstance(truck_modal);
          modalTest.hide();
          removeElementsByClass("modal-backdrop");
          if (isAdmin) {
            navigate(paths.adminRoutes.ADMIN);
          } else {
            navigate("/");
          }
        }
      }
    }
  };

  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content forgot-password">
          <div className="modal-header pb-0">
            {!isForgotPasswordLoading &&
              !isForgotPasswordOTPVerifyLoading &&
              !isResetPasswordLoading && (
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={resetEverything}
                ></button>
              )}
          </div>
          <div className="modal-body">
            {isForgotPasswordLoading ||
            isForgotPasswordOTPVerifyLoading ||
            isResetPasswordLoading ? (
              <Loader isAdmin={isAdmin} />
            ) : !openOTPVerification && !openResetPassword ? (
              <ForgotPasswordComponent
                email={forgotPasswordEmail}
                setEmail={setForgotPasswordEmail}
                btnClickHandler={forgotPasswordClickHandler}
              />
            ) : openOTPVerification && !openResetPassword ? (
              <OtpVerification
                email={forgotPasswordEmail}
                otps={OTP}
                seconds={seconds}
                minutes={minutes}
                isAdmin={isAdmin}
                resendCodeHandler={resendCodeHandler}
                setResetPassword={setResetPassword}
                setOpenOTPVerification={setOpenOTPVerification}
                id={id}
                setOTP={setOTP}
              />
            ) : (
              <ChangePassword
                newPassword={newPassword}
                setNewPassword={setNewPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                changePasswordHandler={changePasswordHandler}
              />
            )}
          </div>
        </div>
      </div>
      <script
        type="text/javascript"
        src="https://code.jquery.com/jquery-1.11.3.min.js"
      ></script>
      <script
        type="text/javascript"
        src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.5/js/bootstrap.min.js"
      ></script>
    </div>
  );
}
