import "./CMSTable.css";
import React, { useEffect } from "react";
import { BsFillPencilFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAdminCMSPagesAction } from "../../redux/Actions";
import {
  isFromCMSPagesForm,
  isFromEditCMSPagesData,
} from "../../redux/Reducers/AdminCMSPagesReducer";
import { strings, paths } from "../../utils/constants";
import NoRecordFound from "../NoRecordFound";

export const CMSTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { fetchAdminCMSPagesData } = useSelector(
    (state) => state.adminCMSPagesReducer
  );

  const tableRecords = fetchAdminCMSPagesData?.data
    ? fetchAdminCMSPagesData.data
    : [];

  const getAPI = async () => {
    await dispatch(fetchAdminCMSPagesAction({ language: strings.language.EN }));
  };

  useEffect(() => {
    getAPI();
  }, []);

  const handleEdit = (data) => {
    dispatch(isFromCMSPagesForm(true));
    dispatch(isFromEditCMSPagesData(data));
    navigate(paths.adminRoutes.EDIT_CMS_PAGES);
  };

  return (
    <div className="cmtable-wrapper">
      <table className="cmtable">
        <thead>
          <tr>
            <th>{strings.general.SR_NO}</th>
            <th className="w-50">{strings.general.PAGE_TITLE_ENGLISH}</th>
            <th className="w-50">{strings.general.PAGE_TITLE_FRENCH}</th>
            <th>{strings.words.ACTIONS}</th>
          </tr>
        </thead>
        <tbody>
          {tableRecords.length > 0 ? (
            tableRecords.map((row, idx) => {
              return (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{row?.title}</td>
                  <td>{row?.title_fr}</td>
                  <td className="fit">
                    <span className="cmactions">
                      <BsFillPencilFill
                        onClick={handleEdit.bind(this, row)}
                        className="cursor-pointer"
                      />
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            <NoRecordFound colSpan={3} />
          )}
        </tbody>
      </table>
    </div>
  );
};
