import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postRequest,
  postRequestForFormData,
  deleteRequest,
  patchRequest,
  patchRequestForFormData,
  getRequestWithParams,
} from "../../services/API";
import { isEmpty } from "../../services/validation";
import { strings } from "../../utils/constants/strings";

const ADD_ADMIN_FORM_DETAILS = "addAdminFormAction";
const EDIT_ADMIN_FORM_DETAILS = "editAdminFormAction";
const DELETE_ADMIN_FORM_DETAILS = "deleteAdminFormAction";
const FETCH_ADMIN_FORM_DETAILS = "fetchAdminFormAction";

export const fetchAdminFormAction = createAsyncThunk(
  FETCH_ADMIN_FORM_DETAILS,
  async (requestInfo, { dispatch, rejectWithValue }) => {
    let requestUrl =
      "?page=" + requestInfo.page + "&limit=" + requestInfo?.limit;

    try {
      if (!isEmpty(requestInfo.searchText)) {
        requestUrl = requestUrl + "&search_key=" + requestInfo.searchText;
      }

      if (!isEmpty(requestInfo?.carrierId)) {
        requestUrl = requestUrl + "&carrier_id=" + requestInfo?.carrierId;
      }

      if (!isEmpty(requestInfo?.privacyServiceId)) {
        requestUrl =
          requestUrl + "&policy_service_id=" + requestInfo?.privacyServiceId;
      }

      if (requestInfo?.isFilter === strings.general.ADA_FORMS) {
        requestUrl = requestUrl + "&isADA=true";
      }

      if (requestInfo?.isFilter === strings.general.NON_ADA_FORMS) {
        requestUrl = requestUrl + "&isADA=false";
      }

      if (requestInfo?.isFilter === strings.general.MAPPED_FORMS) {
        requestUrl = requestUrl + "&isMapped=true";
      }

      if (requestInfo?.isFilter === strings.general.NON_MAPPED_FORMS) {
        requestUrl = requestUrl + "&isMapped=false";
      }

      let fetchAdminFormResult = await getRequestWithParams(
        requestInfo.language,
        "form",
        requestUrl,
        true
      );
      return fetchAdminFormResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);

export const addAdminFormAction = createAsyncThunk(
  ADD_ADMIN_FORM_DETAILS,
  async (
    { language, requestInfo, isFile, customTost = false },
    { dispatch, rejectWithValue }
  ) => {
    try {
      let addAdminFormResult;
      if (!isFile) {
        addAdminFormResult = await postRequest(
          language,
          "form",
          requestInfo,
          true,
          customTost
        );
      } else {
        addAdminFormResult = await postRequestForFormData(
          language,
          "form",
          requestInfo,
          true,
          customTost
        );
      }

      return addAdminFormResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);

export const editAdminFormAction = createAsyncThunk(
  EDIT_ADMIN_FORM_DETAILS,
  async (
    { language, requestInfo, isFile, customTost },
    { dispatch, rejectWithValue }
  ) => {
    try {
      let addAdminFormResult;

      if (!isFile) {
        addAdminFormResult = await patchRequest(
          language,
          "form",
          requestInfo,
          true,
          customTost
        );
      } else {
        addAdminFormResult = await patchRequestForFormData(
          language,
          "form",
          requestInfo,
          true,
          customTost
        );
      }
      return addAdminFormResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);

export const deleteAdminFormAction = createAsyncThunk(
  DELETE_ADMIN_FORM_DETAILS,
  async ({ language, formId }, { dispatch, rejectWithValue }) => {
    try {
      let deleteFormResult = await deleteRequest(
        language,
        "form",
        formId,
        true
      );
      return deleteFormResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);
