import { createSlice } from "@reduxjs/toolkit";
import { fetchAdminCMSPagesAction, editAdminCMSPagesAction } from "../Actions";

const ADMIN_CMS_PAGES_SLICE_NAME = "adminCMSPagesDetails";

const adminCMSPagesInitialState = {
  fetchAdminCMSPagesData: {},
  editAdminCMSPagesData: {},
  isLoading: false,
  isEditCMSPages: false,
  editCMSPagesData: {},
};

const adminCMSPagesSlice = createSlice({
  name: ADMIN_CMS_PAGES_SLICE_NAME,
  initialState: adminCMSPagesInitialState,
  reducers: {
    isFromCMSPagesForm(state, action) {
      state.isEditCMSPages = action.payload;
      return state;
    },
    isFromEditCMSPagesData(state, action) {
      state.editCMSPagesData = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(fetchAdminCMSPagesAction.pending, (state) => {
        state.isLoading = true;
        state.fetchAdminCMSPagesData = {};
        state.editAdminCMSPagesData = {};
        return state;
      })
      .addCase(fetchAdminCMSPagesAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.fetchAdminCMSPagesData = action.payload;
        state.editAdminCMSPagesData = {};
        return state;
      })
      .addCase(fetchAdminCMSPagesAction.rejected, (state, action) => {
        state.isLoading = false;
        state.fetchAdminCMSPagesData = action.payload;
        state.editAdminCMSPagesData = {};
      })
      .addCase(editAdminCMSPagesAction.pending, (state) => {
        state.isLoading = true;
        state.editAdminCMSPagesData = {};
        return state;
      })
      .addCase(editAdminCMSPagesAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.editAdminCMSPagesData = action.payload;
        return state;
      })
      .addCase(editAdminCMSPagesAction.rejected, (state, action) => {
        state.isLoading = false;
        state.editAdminCMSPagesData = action.payload;
      });
  },
});

export const { isFromCMSPagesForm, isFromEditCMSPagesData } =
  adminCMSPagesSlice.actions;
export const adminCMSPagesReducer = adminCMSPagesSlice.reducer;
