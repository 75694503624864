import { createSlice } from "@reduxjs/toolkit";
import {
  fetchMGAUserAction,
  addAdminMGAUserAction,
  editAdminMGAUserAction,
  deleteAdminMGAUserAction,
} from "../Actions"; 

const ADMIN_MGA_USER_SLICE_NAME = "adminMGAUserDetails";

const adminMGAUserInitialState = {
  MGAUser: {},
  addMGAUser: {},
  editMGAUser: {},
  deleteMGAUser: {},
  isLoading: false,
  isEdit: false,
  editData: {},
};

const adminMGAUserSlice = createSlice({
  name: ADMIN_MGA_USER_SLICE_NAME,
  initialState: adminMGAUserInitialState,
  reducers: {
    isFromEdit(state, action) {
      state.isEdit = action.payload;
      return state;
    },
    isFromEditData(state, action) {
      state.editData = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(addAdminMGAUserAction.pending, (state) => {
        state.isLoading = true;
        state.addMGAUser = {};
        state.deleteMGAUser = {};
        state.editMGAUser = {};
        return state;
      })
      .addCase(addAdminMGAUserAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.addMGAUser = action.payload;
        state.deleteMGAUser = {};
        state.editMGAUser = {};
        return state;
      })
      .addCase(addAdminMGAUserAction.rejected, (state, action) => {
        state.isLoading = false;
        state.addMGAUser = action.payload;
        state.deleteMGAUser = {};
        state.editMGAUser = {};
      })
      .addCase(editAdminMGAUserAction.pending, (state) => {
        state.isLoading = true;
        state.editMGAUser = {};
        state.deleteMGAUser = {};
        state.addMGAUser = {};
        return state;
      })
      .addCase(editAdminMGAUserAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.editMGAUser = action.payload;
        state.deleteMGAUser = {};
        state.addMGAUser = {};
        return state;
      })
      .addCase(editAdminMGAUserAction.rejected, (state, action) => {
        state.isLoading = false;
        state.editMGAUser = action.payload;
        state.deleteMGAUser = {};
        state.addMGAUser = {};
      })
      .addCase(deleteAdminMGAUserAction.pending, (state) => {
        state.isLoading = true;
        state.deleteMGAUser = {};
        state.addMGAUser = {};
        state.editMGAUser = {};
        return state;
      })
      .addCase(deleteAdminMGAUserAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.deleteMGAUser = action.payload;
        state.addMGAUser = {};
        state.editMGAUser = {};
        return state;
      })
      .addCase(deleteAdminMGAUserAction.rejected, (state, action) => {
        state.isLoading = false;
        state.deleteMGAUser = action.payload;
        state.addMGAUser = {};
        state.editMGAUser = {};
      })
      .addCase(fetchMGAUserAction.pending, (state) => {
        state.isLoading = true;
        state.MGAUser = {};
        return state;
      })
      .addCase(fetchMGAUserAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.MGAUser = action.payload;
        return state;
      })
      .addCase(fetchMGAUserAction.rejected, (state, action) => {
        state.isLoading = false;
        state.MGAUser = action.payload;
      });
  },
});

export const { isFromEdit, isFromEditData } = adminMGAUserSlice.actions;
export const adminMGAUserReducer = adminMGAUserSlice.reducer;
