import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Stack, Button, Typography } from "@mui/material";
import ProgressDialog from "../../component/ProgressDialog";
import { isEmpty } from "../../services/validation";
import { Toast } from "../../component/Toast";
import {
  editAdminMgaAction,
  addAdminMgaAction,
} from "../../redux/Actions";
import { strings, paths } from "../../utils/constants";
import CustomTextField from "../../component/CustomTextField";

export default function AddEditMgaPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading, isEditMga, editMgaData } = useSelector(
    (state) => state.adminMgaReducer
  );

  const [mgaName, setMgaName] = useState(
    isEditMga && editMgaData?.mga_name != null ? editMgaData?.mga_name : ""
  );

  const mgaNameChangeHandler = (e) => {
    setMgaName(e.target.value);
  };

  const submitUpateHandler = async (e) => {
    e.preventDefault();
    if (isEmpty(mgaName)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.MGA_NAME_REQUIRED,
      });
    } else {
      if (isEditMga) {
        let editAdminMGAResponse = await dispatch(
          editAdminMgaAction({
            language: strings.language.EN,
            requestInfo: {
              id: editMgaData?.id,
              mga_name: mgaName,
            },
          })
        );
        if (editAdminMGAResponse.payload?.success) {
          navigate(paths.adminRoutes.MGAS, { replace: true });
        }
      } else {
        let addAdminMGAResponse = await dispatch(
          addAdminMgaAction({
            language: strings.language.EN,
            requestInfo: {
              mga_name: mgaName,
            },
          })
        );
        if (addAdminMGAResponse.payload?.success) {
          navigate(paths.adminRoutes.MGAS, { replace: true });
        }
      }
    }
  };

  const cancelHandler = () => {
    navigate(paths.adminRoutes.MGAS, {
      replace: true,
    });
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom sx={{ marginLeft: 5 }}>
          {isEditMga ? strings.general.EDIT_MGA : strings.general.ADD_NEW_MGA}
        </Typography>
      </Stack>
      <form
        style={{
          marginBottom: "5px",
          marginLeft: "50px",
          marginRight: "50px",
        }}
      >
        <CustomTextField
          style={{
            width: "100%",
            marginTop: "5px",
            marginBottom: "5px",
          }}
          type="text"
          label={`${strings.general.MGA_NAME}*`}
          variant="outlined"
          focusColor="#184437"
          value={mgaName}
          autoFocus
          autoComplete="off"
          inputProps={{ maxLength: 150 }}
          onChange={mgaNameChangeHandler}
        />
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="start"
          justifyContent="left"
          style={{ paddingBottom: "20px" }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "#184437",
              marginTop: "14px",
              marginRight: "12px",
            }}
            onClick={submitUpateHandler}
          >
            {isEditMga ? strings.words.UPDATE : strings.words.SUBMIT}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#184437", marginTop: "14px" }}
            onClick={cancelHandler}
          >
            {strings.words.CANCEL}
          </Button>
        </Stack>
      </form>
      {isLoading && <ProgressDialog open={isLoading} isAdmin={true} />}
    </>
  );
}
