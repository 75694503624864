import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Stack, Button, Typography } from "@mui/material";
import { updateProfile } from "../../redux/Actions";
import { useDispatch } from "react-redux";
import { Toast } from "../../component/Toast";
import { adminContactNumberValidation, isEmpty } from "../../services/validation";
import ProgressDialog from "../../component/ProgressDialog";
import { strings, paths } from "../../utils/constants";
import CustomTextField from "../../component/CustomTextField";
import Flag from "../../utils/Logo/caneda_flag.png";


export default function ProfilePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isProfileDataLoading, profileData } = useSelector(
    (state) => state.adminProfileReducer
  );

  const [firstName, setFirstName] = useState(
    profileData?.data?.firstname != null ? profileData?.data?.firstname : ""
  );
  const [lastName, setLastName] = useState(
    profileData?.data?.lastname != null ? profileData?.data?.lastname : ""
  );

  const [userName] = useState(profileData?.data?.username);
  const [email] = useState(profileData?.data?.email);
  const [phone, setPhone] = useState(
    profileData?.data?.phone ? (profileData?.data?.phone).toString() : ""
  );

  const handleClick = async (e) => {
    e.preventDefault();
    if (isEmpty(firstName)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.FIRST_NAME_REQUIRED,
      });
    } else if (isEmpty(lastName)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: strings.admin.error.LAST_NAME_REQUIRED,
      });
    } else if (adminContactNumberValidation(phone)) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: adminContactNumberValidation(phone),
      });
    } else {
      let requestInfo = {
        firstname: firstName,
        lastname: lastName,
        phone: phone,
      };
      let editAdminProfileResponse = await dispatch(
        updateProfile({
          language: strings.language.EN,
          isAdmin: true,
          userInfo: requestInfo,
        })
      );
      if (editAdminProfileResponse.payload?.success) {
        navigate(paths.adminRoutes.USERS, { replace: true });
      }
    }
  };

  const firstNameChangeHandler = (e) => {
    setFirstName(e.target.value);
  };

  const lastNameChangeHandler = (e) => {
    setLastName(e.target.value);
  };

  const phoneChangeHandler = (e) => {
    const regex = new RegExp(/^[0-9]*$/);
    if (regex.test(e.target.value)) {
      setPhone(e.target.value);
    }
  };
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginLeft: 5, marginTop: 5 }}
        >
          {strings.general.MY_PROFILE}
        </Typography>
      </Stack>
      <form
        style={{ marginBottom: "5px", marginLeft: "50px", marginRight: "50px" }}
      >
        <CustomTextField
          style={{
            width: "100%",
            marginTop: "5px",
            marginBottom: "5px",
          }}
          type="text"
          label={`${strings.general.FIRST_NAME}*`}
          variant="outlined"
          focusColor="#184437"
          value={firstName}
          autoFocus
          autoComplete="off"
          inputProps={{ maxLength: 150 }}
          onChange={firstNameChangeHandler}
        />
        <br />
        <CustomTextField
          style={{
            width: "100%",
            marginTop: "10px",
            marginBottom: "5px",
          }}
          type="text"
          label={`${strings.general.LAST_NAME}*`}
          variant="outlined"
          focusColor="#184437"
          value={lastName}
          autoComplete="off"
          inputProps={{ maxLength: 150 }}
          onChange={lastNameChangeHandler}
        />
        <CustomTextField
          style={{
            width: "100%",
            marginTop: "10px",
            marginBottom: "5px",
          }}
          type="text"
          label={`${strings.words.USERNAME}*`}
          variant="outlined"
          focusColor="#184437"
          value={userName}
          autoComplete="off"
          disabled={true}
          inputProps={{ maxLength: 150 }}
        />
        <CustomTextField
          style={{
            width: "100%",
            marginTop: "10px",
            marginBottom: "5px",
          }}
          type="text"
          label={`${strings.words.EMAIL}*`}
          variant="outlined"
          focusColor="#184437"
          value={email}
          autoComplete="off"
          disabled={true}
        />
        <div
          direction="row"
          style={{
            width: "100%",
            marginTop: "10px",
            marginBottom: "5px",
            marginRight: "5px",
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ widht: "8%" }}>
            <div
              className="d-flex align-items-center"
              style={{
                border: "1px solid",
                borderColor: "#184437",
                borderRadius: "5px",
                padding: "16px 12px",
              }}
            >
              <img src={Flag} alt="flag" width={40} height={20} />
              <span className="ms-2">+1</span>
            </div>
          </div>
          <CustomTextField
            style={{
              width: "100%",
              marginLeft: "8px",
            }}
            type="text"
            label={`${strings.general.MOBILE_NUMBER}*`}
            variant="outlined"
            focusColor="#184437"
            value={phone}
            autoComplete="off"
            inputProps={{ maxLength: 10 }}
            onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
            onChange={phoneChangeHandler}
          />
        </div>
        <br />
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="start"
          justifyContent="left"
          style={{ paddingBottom: "20px" }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "#184437",
              marginTop: 5,
              marginRight: "12px",
            }}
            onClick={handleClick}
          >
            {strings.words.SUBMIT}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#184437", marginTop: 5 }}
            onClick={() => {
              navigate(paths.adminRoutes.USERS, { replace: true });
            }}
          >
            {strings.words.CANCEL}
          </Button>
        </Stack>
      </form>
      {isProfileDataLoading && (
        <ProgressDialog open={isProfileDataLoading} isAdmin={true} />
      )}
    </>
  );
}
