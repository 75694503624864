import React from "react";
import MgaTable from "../../component/MgaTable";
import { Stack, Button, Container } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { strings, paths } from "../../utils/constants";
import { isFromEditMga, isFromEditMgaData } from "../../redux/Reducers/AdminMgaReducer";

export default function MgaPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addHandler = () => {
    dispatch(isFromEditMga(false));
    dispatch(isFromEditMgaData({}));
    navigate(paths.adminRoutes.ADD_EDIT_MGA);
  };
  return (
    <>
      <Container
        style={{ width: "80%", marginRight: "4%", marginBottom: "12px" }}
      >
        <Stack direction="column" alignItems="end">
          <Button
            variant="contained"
            sx={{ color: "#184437" }}
            className="button"
            startIcon={<AddIcon />}
            onClick={addHandler}
          >
            {strings.general.ADD_NEW}
          </Button>
        </Stack>
      </Container>
      <MgaTable />;
    </>
  );
}
