import { createSlice } from "@reduxjs/toolkit";
import { forgotPasswordOTPVerityAction } from "../Actions"; 

const FORGOT_PASSWORD_OTP_VERIFY_SLICE_NAME = "forgotPasswordOTPVerifyDetails";

const forgotPasswordOTPVerifyInitialState = {
  forgotPasswordOTPVerify: {},
  isForgotPasswordOTPVerifyLoading: false,
};

const forgotPasswordOTPVerifySlice = createSlice({
  name: FORGOT_PASSWORD_OTP_VERIFY_SLICE_NAME,
  initialState: forgotPasswordOTPVerifyInitialState,
  reducers: {},
  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(forgotPasswordOTPVerityAction.pending, (state) => {
        state.isForgotPasswordOTPVerifyLoading = true;
        state.forgotPasswordOTPVerify = {};
        return state;
      })
      .addCase(
        forgotPasswordOTPVerityAction.fulfilled,
        (state, action) => {
          state.isForgotPasswordOTPVerifyLoading = false;
          state.forgotPasswordOTPVerify = action.payload;
          return state;
        }
      )
      .addCase(
        forgotPasswordOTPVerityAction.rejected,
        (state, action) => {
          state.isForgotPasswordOTPVerifyLoading = false;
          state.forgotPasswordOTPVerify = action.payload;
        }
      );
  },
});

export const {} = forgotPasswordOTPVerifySlice.actions;
export const forgotPasswordOTPVerifyReducer = forgotPasswordOTPVerifySlice.reducer;
