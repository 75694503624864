import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, patchRequest } from "../../services/API";

const GET_CLIENT_EMAILS = "getClientEmails";
const CHANGE_CLIENT_EMAILS = "changeClientEmails";

export const getClientEmails = createAsyncThunk(
  GET_CLIENT_EMAILS,
  async ({ language }) => {
    try {
      let clientEmails = await getRequest(language, "licencekey");
      return clientEmails;
    } catch (error) {}
  }
);

export const changeClientEmails = createAsyncThunk(
  CHANGE_CLIENT_EMAILS,
  async ({ language, client_email }) => {
    try {
      let clientEmails = await patchRequest(
        language,
        "updateLicencekey",
        client_email
      );
      return clientEmails;
    } catch (error) {}
  }
);
