import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, getRequestWithParams } from "../../services/API";

const COUNTRY_LIST = "countryList";
const STATE_LIST = "stateList";
const CMS_LIST = "getCMSList";

export const countryList = createAsyncThunk(
  COUNTRY_LIST,
  async ({ language }, { dispatch, rejectWithValue }) => {
    try {
      let countryResult = await getRequest(language, "country", false);
      return countryResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);

// get states

export const stateList = createAsyncThunk(
  STATE_LIST,
  async (
    { language, selectedKey },
    { dispatch, rejectWithValue }
  ) => {
    try {
      let countryResult = await getRequestWithParams(
        language,
        "province",
        selectedKey,
        false
      );
      return countryResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);

// get CMS details

export const getCMSList = createAsyncThunk(
  CMS_LIST,
  async ({ language }, { dispatch, rejectWithValue }) => {
    try {
      let cmsResult = await getRequest(language, "cms", false);
      return cmsResult;
    } catch (error) {
      return rejectWithValue(JSON.parse(error.message));
    }
  }
);
