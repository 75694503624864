import axios from "axios";
import { strings, paths } from "../utils/constants";
import { Toast } from "../component/Toast";

let accessToken = "";

const getToken = async (isAdmin) => {
  let tokenValue = "";
  if (isAdmin) {
    tokenValue = await localStorage.getItem("adminToken");
  } else {
    tokenValue = await localStorage.getItem("token");
  }
  accessToken = tokenValue;
  return tokenValue;
};

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 10000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosApi.interceptors.request.use(function (config) {
  config.headers.Authorization = accessToken ? `${accessToken}` : "";
  return config;
});

export const buildEndpoint = (callFor) => {
  return paths.endpoints[callFor];
};

const onInActive = async (isAdmin) => {
  await getToken(isAdmin);
  if (isAdmin) {
    localStorage.removeItem("adminToken");
    localStorage.setItem("unAuthorized", "admin");
  } else {
    localStorage.removeItem("token");
    localStorage.setItem("unAuthorized", "user");
  }
  window.location.reload(false);
};

const exceptionHandler = (e, isAdmin) => {
  if (e.code === strings.error.NETWORK_ERROR) {
    onInActive(isAdmin);
    Toast({
      type: "error",
      title: e.message,
      message: e.message,
    });
    return e.message;
  }
  if (e.response.status === 401) {
    onInActive(isAdmin);
    let errorData = {
      errors: [{ message: strings.error.NOT_AUTHORIZED_ERROR }],
    };
    Toast({
      type: "error",
      title: `${e.response.status} ${e.response.statusText}`,
      message: strings.error.NOT_AUTHORIZED_ERROR,
    });
    return errorData;
  } else {
    Toast({
      type: "error",
      title: `${e.response.status} ${e.response.statusText}`,
      message: e.response.data.errors[0].message,
    });
    return e.response.data;
  }
};

export const getRequest = async (
  language = strings.language.EN,
  endpoint,
  isAdmin = false
) => {
  try {
    const { data } = await axiosApi.get(language + buildEndpoint(endpoint), {
      headers: {
        token: await getToken(isAdmin),
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (e) {
    exceptionHandler(e, isAdmin);
  }
};

export const getRequestWithParams = async (
  language = strings.language.EN,
  endpoint,
  urlParams,
  isAdmin = false
) => {
  await getToken(isAdmin);
  try {
    const endPoint = buildEndpoint(endpoint);
    const requestUrl = language + endPoint + urlParams;
    const { data } = await axiosApi.get(requestUrl, {
      headers: {
        token: await getToken(isAdmin),
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (e) {
    exceptionHandler(e, isAdmin);
  }
};

export const postRequest = async (
  language = strings.language.EN,
  endpoint,
  body,
  isAdmin = false,
  customTost = false
) => {
  await getToken(isAdmin);
  try {
    const { data } = await axiosApi.post(
      language + buildEndpoint(endpoint),
      body,
      {
        headers: {
          token: await getToken(isAdmin),
          "Content-Type": "application/json",
        },
      }
    );
    if (!customTost) {
      Toast({
        type: "success",
        title: strings.words.SUCCESS,
        message: data.message,
      });
    }
    return data;
  } catch (e) {
    exceptionHandler(e, isAdmin);
  }
};

export const postRequestForFormData = async (
  language = strings.language.EN,
  endpoint,
  body,
  isAdmin = false,
  customTost = false
) => {
  await getToken(isAdmin);
  try {
    const { data } = await axiosApi.post(
      language + buildEndpoint(endpoint),
      body,
      {
        headers: {
          token: await getToken(isAdmin),
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (!customTost) {
      Toast({
        type: "success",
        title: strings.words.SUCCESS,
        message: data.message,
      });
    }
    return data;
  } catch (e) {
    exceptionHandler(e, isAdmin);
  }
};

export const putRequest = async (
  language = strings.language.EN,
  endpoint,
  body,
  isAdmin = false
) => {
  await getToken(isAdmin);
  try {
    const { data } = await axiosApi.put(
      language + buildEndpoint(endpoint),
      body,
      {
        headers: {
          token: await getToken(isAdmin),
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (e) {
    exceptionHandler(e, isAdmin);
  }
};

export const patchRequestForFormData = async (
  language = strings.language.EN,
  endpoint,
  body,
  isAdmin = false,
  customTost = false
) => {
  await getToken(isAdmin);
  try {
    const { data } = await axiosApi.patch(
      language + buildEndpoint(endpoint),
      body,
      {
        headers: {
          token: await getToken(isAdmin),
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (!customTost) {
      Toast({
        type: "success",
        title: strings.words.SUCCESS,
        message: data.message,
      });
    }
    return data;
  } catch (e) {
    exceptionHandler(e, isAdmin);
  }
};

export const patchRequest = async (
  language = strings.language.EN,
  endpoint,
  body,
  isAdmin = false,
  customTost = false
) => {
  await getToken(isAdmin);
  try {
    const { data } = await axiosApi.patch(
      language + buildEndpoint(endpoint),
      body,
      {
        headers: {
          token: await getToken(isAdmin),
          "Content-Type": "application/json",
        },
      }
    );
    if (!customTost) {
      Toast({
        type: "success",
        title: strings.words.SUCCESS,
        message: data.message,
      });
    }
    return data;
  } catch (e) {
    exceptionHandler(e, isAdmin);
  }
};

export const deleteRequest = async (
  language = strings.language.EN,
  endpoint,
  body,
  isAdmin = false
) => {
  await getToken(isAdmin);
  try {
    const endPoint = buildEndpoint(endpoint);

    const requestUrl = language + endPoint + "/" + body;

    const { data } = await axiosApi.delete(requestUrl, {
      headers: {
        token: await getToken(isAdmin),
        "Content-Type": "application/json",
      },
    });
    Toast({
      type: "success",
      title: strings.words.SUCCESS,
      message: data.message,
    });
    return data;
  } catch (e) {
    exceptionHandler(e, isAdmin);
  }
};

export { getToken };
