import { createSlice } from "@reduxjs/toolkit";
import {
  deleteSubscriptionPlanAction,
  fetchSubscriptionPlanAction,
  editSubscriptionPlanAction,
} from "../Actions";

const ADMIN_SUBSCRIPTION_SLICE_NAME = "adminSubscriptionDetails";

const adminSubscriptionInitialState = {
  subscriptionPlanData: {},
  updateSubscriptionPlanData: {},
  deleteSubscriptionPlanData: {},
  isLoading: false,
  isEditSubscriptionPlan: false,
  editSubscriptionData: {},
};

const adminSubscriptionSlice = createSlice({
  name: ADMIN_SUBSCRIPTION_SLICE_NAME,
  initialState: adminSubscriptionInitialState,
  reducers: {
    isFromEditSubscription(state, action) {
      state.isEditSubscriptionPlan = action.payload;
      return state;
    },
    isFromEditSubscriptionData(state, action) {
      state.editSubscriptionData = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(editSubscriptionPlanAction.pending, (state) => {
        state.isLoading = true;
        state.updateSubscriptionPlanData = {};
        state.deleteSubscriptionPlanData = {};
        return state;
      })
      .addCase(editSubscriptionPlanAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSubscriptionPlanData = action.payload;
        state.deleteSubscriptionPlanData = {};
        return state;
      })
      .addCase(editSubscriptionPlanAction.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSubscriptionPlanData = action.payload;
        state.deleteSubscriptionPlanData = {};
      })
      .addCase(deleteSubscriptionPlanAction.pending, (state) => {
        state.isLoading = true;
        state.deleteSubscriptionPlanData = {};
        state.updateSubscriptionPlanData = {};
        return state;
      })
      .addCase(deleteSubscriptionPlanAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.deleteSubscriptionPlanData = action.payload;
        state.updateSubscriptionPlanData = {};
        return state;
      })
      .addCase(deleteSubscriptionPlanAction.rejected, (state, action) => {
        state.isLoading = false;
        state.deleteSubscriptionPlanData = action.payload;
        state.updateSubscriptionPlanData = {};
      })
      .addCase(fetchSubscriptionPlanAction.pending, (state) => {
        state.isLoading = true;
        state.subscriptionPlanData = {};
        return state;
      })
      .addCase(fetchSubscriptionPlanAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subscriptionPlanData = action.payload;
        return state;
      })
      .addCase(fetchSubscriptionPlanAction.rejected, (state, action) => {
        state.isLoading = false;
        state.subscriptionPlanData = action.payload;
      });
  },
});

export const { isFromEditSubscription, isFromEditSubscriptionData } =
  adminSubscriptionSlice.actions;
export const adminSubscriptionPlanReducer = adminSubscriptionSlice.reducer;
