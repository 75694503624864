import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "@mui/material";
import { strings } from "../../utils/constants";
import { isEmpty } from "../../services/validation";
import { Toast } from "../Toast";
import { forgotPasswordOTPVerityAction } from "../../redux/Actions";
import { useDispatch, useSelector } from "react-redux";

export default function OtpVerification({
  email,
  otps,
  seconds,
  minutes,
  id,
  resendCodeHandler,
  isAdmin,
  setResetPassword,
  setOpenOTPVerification,
  setOTP,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.generalReducer);
  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        if (elmnt?.target?.value !== "") {
          return true;
        }
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next === 6 && elmnt?.target?.value !== "") {
        return true;
      } else if (next < 6 && elmnt?.target?.value !== "") {
        elmnt.target.form.elements[next].focus();
      }
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    if (
      isEmpty(otps.otp1) ||
      isEmpty(otps.otp2) ||
      isEmpty(otps.otp3) ||
      isEmpty(otps.otp4) ||
      isEmpty(otps.otp5) ||
      isEmpty(otps.otp6)
    ) {
      Toast({
        type: "error",
        title: strings.words.ERROR,
        message: t(strings.error.OTP_ERROR),
      });
    } else {
      if (isAdmin) {
        let requestInfo = {
          admin_id: id,
          code:
            otps.otp1 +
            otps.otp2 +
            otps.otp3 +
            otps.otp4 +
            otps.otp5 +
            otps.otp6,
        };

        let otpverify = await dispatch(
          forgotPasswordOTPVerityAction({
            language: strings.language.EN,
            isAdmin,
            requestInfo,
          })
        );

        if (otpverify.payload?.success) {
          setOpenOTPVerification(false);
          setResetPassword(true);
        } else if (otpverify.payload?.errors) {
          setOpenOTPVerification(true);
          setResetPassword(false);
          setOTP({
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
            otp6: "",
          });
        }
      } else {
        let requestInfo = {
          user_id: id,
          code:
            otps.otp1 +
            otps.otp2 +
            otps.otp3 +
            otps.otp4 +
            otps.otp5 +
            otps.otp6,
        };

        let otpverify = await dispatch(
          forgotPasswordOTPVerityAction({
            language: language,
            isAdmin,
            requestInfo,
          })
        );
        if (otpverify.payload?.success) {
          setOpenOTPVerification(false);
          setResetPassword(true);
        } else if (otpverify.payload?.errors) {
          setOpenOTPVerification(true);
          setResetPassword(false);
          setOTP({
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
            otp6: "",
          });
        }
      }
    }
  };

  const otpChangeHandler = (e, otpName) => {
    switch (otpName) {
      case "otp1":
        setOTP((prev) => {
          return { ...prev, otp1: e.target.value };
        });
        inputfocus(e);
        break;
      case "otp2":
        setOTP((prev) => {
          return { ...prev, otp2: e.target.value };
        });
        inputfocus(e);
        break;
      case "otp3":
        setOTP((prev) => {
          return { ...prev, otp3: e.target.value };
        });
        inputfocus(e);
        break;
      case "otp4":
        setOTP((prev) => {
          return { ...prev, otp4: e.target.value };
        });
        inputfocus(e);
        break;
      case "otp5":
        setOTP((prev) => {
          return { ...prev, otp5: e.target.value };
        });
        inputfocus(e);
        break;
      case "otp6":
        setOTP((prev) => {
          return { ...prev, otp6: e.target.value };
        });
        inputfocus(e);
        break;
      default:
        return;
    }
  };

  const keyDownHandler = (e, otpName) => {
    switch (otpName) {
      case "otp1":
        if (e.key === "Delete" || e.key === "Backspace") {
          setOTP((prev) => {
            return { ...prev, otp1: "" };
          });
        }
        inputfocus(e);
        break;
      case "otp2":
        if (e.key === "Delete" || e.key === "Backspace") {
          if (e.target.value !== "") {
            setOTP((prev) => {
              return { ...prev, otp2: "" };
            });
          } else {
            setOTP((prev) => {
              return { ...prev, otp1: "", otp2: "" };
            });
          }
        }
        inputfocus(e);
        break;
      case "otp3":
        if (e.key === "Delete" || e.key === "Backspace") {
          if (e.target.value !== "") {
            setOTP((prev) => {
              return { ...prev, otp3: "" };
            });
          } else {
            setOTP((prev) => {
              return { ...prev, otp3: "", otp2: "" };
            });
          }
        }
        inputfocus(e);
        break;
      case "otp4":
        if (e.key === "Delete" || e.key === "Backspace") {
          if (e.target.value !== "") {
            setOTP((prev) => {
              return { ...prev, otp4: "" };
            });
          } else {
            setOTP((prev) => {
              return { ...prev, otp3: "", otp4: "" };
            });
          }
        }
        inputfocus(e);
        break;
      case "otp5":
        if (e.key === "Delete" || e.key === "Backspace") {
          if (e.target.value !== "") {
            setOTP((prev) => {
              return { ...prev, otp5: "" };
            });
          } else {
            setOTP((prev) => {
              return { ...prev, otp4: "", otp5: "" };
            });
          }
        }
        inputfocus(e);
        break;
      case "otp6":
        if (e.key === "Delete" || e.key === "Backspace") {
          if (e.target.value !== "") {
            setOTP((prev) => {
              return { ...prev, otp6: "" };
            });
          } else {
            setOTP((prev) => {
              return { ...prev, otp5: "", otp6: "" };
            });
          }
        }
        inputfocus(e);
        break;
      default:
        return;
    }
  };
  return (
    <form>
      <div className="verification-code">
        <h1 className="font-30 font-lato_bold fg-primary mb-4">
          {t(strings.translate.VERIFICATION_CODE)}
        </h1>
        <p>
          {t(strings.translate.SENT_VERIFICATION_CODE_MESSAGE) +
            email +
            t(strings.translate.CHECK_VERIFICATION_CODE_MESSAGE)}
        </p>
        <div id="otp" className="d-flex mb-3">
          <input
            name="otp1"
            id="first"
            type="text"
            autoComplete="off"
            tabIndex="1"
            maxLength="1"
            value={otps.otp1}
            autoFocus
            className="form-control me-3 text-center"
            onChange={(e) => otpChangeHandler(e, "otp1")}
            onKeyDown={(e) => keyDownHandler(e, "otp1")}
          />
          <input
            name="otp2"
            id="second"
            type="text"
            autoComplete="off"
            tabIndex="2"
            maxLength="1"
            value={otps.otp2}
            className="form-control me-3 text-center"
            onChange={(e) => otpChangeHandler(e, "otp2")}
            onKeyDown={(e) => keyDownHandler(e, "otp2")}
          />
          <input
            name="otp3"
            id="third"
            type="text"
            autoComplete="off"
            tabIndex="3"
            maxLength="1"
            value={otps.otp3}
            className="form-control me-3 text-center"
            onChange={(e) => otpChangeHandler(e, "otp3")}
            onKeyDown={(e) => keyDownHandler(e, "otp3")}
          />
          <input
            name="otp4"
            id="fourth"
            type="text"
            autoComplete="off"
            tabIndex="4"
            maxLength="1"
            value={otps.otp4}
            className="form-control me-3 text-center"
            onChange={(e) => otpChangeHandler(e, "otp4")}
            onKeyDown={(e) => keyDownHandler(e, "otp4")}
          />
          <input
            name="otp5"
            id="fifth"
            type="text"
            autoComplete="off"
            tabIndex="5"
            maxLength="1"
            value={otps.otp5}
            className="form-control me-3 text-center"
            onChange={(e) => otpChangeHandler(e, "otp5")}
            onKeyDown={(e) => keyDownHandler(e, "otp5")}
          />
          <input
            name="otp6"
            id="sixth"
            type="text"
            autoComplete="off"
            tabIndex="6"
            maxLength="1"
            value={otps.otp6}
            className="form-control text-center"
            onChange={(e) => otpChangeHandler(e, "otp6")}
            onKeyDown={(e) => keyDownHandler(e, "otp6")}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between mb-3">
          {seconds > 0 || minutes > 0 ? (
            <>
              <p className="m-0">{t(strings.translate.TIME_REMAINING)}</p>
              <span className="timer-span position-static">
                {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </span>
            </>
          ) : (
            <p className="m-0">
              {t(strings.translate.DID_NOT_RECIVED_YET)}
              <Link
                href="#"
                className="fg-secondary"
                onClick={resendCodeHandler}
              >
                {t(strings.translate.RESEND_CODE)}
              </Link>
            </p>
          )}
        </div>
        <button
          className="btn btn--primary text-uppercase mt-3"
          id="submitCode"
          href=""
          onClick={submitHandler}
        >
          {t(strings.words.SUBMIT)}
        </button>
      </div>
    </form>
  );
}
