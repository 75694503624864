import { createSlice } from "@reduxjs/toolkit";
import { fetchCountryAction } from "../Actions";

const FETCH_COUNTRY_SLICE_NAME = "fetchCountryDetails";

const fetchCountryInitialState = {
  fetchCountry: {},
  isCountryLoading: false,
};

const fetchCountrySlice = createSlice({
  name: FETCH_COUNTRY_SLICE_NAME,
  initialState: fetchCountryInitialState,
  reducers: {},
  extraReducers: (builder) => {
    //@ts-ignore
    builder
      .addCase(fetchCountryAction.pending, (state) => {
        state.isCountryLoading = true;
        state.fetchCountry = {};
        return state;
      })
      .addCase(
        fetchCountryAction.fulfilled,
        (state, action) => {
          state.isCountryLoading = false;
          state.fetchCountry = action.payload;
          return state;
        }
      )
      .addCase(
        fetchCountryAction.rejected,
        (state, action) => {
          state.isCountryLoading = false;
          state.fetchCountry = action.payload;
        }
      );
  },
});

export const {} = fetchCountrySlice.actions;
export const fetchCountryReducer = fetchCountrySlice.reducer;
